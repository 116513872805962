<google-map
    id="map"
    height="100%"
    width="100%"
    [zoom]="zoom"
    [center]="center"
    [options]="mapOptions"
    (zoomChanged)="onZoomChanged()"
    (centerChanged)="onCenterChanged()"
    (dblclick)="mapDlclick($event)"
    (mapClick)="mapClick($event)"
>
    <div *ngIf="!loading">
        <div *ngIf="footprintMarkers && footprintMarkers.size > 0">
            <map-marker
                *ngFor="let marker of footprintMarkers; let i = index"
                [position]="marker.getPosition()!"
                [title]="marker.getTitle()!"
                [options]="footprintMarkerOption"
            >
            </map-marker>
            <map-polyline style="z-index: 3" [options]="polylineOptions"> </map-polyline>
            <map-marker
                *ngFor="let marker of endPositionMarkers; let i = index"
                [position]="marker.getPosition()!"
                [title]="marker.getTitle()!"
                [options]="endMarkerOption"
            >
            </map-marker>
            <map-marker
                *ngFor="let marker of startPositionMarkers; let i = index"
                [position]="marker.getPosition()!"
                [title]="marker.getTitle()!"
                [options]="startMarkerOption"
            >
            </map-marker>
        </div>

        <div *ngIf="userActionMarkers && userActionMarkers.size > 0">
            <map-polyline  *ngIf="polylineHighlightOptions.path.length > 0"
                style="z-index: 4" [options]="polylineHighlightOptions"> 
            </map-polyline>
            <map-marker
                *ngFor="let marker of userActionMarkers; let i = index"
                [position]="marker.getPosition()!"
                [title]="marker.getTitle()!"
                [options]="i === highlightedIndex ? highlightedMarkerOptions : userActionMarkerOption"
                (mapClick)="onClickedUserActionMarker($event, marker)"
                (mapMouseover)="onMarkerHover($event, marker, i)"
                (mapMouseout)="onMarkerLeave($event, marker)"
            >
            </map-marker>

            <!-- Floating list of cards -->
            <div *ngIf="showFloatingList && (cardListTasks && cardListTasks.length > 0 || cardListItacs && cardListItacs.length > 0)" 
                [style.top.px]="clickPosition.y" 
                [style.left.px]="clickPosition.x" 
                class="floating-card-list"
                [ngClass]="{
                    'show-above': !isListAbove,
                    'show-below': isListAbove,
                    'show-left': !isListLeft,
                    'show-right': isListLeft
                }"
            >
                <mat-card
                    *ngFor="let task of cardListTasks; let i = index"
                    class="home-card"
                    (click)="selectedUserActionTask($event, i)"
                    [matTooltip]="getCardTaskTooltip(task)"
                    matTooltipClass="wider-mat-tooltip"
                >
                    <mat-card-content class="home-card-content">
                        <img
                            class="mat-card-image"
                            mat-card-image
                            [src]="getTaskImage(task)"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                            alt="Photo of Task"
                        />
                        <div class="card-info">
                            <div *ngIf="getDirOfTask(task).length < 40">
                                <b>{{ getDirOfTask(task) }}</b>
                            </div>
                            <marquee *ngIf="getDirOfTask(task).length >= 40">
                                <b>{{ getDirOfTask(task) }}</b>
                            </marquee>
                            <div>{{ getTaskSubscriberTextLine(task) }}</div>
                            <div>{{ getTaskCounterTextLine(task) }}</div>
                        </div>
                    </mat-card-content>
                    <div class="tag-line">
                        <mat-icon>date_range_rounded</mat-icon>
                        <div>
                            {{
                                task.cita_pendiente ? 'CITA ' + (task.fecha_hora_cita | fieldPipe) : 'MOD ' + (task.date_time_modified | fieldPipe)
                            }}
                        </div>
                    </div>
                    <div
                        class="priority-circle"
                        [ngStyle]="{ 'background-color': getPriorityColor(task) }"
                    ></div>
                </mat-card>                
                <mat-card
                    *ngFor="let itac of cardListItacs; let i = index"
                    class="home-card"
                    (click)="selectedUserActionItac($event, i)"
                    [matTooltip]="getCardItacTooltip(itac)"
                    matTooltipClass="wider-mat-tooltip"
                >
                    <mat-card-content class="home-card-content">
                        <img
                            mat-card-image
                            [src]="getItacImage(itac)"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                            alt="Photo of Task"
                        />
                        <div class="card-info">
                            <div *ngIf="itac.itac && itac.itac.length < 40">
                                <b>{{ itac.itac }}</b>
                            </div>
                            <marquee *ngIf="itac.itac && itac.itac.length >= 40">
                                <b>{{ itac.itac }}</b>
                            </marquee>
                            <div>{{ itac.acceso }}</div>
                            <div>{{ itac.codigo_itac }}</div>
                        </div>
                    </mat-card-content>
                    <div class="tag-line">
                        <mat-icon>date_range_rounded</mat-icon>
                        <div>
                            {{
                                itac.cita_pendiente ? 'CITA ' + (itac.fecha_hora_cita | fieldPipe) : 'MOD ' + (itac.date_time_modified | fieldPipe)
                            }}
                        </div>
                    </div>
                    <div
                        class="priority-circle"
                        [ngStyle]="{ 'background-color': getPriorityColor(itac) }"
                    ></div>
                </mat-card>
            </div>

            <mat-card class="card-action-data" *ngIf="selectedImagePosition"
                [ngClass]="{
                    'show-above': !isAbove,
                    'show-below': isAbove,
                    'show-left': !isLeft,
                    'show-right': isLeft
                }"
                [style.top.px]="selectedImagePosition.y" 
                [style.left.px]="selectedImagePosition.x"
            >
                <div *ngIf="selectedImage">
                    <button mat-icon-button 
                        color="primary" aria-label="Previous Image" class="switch-image-button left"
                        matTooltip="Anterior foto"
                        (click)="switchImage('right')">
                    <mat-icon>chevron_left</mat-icon>
                    </button>
                    <img [src]="selectedImage" 
                        onerror="this.onerror=null; this.src='assets/img/noimage.png'" 
                        alt="Marker Image"
                        />
                    <button 
                        mat-icon-button color="primary" aria-label="Next Image" class="switch-image-button right"
                        matTooltip="Siguiente foto"
                        (click)="switchImage('left')">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
                <div *ngIf="actionText">
                    <span>{{ actionText }}</span>
                </div>
                <div class="buttons-container">
                    <button *ngIf="selectedActions && selectedActions.length > 1"
                        mat-raised-button color="primary" 
                        aria-label="Next Action" class="switch-action-button"
                        matTooltip="Anterior acción"
                        (click)="switchAction('left')">
                        <mat-icon>chevron_left</mat-icon>
                    </button>
                    <div *ngIf="selectedTask">
                        <button mat-raised-button color="primary" alt="Open task" 
                        (click)="openTask();"
                        >ABRIR TAREA</button>
                    </div>
                    <div *ngIf="selectedItac">
                        <button mat-raised-button color="primary" alt="Open itac"
                        (click)="openItac();"
                        >ABRIR ITAC</button>
                    </div>
                    <button *ngIf="selectedActions && selectedActions.length > 1"
                        mat-raised-button color="primary" 
                        aria-label="Previous Action" class="switch-action-button"
                        matTooltip="Siguiente acción"
                        (click)="switchAction('right')">
                        <mat-icon>chevron_right</mat-icon>
                    </button>
                </div>
            </mat-card>

            <map-polyline style="z-index: 3" [options]="userActionPolylineOptions"> </map-polyline>
            <map-marker
                *ngFor="let marker of endUserActionPositionMarkers; let i = index"
                [position]="marker.getPosition()!"
                [title]="marker.getTitle()!"
                [options]="endMarkerOption"
                (mapClick)="onClickedUserActionMarker($event, marker)"
            >
            </map-marker>
            <map-marker
                *ngFor="let marker of startUserActionPositionMarkers; let i = index"
                [position]="marker.getPosition()!"
                [title]="marker.getTitle()!"
                [options]="startMarkerOption"
                (mapClick)="onClickedUserActionMarker($event, marker)"
            >
            </map-marker>
        </div>

        <map-marker
            #userMarkerElem
            *ngFor="let marker of usersMarkers; let i = index"
            [position]="marker.getPosition()!"
            [title]="marker.getTitle()!"
            [options]="userMarkerOption"
            (mapClick)="onClickedUserMarker(marker)"
        >
        </map-marker>
    </div>
    <map-info-window>Hello Google Maps</map-info-window>
</google-map>

<div class="search-input">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Ubicación</mat-label>
        <input
            type="text"
            placeholder="Seleccione lugar ..."
            aria-label="Number"
            matInput
            [formControl]="inputSearchControl"
            [matAutocomplete]="auto"
        />
        <mat-autocomplete #auto="matAutocomplete">
            <mat-option
                *ngFor="let prediction of placePredictions"
                [value]="prediction.description"
                [matTooltip]="prediction.description"
                (click)="searchPlace(prediction)"
            >
                {{ prediction.description }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>
</div>

<div class="top-left-button-filter div_background_slider">
    <mat-slide-toggle color="primary" (change)="onMap3D($event)">Mapa en 3D</mat-slide-toggle>
</div>

<div class="top-left-button-filter div_background_slider user_actions_button">
    <button
        mat-raised-button
        color="primary" 
        (click)="showUserActions()">Ver acciones
    </button>
</div>

<div *ngIf="_electronService.isElectronApp()" class="floating-top-left" (click)="goBack()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<ngx-spinner
    [bdColor]="user ? 'rgba(0, 0, 0, 0.3)' : 'rgba(255, 255, 255, 1)'"
    size="large"
    color="#43B0FF"
    type="ball-scale-multiple"
    [fullScreen]="true"
    name="mapSpinner"
>
</ngx-spinner>
