/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormControl, FormGroup } from '@angular/forms';

export interface OptionsDialogData {
    placeHolderText: '';
    options: [];
    enableSearch: boolean;
}

@Component({
    selector: 'app-selector',
    templateUrl: './selector.component.html',
    styleUrls: ['./selector.component.scss'],
})
export class SelectorComponent implements OnInit {

    formGroup: FormGroup = new FormGroup({
        search_value: new FormControl(),
    });

    @Input() placeHolderText = '';
    @Input() options = [];
    originalOptions = [];
    enableSearch = false;

    /**
     * Constructor for SelectorComponent.
     * 
     * @param data - The data injected into the dialog, containing options and settings.
     * 
     * Initializes the component with the provided data, setting placeholder text, options, 
     * and enabling search functionality if specified. If search is enabled, sets up a 
     * subscription to filter options based on the search value.
     * 
     * @param data.placeHolderText - The placeholder text for the selector.
     * @param data.options - The list of options to display in the selector.
     * @param data.enableSearch - A boolean indicating whether search functionality is enabled.
     */
    constructor(
        @Inject(MAT_DIALOG_DATA) public data: OptionsDialogData,
    ) {
        this.placeHolderText = data.placeHolderText;
        this.options = data.options;
        this.originalOptions = [...data.options];
        this.enableSearch = data.enableSearch;
        
        if (this.enableSearch) {
            this.formGroup.controls['search_value'].valueChanges.subscribe((value: string) => {
                if(value){
                    this.options = this.originalOptions.filter((option: string) => option.toLowerCase().includes(value.toLowerCase()));
                }
                else this.options = [...this.originalOptions];
            });
        }
    }

    /**
     * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
     * Define an `ngOnInit` method to handle any additional initialization tasks.
     */
    ngOnInit(): void {}
}
