<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="callLogFormData" class="animate__animated animate__fadeIn">
        <div>
            <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px; display: flex;">                        
                <p style="font-size: large;">Datos de la llamada</p>
                <div style="flex: 2"></div>
                <button
                    mat-flat-button
                    aria-label="Phone"
                    style="margin-top: 0px; margin-right: 10px"
                    matTooltip="Llamar a cliente"
                    (click)="makeCall()"
                    >
                    <mat-icon color="primary">phone</mat-icon>
                    <span style="margin-left: 10px">Llamar</span>
                </button>
            </div> 
            <div class="container">
                <div class="row-content">
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>Número de Cliente</mat-label>
                        <input formControlName="client_number" matInput type="text" placeholder="Número de Cliente" readonly>
                    </mat-form-field>
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>Número de Empresa</mat-label>
                        <input formControlName="company_number" matInput type="text" placeholder="Número de Empresa" readonly>
                    </mat-form-field>
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>UUID de Conversación</mat-label>
                        <input formControlName="conversation_uuid" matInput type="text" placeholder="UUID de Conversación" readonly>
                    </mat-form-field>
                </div>
                <div class="row-content">
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>Estado de Llamada</mat-label>
                        <input formControlName="call_status" matInput type="text" placeholder="Estado de Llamada" readonly>
                    </mat-form-field>
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>Comentarios</mat-label>
                        <textarea matInput formControlName="comments" placeholder="Comentarios..." cdkTextareaAutosize></textarea>
                    </mat-form-field>
                </div>
                <div class="row-content">
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>Fecha de Creación</mat-label>
                        <input matInput [matDatepicker]="picker_created_at" formControlName="created_at" placeholder="Fecha de Creación" readonly>
                        <mat-datepicker #picker_created_at></mat-datepicker>
                    </mat-form-field>
                    <mat-form-field style="width: 200px;" appearance="fill">
                        <mat-label>Fecha de Modificación</mat-label>
                        <input matInput [matDatepicker]="picker_date_time_modified" formControlName="date_time_modified" placeholder="Fecha de Modificación" readonly>
                        <mat-datepicker #picker_date_time_modified></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner [bdColor]="(callLog)?'rgba(0, 0, 0, 0.2)': 'rgba(255, 255, 255, 1)'" size="large" color="#368DCE"
                 type="ball-scale-multiple" [fullScreen]="false" name="callLogSpinner">
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="callLog">Cancelar</button>
    <button mat-button cdkFocusInitial matTooltip="Guardar cambios" (click)="saveChanges()">
        <mat-icon color="primary">check</mat-icon> {{ (callLogId)?'Actualizar':'Añadir' }}
    </button>
</mat-dialog-actions>
