import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ClientPhoneStatus } from './client-phone-status';
import { ServicesTasks } from './services-tasks';
import { SuppliesTasks } from './supplies-tasks';
import { MyLatLng } from './lat-lng';
import { MiRutaUser } from './mi-ruta-user';
import { FreeMessages } from './free-messages';
import { FechasNoContesta } from './fechas-no-contesta';
import { FechasTocadoPuerta } from './fechas-tocado-puerta';
import { FechasNotaAviso } from './fechas-nota-aviso';
import { PartsTasks } from './parts-tasks';
import { ReturnDetails } from './return-details';
import Geohash from 'latlon-geohash';
import { TaskLocationInfo } from './task-location-info';
import { DateStatus } from './date-status';
import { Sector } from './sector';
import { PlanningDetailExtras } from './planning_detail_extras';

export const MAX_SIZE_WARNING: number = 120;
export const defaultAnomaly: string = 'Z21';
export const defaultTaskType: string = 'NCRI';
export const defaultTaskIntervention: string = 'Renovación Periódica del CDT y Emisor';
export const defaultTaskOrderAction: string = 'NUEVO CONTADOR Y RADIO INSTALAR';
export const defaultTaskToDo: string = 'SUSTITUIR CO';


export const bilbaoCenter: any = {
    lat: 43.2633182,
    lng: -2.9349041608216098
}

/**
 * Represents the status of an order.
 */
export enum order_status {
    DAILY,
    MASIVE,
    SPECIAL,
}

/**
 * Represents the priority status of a water task.
 */
export enum priority_status {
    HIBERNATE,
    LOW,
    MEDIUM,
    HIGH,
}

/**
 * Represents the status of a task.
 */
export enum task_status {
    REQUIRED,
    IDLE,
    DONE,
    CLOSED,
    INFORMED,
    INCIDENCE,
    TRASHED,
}

/**
 * Represents the days of the week for a task.
 */
export enum task_block {
    MONDAY = 1,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
}

/**
 * Represents a water task.
 */
export interface WaterTask {
    ID?: number; //useless only for displaying info
    id?: number; //server id
    ACCESO?: string;
    acceso_devuelto?: string;
    ACTIVI?: string;
    ANOMALIA?: string;
    AREALIZAR?: string;
    AREALIZARDV?: string;
    accion_ordenada?: string;
    audio_detalle?: string;
    audio_detalle_server_path?: string;
    BIS?: string;
    CALIBRE?: string;
    CALIBREDV?: string;
    CALLE?: string;
    CODLEC?: number;
    COMENTARIOS?: string;
    CONTADOR?: string;
    C_AGRUPA?: string;
    C_CANAL?: string;
    C_COMUNERO?: string;
    C_LYC?: string;
    causa_origen?: string;
    codigo_de_geolocalizacion?: string; //codigo de emplazamiento
    codigo_de_localizacion?: MyLatLng;
    contadordv?: string;
    company?: number;
    DNI_CIF_ABONADO?: string;
    DNI_CIF_COMUNIDAD?: string;
    date_time_modified?: Date;
    EMPLAZA?: string;
    EMPLAZADV?: string;
    equipo?: number;
    escalera?: string;
    Estado?: string;
    FECEMISIO?: Date;
    FECH_CIERRE?: Date;
    FECINST?: Date;
    FECULTREP?: Date;
    F_INST?: Date;
    f_instnew?: Date;
    FechImportacion?: Date;
    fech_cierrenew?: Date;
    fech_facturacion?: Date;
    fech_informacionnew?: Date;
    fecha_de_cambio?: Date;
    fecha_hora_cita?: Date;
    fecha_hora_cita_end?: Date;
    fecha_informe_servicios?: Date;
    fecha_realizacion?: Date;
    fechas_nota_aviso?: FechasNotaAviso[];
    fechas_tocado_puerta?: FechasTocadoPuerta[];
    fechas_no_contesta?: FechasNoContesta[];
    firma_cliente?: string;
    foto_antes_instalacion?: string;
    foto_despues_instalacion?: string;
    foto_incidencia_1?: string;
    foto_incidencia_2?: string;
    foto_incidencia_3?: string;
    foto_lectura?: string;
    foto_numero_serie?: string;
    foto_entorno?: string;
    firma_cliente_server_path?: string;
    foto_antes_instalacion_server_path?: string;
    foto_despues_instalacion_server_path?: string;
    foto_incidencia_1_server_path?: string;
    foto_incidencia_2_server_path?: string;
    foto_incidencia_3_server_path?: string;
    foto_lectura_server_path?: string;
    foto_numero_serie_server_path?: string;
    foto_entorno_server_path?: string;
    GESTOR?: number;
    geolocalizacion?: MyLatLng;
    hibernacion?: boolean;
    ID_FINCA?: string;
    ID_SAT?: number;
    INDICE?: string;
    INTERVENCI?: string;
    idOrdenCABB?: number;
    idexport?: number;
    incidencia?: string;
    intervencidv?: string;
    LARGO?: string;
    LECTURA_CONTADOR_NUEVO?: number;
    LECT_LEV?: number;
    LONGDV?: string;
    MANO?: string;
    MARCA?: string;
    MARCADV?: string;
    MENSAJE_LIBRE?: FreeMessages[];
    MUNICIPIO?: string;
    marcaR?: string;
    NOMBRE_ABONADO?: string;
    NOMBRE_FIRMANTE?: string;
    NUME?: string;
    NUMERO_CARNET_FIRMANTE?: string;
    NUMIN?: string;
    nuevo_citas?: string;
    Numero_de_ABONADO?: string;
    numero_serie_modulo?: string;
    OBSERVA?: string;
    OBSERVADV?: ReturnDetails[];
    OPERARIO?: MiRutaUser[];
    observaciones?: string;
    PISO?: string;
    PROPIEDAD?: string;
    piezas?: PartsTasks[];
    prioridad?: number;
    REPARACION?: string;
    RESTEMPLAZA?: string;
    RESTO_EM?: string;
    RS?: string;
    RUEDAS?: string;
    RUEDASDV?: string;
    resultado?: string;
    ruta?: string;
    SERIE?: string;
    seriedv?: string;
    servicios?: ServicesTasks[];
    last_service?: string;
    status_tarea?: number;
    suministros?: SuppliesTasks[];
    TARIFA?: string;
    TIPO?: string; //tipo o clase de contador
    TIPOFLUIDO?: string;
    TIPOFLUIDO_DEVUELTO?: string;
    TIPORADIO_DEVUELTO?: string;
    TIPORDEN?: number;
    TIPO_DEVUELTO?: string; //tipo o clase de contador DEVUELTO
    TOTAL_CONTADORES?: string;
    thumbnail_type?: string;
    thumbnail?: string;
    thumbnail_server_path?: string;
    telefono1?: string;
    telefono2?: string;
    telefonos_cliente?: ClientPhoneStatus[];
    tipoRadio?: string;
    tipo_tarea?: string;
    ubicacion_en_bateria?: string;
    url_geolocalizacion?: string;
    zona?: string;
    upload_pendent?: boolean;
    incidence?: boolean;
    absent?: boolean;
    contador_bateria?: boolean;
    cita_pendiente?: boolean;
    end_hibernation_date?: Date;
    end_hibernation_priority?: number;
    geolocation_details?: string;
    last_modification_operator_uid?: string;
    numero_precinto?: string;
    bloque?: string;
    ultima_modificacion?: string; //Last modification user
    last_modification_android?: boolean;
    control_digit?: string;
    date_status?: DateStatus;
    date_modification_android?: Date;
    planning?: string;
    planning_details?: string;
    planning_date_modified?: Date;
    ORD_RUT?: number;
    sectors?: Sector[];
    pendent_location?: boolean;
    serial_old_changed?: boolean;
    planning_detail_extras?: PlanningDetailExtras[];
}

/**
 * Retrieves an array of column names to be displayed.
 * @returns An array of column names.
 */
export function getDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID', //useless only for displaying info
        'idOrden',
        'Fecha Emisión',
        'Fecha Modificación',
        'CAUSA ORIGEN',
        'ACCION ORD.',
        'C.ACCION ORD.',
        'AREALIZAR',
        'DATOS ESPECIFICOS',
        'INTERVENCIÓN',
        'PROP.',
        'AÑO o PREFIJO',
        'SERIE',
        'MARCA',
        'CALIBRE',
        'RUEDAS',
        'FECINST',
        'ACTIVI',
        'EMPLAZA',
        'ACCESO',
        'ACCESODV',
        'CALLE',
        'NUME',
        'ESCALERA',
        'BIS',
        'PISO',
        'MANO',
        'MUNICIPIO',
        'CITA',
        'FIN DE CITA',
        'TELEFONO 1',
        'TELEFONO 2',
        'NOMBRE',
        'ABONADO',
        'CODLEC',
        'RESTO_EM',
        'LECT_LEV',
        'MENSAJE LIBRE',
        'SECTOR P',
        'F_EJEC',
        'RESULTADO',
        'ESTADO',
        'MARCADV',
        'CALIBREDV',
        'RUEDASDV',
        'LONGDV',
        'PREFIJO DV',
        'seriedv',
        'DÍGITO DE CONTROL',
        'CAUSA DESTINO',
        'intervencidv',
        'FECH_CIERRE',
        'TIPORDEN',
        'EQUIPO',
        'OPERARIO',
        'MODIFICADOR',
        'PRIORIDAD',
        'TIPOFLUIDO',
        'tipoRadio',
        'REQUERIDA',
        'idexport',
        'fech_cierrenew',
        'SERVICIO',
        'SUMINISTROS',
        'fech_informacionnew',
        'Módulo',
        'Número de Precinto',
        'Bloque',
        'Ubicacion en batería',
        'C.EMPLAZAMIENTO',
        'Geolocalización',
        'PLANIFICACIÓN',
        'MODIFICACIÓN ANDROID',
        'ORD_RUT',
        'PERIMETROS',
        'foto_antes_instalacion',
        'foto_lectura',
        'foto_numero_serie',
        'foto_despues_instalacion',
        'foto_entorno',
    ];
    return displayedColumns;
}

/**
 * Retrieves the list of client display columns.
 * 
 * @returns An array of strings representing the display columns.
 */
export function getClientDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'MUNICIPIO',
        'CALLE',
        'PORTAL',
        'BIS',
        'PISO',
        'MANO',
        'ABONADO',
        'NOMBRE',
        'TELEFONO 1',
        'TELEFONO 2',
        'SERIE LEV.',
        'CALIBRE',
        'LECTURA LEV.',
        'SERIE INST.',
        'CALIBREDV',
        'LECTURA INST.',
        'ESTADO',
        'C.EMPLAZAMIENTO',
        'Geolocalización',
    ];
    return displayedColumns;
}

/**
 * Creates a Date object from a string representation of a date.
 * If the string is empty or null, it returns null.
 * If the resulting date is January 1, 1970, it returns null.
 * @param dateString - The string representation of the date.
 * @returns A Date object representing the parsed date, or null if the string is empty, null, or the resulting date is January 1, 1970.
 */
export function createDateWithString(dateString: string): Date | null {
    const date = dateString ? new Date(dateString) : null;
    if (date) {
        if (date.getDate() == 1 && date.getMonth() == 0 && date.getFullYear() == 1970) return null;
        return date;
    }
    return null;
}

/**
 * Converts data from the server to a TaskLocationInfo object.
 * @param data - The data to be converted.
 * @returns The converted TaskLocationInfo object.
 */
export function convertFromTaskLocationInfoServer(data: any): TaskLocationInfo {
    let codigo_de_localizacion;
    try {
        codigo_de_localizacion = JSON.parse(data['codigo_de_localizacion']) as MyLatLng;
    } catch (err) {
        codigo_de_localizacion = data['codigo_de_localizacion'];
    }
    let geolocalizacion;
    try {
        geolocalizacion = JSON.parse(data['geolocalizacion']) as MyLatLng;
    } catch (err) {
        geolocalizacion = data['geolocalizacion'];
    }
    let taskLocationInfo = {
        codigo_de_localizacion: codigo_de_localizacion,
        geolocalizacion: geolocalizacion,
        url_geolocalizacion: data['url_geolocalizacion'],
        codigo_de_geolocalizacion: data['codigo_de_geolocalizacion'],
        zona: data['zona'],
    }
    return taskLocationInfo;
}

/**
 * Converts the server data object to a WaterTask object.
 * 
 * @param data - The server data object.
 * @returns The converted WaterTask object.
 */
export function convertFromServer(data: any): any {
    let codigo_de_localizacion;
    try {
        codigo_de_localizacion = JSON.parse(data['codigo_de_localizacion']) as MyLatLng;
    } catch (err) {
        codigo_de_localizacion = data['codigo_de_localizacion'];
    }
    let geolocalizacion;
    try {
        geolocalizacion = JSON.parse(data['geolocalizacion']) as MyLatLng;
    } catch (err) {
        geolocalizacion = data['geolocalizacion'];
    }
    let waterTask = {
        ACCESO: data['ACCESO'],
        acceso_devuelto: data['acceso_devuelto'],
        ACTIVI: data['ACTIVI'],
        ANOMALIA: data['ANOMALIA'],
        AREALIZAR: data['AREALIZAR'],
        AREALIZARDV: data['AREALIZARDV'],
        accion_ordenada: data['accion_ordenada'],
        audio_detalle: data['audio_detalle'],
        audio_detalle_server_path: data['audio_detalle_server_path'],
        BIS: data['BIS'],
        CALIBRE: data['CALIBRE'],
        CALIBREDV: data['CALIBREDV'],
        CALLE: data['CALLE'],
        CODLEC: data['CODLEC'],
        COMENTARIOS: data['COMENTARIOS'],
        CONTADOR: data['CONTADOR'],
        C_AGRUPA: data['C_AGRUPA'],
        C_CANAL: data['C_CANAL'],
        C_COMUNERO: data['C_COMUNERO'],
        C_LYC: data['C_LYC'],
        causa_origen: data['causa_origen'],
        codigo_de_geolocalizacion: data['codigo_de_geolocalizacion'], //codigo de emplazamiento
        codigo_de_localizacion: codigo_de_localizacion,
        contadordv: data['contadordv'],
        company: data['company'],
        DNI_CIF_ABONADO: data['DNI_CIF_ABONADO'],
        DNI_CIF_COMUNIDAD: data['DNI_CIF_COMUNIDAD'],
        date_time_modified: new Date(data['date_time_modified']),
        EMPLAZA: data['EMPLAZA'],
        EMPLAZADV: data['EMPLAZADV'],
        equipo: data['equipo'],
        escalera: data['escalera'],
        Estado: data['Estado'],
        FECEMISIO: createDateWithString(data['FECEMISIO']),
        FECH_CIERRE: createDateWithString(data['FECH_CIERRE']),
        FECINST: createDateWithString(data['FECINST']),
        FECULTREP: createDateWithString(data['FECULTREP']),
        F_INST: createDateWithString(data['F_INST']),
        f_instnew: createDateWithString(data['f_instnew']),
        FechImportacion: createDateWithString(data['FechImportacion']),
        fech_cierrenew: createDateWithString(data['fech_cierrenew']),
        fech_facturacion: createDateWithString(data['fech_facturacion']),
        fech_informacionnew: createDateWithString(data['fech_informacionnew']),
        fecha_de_cambio: createDateWithString(data['fecha_de_cambio']),
        fecha_hora_cita: createDateWithString(data['fecha_hora_cita']),
        fecha_hora_cita_end: createDateWithString(data['fecha_hora_cita_end']),
        fecha_informe_servicios: createDateWithString(data['fecha_informe_servicios']),
        fecha_realizacion: createDateWithString(data['fecha_realizacion']),
        fechas_nota_aviso: data['fechas_nota_aviso'],
        fechas_tocado_puerta: data['fechas_tocado_puerta'],
        fechas_no_contesta: data['fechas_no_contesta'],
        thumbnail_type: data['thumbnail_type'],
        thumbnail: data['thumbnail'],
        thumbnail_server_path: data['thumbnail_server_path'],
        firma_cliente: data['firma_cliente'],
        foto_antes_instalacion: data['foto_antes_instalacion'],
        foto_despues_instalacion: data['foto_despues_instalacion'],
        foto_incidencia_1: data['foto_incidencia_1'],
        foto_incidencia_2: data['foto_incidencia_2'],
        foto_incidencia_3: data['foto_incidencia_3'],
        foto_lectura: data['foto_lectura'],
        foto_numero_serie: data['foto_numero_serie'],
        foto_entorno: data['foto_entorno'],
        firma_cliente_server_path: data['firma_cliente_server_path'],
        foto_antes_instalacion_server_path: data['foto_antes_instalacion_server_path'],
        foto_despues_instalacion_server_path: data['foto_despues_instalacion_server_path'],
        foto_incidencia_1_server_path: data['foto_incidencia_1_server_path'],
        foto_incidencia_2_server_path: data['foto_incidencia_2_server_path'],
        foto_incidencia_3_server_path: data['foto_incidencia_3_server_path'],
        foto_lectura_server_path: data['foto_lectura_server_path'],
        foto_numero_serie_server_path: data['foto_numero_serie_server_path'],
        foto_entorno_server_path: data['foto_entorno_server_path'],
        GESTOR: data['GESTOR'],
        geolocalizacion: geolocalizacion,
        hibernacion: data['hibernacion'] ? true : false,
        ID_FINCA: data['ID_FINCA'],
        ID_SAT: data['ID_SAT'],
        INDICE: data['INDICE'],
        INTERVENCI: data['INTERVENCI'],
        id: data['id'],
        idOrdenCABB: data['idOrdenCABB'],
        idexport: data['idexport'],
        incidencia: data['incidencia'],
        intervencidv: data['intervencidv'],
        LARGO: data['LARGO'],
        LECTURA_CONTADOR_NUEVO: data['LECTURA_CONTADOR_NUEVO'],
        LECT_LEV: data['LECT_LEV'],
        LONGDV: data['LONGDV'],
        MANO: data['MANO'],
        MARCA: data['MARCA'],
        MARCADV: data['MARCADV'],
        MENSAJE_LIBRE: data['MENSAJE_LIBRE'],
        MUNICIPIO: data['MUNICIPIO'],
        marcaR: data['marcaR'],
        NOMBRE_ABONADO: data['NOMBRE_ABONADO'],
        NOMBRE_FIRMANTE: data['NOMBRE_FIRMANTE'],
        NUME: data['NUME'],
        NUMERO_CARNET_FIRMANTE: data['NUMERO_CARNET_FIRMANTE'],
        NUMIN: data['NUMIN'],
        nuevo_citas: data['nuevo_citas'],
        Numero_de_ABONADO: data['Numero_de_ABONADO'],
        numero_serie_modulo: data['numero_serie_modulo'],
        OBSERVA: data['OBSERVA'],
        OBSERVADV: data['OBSERVADV'],
        OPERARIO: data['OPERARIO'],
        sectors: data['sectors'],
        observaciones: data['observaciones'],
        PISO: data['PISO'],
        PROPIEDAD: data['PROPIEDAD'],
        piezas: data['piezas'],
        prioridad: data['prioridad'],
        REPARACION: data['REPARACION'],
        RESTEMPLAZA: data['RESTEMPLAZA'],
        RESTO_EM: data['RESTO_EM'],
        RS: data['RS'],
        RUEDAS: data['RUEDAS'],
        RUEDASDV: data['RUEDASDV'],
        resultado: data['resultado'],
        ruta: data['ruta'],
        SERIE: data['SERIE'],
        seriedv: data['seriedv'],
        servicios: data['servicios'],
        last_service: data['last_service'],
        status_tarea: data['status_tarea'],
        suministros: data['suministros'],
        TARIFA: data['TARIFA'],
        TIPO: data['TIPO'], //tipo o clase de contador
        TIPOFLUIDO: data['TIPOFLUIDO'],
        TIPOFLUIDO_DEVUELTO: data['TIPOFLUIDO_DEVUELTO'],
        TIPORADIO_DEVUELTO: data['TIPORADIO_DEVUELTO'],
        TIPORDEN: data['TIPORDEN'],
        TIPO_DEVUELTO: data['TIPO_DEVUELTO'], //tipo o clase de contador DEVUELTO
        TOTAL_CONTADORES: data['TOTAL_CONTADORES'],
        telefono1: data['telefono1'],
        telefono2: data['telefono2'],
        telefonos_cliente: data['telefonos_cliente'],
        tipoRadio: data['tipoRadio'],
        tipo_tarea: data['tipo_tarea'],
        ubicacion_en_bateria: data['ubicacion_en_bateria'],
        url_geolocalizacion: data['url_geolocalizacion'],
        zona: data['zona'],
        upload_pendent: data['upload_pendent'] ? true : false,
        incidence: data['incidence'] ? true : false,
        absent: data['absent'] ? true : false,
        contador_bateria: data['contador_bateria'] ? true : false,
        cita_pendiente: data['cita_pendiente'] ? true : false,
        end_hibernation_date: createDateWithString(data['end_hibernation_date']),
        end_hibernation_priority: data['end_hibernation_priority'],
        geolocation_details: data['geolocation_details'],
        last_modification_operator_uid: data['last_modification_operator_uid'],
        numero_precinto: data['numero_precinto'],
        control_digit: data['control_digit'],
        bloque: data['bloque'],
        ultima_modificacion: data['ultima_modificacion'],
        last_modification_android: data['last_modification_android'] ? true : false,
        date_status: data['date_status'],
        date_modification_android: createDateWithString(data['date_modification_android']),
        planning: data['planning'],
        planning_details: data['planning_details'],
        planning_date_modified: createDateWithString(data['planning_date_modified']),
        ORD_RUT: data['ORD_RUT'],
        pendent_location: data['pendent_location'] ? true : false,
        serial_old_changed: data['serial_old_changed'] ? true : false,
        planning_detail_extras: data['planning_detail_extras'],
    };
    return waterTask;
}

export function getWaterTaskColumns(): string[] {
    const formGroup = getFormControls();
    return Object.keys(formGroup.controls);
}

/**
 * Creates and returns a FormGroup object with all the necessary FormControls for the water task form.
 * 
 * @returns A FormGroup object with all the FormControls.
 */
export function getFormControls(): FormGroup {
    const taskFormData: FormGroup = new FormGroup({
        ACCESO: new FormControl(),
        acceso_devuelto: new FormControl(),
        ACTIVI: new FormControl(),
        ANOMALIA: new FormControl(defaultAnomaly, [Validators.required, Validators.pattern('^[a-zA-Z0-9\\-._]+$')]),
        AREALIZAR: new FormControl(),
        AREALIZARDV: new FormControl(),
        accion_ordenada: new FormControl(),
        audio_detalle: new FormControl(),
        audio_detalle_server_path: new FormControl(),
        BIS: new FormControl(),
        CALIBRE: new FormControl(),
        CALIBREDV: new FormControl(),
        CALLE: new FormControl(),
        CODLEC: new FormControl(),
        COMENTARIOS: new FormControl(),
        CONTADOR: new FormControl(),
        C_AGRUPA: new FormControl(),
        C_CANAL: new FormControl(),
        C_COMUNERO: new FormControl(),
        C_LYC: new FormControl(),
        company: new FormControl(),
        causa_origen: new FormControl(),
        codigo_de_geolocalizacion: new FormControl('', [Validators.pattern('^[a-zA-Z0-9\\-._]+$')]), //codigo de emplazamiento
        codigo_de_localizacion: new FormControl(),
        contadordv: new FormControl(),
        DNI_CIF_ABONADO: new FormControl(),
        DNI_CIF_COMUNIDAD: new FormControl(),
        date_time_modified: new FormControl(),
        EMPLAZA: new FormControl(),
        EMPLAZADV: new FormControl(),
        equipo: new FormControl(),
        escalera: new FormControl(),
        Estado: new FormControl(),
        FECEMISIO: new FormControl(),
        FECH_CIERRE: new FormControl(),
        FECINST: new FormControl(),
        FECULTREP: new FormControl(),
        F_INST: new FormControl(),
        f_instnew: new FormControl(),
        FechImportacion: new FormControl(),
        fech_cierrenew: new FormControl(),
        fech_facturacion: new FormControl(),
        fech_informacionnew: new FormControl(),
        fecha_de_cambio: new FormControl(),
        fecha_hora_cita: new FormControl(),
        fecha_hora_cita_end: new FormControl(),
        fecha_informe_servicios: new FormControl(),
        fecha_realizacion: new FormControl(),
        fechas_nota_aviso: new FormControl(),
        fechas_tocado_puerta: new FormControl(),
        fechas_no_contesta: new FormControl(),
        firma_cliente: new FormControl(),
        thumbnail_type: new FormControl(),
        thumbnail: new FormControl(),
        thumbnail_server_path: new FormControl(),
        foto_antes_instalacion: new FormControl(),
        foto_despues_instalacion: new FormControl(),
        foto_incidencia_1: new FormControl(),
        foto_incidencia_2: new FormControl(),
        foto_incidencia_3: new FormControl(),
        foto_lectura: new FormControl(),
        foto_numero_serie: new FormControl(),
        foto_entorno: new FormControl(),
        firma_cliente_server_path: new FormControl(),
        foto_antes_instalacion_server_path: new FormControl(),
        foto_despues_instalacion_server_path: new FormControl(),
        foto_incidencia_1_server_path: new FormControl(),
        foto_incidencia_2_server_path: new FormControl(),
        foto_incidencia_3_server_path: new FormControl(),
        foto_lectura_server_path: new FormControl(),
        foto_numero_serie_server_path: new FormControl(),
        foto_entorno_server_path: new FormControl(),
        GESTOR: new FormControl(),
        geolocalizacion: new FormControl(),
        hibernacion: new FormControl(),
        ID_FINCA: new FormControl(),
        ID_SAT: new FormControl(),
        INDICE: new FormControl(),
        INTERVENCI: new FormControl(),
        id: new FormControl(),
        idOrdenCABB: new FormControl(),
        idexport: new FormControl(),
        incidencia: new FormControl(),
        intervencidv: new FormControl(),
        LARGO: new FormControl(),
        LECTURA_CONTADOR_NUEVO: new FormControl(),
        LECT_LEV: new FormControl(),
        LONGDV: new FormControl(),
        MANO: new FormControl(),
        MARCA: new FormControl(),
        MARCADV: new FormControl(),
        MENSAJE_LIBRE: new FormControl(),
        MUNICIPIO: new FormControl(),
        marcaR: new FormControl(),
        NOMBRE_ABONADO: new FormControl(),
        NOMBRE_FIRMANTE: new FormControl(),
        NUME: new FormControl(),
        NUMERO_CARNET_FIRMANTE: new FormControl(),
        NUMIN: new FormControl(),
        nuevo_citas: new FormControl(),
        Numero_de_ABONADO: new FormControl('', [Validators.required, Validators.pattern('^[a-zA-Z0-9\\-._]+$')]),
        numero_serie_modulo: new FormControl(),
        OBSERVA: new FormControl(),
        OBSERVADV: new FormControl(),
        OPERARIO: new FormControl(),
        sectors: new FormControl(),
        observaciones: new FormControl(),
        PISO: new FormControl(),
        PROPIEDAD: new FormControl(),
        piezas: new FormControl(),
        prioridad: new FormControl(priority_status.LOW, [Validators.min(priority_status.HIBERNATE), Validators.max(priority_status.HIGH)]),
        REPARACION: new FormControl(),
        RESTEMPLAZA: new FormControl(),
        RESTO_EM: new FormControl(),
        RS: new FormControl(),
        RUEDAS: new FormControl(),
        RUEDASDV: new FormControl(),
        resultado: new FormControl(),
        ruta: new FormControl(),
        SERIE: new FormControl(),
        seriedv: new FormControl(),
        servicios: new FormControl(),
        last_service: new FormControl(),
        status_tarea: new FormControl(task_status.REQUIRED, [Validators.min(task_status.REQUIRED), Validators.max(task_status.TRASHED)]),
        suministros: new FormControl(),
        TARIFA: new FormControl(),
        TIPO: new FormControl(), //tipo o clase de contador
        TIPOFLUIDO: new FormControl(),
        TIPOFLUIDO_DEVUELTO: new FormControl(),
        TIPORADIO_DEVUELTO: new FormControl(),
        TIPORDEN: new FormControl(order_status.DAILY),
        TIPO_DEVUELTO: new FormControl(), //tipo o clase de contador DEVUELTO
        TOTAL_CONTADORES: new FormControl(),
        telefono1: new FormControl(),
        telefono2: new FormControl(),
        telefonos_cliente: new FormControl(),
        tipoRadio: new FormControl(),
        tipo_tarea: new FormControl(),
        ubicacion_en_bateria: new FormControl(),
        url_geolocalizacion: new FormControl(),
        zona: new FormControl(),
        upload_pendent: new FormControl(),
        incidence: new FormControl(),
        absent: new FormControl(),
        contador_bateria: new FormControl(),
        cita_pendiente: new FormControl(),
        end_hibernation_date: new FormControl(),
        end_hibernation_priority: new FormControl(),
        geolocation_details: new FormControl(),
        last_modification_operator_uid: new FormControl(),
        numero_precinto: new FormControl(),
        control_digit: new FormControl(),
        bloque: new FormControl(task_block.MONDAY.toString()),
        ultima_modificacion: new FormControl(),
        last_modification_android: new FormControl(),
        date_status: new FormControl(),
        date_modification_android: new FormControl(),
        planning: new FormControl(),
        planning_details: new FormControl(),
        planning_date_modified: new FormControl(),
        ORD_RUT: new FormControl(),
        pendent_location: new FormControl(),
        serial_old_changed: new FormControl(),
        planning_detail_extras: new FormControl(),
    });
    return taskFormData;
}

/**
 * Retrieves the field type based on the given field name.
 * @param field_name - The name of the field.
 * @returns The type of the field.
 */
export function getFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'id':
            value = 'number';
            break;
        case 'idOrdenCABB':
            value = 'number';
            break;
        case 'FECEMISIO':
            value = 'Date';
            break;
        case 'causa_origen':
            value = 'string';
            break;
        case 'accion_ordenada':
            value = 'string';
            break;
        case 'tipo_tarea':
            value = 'string';
            break;
        case 'AREALIZAR':
            value = 'string';
            break;
        case 'ANOMALIA':
            value = 'string';
            break;
        case 'DATOS ESPECÍFICOS':
            value = 'string';
            break;
        case 'DATOS ESPECIFICOS':
            value = 'string';
            break;
        case 'INTERVENCI':
            value = 'string';
            break;
        case 'PROPIEDAD':
            value = 'string';
            break;
        case 'CONTADOR':
            value = 'string';
            break;
        case 'SERIE':
            value = 'string';
            break;
        case 'SERIE LEV.':
            value = 'string';
            break;
        case 'SERIE INST.':
            value = 'string';
            break;
        case 'MARCA':
            value = 'string';
            break;
        case 'CALIBRE':
            value = 'string';
            break;
        case 'RUEDAS':
            value = 'string';
            break;
        case 'FECINST':
            value = 'Date';
            break;
        case 'ACTIVI':
            value = 'string';
            break;
        case 'EMPLAZA':
            value = 'string';
            break;
        case 'ACCESO':
            value = 'string';
            break;
        case 'acceso_devuelto':
            value = 'string';
            break;
        case 'CALLE':
            value = 'string';
            break;
        case 'NUME':
            value = 'string';
            break;
        case 'PORTAL':
            value = 'string';
            break;
        case 'BIS':
            value = 'string';
            break;
        case 'PISO':
            value = 'string';
            break;
        case 'MANO':
            value = 'string';
            break;
        case 'MUNICIPIO':
            value = 'string';
            break;
        case 'telefono1':
            value = 'string';
            break;
        case 'telefono2':
            value = 'string';
            break;
        case 'NOMBRE_ABONADO':
            value = 'string';
            break;
        case 'Numero_de_ABONADO':
            value = 'string';
            break;
        case 'CODLEC':
            value = 'number';
            break;
        case 'LECTURA LEV.':
            value = 'number';
            break;
        case 'LECTURA INST.':
            value = 'number';
            break;
        case 'observaciones':
            value = 'string';
            break;
        case 'zona':
            value = 'string';
            break;
        case 'prioridad':
            value = 'number';
            break;
        case 'resultado':
            value = 'string';
            break;
        case 'F_INST':
            value = 'Date';
            break;
        case 'LECTURA_CONTADOR_NUEVO':
            value = 'number';
            break;
        case 'EMPLAZADV':
            value = 'string';
            break;
        case 'RESTO_EM':
            value = 'string';
            break;
        case 'LECT_LEV':
            value = 'number';
            break;
        case 'OBSERVADV':
            value = 'string_array';
            break;
        case 'MENSAJE_LIBRE':
            value = 'string';
            break;
        case 'status_tarea':
            value = 'number';
            break;
        case 'MARCADV':
            value = 'string';
            break;
        case 'CALIBREDV':
            value = 'string';
            break;
        case 'RUEDASDV':
            value = 'string';
            break;
        case 'LONGDV':
            value = 'string';
            break;
        case 'seriedv':
            value = 'string';
            break;
        case 'contadordv':
            value = 'string';
            break;
        case 'AREALIZARDV':
            value = 'string';
            break;
        case 'intervencidv':
            value = 'string';
            break;
        case 'FECH_CIERRE':
            value = 'Date';
            break;
        case 'TIPORDEN':
            value = 'number';
            break;
        case 'equipo':
            value = 'number';
            break;
        case 'escalera':
            value = 'string';
            break;
        case 'OPERARIO':
            value = 'string';
            break;
        case 'sectors':
            value = 'string';
            break;
        case 'MODIFICADOR':
            value = 'string';
            break;
        case 'telefonos_cliente':
            value = 'string';
            break;
        case 'observaciones':
            value = 'string';
            break;
        case 'TIPOFLUIDO':
            value = 'string';
            break;
        case 'tipoRadio':
            value = 'string';
            break;
        case 'marcaR':
            value = 'string';
            break;
        case 'idexport':
            value = 'number';
            break;
        case 'fech_cierrenew':
            value = 'Date';
            break;
        case 'fech_informacionnew':
            value = 'Date';
            break;
        case 'numero_serie_modulo':
            value = 'string';
            break;
        case 'numero_precinto':
            value = 'string';
            break;
        case 'control_digit':
            value = 'string';
            break;
        case 'date_modification_android':
            value = 'Date';
            break;
        case 'planning':
            value = 'string';
            break;
        case 'planning_details':
            value = 'string';
            break;
        case 'planning_date_modified':
            value = 'Date';
            break;
        case 'bloque':
            value = 'string';
            break;
        case 'geolocalizacion':
            value = 'string';
            break;
        case 'codigo_de_localizacion':
            value = 'string';
            break;
        case 'codigo_de_geolocalizacion':
            value = 'string';
            break;
        case 'url_geolocalizacion':
            value = 'string';
            break;
        case 'firma_cliente':
            value = 'string';
            break;
        case 'foto_antes_instalacion':
            value = 'string';
            break;
        case 'thumbnail':
            value = 'string';
            break;
        case 'foto_despues_instalacion':
            value = 'string';
            break;
        case 'foto_incidencia_1':
            value = 'string';
            break;
        case 'foto_incidencia_2':
            value = 'string';
            break;
        case 'foto_incidencia_3':
            value = 'string';
            break;
        case 'foto_lectura':
            value = 'string';
            break;
        case 'foto_numero_serie':
            value = 'string';
            break;
        case 'foto_entorno':
            value = 'string';
            break;
        case 'fecha_hora_cita':
            value = 'Date';
            break;
        case 'fecha_hora_cita_end':
            value = 'Date';
            break;
        case 'FechImportacion':
            value = 'Date';
            break;
        case 'date_time_modified':
            value = 'Date';
            break;
        case 'last_modification_android':
            value = 'number';
            break;
        case 'incidence':
            value = 'number';
            break;
        case 'ubicacion_en_bateria':
            value = 'string';
            break;
        case 'ultima_modificacion':
            value = 'string';
            break;
        case 'last_modification_operator_uid':
            value = 'string';
            break;
        case 'servicios':
            value = 'string';
            break;
        case 'last_service':
            value = 'string';
            break;
        case 'suministros':
            value = 'string';
            break;
        case 'ORD_RUT':
            value = 'number';
            break;
        case 'pendent_location':
            value = 'number';
            break;
        case 'serial_old_changed':
            value = 'number';
            break;
        case 'hibernation':
            value = 'number';
            break;
        case 'planning_detail_extras':
            value = 'string';
            break;
    }
    return value;
}

/**
 * Retrieves the corresponding field value based on the given field name.
 * @param field_name - The name of the field.
 * @returns The corresponding field value.
 */
export function getField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'id':
            value = 'id';
            break;
        case 'idOrden':
            value = 'idOrdenCABB';
            break;
        case 'Fecha Emisión':
            value = 'FECEMISIO';
            break;
        case 'Fecha Modificación':
            value = 'date_time_modified';
            break;
        case 'CAUSA ORIGEN':
            value = 'causa_origen';
            break;
        case 'ACCION ORD.':
            value = 'accion_ordenada';
            break;
        case 'C.ACCION ORD.':
            value = 'tipo_tarea';
            break;
        case 'AREALIZAR':
            value = 'AREALIZAR';
            break;
        case 'DATOS ESPECÍFICOS':
            value = 'observaciones';
            break;
        case 'DATOS ESPECIFICOS':
            value = 'observaciones';
            break;
        case 'ANOMALIA':
            value = 'ANOMALIA';
            break;
        case 'INTERVENCIÓN':
            value = 'INTERVENCI';
            break;
        case 'PROP.':
            value = 'PROPIEDAD';
            break;
        case 'AÑO o PREFIJO':
            value = 'CONTADOR';
            break;
        case 'SERIE':
            value = 'SERIE';
            break;
        case 'SERIE LEV.':
            value = 'SERIE';
            break;
        case 'MARCA':
            value = 'MARCA';
            break;
        case 'CALIBRE':
            value = 'CALIBRE';
            break;
        case 'RUEDAS':
            value = 'RUEDAS';
            break;
        case 'FECINST':
            value = 'FECINST';
            break;
        case 'ACTIVI':
            value = 'ACTIVI';
            break;
        case 'EMPLAZA':
            value = 'EMPLAZA';
            break;
        case 'ACCESO':
            value = 'ACCESO';
            break;
        case 'ACCESODV':
            value = 'acceso_devuelto';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'PORTAL':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'ESCALERA':
            value = 'escalera';
            break;
        case 'PISO':
            value = 'PISO';
            break;
        case 'MANO':
            value = 'MANO';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'CITA':
            value = 'fecha_hora_cita';
            break;
        case 'FIN DE CITA':
            value = 'fecha_hora_cita_end';
            break;
        case 'TELEFONO 1':
            value = 'telefono1';
            break;
        case 'TELEFONO 2':
            value = 'telefono2';
            break;
        case 'NOMBRE':
            value = 'NOMBRE_ABONADO';
            break;
        case 'ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'CODLEC':
            value = 'CODLEC';
            break;
        case 'DATOS ESPECÍFICOS':
            value = 'observaciones';
            break;
        case 'SECTOR P':
            value = 'zona';
            break;
        case 'PRIORIDAD':
            value = 'prioridad';
            break;
        case 'RESULTADO':
            value = 'resultado';
            break;
        case 'RS':
            value = 'resultado';
            break;
        case 'F_EJEC':
            value = 'F_INST';
            break;
        case 'LECT_INS':
            value = 'LECTURA_CONTADOR_NUEVO';
            break;
        case 'EMPLAZADV':
            value = 'EMPLAZADV';
            break;
        case 'RESTO_EM':
            value = 'RESTO_EM';
            break;
        case 'LECT_LEV':
            value = 'LECT_LEV';
            break;
        case 'LECTURA LEV.':
            value = 'LECT_LEV';
            break;
        case 'LECTURA INST.':
            value = 'LECTURA_CONTADOR_NUEVO';
            break;
        case 'OBSERVADV':
            value = 'OBSERVADV';
            break;
        case 'MENSAJE LIBRE':
            value = 'MENSAJE_LIBRE';
            break;
        case 'ESCALERA':
            value = 'escalera';
            break;
        case 'Estado':
            value = 'status_tarea';
            break;
        case 'ESTADO':
            value = 'status_tarea';
            break;
        case 'MARCADV':
            value = 'MARCADV';
            break;
        case 'CALIBREDV':
            value = 'CALIBREDV';
            break;
        case 'RUEDASDV':
            value = 'RUEDASDV';
            break;
        case 'LONGDV':
            value = 'LONGDV';
            break;
        case 'seriedv':
            value = 'seriedv';
            break;
        case 'SERIE INST.':
            value = 'seriedv';
            break;
        case 'PREFIJO DV':
            value = 'contadordv';
            break;
        case 'CAUSA DESTINO':
            value = 'AREALIZARDV';
            break;
        case 'intervencidv':
            value = 'intervencidv';
            break;
        case 'FECH_CIERRE':
            value = 'FECH_CIERRE';
            break;
        case 'TIPORDEN':
            value = 'TIPORDEN';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
        case 'telefonos_cliente':
            value = 'telefonos_cliente';
            break;
        case 'MODIFICADOR':
            value = 'last_modification_operator_uid';
            break;
        case 'ULTIMO MODIFICADOR':
            value = 'ultima_modificacion';
            break;
        case 'observaciones':
            value = 'MENSAJE_LIBRE';
            break;
        case 'TIPOFLUIDO':
            value = 'TIPOFLUIDO';
            break;
        case 'tipoRadio':
            value = 'tipoRadio';
            break;
        case 'REQUERIDA':
            value = 'marcaR';
            break;
        case 'idexport':
            value = 'idexport';
            break;
        case 'fech_cierrenew':
            value = 'fech_cierrenew';
            break;
        case 'fech_informacionnew':
            value = 'fech_informacionnew';
            break;
        case 'Módulo':
            value = 'numero_serie_modulo';
            break;
        case 'Número de Precinto':
            value = 'numero_precinto';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'MODIFICACIÓN ANDROID':
            value = 'date_modification_android';
            break;
        case 'PLANIFICACIÓN':
            value = 'planning';
            break;
        case 'DETALLES PLANIFICACIÓN':
            value = 'planning_details';
            break;
        case 'DETALLE PLANIFICACIÓN EXTRA':
            value = 'planning_detail_extras';
            break;
        case 'Bloque':
            value = 'bloque';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'Geolocalización':
            value = 'url_geolocalizacion';
            break;
        case 'Fecha de cita':
            value = 'fecha_hora_cita';
            break;
        case 'Fecha final de cita':
            value = 'fecha_hora_cita_end';
            break;
        case 'Fecha de importación':
            value = 'FechImportacion';
            break;
        case 'Fecha de modificación':
            value = 'date_time_modified';
            break;
        case 'Sin revisar':
            value = 'last_modification_android';
            break;
        case 'Incidencias':
            value = 'incidence';
            break;
        case 'Incidencia':
            value = 'incidence';
            break;
        case 'Ubicacion en batería':
            value = 'ubicacion_en_bateria';
            break;
        case 'SERVICIOS':
            value = 'servicios';
            break;
        case 'SERVICIO':
            value = 'last_service';
            break;
        case 'SUMINISTROS':
            value = 'suministros';
            break;        
        case 'ORD_RUT':
            value = 'ORD_RUT';
            break;
        case 'LOCALIZACIÓN PENDIENTE':
            value = 'pendent_location';
            break;
        case 'SERIE LEV MODIFICADA':
            value = 'serial_old_changed';
            break; 
        case 'foto_antes_instalacion':
            value = 'foto_antes_instalacion';
            break;  
        case 'foto_despues_instalacion':
            value = 'foto_despues_instalacion';
            break;  
        case 'foto_lectura':
            value = 'foto_lectura';
            break;  
        case 'foto_numero_serie':
            value = 'foto_numero_serie';
            break; 
        case 'foto_entorno':
            value = 'foto_entorno';
            break;
        
    }
    return value;
}

/**
 * Retrieves the field name based on the provided field key.
 * @param field - The field key.
 * @returns The corresponding field name.
 */
export function getFieldName(field: string): string {
    let value: string = '';
    switch (field) {
        case 'term':
            value = 'Búsqueda';
            break;
        case 'ID':
            value = 'ID';
            break;
        case 'id':
            value = 'id';
            break;
        case 'idOrdenCABB':
            value = 'idOrden';
            break;
        case 'FECEMISIO':
            value = 'Fecha Emisión';
            break;
        case 'date_time_modified':
            value = 'Fecha Modificación';
            break;
        case 'causa_origen':
            value = 'CAUSA ORIGEN';
            break;
        case 'accion_ordenada':
            value = 'ACCION ORD.';
            break;
        case 'tipo_tarea':
            value = 'C.ACCION ORD.';
            break;
        case 'AREALIZAR':
            value = 'AREALIZAR';
            break;
        case 'observaciones':
            value = 'DATOS ESPECÍFICOS';
            break;
        case 'observaciones':
            value = 'DATOS ESPECIFICOS';
            break;
        case 'ANOMALIA':
            value = 'ANOMALIA';
            break;
        case 'INTERVENCI':
            value = 'INTERVENCIÓN';
            break;
        case 'PROPIEDAD':
            value = 'PROP.';
            break;
        case 'CONTADOR':
            value = 'AÑO o PREFIJO';
            break;
        case 'SERIE':
            value = 'SERIE';
            break;
        case 'MARCA':
            value = 'MARCA';
            break;
        case 'CALIBRE':
            value = 'CALIBRE';
            break;
        case 'RUEDAS':
            value = 'RUEDAS';
            break;
        case 'FECINST':
            value = 'FECINST';
            break;
        case 'ACTIVI':
            value = 'ACTIVI';
            break;
        case 'EMPLAZA':
            value = 'EMPLAZA';
            break;
        case 'ACCESO':
            value = 'ACCESO';
            break;
        case 'acceso_devuelto':
            value = 'ACCESODV';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'PISO':
            value = 'PISO';
            break;
        case 'escalera':
            value = 'ESCALERA';
            break;
        case 'MANO':
            value = 'MANO';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'fecha_hora_cita':
            value = 'CITA';
            break;
        case 'fecha_hora_cita_end':
            value = 'FIN DE CITA';
            break;
        case 'telefono1':
            value = 'TELEFONO 1';
            break;
        case 'telefono2':
            value = 'TELEFONO 2';
            break;
        case 'NOMBRE_ABONADO':
            value = 'NOMBRE';
            break;
        case 'Numero_de_ABONADO':
            value = 'ABONADO';
            break;
        case 'CODLEC':
            value = 'CODLEC';
            break;
        case 'observaciones':
            value = 'DATOS ESPECÍFICOS';
            break;
        case 'zona':
            value = 'SECTOR P';
            break;
        case 'prioridad':
            value = 'PRIORIDAD';
            break;
        case 'resultado':
            value = 'RS';
            break;
        case 'F_INST':
            value = 'F_EJEC';
            break;
        case 'LECTURA_CONTADOR_NUEVO':
            value = 'LECT_INS';
            break;
        case 'EMPLAZADV':
            value = 'EMPLAZADV';
            break;
        case 'RESTO_EM':
            value = 'RESTO_EM';
            break;
        case 'LECT_LEV':
            value = 'LECT_LEV';
            break;
        case 'OBSERVADV':
            value = 'OBSERVADV';
            break;
        case 'MENSAJE_LIBRE':
            value = 'MENSAJE LIBRE';
            break;
        case 'status_tarea':
            value = 'ESTADO';
            break;
        case 'MARCADV':
            value = 'MARCADV';
            break;
        case 'CALIBREDV':
            value = 'CALIBREDV';
            break;
        case 'RUEDASDV':
            value = 'RUEDASDV';
            break;
        case 'LONGDV':
            value = 'LONGDV';
            break;
        case 'seriedv':
            value = 'seriedv';
            break;
        case 'contadordv':
            value = 'PREFIJO DV';
            break;
        case 'AREALIZARDV':
            value = 'CAUSA DESTINO';
            break;
        case 'intervencidv':
            value = 'intervencidv';
            break;
        case 'FECH_CIERRE':
            value = 'FECH_CIERRE';
            break;
        case 'TIPORDEN':
            value = 'TIPORDEN';
            break;
        case 'equipo':
            value = 'EQUIPO';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'sectors':
            value = 'PERIMETROS';
            break;
        case 'telefonos_cliente':
            value = 'telefonos_cliente';
            break;
        case 'last_modification_operator_uid':
            value = 'MODIFICADOR';
            break;
        case 'ultima_modificacion':
            value = 'ULTIMO MODIFICADOR';
            break;
        case 'observaciones':
            value = 'observaciones';
            break;
        case 'TIPOFLUIDO':
            value = 'TIPOFLUIDO';
            break;
        case 'tipoRadio':
            value = 'tipoRadio';
            break;
        case 'marcaR':
            value = 'REQUERIDA';
            break;
        case 'idexport':
            value = 'idexport';
            break;
        case 'fech_cierrenew':
            value = 'fech_cierrenew';
            break;
        case 'fech_informacionnew':
            value = 'fech_informacionnew';
            break;
        case 'numero_serie_modulo':
            value = 'Módulo';
            break;
        case 'numero_precinto':
            value = 'Número de Precinto';
            break;
        case 'control_digit':
            value = 'DÍGITO DE CONTROL';
            break;
        case 'date_modification_android':
            value = 'MODIFICACIÓN ANDROID';
            break;
        case 'planning':
            value = 'PLANIFICACIÓN';
            break;
        case 'planning_details':
            value = 'DETALLES PLANIFICACIÓN';
            break;
        case 'bloque':
            value = 'Bloque';
            break;
        case 'codigo_de_geolocalizacion':
            value = 'C.EMPLAZAMIENTO';
            break;
        case 'url_geolocalizacion':
            value = 'Geolocalización';
            break;
        case 'fecha_hora_cita':
            value = 'Fecha de cita';
            break;
        case 'fecha_hora_cita_end':
            value = 'Fecha final de cita';
            break;
        case 'FechImportacion':
            value = 'Fecha de importación';
            break;
        case 'date_time_modified':
            value = 'Fecha de modificación';
            break;
        case 'last_modification_android':
            value = 'Sin revisar';
            break;
        case 'incidence':
            value = 'Incidencias';
            break;
        case 'ubicacion_en_bateria':
            value = 'Ubicacion en batería';
            break;
        case 'servicios':
            value = 'SERVICIOS';
            break;
        case 'last_service':
            value = 'SERVICIO';
            break;
        case 'suministros':
            value = 'SUMINISTROS';
            break;
        case 'ORD_RUT':
            value = 'ORD_RUT';
            break;
        case 'pendent_location':
            value = 'LOCALIZACIÓN PENDIENTE';
            break;
        case 'serial_old_changed':
            value = 'SERIE LEV MODIFICADA';
            break;
    }
    return value;
}

/**
 * Converts an Excel field name to its corresponding value in the application.
 * @param excel_field - The Excel field name to convert.
 * @returns The corresponding value in the application.
 */
export function getExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toUpperCase()) {
        case 'ID.ORD':
            value = 'idOrdenCABB';
            break;
        case 'ID. ORD':
            value = 'idOrdenCABB';
            break;
        case 'ID. ORDEN':
            value = 'idOrdenCABB';
            break;
        case 'ID ORDEN':
            value = 'idOrdenCABB';
            break;
        case 'ORDEN':
            value = 'idOrdenCABB';
            break;
        case 'NUMIN':
            value = 'NUMIN';
            break;
        case 'NUMERO INTERNO':
            value = 'NUMIN';
            break;
        case 'NÚMERO INTERNO':
            value = 'NUMIN';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'POBLACION':
            value = 'MUNICIPIO';
            break;
        case 'POBLACIÓN':
            value = 'MUNICIPIO';
            break;
        case 'Poblacion':
            value = 'MUNICIPIO';
            break;
        case 'Población':
            value = 'MUNICIPIO';
            break;
        case 'DES_POBL':
            value = 'MUNICIPIO';
            break;
        case 'PROP':
            value = 'PROPIEDAD';
            break;
        case 'ORD_RUT':
            value = 'ORD_RUT';
            break;
        case 'ORD RUT':
            value = 'ORD_RUT';
            break;
        case 'ORD RUTA':
            value = 'ORD_RUT';
            break;
        case 'ORDEN RUTA':
            value = 'ORD_RUT';
            break;
        case 'ORDEN RUT':
            value = 'ORD_RUT';
            break;
        case 'CONT PADRE':
            value = 'ruta';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'DES_VIA':
            value = 'CALLE';
            break;
        case 'Nº':
            value = 'NUME';
            break;
        case 'NUM':
            value = 'NUME';
            break;
        case 'NUM_EDIF':
            value = 'NUME';
            break;
        case 'NÚMERO':
            value = 'NUME';
            break;
        case 'NUMERO':
            value = 'NUME';
            break;
        case 'PORTAL':
            value = 'NUME';
            break;
        case 'NÚMERO PORTAL':
            value = 'NUME';
            break;
        case 'NUMERO PORTAL':
            value = 'NUME';
            break;
        case 'NÚMERO DE PORTAL':
            value = 'NUME';
            break;
        case 'NUMERO DE PORTAL':
            value = 'NUME';
            break;
        case 'ESC':
            value = 'escalera';
            break;
        case 'ESCALERA':
            value = 'escalera';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'BLOQUE':
            value = 'BIS';
            break;
        case 'PISO':
            value = 'PISO';
            break;
        case 'MANO':
            value = 'MANO';
            break;
        case 'PUERTA':
            value = 'MANO';
            break;
        case 'LETRA':
            value = 'MANO';
            break;
        case 'AÑO  O PREFIJO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO CONT. RETIRADO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO CONTADOR RETIRADO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO CONTADOR':
            value = 'CONTADOR';
            break;
        case 'AÑO':
            value = 'CONTADOR';
            break;
        case 'PREFIJO':
            value = 'CONTADOR';
            break;
        case 'CONTADOR':
            value = 'SERIE';
            break;
        case 'CONTADOR':
            value = 'SERIE';
            break;
        case 'NUM_CONT':
            value = 'SERIE';
            break;
        case 'Nº SERIE':
            value = 'SERIE';
            break;
        case 'NºSERIE':
            value = 'SERIE';
            break;
        case 'N SERIE':
            value = 'SERIE';
            break;
        case 'SERIE':
            value = 'SERIE';
            break;
        case 'NUMERO SERIE':
            value = 'SERIE';
            break;
        case 'NÚMERO SERIE':
            value = 'SERIE';
            break;
        case 'NUMERO SERIE DEL CONTADOR':
            value = 'SERIE';
            break;
        case 'NÚMERO SERIE DEL CONTADOR':
            value = 'SERIE';
            break;
        case 'Nº SERIE CONT. RETIRADO':
            value = 'SERIE';
            break;
        case 'CALIBRE':
            value = 'CALIBRE';
            break;
        case 'COD_CAL':
            value = 'CALIBRE';
            break;
        case 'CALIBRE DE CONTADOR':
            value = 'CALIBRE';
            break;
        case 'CALIBRE CONTADOR':
            value = 'CALIBRE';
            break;
        case 'CALIBRE CONT. RETIRADO':
            value = 'CALIBRE';
            break;
        case 'CALIBRE. INST.':
            value = 'CALIBREDV';
            break;
        case 'ANOMALÍA (TAREA A REALIZAR)':
            value = 'ANOMALIA';
            break;
        case 'OPCIÓN':
            value = 'ANOMALIA';
            break;
        case 'OPCION':
            value = 'ANOMALIA';
            break;
        case 'CAUSA ORIGEN':
            value = 'ANOMALIA';
            break;
        case 'TAREA A REALIZAR':
            value = 'ANOMALIA';
            break;
        case 'ANOMALÍA':
            value = 'ANOMALIA';
            break;
        case 'ANOMALIA':
            value = 'ANOMALIA';
            break;
        case 'Anomalía (TAREA A REALIZAR)':
            value = 'ANOMALIA';
            break;
        case 'Anomalia (TAREA A REALIZAR)':
            value = 'ANOMALIA';
            break;
        case 'EMPLAZAMIENTO':
            value = 'EMPLAZA';
            break;
        case 'COMENTARIOS':
            value = 'observaciones';
            break;
        case 'DATOS ESPECIFICOS':
            value = 'observaciones';
            break;
        case 'DATOS ESPECÍFICOS':
            value = 'observaciones';
            break;
        case 'OBSERVACIONES':
            value = 'observaciones';
            break;
        case 'MENSAJE LIBRE':
            value = 'MENSAJE_LIBRE';
            break;
        case 'ACTIVIDAD':
            value = 'ACTIVI';
            break;
        case 'NOMBRE':
            value = 'NOMBRE_ABONADO';
            break;
        case 'TITULAR':
            value = 'NOMBRE_ABONADO';
            break;
        case 'NOMBRE CLIENTE':
            value = 'NOMBRE_ABONADO';
            break;
        case 'APELLIDO 1':
            value = 'APELLIDO 1';
            break;
        case 'APELLIDO 2':
            value = 'APELLIDO 2';
            break;
        case 'REF':
            value = 'Numero_de_ABONADO';
            break;
        case 'CONTRATO':
            value = 'Numero_de_ABONADO';
            break;
        case 'ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NºABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NUM_CTT':
            value = 'Numero_de_ABONADO';
            break;
        case 'Nº ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'N ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NUMERO ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NÚMERO ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'TELEFONO':
            value = 'telefono1';
            break;
        case 'TELÉFONO':
            value = 'telefono1';
            break;
        case 'TELEFONO 1':
            value = 'telefono1';
            break;
        case 'TELÉFONO 1':
            value = 'telefono1';
            break;
        case 'TELEFONO 2':
            value = 'telefono2';
            break;
        case 'TELÉFONO 2':
            value = 'telefono2';
            break;
        case 'UBICACION':
            value = 'EMPLAZA';
            break;
        case 'UBICACIÓN':
            value = 'EMPLAZA';
            break;
        case 'ACCESO':
            value = 'ACCESO';
            break;
        case 'DES_SITU_CONT':
            value = 'ACCESO';
            break;
        case 'KOKAPENA':
            value = 'ACCESO';
            break;
        case 'ACCESODV':
            value = 'acceso_devuelto';
            break;
        case 'ACCESODEVUELTO':
            value = 'acceso_devuelto';
            break;
        case 'ACCESO DEVUELTO':
            value = 'acceso_devuelto';
            break;
        case 'ACCESO_DEVUELTO':
            value = 'acceso_devuelto';
            break;
        case 'RESULTADO':
            value = 'resultado';
            break;
        case 'NUEVO':
            value = 'nuevo_citas';
            break;
        case 'NUEVO CITAS':
            value = 'nuevo_citas';
            break;
        case 'CITA':
            value = 'nuevo_citas';
            break;
        case 'CITAS':
            value = 'nuevo_citas';
            break;
        case 'FECHA':
            value = 'F_INST';
            break;
        case 'ZONA':
            value = 'zona';
            break;
        case 'ZONAS':
            value = 'zona';
            break;
        case 'SECTOR':
            value = 'zona';
            break;
        case 'SECTOR P':
            value = 'zona';
            break;
        case 'DES_RUT':
            value = 'zona';
            break;
        case 'RUTA':
            value = 'ruta';
            break;
        case 'COD_CALLE':
            value = 'ruta';
            break;
        case 'COD CALLE':
            value = 'ruta';
            break;
        case 'CODIGO CALLE':
            value = 'ruta';
            break;
        case 'CÓDIGO CALLE':
            value = 'ruta';
            break;
        case 'MARCA':
            value = 'MARCA';
            break;
        case 'MARCA ACT':
            value = 'MARCA';
            break;
        case 'MARCA CONTADOR':
            value = 'MARCA';
            break;
        case 'MARCA DE CONTADOR':
            value = 'MARCA';
            break;
        case 'CÓDIGO DE EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO DE EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CÓDIGO EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'COORDENADA':
            value = 'codigo_de_localizacion';
            break;
        case 'COORDENADAS':
            value = 'codigo_de_localizacion';
            break;
        case 'CÓDIGO DE LOCALIZACIÓN':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CÓDIGO DE LOCALIZACION':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO DE LOCALIZACIÓN':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO DE LOCALIZACION':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'GEOLOCALIZACIÓN':
            value = 'geolocalizacion';
            break;
        case 'GEOLOCALIZACION':
            value = 'geolocalizacion';
            break;
        case 'ÚLTIMA LECTURA':
            value = 'LECT_LEV';
            break;
        case 'ULTIMA LECTURA':
            value = 'LECT_LEV';
            break;
        case 'GESTOR':
            value = 'GESTOR';
            break;
        case 'LINK GEOLOCALIZACIÓN':
            value = 'url_geolocalizacion';
            break;
        case 'LINK GEOLOCALIZACION':
            value = 'url_geolocalizacion';
            break;
        case 'LECTURA DE CONTADOR INSTALADO':
            value = 'LECTURA_CONTADOR_NUEVO';
            break;
        case 'Nº SERIE CONTADOR INSTALADO':
            value = 'seriedv';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'Nº ANTENA CONTADOR INSTALADO':
            value = 'numero_serie_modulo';
            break;
        case 'CALIBRE CONTADOR INSTALADO':
            value = 'CALIBREDV';
            break;
        case 'LONGITUD CONTADOR INSTALADO':
            value = 'LONGDV';
            break;
        case 'CLASE CONTADOR INSTALADO':
            value = 'TIPO_DEVUELTO';
            break;
        case 'MARCA CONTADOR INSTALADO':
            value = 'MARCADV';
            break;
        case 'UBICACIÓN BATERÍA':
            value = 'ubicacion_en_bateria';
            break;
        case 'UBICACIÓN EN BATERÍA':
            value = 'ubicacion_en_bateria';
            break;
        case 'UBICACION BATERÍA':
            value = 'ubicacion_en_bateria';
            break;
        case 'UBICACIÓN BATERIA':
            value = 'ubicacion_en_bateria';
            break;
        case 'FECINST':
            value = 'FECINST';
            break;
        case 'FECEMISIO':
            value = 'FECEMISIO';
            break;
        case 'F_INST':
            value = 'F_INST';
            break;
        case 'FECH_CIERRE':
            value = 'FECH_CIERRE';
            break;
        case 'FECHIMPORTACION':
            value = 'FechImportacion';
            break;
        case 'FECH_CIERRENEW':
            value = 'fech_cierrenew';
            break;
        case 'FECH_FACTURACION':
            value = 'fech_facturacion';
            break;
        case 'FECH_INFORMACIONNEW':
            value = 'fech_informacionnew';
            break;
        case 'F_INSTNEW':
            value = 'f_instnew';
            break;
        case 'PROPIEDAD':
            value = 'PROPIEDAD';
            break;
        case 'ALQUILER/PROPIEDAD':
            value = 'PROPIEDAD';
            break;
        case 'IDORDEN':
            value = 'idOrdenCABB';
            break;
        case 'FECHA EMISIÓN':
            value = 'FECEMISIO';
            break;
        case 'CAUSA ORIGEN':
            value = 'causa_origen';
            break;
        case 'ACCION ORD.':
            value = 'accion_ordenada';
            break;
        case 'C.ACCION ORD.':
            value = 'tipo_tarea';
            break;
        case 'AREALIZAR':
            value = 'AREALIZAR';
            break;
        case 'INTERVENCIÓN':
            value = 'INTERVENCI';
            break;
        case 'PROP.':
            value = 'PROPIEDAD';
            break;
        case 'AÑO O PREFIJO':
            value = 'CONTADOR';
            break;
        case 'SERIE':
            value = 'SERIE';
            break;
        case 'MARCA':
            value = 'MARCA';
            break;
        case 'CALIBRE':
            value = 'CALIBRE';
            break;
        case 'RUEDAS':
            value = 'RUEDAS';
            break;
        case 'DIGITOS':
            value = 'RUEDAS';
            break;
        case 'DÍGITOS':
            value = 'RUEDAS';
            break;
        case 'FECINST':
            value = 'FECINST';
            break;
        case 'ACTIVI':
            value = 'ACTIVI';
            break;
        case 'NOM_EDIF':
            value = 'ACTIVI';
            break;
        case 'EMPLAZA':
            value = 'EMPLAZA';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'PISO':
            value = 'PISO';
            break;
        case 'MANO':
            value = 'MANO';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'CODLEC':
            value = 'CODLEC';
            break;
        case 'RESTO_EM':
            value = 'RESTO_EM';
            break;
        case 'LECT_LEV':
            value = 'LECT_LEV';
            break;
        case 'OBSERVADV':
            value = 'OBSERVADV';
            break;
        case 'MENSAJE LIBRE':
            value = 'MENSAJE_LIBRE';
            break;
        case 'ESTADO':
            value = 'status_tarea';
            break;
        case 'MARCADV':
            value = 'MARCADV';
            break;
        case 'CALIBREDV':
            value = 'CALIBREDV';
            break;
        case 'RUEDASDV':
            value = 'RUEDASDV';
            break;
        case 'LONGDV':
            value = 'LONGDV';
            break;
        case 'SERIEDV':
            value = 'seriedv';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'PREFIJO DV':
            value = 'contadordv';
            break;
        case 'CAUSA DESTINO':
            value = 'AREALIZARDV';
            break;
        case 'INTERVENCIDV':
            value = 'intervencidv';
            break;
        case 'FECH_CIERRE':
            value = 'FECH_CIERRE';
            break;
        case 'TIPORDEN':
            value = 'TIPORDEN';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
        case 'telefonos_cliente':
            value = 'telefonos_cliente';
            break;
        case 'MODIFICADOR':
            value = 'last_modification_operator_uid';
            break;
        case 'ULTIMO MODIFICADOR':
            value = 'ultima_modificacion';
            break;
        case 'OBSERVACIONES':
            value = 'observaciones';
            break;
        case 'TIPO':
            value = 'TIPOFLUIDO_DEVUELTO';
            break;
        case 'TIPOFLUIDO':
            value = 'TIPOFLUIDO';
            break;
        case 'TIPO FLUIDO':
            value = 'TIPOFLUIDO';
            break;
        case 'tipo contador a instalar':
            value = 'tipoRadio';
            break;
        case 'TIPORADIO':
            value = 'tipoRadio';
            break;
        case 'TIPO RADIO':
            value = 'tipoRadio';
            break;
        case 'REQUERIDA':
            value = 'marcaR';
            break;
        case 'IDEXPORT':
            value = 'idexport';
            break;
        case 'FECH_CIERRENEW':
            value = 'fech_cierrenew';
            break;
        case 'FECH_INFORMACIONNEW':
            value = 'fech_informacionnew';
            break;
        case 'MÓDULO':
            value = 'numero_serie_modulo';
            break;
        case 'NÚMERO DE PRECINTO':
            value = 'numero_precinto';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'DIGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'MODIFICACIÓN ANDROID':
            value = 'date_modification_android';
            break;
        case 'MODIFICACION ANDROID':
            value = 'date_modification_android';
            break;
        case 'PLANIFICACIÓN':
            value = 'planning';
            break;
        case 'PLANIFICACION':
            value = 'planning';
            break;
        case 'PLANNING':
            value = 'planning';
            break;
        case 'DETALLES PLANIFICACIÓN':
            value = 'planning_details';
            break;
        case 'DETALLES PLANIFICACION':
            value = 'planning_details';
            break;
        case 'BLOQUE':
            value = 'bloque';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'GEOLOCALIZACIÓN':
            value = 'url_geolocalizacion';
            break;
        case 'FOTO ANTES DE INSTALACIÓN':
            value = 'foto_antes_instalacion';
            break;
        case 'FOTO DESPUÉS DE INSTALACIÓN':
            value = 'foto_despues_instalacion';
            break;
        case 'FOTO LECTURA':
            value = 'foto_lectura';
            break;
        case 'FOTO NÚMERO SERIE':
            value = 'foto_numero_serie';
            break;
        case 'FOTO ENTORNO':
            value = 'foto_entorno';
            break;
        case 'INFORMACIÓN INTERNA':
            value = 'intervencidv';
            break;
    }
    return value;
}

/**
 * Retrieves the corresponding exportation field name for a given Excel field.
 * @param excel_field - The Excel field for which to retrieve the exportation field name.
 * @returns The exportation field name.
 */
export function getExcelFieldNameExportation(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toUpperCase()) {
        case 'ID.ORD':
            value = 'idOrdenCABB';
            break;
        case 'ID. ORD':
            value = 'idOrdenCABB';
            break;
        case 'ID. ORDEN':
            value = 'idOrdenCABB';
            break;
        case 'ID ORDEN':
            value = 'idOrdenCABB';
            break;
        case 'ORDEN':
            value = 'idOrdenCABB';
            break;
        case 'NUMIN':
            value = 'NUMIN';
            break;
        case 'NUMERO INTERNO':
            value = 'NUMIN';
            break;
        case 'NÚMERO INTERNO':
            value = 'NUMIN';
            break;
        case 'DIRECCION':
            value = 'DIRECCION';
            break;
        case 'DIRECCIÓN':
            value = 'DIRECCION';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'POBLACION':
            value = 'MUNICIPIO';
            break;
        case 'POBLACIÓN':
            value = 'MUNICIPIO';
            break;
        case 'Poblacion':
            value = 'MUNICIPIO';
            break;
        case 'Población':
            value = 'MUNICIPIO';
            break;
        case 'DES_POBL':
            value = 'MUNICIPIO';
            break;
        case 'ORD_RUT':
            value = 'ORD_RUT';
            break;
        case 'CONT PADRE':
            value = 'ruta';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'DES_VIA':
            value = 'CALLE';
            break;
        case 'Nº':
            value = 'NUME';
            break;
        case 'NUM':
            value = 'NUME';
            break;
        case 'NUM_EDIF':
            value = 'NUME';
            break;
        case 'NÚMERO':
            value = 'NUME';
            break;
        case 'NUMERO':
            value = 'NUME';
            break;
        case 'PORTAL':
            value = 'NUME';
            break;
        case 'NÚMERO PORTAL':
            value = 'NUME';
            break;
        case 'NUMERO PORTAL':
            value = 'NUME';
            break;
        case 'NÚMERO DE PORTAL':
            value = 'NUME';
            break;
        case 'NUMERO DE PORTAL':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'ESCALERA':
            value = 'escalera';
            break;
        case 'BLOQUE':
            value = 'BIS';
            break;
        case 'PISO':
            value = 'PISO';
            break;
        case 'MANO':
            value = 'MANO';
            break;
        case 'PUERTA':
            value = 'MANO';
            break;
        case 'LETRA':
            value = 'MANO';
            break;
        case 'AÑO  O PREFIJO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO CONT. RETIRADO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO CONTADOR RETIRADO':
            value = 'CONTADOR';
            break;
        case 'AÑO O PREFIJO CONTADOR':
            value = 'CONTADOR';
            break;
        case 'AÑO':
            value = 'CONTADOR';
            break;
        case 'PREFIJO':
            value = 'CONTADOR';
            break;
        case 'CONTADOR':
            value = 'SERIE';
            break;
        case 'CONTADOR':
            value = 'SERIE';
            break;
        case 'NUM_CONT':
            value = 'SERIE';
            break;
        case 'Nº SERIE':
            value = 'SERIE';
            break;
        case 'NºSERIE':
            value = 'SERIE';
            break;
        case 'N SERIE':
            value = 'SERIE';
            break;
        case 'SERIE':
            value = 'SERIE';
            break;
        case 'NUMERO SERIE':
            value = 'SERIE';
            break;
        case 'NÚMERO SERIE':
            value = 'SERIE';
            break;
        case 'NUMERO SERIE DEL CONTADOR':
            value = 'SERIE';
            break;
        case 'NÚMERO SERIE DEL CONTADOR':
            value = 'SERIE';
            break;
        case 'Nº SERIE CONT. RETIRADO':
            value = 'SERIE';
            break;
        case 'CALIBRE':
            value = 'CALIBRE';
            break;
        case 'COD_CAL':
            value = 'CALIBRE';
            break;
        case 'CALIBRE DE CONTADOR':
            value = 'CALIBRE';
            break;
        case 'CALIBRE CONTADOR':
            value = 'CALIBRE';
            break;
        case 'CALIBRE CONT. RETIRADO':
            value = 'CALIBRE';
            break;
        case 'CALIBRE. INST.':
            value = 'CALIBREDV';
            break;
        case 'ANOMALÍA (TAREA A REALIZAR)':
            value = 'ANOMALIA';
            break;
        case 'OPCIÓN':
            value = 'ANOMALIA';
            break;
        case 'OPCION':
            value = 'ANOMALIA';
            break;
        case 'CAUSA ORIGEN':
            value = 'ANOMALIA';
            break;
        case 'TAREA A REALIZAR':
            value = 'ANOMALIA';
            break;
        case 'ANOMALÍA':
            value = 'ANOMALIA';
            break;
        case 'ANOMALIA':
            value = 'ANOMALIA';
            break;
        case 'Anomalía (TAREA A REALIZAR)':
            value = 'ANOMALIA';
            break;
        case 'Anomalia (TAREA A REALIZAR)':
            value = 'ANOMALIA';
            break;
        case 'EMPLAZAMIENTO':
            value = 'EMPLAZA';
            break;
        case 'COMENTARIOS':
            value = 'observaciones';
            break;
        case 'DATOS ESPECIFICOS':
            value = 'observaciones';
            break;
        case 'DATOS ESPECÍFICOS':
            value = 'observaciones';
            break;
        case 'OBSERVACIONES':
            value = 'observaciones';
            break;
        case 'MENSAJE LIBRE':
            value = 'MENSAJE_LIBRE';
            break;
        case 'ACTIVIDAD':
            value = 'ACTIVI';
            break;
        case 'NOMBRE':
            value = 'NOMBRE_ABONADO';
            break;
        case 'TITULAR':
            value = 'NOMBRE_ABONADO';
            break;
        case 'NOMBRE CLIENTE':
            value = 'NOMBRE_ABONADO';
            break;
        case 'APELLIDO 1':
            value = 'APELLIDO 1';
            break;
        case 'APELLIDO 2':
            value = 'APELLIDO 2';
            break;
        case 'REF':
            value = 'Numero_de_ABONADO';
            break;
        case 'CONTRATO':
            value = 'Numero_de_ABONADO';
            break;
        case 'ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NºABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NUM_CTT':
            value = 'Numero_de_ABONADO';
            break;
        case 'Nº ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'N ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NUMERO ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'NÚMERO ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'TELEFONO':
            value = 'telefono1';
            break;
        case 'TELEFONO 1':
            value = 'telefono1';
            break;
        case 'TELÉFONO':
            value = 'telefono1';
            break;
        case 'TELÉFONO 1':
            value = 'telefono1';
            break;
        case 'TELEFONO 2':
            value = 'telefono2';
            break;
        case 'TELÉFONO 2':
            value = 'telefono2';
            break;
        case 'UBICACION':
            value = 'EMPLAZA';
            break;
        case 'UBICACIÓN':
            value = 'EMPLAZA';
            break;
        case 'ACCESODV':
            value = 'acceso_devuelto';
            break;
        case 'ACCESO':
            value = 'ACCESO';
            break;
        case 'DES_SITU_CONT':
            value = 'ACCESO';
            break;
        case 'KOKAPENA':
            value = 'ACCESO';
            break;
        case 'RESULTADO':
            value = 'resultado';
            break;
        case 'NUEVO':
            value = 'nuevo_citas';
            break;
        case 'NUEVO CITAS':
            value = 'nuevo_citas';
            break;
        case 'CITA':
            value = 'nuevo_citas';
            break;
        case 'CITAS':
            value = 'nuevo_citas';
            break;
        case 'FECHA':
            value = 'F_INST';
            break;
        case 'ZONA':
            value = 'zona';
            break;
        case 'ZONAS':
            value = 'zona';
            break;
        case 'SECTOR':
            value = 'zona';
            break;
        case 'SECTOR P':
            value = 'zona';
            break;
        case 'DES_RUT':
            value = 'zona';
            break;
        case 'RUTA':
            value = 'ruta';
            break;
        case 'COD_CALLE':
            value = 'ruta';
            break;
        case 'MARCA':
            value = 'MARCA';
            break;
        case 'MARCA ACT':
            value = 'MARCA';
            break;
        case 'MARCA CONTADOR':
            value = 'MARCA';
            break;
        case 'MARCA DE CONTADOR':
            value = 'MARCA';
            break;
        case 'CÓDIGO DE EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO DE EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CÓDIGO EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CÓDIGO DE LOCALIZACIÓN':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CÓDIGO DE LOCALIZACION':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO DE LOCALIZACIÓN':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'CODIGO DE LOCALIZACION':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'GEOLOCALIZACIÓN':
            value = 'geolocalizacion';
            break;
        case 'GEOLOCALIZACION':
            value = 'geolocalizacion';
            break;
        case 'ÚLTIMA LECTURA':
            value = 'LECT_LEV';
            break;
        case 'ULTIMA LECTURA':
            value = 'LECT_LEV';
            break;
        case 'GESTOR':
            value = 'GESTOR';
            break;
        case 'LINK GEOLOCALIZACIÓN':
            value = 'url_geolocalizacion';
            break;
        case 'LINK GEOLOCALIZACION':
            value = 'url_geolocalizacion';
            break;
        case 'LECTURA DE CONTADOR INSTALADO':
            value = 'LECTURA_CONTADOR_NUEVO';
            break;
        case 'Nº SERIE CONTADOR INSTALADO':
            value = 'seriedv';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'MODIFICACIÓN ANDROID':
            value = 'date_modification_android';
            break;
        case 'PLANIFICACIÓN':
            value = 'planning';
            break;
        case 'DETALLES PLANIFICACIÓN':
            value = 'planning_details';
            break;
        case 'Nº ANTENA CONTADOR INSTALADO':
            value = 'numero_serie_modulo';
            break;
        case 'CALIBRE CONTADOR INSTALADO':
            value = 'CALIBREDV';
            break;
        case 'LONGITUD CONTADOR INSTALADO':
            value = 'LONGDV';
            break;
        case 'CLASE CONTADOR INSTALADO':
            value = 'TIPO_DEVUELTO';
            break;
        case 'MARCA CONTADOR INSTALADO':
            value = 'MARCADV';
            break;
        case 'UBICACIÓN BATERÍA':
            value = 'ubicacion_en_bateria';
            break;
        case 'UBICACIÓN EN BATERÍA':
            value = 'ubicacion_en_bateria';
            break;
        case 'UBICACION BATERÍA':
            value = 'ubicacion_en_bateria';
            break;
        case 'UBICACIÓN BATERIA':
            value = 'ubicacion_en_bateria';
            break;
        case 'FECINST':
            value = 'FECINST';
            break;
        case 'FECEMISIO':
            value = 'FECEMISIO';
            break;
        case 'F_INST':
            value = 'F_INST';
            break;
        case 'FECH_CIERRE':
            value = 'FECH_CIERRE';
            break;
        case 'FECHIMPORTACION':
            value = 'FechImportacion';
            break;
        case 'FECH_CIERRENEW':
            value = 'fech_cierrenew';
            break;
        case 'FECH_FACTURACION':
            value = 'fech_facturacion';
            break;
        case 'FECH_INFORMACIONNEW':
            value = 'fech_informacionnew';
            break;
        case 'F_INSTNEW':
            value = 'f_instnew';
            break;
        case 'PROPIEDAD':
            value = 'PROPIEDAD';
            break;
        case 'ALQUILER/PROPIEDAD':
            value = 'PROPIEDAD';
            break;
        case 'IDORDEN':
            value = 'idOrdenCABB';
            break;
        case 'FECHA EMISIÓN':
            value = 'FECEMISIO';
            break;
        case 'CAUSA ORIGEN':
            value = 'causa_origen';
            break;
        case 'ACCION ORD.':
            value = 'accion_ordenada';
            break;
        case 'C.ACCION ORD.':
            value = 'tipo_tarea';
            break;
        case 'AREALIZAR':
            value = 'AREALIZAR';
            break;
        case 'INTERVENCIÓN':
            value = 'INTERVENCI';
            break;
        case 'PROP.':
            value = 'PROPIEDAD';
            break;
        case 'AÑO O PREFIJO':
            value = 'CONTADOR';
            break;
        case 'SERIE':
            value = 'SERIE';
            break;
        case 'MARCA':
            value = 'MARCA';
            break;
        case 'CALIBRE':
            value = 'CALIBRE';
            break;
        case 'RUEDAS':
            value = 'RUEDAS';
            break;
        case 'DIGITOS':
            value = 'RUEDASDV';
            break;
        case 'DÍGITOS':
            value = 'RUEDASDV';
            break;
        case 'FECINST':
            value = 'FECINST';
            break;
        case 'ACTIVI':
            value = 'ACTIVI';
            break;
        case 'NOM_EDIF':
            value = 'ACTIVI';
            break;
        case 'EMPLAZA':
            value = 'EMPLAZA';
            break;
        case 'ACCESO':
            value = 'ACCESO';
            break;
        case 'ACCESODV':
            value = 'acceso_devuelto';
            break;
        case 'CALLE':
            value = 'CALLE';
            break;
        case 'NUME':
            value = 'NUME';
            break;
        case 'BIS':
            value = 'BIS';
            break;
        case 'PISO':
            value = 'PISO';
            break;
        case 'MANO':
            value = 'MANO';
            break;
        case 'MUNICIPIO':
            value = 'MUNICIPIO';
            break;
        case 'ABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'CODLEC':
            value = 'CODLEC';
            break;
        case 'RESTO_EM':
            value = 'RESTO_EM';
            break;
        case 'LECT_LEV':
            value = 'LECT_LEV';
            break;
        case 'OBSERVADV':
            value = 'OBSERVADV';
            break;
        case 'MENSAJE LIBRE':
            value = 'MENSAJE_LIBRE';
            break;
        case 'ESTADO':
            value = 'status_tarea';
            break;
        case 'MARCADV':
            value = 'MARCADV';
            break;
        case 'CALIBREDV':
            value = 'CALIBREDV';
            break;
        case 'RUEDASDV':
            value = 'RUEDASDV';
            break;
        case 'LONGDV':
            value = 'LONGDV';
            break;
        case 'SERIEDV':
            value = 'seriedv';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'PLANIFICACIÓN':
            value = 'planning';
            break;
        case 'PLANIFICACION':
            value = 'planning';
            break;
        case 'DETALLES PLANIFICACIÓN':
            value = 'planning_details';
            break;
        case 'DETALLES PLANIFICACION':
            value = 'planning_details';
            break;
        case 'PREFIJO DV':
            value = 'contadordv';
            break;
        case 'CAUSA DESTINO':
            value = 'AREALIZARDV';
            break;
        case 'INTERVENCIDV':
            value = 'intervencidv';
            break;
        case 'FECH_CIERRE':
            value = 'FECH_CIERRE';
            break;
        case 'TIPORDEN':
            value = 'TIPORDEN';
            break;
        case 'EQUIPO':
            value = 'equipo';
            break;
        case 'OPERARIO':
            value = 'OPERARIO';
            break;
        case 'PERIMETROS':
            value = 'sectors';
            break;
        case 'telefonos_cliente':
            value = 'telefonos_cliente';
            break;
        case 'MODIFICADOR':
            value = 'last_modification_operator_uid';
            break;
        case 'ULTIMO MODIFICADOR':
            value = 'ultima_modificacion';
            break;
        case 'OBSERVACIONES':
            value = 'observaciones';
            break;
        case 'TIPO':
            value = 'TIPOFLUIDO_DEVUELTO';
            break;
        case 'TIPOFLUIDO':
            value = 'TIPOFLUIDO';
            break;
        case 'TIPO FLUIDO':
            value = 'TIPOFLUIDO';
            break;
        case 'TIPORADIO':
            value = 'tipoRadio';
            break;
        case 'TIPO RADIO':
            value = 'tipoRadio';
            break;
        case 'REQUERIDA':
            value = 'marcaR';
            break;
        case 'IDEXPORT':
            value = 'idexport';
            break;
        case 'FECH_CIERRENEW':
            value = 'fech_cierrenew';
            break;
        case 'FECH_INFORMACIONNEW':
            value = 'fech_informacionnew';
            break;
        case 'MÓDULO':
            value = 'numero_serie_modulo';
            break;
        case 'NÚMERO DE PRECINTO':
            value = 'numero_precinto';
            break;
        case 'DÍGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'DIGITO DE CONTROL':
            value = 'control_digit';
            break;
        case 'MODIFICACIÓN ANDROID':
            value = 'date_modification_android';
            break;
        case 'MODIFICACION ANDROID':
            value = 'date_modification_android';
            break;
        case 'BLOQUE':
            value = 'bloque';
            break;
        case 'C.EMPLAZAMIENTO':
            value = 'codigo_de_geolocalizacion';
            break;
        case 'GEOLOCALIZACIÓN':
            value = 'url_geolocalizacion';
            break;
        case 'LATITUD':
            value = 'LATITUD';
            break;
        case 'LONGITUD':
            value = 'LONGITUDE';
            break;
        case 'LONGITUDE':
            value = 'LONGITUDE';
            break;
        case 'FOTO ANTES DE INSTALACIÓN':
            value = 'foto_antes_instalacion';
            break;
        case 'FOTO DESPUÉS DE INSTALACIÓN':
            value = 'foto_despues_instalacion';
            break;
        case 'FOTO LECTURA':
            value = 'foto_lectura';
            break;
        case 'FOTO NÚMERO SERIE':
            value = 'foto_numero_serie';
            break;
        case 'FOTO ENTORNO':
            value = 'foto_entorno';
            break;
        case 'EMPLAZAMIENTO DEVUELTO':
            value = 'EMPLAZADV';
            break;
        case 'CÓDIGO OBSERVACIONES':
            value = 'OBSERVADV';
            break;
        case 'PIEZAS':
            value = 'piezas';
            break;
        case 'INFORMACIÓN INTERNA':
            value = 'intervencidv';
            break;
    }
    return value;
}

/**
 * Retrieves the list of columns for the export in Excel.
 * 
 * @returns An array of strings representing the column names.
 */
export function getExcelExportColumns(): string[] {
    const columns = [
        'Población',
        'CALLE',
        'Nº',
        'BIS',
        'ESCALERA',
        'PISO',
        'MANO',
        'AÑO O PREFIJO CONT. RETIRADO',
        'Nº SERIE CONT. RETIRADO',
        'CALIBRE CONT. RETIRADO',
        'OPERARIO',
        'Anomalía (TAREA A REALIZAR)',
        'EMPLAZAMIENTO',
        'UBICACIÓN BATERÍA',
        'OBSERVACIONES',
        'ACTIVIDAD',
        'TITULAR',
        'NºABONADO',
        'TELEFONO',
        'ACCESO',
        'ACCESODV',
        'RESULTADO',
        'NUEVO',
        'FECHA',
        'ZONAS',
        'RUTA',
        'MARCA',
        'ÚLTIMA LECTURA',
        'GESTOR',
        'LECTURA DE CONTADOR INSTALADO',
        'Nº SERIE CONTADOR INSTALADO',
        'DÍGITO DE CONTROL',
        'Nº ANTENA CONTADOR INSTALADO',
        'CALIBRE CONTADOR INSTALADO',
        'MARCA CONTADOR INSTALADO',
        'CLASE CONTADOR INSTALADO',
        'LONGITUD CONTADOR INSTALADO',
        'NÚMERO DE PRECINTO',
        'CÓDIGO DE EMPLAZAMIENTO',
        'LINK GEOLOCALIZACIÓN',
        'FOTO ANTES DE INSTALACIÓN',
        'FOTO NÚMERO SERIE',
        'FOTO LECTURA',
        'FOTO DESPUÉS DE INSTALACIÓN',
        'PLANIFICACIÓN',
        'TELÉFONO 2',
        'ORD_RUT',
    ];
    return columns;
}

/**
 * Retrieves the list of columns for the extended export in Excel.
 * 
 * @returns An array of strings representing the column names.
 */
export function getExcelExtendedExportColumns(): string[] {
    const columns = [
        'Población',
        'CALLE',
        'Nº',
        'BIS',
        'PISO',
        'MANO',
        'AÑO O PREFIJO CONT. RETIRADO',
        'Nº SERIE CONT. RETIRADO',
        'CALIBRE CONT. RETIRADO',
        'OPERARIO',
        'Anomalía (TAREA A REALIZAR)',
        'EMPLAZAMIENTO',
        'UBICACIÓN BATERÍA',
        'OBSERVACIONES',
        'ACTIVIDAD',
        'TITULAR',
        'NºABONADO',
        'TELEFONO',
        'ACCESO',
        'NUEVO',
        'ZONAS',
        'MARCA',
        'GESTOR',
        'CÓDIGO DE EMPLAZAMIENTO',
        'LINK GEOLOCALIZACIÓN',
        'FECHA',
        'RESULTADO',
        'ÚLTIMA LECTURA',
        'LECTURA DE CONTADOR INSTALADO',
        'Nº SERIE CONTADOR INSTALADO',
        'Nº ANTENA CONTADOR INSTALADO',
        'MARCA CONTADOR INSTALADO',
        'CALIBRE CONTADOR INSTALADO',
        'LONGITUD CONTADOR INSTALADO',
        'DIGITOS',
        'TIPO',
        'CLASE CONTADOR INSTALADO',
        'EMPLAZAMIENTO DEVUELTO',
        'Causa Destino',
        'Código Observaciones',
        'Piezas',
        'NUMERO INTERNO',
        'INFORMACIÓN INTERNA',
    ];
    return columns;
}

/**
 * Returns the corresponding column name for the given Excel field.
 * @param excel_field - The Excel field for which to retrieve the column name.
 * @returns The column name corresponding to the given Excel field.
 */
export function getExcelExtendedExportHeadersColumnName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toUpperCase()) {
        case 'IDCENTRO':
            value = 'IDCENTRO';
            break;
        case 'IDEXPORT':
            value = 'idexport';
            break;
        case 'IDSAT':
            value = 'ID_SAT';
            break;
        case 'IDMODULO2':
            value = 'seriedv';
            break;
        case 'CODIGOCAUDAL':
            value = 'TIPO_DEVUELTO';
            break;
        case 'CALIBRE2':
            value = 'CALIBREDV';
            break;
        case 'DIGITOS2':
            value = 'RUEDASDV';
            break;
        case 'DIGITOS':
            value = 'RUEDASDV';
            break;
        case 'REFEXPORTABONADO':
            value = 'Numero_de_ABONADO';
            break;
        case 'CODIGORESULTADO':
            value = 'resultado';
            break;
        case 'CODIGOMOTIVO':
            value = 'OBSERVADV';
            break;
        case 'FECHASERVICIO':
            value = 'F_INST';
            break;
        case 'LECTURA2':
            value = 'LECTURA_CONTADOR_NUEVO';
            break;
        case 'LECTURAVIEJO':
            value = 'LECT_LEV';
            break;
        case 'ADICIONALLECTORCONTADOR':
            value = 'EMPLAZADV';
            break;
        case 'CODIGOAGUAS':
            value = 'NUMIN';
            break;
        case 'CODIGOMARCA':
            value = 'MARCADV';
            break;
        case 'FECHABAJA':
            value = 'F_INST';
            break;
        case 'CODIGOAVERIA':
            value = 'ANOMALIA';
            break;
        case 'OBSINTERNAS':
            value = 'MENSAJE_LIBRE';
            break;
        case 'CODIGOAVERIAFINAL':
            value = 'AREALIZARDV';
            break;
        case 'EMPLAZAMIENTONUEVO':
            value = 'EMPLAZADV';
            break;
        case 'EMPLAZAMIENTO NUEVO':
            value = 'EMPLAZADV';
            break;
        case 'TIPO FLUIDO':
            value = 'TIPOFLUIDO_DEVUELTO';
            break;
        case 'TIPO DE RADIO':
            value = 'TIPORADIO_DEVUELTO';
            break;
        case 'GEOLOCALIZACION':
            value = 'url_geolocalizacion';
            break;
    }
    return value;
}

/**
 * Retrieves the headers for the extended Excel export.
 * @returns An array of strings representing the headers.
 */
export function getExcelExtendedExportHeaders(): string[] {
    const columns = [
        'IDCENTRO',
        'IDEXPORT',
        'IDSAT',
        'IDMODULO2',
        'CODIGOCAUDAL',
        'CALIBRE2',
        'DIGITOS2',
        'REFEXPORTABONADO',
        'CODIGORESULTADO',
        'CODIGOMOTIVO',
        'FECHASERVICIO',
        'LECTURA2',
        'LECTURAVIEJO',
        'ADICIONALLECTORCONTADOR',
        'CODIGOAGUAS',
        'CODIGOMARCA',
        'FECHABAJA',
        'CODIGOAVERIA',
        'OBSINTERNAS',
        'CODIGOAVERIAFINAL',
        'TIPO FLUIDO',
        'TIPO DE RADIO',
        'GEOLOCALIZACION',
    ];
    return columns;
}

export function isNotFileField(field_name: string): boolean { 
    return !isFileField(field_name);
}

/**
 * Checks if a given field name corresponds to a file field.
 * @param field_name - The name of the field to check.
 * @returns A boolean indicating whether the field is a file field or not.
 */
export function isFileField(field_name: string): boolean {
    if (field_name == 'firma_cliente') {
        return true;
    }
    if (field_name == 'thumbnail') {
        return true;
    }
    if (field_name == 'thumbnail_server_path') {
        return true;
    }
    if (field_name == 'foto_antes_instalacion') {
        return true;
    }
    if (field_name == 'foto_despues_instalacion') {
        return true;
    }
    if (field_name == 'foto_incidencia_1') {
        return true;
    }
    if (field_name == 'foto_incidencia_2') {
        return true;
    }
    if (field_name == 'foto_incidencia_3') {
        return true;
    }
    if (field_name == 'foto_lectura') {
        return true;
    }
    if (field_name == 'foto_numero_serie') {
        return true;
    }
    if (field_name == 'foto_entorno') {
        return true;
    }
    if (field_name == 'firma_cliente_server_path') {
        return true;
    }
    if (field_name == 'foto_antes_instalacion_server_path') {
        return true;
    }
    if (field_name == 'foto_despues_instalacion_server_path') {
        return true;
    }
    if (field_name == 'foto_incidencia_1_server_path') {
        return true;
    }
    if (field_name == 'foto_incidencia_2_server_path') {
        return true;
    }
    if (field_name == 'foto_incidencia_3_server_path') {
        return true;
    }
    if (field_name == 'foto_lectura_server_path') {
        return true;
    }
    if (field_name == 'foto_numero_serie_server_path') {
        return true;
    }
    if (field_name == 'foto_entorno_server_path') {
        return true;
    }
    if (field_name == 'audio_detalle') {
        return true;
    }
    if (field_name == 'audio_detalle_server_path') {
        return true;
    }
    return false;
}

/**
 * Checks if a given field name is a date field.
 * @param field_name - The name of the field to check.
 * @returns True if the field is a date field, false otherwise.
 */
export function isDateField(field_name: string): boolean {
    if (field_name == 'date_time_modified') {
        return true;
    }
    if (field_name == 'FECEMISIO') {
        return true;
    }
    if (field_name == 'FECH_CIERRE') {
        return true;
    }
    if (field_name == 'FECINST') {
        return true;
    }
    if (field_name == 'FECULTREP') {
        return true;
    }
    if (field_name == 'F_INST') {
        return true;
    }
    if (field_name == 'f_instnew') {
        return true;
    }
    if (field_name == 'FechImportacion') {
        return true;
    }
    if (field_name == 'fech_cierrenew') {
        return true;
    }
    if (field_name == 'fech_facturacion') {
        return true;
    }
    if (field_name == 'fech_informacionnew') {
        return true;
    }
    if (field_name == 'fecha_de_cambio') {
        return true;
    }
    if (field_name == 'fecha_hora_cita') {
        return true;
    }
    if (field_name == 'fecha_hora_cita_end') {
        return true;
    }
    if (field_name == 'fecha_informe_servicios') {
        return true;
    }
    if (field_name == 'fecha_realizacion') {
        return true;
    }
    if (field_name == 'end_hibernation_date') {
        return true;
    }
    if (field_name == 'date_modification_android') {
        return true;
    }
    return false;
}

/**
 * Retrieves the corresponding photo field name based on the given photo name.
 * @param photoName - The name of the photo.
 * @returns The corresponding photo field name, or an empty string if no match is found.
 */
export function getPhotoFieldByName(photoName: string): string {
    if (photoName.includes('detalle')) {
        return 'audio_detalle';
    }
    if (photoName.includes('foto_antes_instalacion')) {
        return 'foto_antes_instalacion';
    }
    if (photoName.includes('thumbnail')) {
        return 'thumbnail';
    }
    if (photoName.includes('foto_despues_instalacion')) {
        return 'foto_despues_instalacion';
    }
    if (photoName.includes('foto_incidencia_1')) {
        return 'foto_incidencia_1';
    }
    if (photoName.includes('foto_incidencia_2')) {
        return 'foto_incidencia_2';
    }
    if (photoName.includes('foto_incidencia_3')) {
        return 'foto_incidencia_3';
    }
    if (photoName.includes('foto_lectura')) {
        return 'foto_lectura';
    }
    if (photoName.includes('foto_numero_serie')) {
        return 'foto_numero_serie';
    }
    if (photoName.includes('foto_entorno')) {
        return 'foto_entorno';
    }
    if (photoName.includes('firma') || photoName.includes('sign')) {
        return 'firma_cliente';
    }
    return '';
}

/**
 * Retrieves an array of photo field names.
 * 
 * @returns An array of strings representing the photo field names.
 */
export function getPhotoFields(): string[] {
    return [
        'foto_despues_instalacion',
        'foto_antes_instalacion',
        'foto_numero_serie',
        'foto_lectura',
        'foto_incidencia_1',
        'foto_incidencia_2',
        'foto_incidencia_3',
        'firma_cliente',
        'foto_entorno',
    ];
}

/**
 * Retrieves an array of strings representing the fields for photo uploads.
 *
 * @returns {string[]} An array of field names to be used for photo uploads.
 */
export function getPhotoFieldsToUpload(): string[] {
    return [
        'foto_antes_instalacion',
        'foto_incidencia_1',
        'firma_cliente',
    ];
}

/**
 * Creates a MyLatLng object with the given latitude and longitude values.
 * @param lat - The latitude value.
 * @param lng - The longitude value.
 * @returns A MyLatLng object with the specified latitude, longitude, and geohash.
 */
export function myLatLng(lat: number, lng: number): MyLatLng | null {
    if (!isValidLatitude(lat) || !isValidLongitude(lng)) return null;
    const geoHash = Geohash.encode(lat, lng, 9);
    const latLng: MyLatLng = {
        lat: lat,
        lng: lng,
        geohash: geoHash,
    };
    return latLng;
}

/**
 * Checks if the given latitude is valid.
 * A valid latitude is a number between -90 and 90 inclusive.
 *
 * @param lat - The latitude to validate.
 * @returns True if the latitude is valid, false otherwise.
 */
export function isValidLatitude(lat: number): boolean {
    return lat >= -90 && lat <= 90;
}

/**
 * Checks if the given longitude is valid.
 * A valid longitude is a number between -180 and 180 inclusive.
 *
 * @param lng - The longitude to validate.
 * @returns True if the longitude is valid, false otherwise.
 */
export function isValidLongitude(lng: number): boolean {
    return lng >= -180 && lng <= 180;
}

/**
 * Converts a string representation of latitude and longitude into a MyLatLng object.
 * @param geoString - The string representation of latitude and longitude in the format "latitude,longitude".
 * @returns The MyLatLng object representing the given latitude and longitude, or null if the input is invalid.
 */
export function myLatLngFromString(geoString: string) {
    if (geoString && geoString.includes(',')) {
        const lat = geoString.split(',')[0];
        const lng = geoString.split(',')[1];
        const geolocation: MyLatLng | null = myLatLng(parseFloat(lat), parseFloat(lng));
        return geolocation;
    }
    return null;
}

/**
 * Converts a priority string to its corresponding number value.
 * @param priorityString - The priority string to convert.
 * @returns The number value corresponding to the priority string.
 */
export function priorityFromString(priorityString: string): number {
    try {
        if ('HIBERNAR' == priorityString.toUpperCase()) {
            return priority_status.HIBERNATE;
        }
        if ('BAJA' == priorityString.toUpperCase()) {
            return priority_status.LOW;
        }
        if ('MEDIA' == priorityString.toUpperCase()) {
            return priority_status.MEDIUM;
        }
        if ('ALTA' == priorityString.toUpperCase()) {
            return priority_status.HIGH;
        }
    } catch (err) {}
    return priority_status.LOW;
}

export function taskStatusFromString(statusString: string): number {
    try {
        if ('REQUERIDA' == statusString.toUpperCase()) {
            return task_status.REQUIRED;
        }
        if ('ABIERTA' == statusString.toUpperCase()) {
            return task_status.IDLE;
        }
        if ('EJECUTADA' == statusString.toUpperCase()) {
            return task_status.DONE;
        }
        if ('CERRADA' == statusString.toUpperCase()) {
            return task_status.CLOSED;
        }
        if ('INCIDENCIA' == statusString.toUpperCase()) {
            return task_status.INCIDENCE;
        }
        if ('INFORMADA' == statusString.toUpperCase()) {
            return task_status.INFORMED;
        }
        if ('TRASTERO' == statusString.toUpperCase()) {
            return task_status.TRASHED;
        }
    } catch (err) {}
    return task_status.IDLE;
}

/**
 * Checks the status of the phones and returns an array of phone status structures.
 * @param phoneStatus - An array of phone status strings.
 * @returns An array of phone status structures.
 */
export function checkPhonesStatus(phoneStatus: string[]) {
    let phoneStatusStruct = [];

    if (phoneStatus.includes('TEL1_NO_CONTESTA')) {
        phoneStatusStruct.push({ value: 'TEL1 No contesta' });
    }
    if (phoneStatus.includes('TEL2_NO_CONTESTA')) {
        phoneStatusStruct.push({ value: 'TEL2 No contesta' });
    }
    if (phoneStatus.includes('TEL1_INCORRECTO')) {
        phoneStatusStruct.push({ value: 'TEL1 Nº incorrecto' });
    }
    if (phoneStatus.includes('TEL2_INCORRECTO')) {
        phoneStatusStruct.push({ value: 'TEL2 Nº incorrecto' });
    }
    return phoneStatusStruct;
}

/**
 * Converts data from the old structure to a WaterTask object.
 * 
 * @param oldStructure - The data in the old structure format.
 * @param companyId - The ID of the company.
 * @param managerId - The ID of the manager.
 * @returns The converted WaterTask object.
 */
export function convertFromOldStructure(
    oldStructure: any,
    companyId: number,
    managerId: number
): WaterTask {
    let waterTask: any = {};

    //waterTask['id'] = oldStructure['id'];
    waterTask['idOrdenCABB'] = parseInt(oldStructure['idOrdenCABB']);
    waterTask['FechImportacion'] = new Date(oldStructure['FechImportacion']);
    waterTask['NUMIN'] = oldStructure['NUMIN'];
    waterTask['company'] = companyId;
    waterTask['GESTOR'] = managerId;
    waterTask['ANOMALIA'] = oldStructure['ANOMALIA'];
    waterTask['AREALIZAR'] = oldStructure['AREALIZAR'];
    waterTask['INTERVENCI'] = oldStructure['INTERVENCI'];
    waterTask['REPARACION'] = oldStructure['REPARACION'];
    waterTask['PROPIEDAD'] = oldStructure['PROPIEDAD'];
    waterTask['CONTADOR'] = oldStructure['CONTADOR'];
    waterTask['SERIE'] = oldStructure['SERIE'];
    waterTask['MARCA'] = oldStructure['MARCA'];
    waterTask['CALIBRE'] = oldStructure['CALIBRE'];
    waterTask['RUEDAS'] = oldStructure['RUEDAS'];
    waterTask['FECINST'] = new Date(oldStructure['FECINST']);
    waterTask['ACTIVI'] = oldStructure['ACTIVI'];
    waterTask['EMPLAZA'] = oldStructure['EMPLAZA'];
    waterTask['ACCESO'] = oldStructure['ACCESO'];
    waterTask['CALLE'] = oldStructure['CALLE'];
    waterTask['NUME'] = oldStructure['NUME'];
    waterTask['BIS'] = oldStructure['BIS'];
    waterTask['PISO'] = oldStructure['PISO'];
    waterTask['MANO'] = oldStructure['MANO'];
    waterTask['MUNICIPIO'] = oldStructure['MUNICIPIO'];
    waterTask['NOMBRE_ABONADO'] = oldStructure['NOMBRE_ABONADO'];
    waterTask['Numero_de_ABONADO'] = oldStructure['Numero_de_ABONADO'];
    waterTask['NOMBRE_FIRMANTE'] = oldStructure['NOMBRE_FIRMANTE'];
    waterTask['NUMERO_CARNET_FIRMANTE'] = oldStructure['NUMERO_CARNET_FIRMANTE'];
    waterTask['FECEMISIO'] = new Date(oldStructure['FECEMISIO']);
    waterTask['FECULTREP'] = new Date(oldStructure['FECULTREP']);
    waterTask['OBSERVA'] = oldStructure['OBSERVA'];
    waterTask['RS'] = oldStructure['RS'];
    waterTask['F_INST'] = new Date(oldStructure['F_INST']);
    waterTask['INDICE'] = oldStructure['INDICE'];
    waterTask['EMPLAZADV'] = oldStructure['EMPLAZADV'];
    waterTask['RESTO_EM'] = oldStructure['RESTO_EM'];
    waterTask['CODLEC'] = parseInt(oldStructure['CODLEC']);
    waterTask['LECT_LEV'] = parseInt(oldStructure['LECT_LEV']);
    waterTask['LECTURA_CONTADOR_NUEVO'] = parseInt(oldStructure['LECTURA_CONTADOR_NUEVO']);

    if (oldStructure['OBSERVADV']) {
        const split = oldStructure['OBSERVADV'].trim().split('\n');
        if (split.length > 0) {
            waterTask['OBSERVADV'] = [];
            for (let obs of split) {
                waterTask['OBSERVADV'].push({ value: obs });
            }
        }
    }

    waterTask['TIPO'] = oldStructure['TIPO'];
    waterTask['TIPO_DEVUELTO'] = oldStructure['TIPO_DEVUELTO'];
    if (oldStructure['Estado'] && oldStructure['Estado'] == 'INCIDENCIA')
        waterTask['incidence'] = true;
    waterTask['MARCADV'] = oldStructure['MARCADV'];
    waterTask['CALIBREDV'] = oldStructure['CALIBREDV'];
    waterTask['RUEDASDV'] = oldStructure['RUEDASDV'];
    waterTask['LARGO'] = oldStructure['LARGO'];
    waterTask['LONGDV'] = oldStructure['LONGDV'];
    waterTask['seriedv'] = oldStructure['seriedv'];
    waterTask['contadordv'] = oldStructure['contadordv'];
    waterTask['AREALIZARDV'] = oldStructure['AREALIZARDV'];
    waterTask['intervencidv'] = oldStructure['intervencidv'];
    waterTask['RESTEMPLAZA'] = oldStructure['RESTEMPLAZA'];
    waterTask['FECH_CIERRE'] = new Date(oldStructure['FECH_CIERRE']);
    waterTask['TIPORDEN'] = parseInt(oldStructure['TIPORDEN']);
    waterTask['observaciones'] = oldStructure['observaciones'];
    waterTask['TIPOFLUIDO'] = oldStructure['TIPOFLUIDO'];
    waterTask['TIPOFLUIDO_DEVUELTO'] = oldStructure['TIPOFLUIDO_DEVUELTO'];
    waterTask['idexport'] = parseInt(oldStructure['idexport']);
    waterTask['fech_facturacion'] = new Date(oldStructure['fech_facturacion']);
    waterTask['fech_cierrenew'] = new Date(oldStructure['fech_cierrenew']);
    waterTask['fech_informacionnew'] = new Date(oldStructure['fech_informacionnew']);
    waterTask['f_instnew'] = new Date(oldStructure['f_instnew']);
    waterTask['tipoRadio'] = oldStructure['tipoRadio'];
    waterTask['TIPORADIO_DEVUELTO'] = oldStructure['TIPORADIO_DEVUELTO'];
    waterTask['marcaR'] = oldStructure['marcaR'];
    waterTask['codigo_de_localizacion'] = myLatLngFromString(oldStructure['codigo_de_localizacion']);
    waterTask['codigo_de_geolocalizacion'] = oldStructure['codigo_de_geolocalizacion'];
    waterTask['geolocalizacion'] = myLatLngFromString(oldStructure['geolocalizacion']);
    waterTask['url_geolocalizacion'] = oldStructure['url_geolocalizacion'];
    waterTask['foto_antes_instalacion'] = oldStructure['foto_antes_instalacion'];
    waterTask['foto_numero_serie'] = oldStructure['foto_numero_serie'];
    waterTask['foto_entorno'] = oldStructure['foto_entorno'];
    waterTask['foto_lectura'] = oldStructure['foto_lectura'];
    waterTask['foto_despues_instalacion'] = oldStructure['foto_despues_instalacion'];
    waterTask['foto_incidencia_1'] = oldStructure['foto_incidencia_1'];
    waterTask['foto_incidencia_2'] = oldStructure['foto_incidencia_2'];
    waterTask['foto_incidencia_3'] = oldStructure['foto_incidencia_3'];
    waterTask['firma_cliente'] = oldStructure['firma_cliente'];
    waterTask['tipo_tarea'] = oldStructure['tipo_tarea'];

    if (oldStructure['telefonos_cliente'])
        waterTask['telefonos_cliente'] = checkPhonesStatus(oldStructure['telefonos_cliente']);

    waterTask['telefono1'] = oldStructure['telefono1'];
    waterTask['telefono2'] = oldStructure['telefono2'];

    if (oldStructure['fechas_tocado_puerta']) {
        const split = oldStructure['fechas_tocado_puerta'].trim().split('\n');
        if (split.length > 0) {
            waterTask['fechas_tocado_puerta'] = [];
            for (let date of split) {
                waterTask['fechas_tocado_puerta'].push({ value: new Date(date) });
            }
        }
    }
    if (oldStructure['fechas_nota_aviso']) {
        const split = oldStructure['fechas_nota_aviso'].trim().split('\n');
        if (split.length > 0) {
            waterTask['fechas_nota_aviso'] = [];
            for (let date of split) {
                waterTask['fechas_nota_aviso'].push({ value: new Date(date) });
            }
        }
    }

    waterTask['resultado'] = oldStructure['resultado'];
    waterTask['nuevo_citas'] = oldStructure['nuevo_citas'];
    waterTask['fecha_hora_cita'] = new Date(oldStructure['fecha_hora_cita']);
    waterTask['fecha_de_cambio'] = new Date(oldStructure['fecha_de_cambio']);
    waterTask['zona'] = oldStructure['zona'];
    waterTask['ruta'] = oldStructure['ruta'];
    waterTask['numero_serie_modulo'] = oldStructure['numero_serie_modulo'];

    waterTask['ubicacion_en_bateria'] = oldStructure['ubicacion_en_bateria'];
    if (waterTask['ACCESO'] && waterTask['ACCESO'].includes('BAT')) {
        waterTask['contador_bateria'] = true;
        if (waterTask['ubicacion_en_bateria']) {
            if (!waterTask['ubicacion_en_bateria'].includes('EMPLAZADV')) {
                waterTask['ubicacion_en_bateria'] =
                    waterTask['EMPLAZADV'] + waterTask['ubicacion_en_bateria'];
            }
        }
    }

    waterTask['incidencia'] = oldStructure['incidencia'];
    waterTask['ID_FINCA'] = oldStructure['ID_FINCA'];
    waterTask['COMENTARIOS'] = oldStructure['COMENTARIOS'];
    waterTask['DNI_CIF_COMUNIDAD'] = oldStructure['DNI_CIF_COMUNIDAD'];
    waterTask['TARIFA'] = oldStructure['TARIFA'];
    waterTask['TOTAL_CONTADORES'] = oldStructure['TOTAL_CONTADORES'];
    waterTask['C_CANAL'] = oldStructure['C_CANAL'];
    waterTask['C_LYC'] = oldStructure['C_LYC'];
    waterTask['C_AGRUPA'] = oldStructure['C_AGRUPA'];
    waterTask['DNI_CIF_ABONADO'] = oldStructure['DNI_CIF_ABONADO'];
    waterTask['C_COMUNERO'] = oldStructure['C_COMUNERO'];

    if (oldStructure['MENSAJE_LIBRE']) {
        waterTask['MENSAJE_LIBRE'] = [];
        waterTask['MENSAJE_LIBRE'].push({ value: oldStructure['MENSAJE_LIBRE'] });
    }

    waterTask['ID_SAT'] = parseInt(oldStructure['ID_SAT']);
    waterTask['fecha_realizacion'] = new Date(oldStructure['fecha_realizacion']);

    if (oldStructure['suministros']) {
        const split = oldStructure['suministros'].trim().split('\n');
        if (split.length > 0) {
            waterTask['suministros'] = [];
            for (let supply of split) {
                waterTask['suministros'].push({ value: supply });
            }
        }
    }
    if (oldStructure['servicios']) {
        const split = oldStructure['servicios'].trim().split('\n');
        if (split.length > 0) {
            waterTask['servicios'] = [];
            for (let service of split) {
                waterTask['servicios'].push({ value: service });
                waterTask['last_service'] = service;
            }
        }
    }
    // waterTask['equipo'] = oldStructure['equipo'];

    waterTask['fecha_informe_servicios'] = new Date(oldStructure['fecha_informe_servicios']);

    if (oldStructure['piezas']) {
        const split = oldStructure['piezas'].trim().split('\n');
        if (split.length > 0) {
            waterTask['piezas'] = [];
            for (let part of split) {
                waterTask['piezas'].push({ value: part });
            }
        }
    }

    waterTask['prioridad'] = priorityFromString(oldStructure['prioridad']);

    waterTask['causa_origen'] = oldStructure['causa_origen'];
    waterTask['accion_ordenada'] = oldStructure['accion_ordenada'];

    if (waterTask['prioridad'] == priority_status.HIBERNATE) waterTask['hibernacion'] = true;

    waterTask['audio_detalle'] = oldStructure['audio_detalle'];

    waterTask['numero_precinto'] = oldStructure['numero_precinto'];
    waterTask['bloque'] = oldStructure['dia_predeterminado'];
    waterTask['date_time_modified'] = new Date(oldStructure['date_time_modified']);

    waterTask['status_tarea'] = taskStatusFromString(oldStructure['status_tarea']);

    if (oldStructure['status_tarea'] && oldStructure['status_tarea'].includes('CITA')) {
        if (oldStructure['fechas_nota_aviso'] || oldStructure['fechas_tocado_puerta'])
            waterTask['absent'] = true;
        else waterTask['cita_pendiente'] = true;
    }

    return waterTask;
}

/**
 * Returns an object containing filter date types.
 * @returns {Object} An object with filter date types as key-value pairs.
 */
export function getFilterDateTypes(): Object {
    return {
        Emisión: 'FECEMISIO',
        Importación: 'FechImportacion',
        Cita: 'fecha_hora_cita',
        Ejecución: 'F_INST',
        Cierre: 'FECH_CIERRE',
        Información: 'fech_informacionnew',
        Modificación: 'date_time_modified',
        'Modificación Android': 'date_modification_android',
    };
}
