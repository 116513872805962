/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { saveAs as importedSaveAs } from 'file-saver';
import { HttpClient } from '@angular/common/http';
import { UtilsService } from './utils.service';
import { IpcService } from './ipc.service';
import { defaultAnomaly, WaterTask } from '../interfaces/water-task';

@Injectable({
    providedIn: 'root',
})
export class FileSaverService {
    constructor(
        private http: HttpClient,
        private _utilsService: UtilsService,
        private _electronService: IpcService) { }

    /**
     * Initiates the download of a file from the given URL and returns a promise that resolves with the file's Blob.
     * 
     * @param url - The URL of the file to be downloaded.
     * @returns A promise that resolves with the Blob of the downloaded file.
     * 
     * @throws Will log an error message if the URL is not found or if there is an error during the download process.
     */
    public startDownloadFile(url: string): Promise<Blob> {
        return new Promise<Blob>(async (resolve, reject) => {
            try {
                const blob = await firstValueFrom(this.http.get(url, { responseType: 'blob' }));
                if (blob) resolve(blob);
            } catch (err) {
                console.log('============= url not found =============');
                console.log(url);
                console.log('============= err =============');
                console.log(err);
            }
            reject();
        });
    }

    /**
     * Retrieves the base64 representation of a file from a given URL.
     *
     * @param url - The URL of the file to be downloaded and converted to base64.
     * @returns A promise that resolves to the base64 string of the file.
     * @throws Will reject the promise if the file download or conversion fails.
     */
    async getBase64FromUrl(url: string): Promise<string> {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const blob = await this.startDownloadFile(url);
                const photoBase64 = await this._utilsService.convertBlobToBase64(blob);
                if (photoBase64) resolve(photoBase64);
            } catch (err) {}
            reject();
        });
    }

    /**
     * Downloads a file from the specified URL and saves it with the given filename.
     *
     * @param {string} url - The URL of the file to be downloaded.
     * @param {string} filename - The name to save the downloaded file as.
     * @returns {Promise<boolean>} A promise that resolves to `true` if the file was successfully downloaded and saved, or `false` if the download failed.
     */
    async downloadFile(url: string, filename: string): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                const blob = await this.startDownloadFile(url);
                if (blob) {
                    importedSaveAs(blob, filename);
                    resolve(true);
                } else resolve(false);
            } catch (err) {}
            reject();
        });
    }

    /**
     * Downloads a file from the given URL and returns it as a Blob.
     * 
     * @param url - The URL of the file to be downloaded.
     * @returns A promise that resolves with the downloaded file as a Blob.
     * @throws An error if the download fails.
     */
    async downloadFileAndGetBlob(url: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            try {
                const blob = await this.startDownloadFile(url);
                if (blob) resolve(blob);
            } catch (err) {}
            reject();
        });
    }

    /**
     * Downloads a photo and sends a message.
     * If the application is running in an Electron environment, it saves the photo to a specific path based on the provided parameters.
     * If the application is not running in an Electron environment, it uses the FileSaverService to download the photo.
     * 
     * @param photo - The photo to download.
     * @param waterTask - The water task associated with the photo.
     * @param companySelected - The selected company.
     * @param managerSelected - The selected manager.
     */
    async downloadPhoto(photo: any, waterTask: WaterTask, companySelected: any, managerSelected: any) {
        if (this._electronService.isElectronApp()) {
            const path = await this.getTaskFolderPath(waterTask, companySelected, managerSelected);
            await this.downloadPhotoAndSendMessage(photo, waterTask, path);
        } else {
            try {
                await this.downloadFile(photo['photo'], photo['downloadName']);
            } catch (err) {}
        }
    }

    /**
     * Returns the task folder path based on the provided water task, company, and manager.
     * @param waterTask - The water task object.
     * @param companySelected - The selected company object.
     * @param managerSelected - The selected manager object.
     * @returns The task folder path.
     */
    getTaskFolderPath(waterTask: WaterTask, companySelected: any, managerSelected: any){
        const path = `C:\\Mi_Ruta\\Empresas\\${
            companySelected.nombre_empresa || companySelected.id
        }\\Gestores\\${
            managerSelected.gestor || managerSelected.id
        }\\fotos_tareas\\${waterTask.Numero_de_ABONADO}\\${
            waterTask.ANOMALIA || defaultAnomaly
        }`;
        return path;
    }

    /**
     * Downloads a photo, converts it to base64, and sends a message with the photo data.
     * @param photo - The photo object to download.
     * @param waterTask - The water task object.
     * @param path - The path to save the downloaded photo.
     */
    async downloadPhotoAndSendMessage(photo: any, waterTask: WaterTask, path: string){
        try {
            const blob = await this.downloadFileAndGetBlob(photo['photo']);
            const base64 = await this._utilsService.convertBlobToBase64(blob);
            const message = {
                message: 'download_photo',
                photoBase64: base64,
                path: path,
                idOrder: waterTask.idOrdenCABB || '',
                filename: photo['downloadName'],
            };
            this._electronService.sendMessageSync(message);
        } catch (err) {}
    }

}
