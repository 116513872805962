import { FormControl, FormGroup } from '@angular/forms';

export interface CallLog {
    ID?: number; //useless only for displaying info
    id?: number;
    client_number?: string;
    company_number?: string;
    vonage_number?: string;
    conversation_uuid?: string;
    user_id?: number;
    call_status?: string;
    comments?: string;
    created_at?: Date;
    date_time_modified?: Date;
    pending_call_id?: number;
}

export function getCallLogDisplayColumns(): string[] {
    return ['ID', 'Número de Cliente', 'Número de Empresa', 'UUID de Conversación', 'ID de Usuario', 'Estado de Llamada', 'Comentarios', 'Fecha de Creación', 'Fecha de Modificación'];
}

export function getCallLogField(field_name: string): string {
    switch (field_name) {
        case 'ID':
            return 'ID';
        case 'Número de Cliente':
            return 'client_number';
        case 'Número de Empresa':
            return 'company_number';
        case 'Número Vonage':
            return 'vonage_number';
        case 'UUID de Conversación':
            return 'conversation_uuid';
        case 'ID de Usuario':
            return 'user_id';
        case 'Estado de Llamada':
            return 'call_status';
        case 'Comentarios':
            return 'comments';
        case 'Fecha de Creación':
            return 'created_at';
        case 'Fecha de Modificación':
            return 'date_time_modified';
        default:
            return '';
    }
}

export function getCallLogFieldName(field_name: string): string {
    switch (field_name) {
        case 'ID':
            return 'ID';
        case 'client_number':
            return 'Número de Cliente';
        case 'company_number':
            return 'Número de Empresa';
        case 'vonage_number':
            return 'Número Vonage';
        case 'conversation_uuid':
            return 'UUID de Conversación';
        case 'user_id':
            return 'ID de Usuario';
        case 'call_status':
            return 'Estado de Llamada';
        case 'comments':
            return 'Comentarios';
        case 'created_at':
            return 'Fecha de Creación';
        case 'date_time_modified':
            return 'Fecha de Modificación';
        default:
            return '';
    }
}

export function getCallLogFormControls(): FormGroup {
    return new FormGroup({
        id: new FormControl(),
        client_number: new FormControl(),
        company_number: new FormControl(),
        vonage_number: new FormControl(),
        conversation_uuid: new FormControl(),
        user_id: new FormControl(),
        call_status: new FormControl(),
        comments: new FormControl(),
        created_at: new FormControl(),
        date_time_modified: new FormControl(),
    });
}

export function getCallLogFieldType(field_name: string): string {
    switch (field_name) {
        case 'id':
        case 'user_id':
            return 'number';
        case 'client_number':
        case 'company_number':
        case 'vonage_number':
        case 'conversation_uuid':
        case 'call_status':
        case 'comments':
            return 'string';
        case 'created_at':
        case 'date_time_modified':
            return 'Date';
        default:
            return '';
    }
}

export function getCallLogExcelFieldName(excel_field: string): string {
    switch (excel_field.trim().toLowerCase()) {
        case 'número de cliente':
            return 'client_number';
        case 'número de empresa':
            return 'company_number';
        case 'número vonage':
            return 'vonage_number';
        case 'uuid de conversación':
            return 'conversation_uuid';
        case 'id de usuario':
            return 'user_id';
        case 'estado de llamada':
            return 'call_status';
        case 'comentarios':
            return 'comments';
        case 'fecha de creación':
            return 'created_at';
        case 'fecha de modificación':
            return 'date_time_modified';
        default:
            return '';
    }
}

export function getCallLogExcelExportColumns(): string[] {
    return ['Número de Cliente', 'Número de Empresa', 'Número Vonage', 'UUID de Conversación', 'ID de Usuario', 'Estado de Llamada', 'Comentarios', 'Fecha de Creación', 'Fecha de Modificación'];
}
