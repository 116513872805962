import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import * as XLSX from 'xlsx';
import { CallLog, getCallLogDisplayColumns, getCallLogExcelExportColumns, getCallLogExcelFieldName, getCallLogField, getCallLogFieldType } from 'src/app/interfaces/call-log';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { WindowRefService } from 'src/app/services/window-ref.service';
import * as moment from 'moment';
import { Moment } from 'moment';
import { faInbox, faBroadcastTower } from '@fortawesome/free-solid-svg-icons';
import { MySqlService } from '../../../services/mysql.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-call-logs',
    templateUrl: './call-logs.component.html',
    styleUrls: ['./call-logs.component.scss'],
})
export class CallLogsComponent implements OnInit  {
    @ViewChild('drawer') homeDrawer?: any;

    faBroadcastTower = faBroadcastTower;
    faInbox = faInbox;
    loading: boolean = true;
    callLogs: CallLog[] = [];
    dataSource: MatTableDataSource<CallLog> = new MatTableDataSource();

    length = 0; //callLog count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 50, 100, 200, 500];

    tableName: string = 'callLogs';
    displayedColumns: string[]= [];
    fixedColumns = [];
    displayedColumnsField: string[];
    clickedRows = new Set<CallLog>();
    allSelected = false;

    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    phone_number?: string;

    loadingText = 'Cargando...';

    is_pendent_call?: boolean;

    menuOptions: string[] = ['Exportar a EXCEL'];

    /**
     * Constructs an instance of the CallLogsComponent.
     * @param _mySqlService - The MySqlService instance.
     * @param _utilsService - The UtilsService instance.
     * @param _windowRefService - The WindowRefService instance.
     * @param router - The Router instance.
     * @param route - The ActivatedRoute instance.
     * @param _electronService - The IpcService instance.
     * @param _electronServiceAnswer - The ElectronService instance.
     * @param _spinner - The NgxSpinnerService instance.
     */
    constructor(
        private _mySqlService: MySqlService,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private _windowRefService: WindowRefService,
        private router: Router,
        private route: ActivatedRoute,
        private _spinner: NgxSpinnerService
    ) {
        this.route.params.subscribe((params) => { this.phone_number = params['phone_number']; });        
        
        this.pageSize = parseInt(localStorage.getItem('callLog_pageSize') || `${this.rowsLimit}`);
        
        this.displayedColumns = this._utilsService.setDisplayColumns(
            this.displayedColumns,
            this.tableName,
            getCallLogDisplayColumns,
        );
        
        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getCallLogField(displayedColumn)
        );
        document.addEventListener('visibilitychange', async () => {
            if (document.hidden) return;
            
            const updateNeeded = localStorage.getItem('callLogUpdateNeeded');
            if (updateNeeded == 'true') {
                this.scrollOffset = 50;
                localStorage.setItem('callLogUpdateNeeded', 'false');
                this.showLoading(true);
                this.setCallLogsInTable(await this._mySqlService.getLastCallLogsPage());
                this.showLoading(false);
            }
        });
    }

    /**
     * Handles the scroll event and updates the data source.
     */
    async onScroll() {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = this.callLogs.slice(0, this.scrollOffset);
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It clears the pending call filter and order, and retrieves the pending calls.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.clearCallLogFilterAndOrder();
        this.setDefaultOrder();
        await this.getCallLogs();
    }

    /**
     * Sets the default order for the pending calls.
     */
    setDefaultOrder(): void {
        this.setOrder('Fecha de Modificación','DESC');
    }

    /**
     * Sets the order for the pending calls table based on the specified column and order type.
     * 
     * @param column - The column to order by.
     * @param orderType - The order type (e.g., 'asc' for ascending, 'desc' for descending).
     */
    setOrder(column: string, orderType: string): void {
        const orderedColumn = getCallLogField(column);
        this._utilsService.processOrder(
            this._mySqlService.callLogsTableName,
            this._utilsService.orderCallLogs,
            orderedColumn,
            orderType
        )
    }

    /**
     * Clears the filter and order settings for pending calls.
     * This method resets the orderCallLogs and filterCallLogs properties
     * in the _utilsService and clears the corresponding session storage items.
     */
    clearCallLogFilterAndOrder() {
        this._utilsService.orderCallLogs = [];
        this._utilsService.filterCallLogs = {};
        sessionStorage.setItem('orderCallLogs', '');
        sessionStorage.setItem('filterCallLogs', '');
    }

    /**
     * Reloads the current route.
     */
    reload() {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Sets the loading state and displays or hides the spinner accordingly.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean) {
        this.loading = state;
        if (state) {
            this._spinner.show('innerSpinner', {
                type: this._utilsService.getRandomNgxSpinnerType(),
            });
        } else {
            this._spinner.hide('innerSpinner');
        }
    }

    /**
     * Sets the pending calls in the table.
     * 
     * @param callLogs - An array of CallLog objects.
     */
    setCallLogsInTable(callLogs: CallLog[]) {
        this.dataSource.data = [];
        this.callLogs = callLogs;
        for (let [index, callLog] of this.callLogs.entries()) {
            callLog.ID = index + 1;
        }
        this.dataSource.data = this.callLogs.slice(0, this.rowsLimit);
        this.showLoading(false);
    }

    /**
     * Retrieves the pending calls.
     * If a phone number is provided, applies a filter based on the phone number.
     */
    async getCallLogs() {
        if (this.phone_number) await this.applyFilter([this.phone_number], 'phone_number');
        else this.setCallLogsInTable(await this.selectCallLogs());
    }

    /**
     * Retrieves pending calls from the MySQL service.
     * 
     * @returns {Promise<any>} A promise that resolves to the pending calls.
     */
    async selectCallLogs(): Promise<CallLog[]> {
        this.showLoading(true);
        this.length = await this._mySqlService.getCallLogsCount('');
        const order = this._utilsService.orderCallLogs;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);

        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndexCallLog');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndexCallLog', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }

        return await this._mySqlService.getCallLogs(
            undefined,
            undefined,
            order_clause,
            offset,
            this.pageSize.toString()
        );
    }

    /**
     * Selects all pending calls in the table.
     */
    async selectAll() {
        this.clickedRows.clear();
        const callLogs = this.callLogs;
        for (const callLog of callLogs) {
            if (!this.clickedRows.has(callLog)) {
                this.clickedRows.add(callLog);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} llamadas pendientes`);
    }

    /**
     * Filters the data source based on the provided search value.
     * @param event - The search value to filter the data source.
     */
    searchValue(event: any) {
        const filterValue: string = event;
        this.dataSource!.filter = filterValue.trim().toLowerCase();
    }

    async pageEvent(event: any) {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            if (this.lastPageIndex < event.pageIndex) {
                this.setCallLogsInTable(
                    await this._mySqlService.getNextCallLogsPage(
                        event.pageIndex - this.lastPageIndex
                    )
                );
            } else {
                this.setCallLogsInTable(
                    await this._mySqlService.getPreviousCallLogsPage(
                        this.lastPageIndex - event.pageIndex
                    )
                );
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('callLog_pageSize', this.pageSize.toString());
            await this.getCallLogs();
        }
        this.scrollOffset = 50;
    }

    async filterCallLog(where?: string) {
        this.length = await this._mySqlService.getCallLogsCount(where);
        const order = this._utilsService.orderCallLogs;
        let order_clause = undefined;
        if (order.length > 0) {
            order_clause = this._utilsService.getOrderClauseFromOrder(order);
        }
        let callLogs: CallLog[] = [];
        try {
            callLogs = await this._mySqlService.getCallLogs(
                undefined,
                where,
                order_clause,
                '0',
                this.pageSize.toString()
            );
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        return callLogs;
    }

    async applyFilter(values: any, column: string, not_empty: boolean = false, empties_checked: boolean = false) {
        let where_clause = this._utilsService.getWhereClauseFromFilter(
            this._utilsService.processFilter(
                this._utilsService.filterCallLogs!,
                values,
                column,
                getCallLogFieldType(column),
                this._mySqlService.callLogsTableName,
                true, 
                not_empty,
                empties_checked
            )
        );
        this.showLoading(true);
        localStorage.setItem('lastPageIndexCallLog', '');
        this.lastPageIndex = 0;
        this.setCallLogsInTable(await this.filterCallLog(where_clause));
    }

    async filterBy(column: string) {
        this.filteredColumn = getCallLogField(column);
        if (getCallLogFieldType(this.filteredColumn) == 'Date') {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            let times: Date[];
            try {
                times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } else {
            const result = await this._utilsService.openFilterDialog(
                column,
                this.filteredColumn,
                this._mySqlService.callLogsTableName,
                this._utilsService.filterCallLogs
            );
            if (result && result.data) {
                this.applyFilter(result.data, result.column, result.not_empty, result.empties_checked);
            }
        }
    }

    /**
     * Handles the selection of a date range and applies the filter based on the selected dates.
     * @param dateRange - The selected date range.
     * @param timeRange - The optional selected time range.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    /**
     * Orders the pending calls based on the specified column and order type.
     * @param event - The event object containing the column and order type.
     */
    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getCallLogField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.callLogsTableName,
                this._utilsService.orderCallLogs,
                orderedColumn,
                orderType
            )
        );
        const filter = this._utilsService.filterCallLogs;
        let where_clause = undefined;
        if (filter && filter.fields) {
            where_clause = this._utilsService.getWhereClauseFromFilter(filter);
        }
        this.showLoading(true);
        const callLogs = await this._mySqlService.getCallLogs(
            undefined,
            where_clause,
            order_clause,
            '0',
            this.pageSize.toString()
        );
        localStorage.setItem('lastPageIndexCallLog', '');
        this.lastPageIndex = 0;
        this.setCallLogsInTable(callLogs);
    }

    /**
     * Adds a new row to the pending calls table.
     * 
     * @param event - The event object triggered by the user action.
     * @returns A Promise that resolves to void.
     */
    async addNewRow(event: any) {
        const callLog = await this._utilsService.openCallLogDialog('');
        if (callLog) {
            this.callLogs.push(callLog);
            this.dataSource.data = [];
            this.dataSource.data = this.callLogs.slice(0, this.scrollOffset);
        }
    }

    /**
     * Handles the double click event on a row in the pending calls table.
     * @param row - The clicked row object.
     * @returns void
     */
    async doubleClickedRow(row: any) {
        localStorage.setItem('lastPageIndexCallLog', this.lastPageIndex.toString());

        let callLog = await this._utilsService.openCallLogDialog(row.id);
        if (callLog) {
            callLog.ID = this.callLogs[row.rowIndex].ID;
            this.callLogs[row.rowIndex] = callLog;
            
            this.setCallLogsInTable(this.callLogs);
        }
    }

    /**
     * Handles the click event on a row in the pending calls table.
     * @param receivedEvent - The event object containing information about the clicked row.
     */
    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
            } else if (event.ctrlKey) {
                this.toggleRow(row);
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
                console.log('**************** event.shiftKey ***************');
            }
        }
        if (this._windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this._windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this._windowRefService.nativeWindow.getSelection().empty();
            } else if (this._windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this._windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    /**
     * Handles the selection of rows in the pending calls table.
     * 
     * @param event - The selection event containing the selected rows.
     */
    selectedRows(event: any) {
        this.allSelected = false;
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    /**
     * Selects rows between two indexes and adds them to the clickedRows set.
     * If showSnackBar is true and more than one row is selected, it displays a snackbar with the number of selected rows.
     *
     * @param lastSelectedRow - The index of the last selected row.
     * @param rowIndex - The index of the current row.
     * @param showSnackBar - Optional. Specifies whether to show a snackbar. Default is true.
     */
    selectRowsBetweenIndexes(lastSelectedRow: number, rowIndex: number, showSnackBar: boolean = true) {
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.callLogs[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} llamadas pendientes`);
        }
    }

    /**
     * Toggles the clicked state of a row.
     * If the row is already clicked, it will be unclicked.
     * If the row is not clicked, it will be clicked.
     * 
     * @param row - The row to toggle.
     */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) {
            this.clickedRows.delete(row);
        } else {
            this.clickedRows.add(row);
        }
    }

    /**
     * Exports the pending calls in a table format.
     * If there are clicked rows, it exports only the selected rows.
     * If there are no clicked rows, it exports all the pending calls.
     */
    exportCallLogsInTable() {
        if (this.clickedRows.size > 0) this.exportExcel(this.clickedRows);
        else this.exportExcel(this.callLogs);
    }

    /**
     * Exports the pending calls to an Excel file.
     * 
     * @param callLogs - The pending calls to export.
     */
    exportExcel(callLogs: any) {
        let excelFormatCallLogs = [];
        for (let callLog of callLogs) {
            let data: any = {};
            const columns = getCallLogExcelExportColumns();
            columns.forEach((column) => {
                data[column] = this._utilsService.fieldPipe(
                    callLog[getCallLogExcelFieldName(column)],
                    column
                );
            });
            excelFormatCallLogs.push(data);
        }
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatCallLogs);

        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

        /* save to file */
        XLSX.writeFile(wb, 'llamadas pendientes_Exportados.xlsx');
    }

    /**
     * Updates the actual page information for the pending call.
     * 
     * @param callLog - The pending call object.
     * @param keys - An array of keys to update in the pending call object.
     */
    updateActualPageInfo(callLog: any, keys: any) {
        for (let row of this.clickedRows) {
            const index = this.callLogs.indexOf(row, 0);
            for (let key of keys) {
                const keyIndex = key as keyof CallLog;
                let value = callLog[keyIndex]!; //this.task[key as keyof Itac];
                row[keyIndex] = value as any;
            }
            this.callLogs[index] = row;
        }
    }

    /**
    * Deletes the selected counters.
    * 
    * @remarks
    * This method deletes the counters that have been selected by the user. It prompts the user for confirmation before deleting the counters.
    * If any errors occur during the deletion process, an error message is displayed.
    * 
    * @returns A Promise that resolves when the counters have been deleted.
    */
    async deleteCallLogs() {
        if (!this.validateSelection()) return;
    
        const confirmed = await this.confirmDeletion();
        if (!confirmed) return;
    
        this.startLoading();
        const { successfulDeletes, errorIds } = await this.processDeletions();
    
        this.handleDeletionResults(successfulDeletes, errorIds);
        this.finalizeDeletion(successfulDeletes);
    }
    
    /**
     * Validates if there are any selected rows.
     * 
     * This method checks if the `clickedRows` set is empty. If it is, it displays a warning
     * message using the `_utilsService` and returns `false`. If there are selected rows, it returns `true`.
     * 
     * @returns {boolean} - Returns `true` if there are selected rows, otherwise `false`.
     */
    private validateSelection(): boolean {
        if (this.clickedRows.size === 0) {
            this._utilsService.openSnackBar(
                'Debe seleccionar al menos una llamada pendiente',
                'warning'
            );
            return false;
        }
        return true;
    }

    /**
     * Opens a confirmation dialog to ask the user if they want to delete the selected pending calls.
     *
     * @returns {Promise<boolean>} A promise that resolves to a boolean indicating whether the user confirmed the deletion.
     */
    private async confirmDeletion(): Promise<boolean> {
        return await this._utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea eliminar las llamadas pendientes seleccionados?'
        );
    }

    /**
     * Initiates the loading process by displaying a spinner and setting the loading text.
     * This method is typically called when pending calls are being eliminated.
     *
     * @private
     */
    private startLoading(): void {
        this._spinner.show();
        this.loadingText = 'Eliminando llamadas pendientes ...';
    }

    /**
     * Processes the deletion of selected pending calls.
     * 
     * This method retrieves the selected row indexes and iterates through the pending calls data source.
     * For each selected row, it attempts to delete the corresponding pending call using the API service.
     * It keeps track of successful deletions and errors, and updates the loading text to reflect the progress.
     * 
     * @returns A promise that resolves to an object containing arrays of successfully deleted items and error IDs.
     * 
     * @property {any[]} successfulDeletes - An array of successfully deleted pending calls.
     * @property {number[]} errorIds - An array of IDs for pending calls that failed to delete.
     */
    private async processDeletions(): Promise<{ successfulDeletes: any[]; errorIds: number[] }> {
        const rowIndexes = this.getSelectedRowIndexes();
        const oldDataSource = this.callLogs;
    
        let callLogs = [];
        let errorIds: number[] = [];
        let successfulDeletes: CallLog[] = [];
        let deleteCount = 0;
    
        for (let i = 0; i < oldDataSource.length; i++) {
            if (rowIndexes.has(i)) {
                this.loadingText = `Eliminando llamadas pendientes ${++deleteCount} de ${rowIndexes.size}`;
                const isDeleted = await this._apiService.deleteCallLog(oldDataSource[i].id!);
                if (isDeleted) successfulDeletes.push(oldDataSource[i]);
                else errorIds.push(oldDataSource[i].id!);
            } 
            else callLogs.push(oldDataSource[i]);
        }
        return { successfulDeletes, errorIds };
    }

    /**
     * Handles the results of deletion operations for pending calls.
     *
     * @param successfulDeletes - An array of successfully deleted items.
     * @param errorIds - An array of IDs that encountered errors during deletion.
     * 
     * If there are any errors (i.e., `errorIds` is not empty), logs the successful deletions and errors,
     * and displays an error message using the `_utilsService.openSnackBar` method.
     * If there are no errors, displays a success message using the `_utilsService.openSnackBar` method.
     */
    private handleDeletionResults(successfulDeletes: any[], errorIds: number[]): void {
        if (errorIds.length > 0) {
            console.log('============= errorIds =============');
            console.log(errorIds);
            this._utilsService.openSnackBar(
                `Hubo errores eliminando llamadas pendientes`,
                'error'
            );
        } else {
            this._utilsService.openSnackBar(
                `Llamadas pendientes eliminadas correctamente`
            );
        }
    }

    /**
     * Finalizes the deletion of pending calls by removing the specified rows from the pending calls list,
     * updating the table, and resetting relevant states.
     *
     * @param removedRows - An array of `CallLog` objects that have been removed.
     */
    private finalizeDeletion(removedRows: CallLog[]): void {
        this.callLogs = this.callLogs.filter(
            (call) => !removedRows.some((removed) => removed.id === call.id)
        );
        this.setCallLogsInTable(this.callLogs);
    
        // Update the total length and reset selections
        this.length -= removedRows.length;
        this.allSelected = false;
        this.clickedRows.clear();
        this._spinner.hide();
    }

    /**
     * Retrieves the indexes of the selected rows from the `callLogs` array.
     *
     * This method iterates over the `clickedRows` array and finds the index of each row
     * in the `callLogs` array. If the row is found, its index is added to a set.
     *
     * @returns {Set<number>} A set containing the indexes of the selected rows.
     */
    private getSelectedRowIndexes(): Set<number> {
        const rowIndexes = new Set<number>();
        this.clickedRows.forEach((row) => {
            const index = this.callLogs.indexOf(row, 0);
            if (index > -1) {
                rowIndexes.add(index);
            }
        });
        return rowIndexes;
    }
    
}
