/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { SplashComponent } from './components/splash/splash.component';
import { GoogleMapsComponent } from './components/google-maps/google-maps.component';
import { TaskComponent } from './components/task/task.component';
import { TaskLocationComponent } from './components/task-location/task-location.component';
import { ItacsComponent } from './components/itacs/itacs.component';
import { ItacComponent } from './components/itac/itac.component';
import { ItacLocationComponent } from './components/itac-location/itac-location.component';
import { CountersComponent } from './components/counters/counters.component';
import { WaterRoutesComponent } from './components/water-routes/water-routes.component';
import { CalibersComponent } from './components/tables/calibers/calibers.component';
import { CausesComponent } from './components/tables/causes/causes.component';
import { ClassCountersComponent } from './components/tables/class-counters/class-counters.component';
import { EmplacementsComponent } from './components/tables/emplacements/emplacements.component';
import { LongitudesComponent } from './components/tables/longitudes/longitudes.component';
import { MarksComponent } from './components/tables/marks/marks.component';
import { ObservationsComponent } from './components/tables/observations/observations.component';
import { PartsComponent } from './components/tables/parts/parts.component';
import { ResultsComponent } from './components/tables/results/results.component';
import { TypeCountersComponent } from './components/tables/type-counters/type-counters.component';
import { ZonesComponent } from './components/tables/zones/zones.component';
import { ZoneLocationComponent } from './components/tables/zone-location/zone-location.component';
import { CompaniesComponent } from './components/tables/companies/companies.component';
import { ManagersComponent } from './components/tables/managers/managers.component';
import { InfosComponent } from './components/tables/infos/infos.component';
import { TeamsComponent } from './components/tables/teams/teams.component';
import { UsersComponent } from './components/users/users.component';
import { AuthGuardService } from './services/auth-guard.service';
import { AuthGuardClientsService } from './services/auth-guard-clients.service';
import { UserLocationComponent } from './components/user-location/user-location.component';
import { TypeRadiussComponent } from './components/tables/type-radiuss/type-radiuss.component';
import { ChangePasswordComponent } from './components/share/change-password/change-password.component';
import { AgrupationsComponent } from './components/tables/agrupations/agrupations.component';
import { ActivationLogsComponent } from './components/tables/activation-logs/activation-logs.component';
import { AuthGuardSuperuserService } from './services/auth-guard-superuser.service';
import { RadiusModulesComponent } from './components/tables/radius-modules/radius-modules.component';
import { IntegrationItelazpisComponent } from './components/tables/integration-itelazpis/integration-itelazpis.component';
import { CalendarComponent } from './components/share/calendar/calendar.component';
import { SidesComponent } from './components/sides/sides.component';
import { CalendarTableComponent } from './components/share/calendar-table/calendar-table.component';
import { StatisticsTotalComponent } from './components/share/statistics/statistics-total/statistics-total.component';
import { StatisticsAvgComponent } from './components/share/statistics/statistics-avg/statistics-avg.component';
import { UserActivityComponent } from './components/share/statistics/user-activity/user-activity.component';
import { PlanningsComponent } from './components/tables/plannings/plannings.component';
import { PlanningDetailsComponent } from './components/tables/planning-details/planning-details.component';
import { PlanningDetailExtrasComponent } from './components/tables/planning-detail-extras/planning-detail-extras.component';
import { PendingCallsComponent } from './components/tables/pending-calls/pending-calls.component';
import { CallLogsComponent } from './components/tables/call-logs/call-logs.component';

//TODO: remake components in lazy load
const routes: Routes = [
    { path: 'splash', component: SplashComponent },
    { path: 'login', component: LoginComponent },
    {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'calendar',
        component: CalendarComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'calendar/:id',
        component: CalendarComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'calendar-table',
        component: CalendarTableComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'task/:id',
        component: TaskComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'task-location/:id',
        component: TaskLocationComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'google_maps',
        component: GoogleMapsComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'itacs',
        component: ItacsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'itac/:id',
        component: ItacComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'itac-location/:id',
        component: ItacLocationComponent,
        canActivate: [AuthGuardService],
    },
    {
        path: 'sides',
        component: SidesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'counters',
        component: CountersComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'water_routes',
        component: WaterRoutesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'calibers',
        component: CalibersComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'causes',
        component: CausesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'class_counters',
        component: ClassCountersComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'emplacements',
        component: EmplacementsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'longitudes',
        component: LongitudesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'marks',
        component: MarksComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'observations',
        component: ObservationsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'parts',
        component: PartsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'results',
        component: ResultsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'plannings',
        component: PlanningsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'planning-details',
        component: PlanningDetailsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'planning-detail-extras',
        component: PlanningDetailExtrasComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'type_counters',
        component: TypeCountersComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'type_radius',
        component: TypeRadiussComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'zones',
        component: ZonesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'zone-location/:id',
        component: ZoneLocationComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'managers',
        component: ManagersComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'agrupations',
        component: AgrupationsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'activation-logs',
        component: ActivationLogsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'activation-logs/:serial',
        component: ActivationLogsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'integration-itelazpis',
        component: IntegrationItelazpisComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'integration-itelazpis/:serial',
        component: IntegrationItelazpisComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'radius-modules',
        component: RadiusModulesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'radius-modules/:serial',
        component: RadiusModulesComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'pending-calls',
        component: PendingCallsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'pending-calls/:phone_number',
        component: PendingCallsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'call-logs',
        component: CallLogsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'infos',
        component: InfosComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'teams',
        component: TeamsComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService],
    },
    {
        path: 'users',
        component: UsersComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'user-location/:id',
        component: UserLocationComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'user-location/:id/:option',
        component: UserLocationComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'statistics-total',
        component: StatisticsTotalComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },
    {
        path: 'statistics-avg',
        component: StatisticsAvgComponent,
        canActivate: [AuthGuardService, AuthGuardClientsService, AuthGuardSuperuserService],
    },

    {
        path: 'new-password',
        component: ChangePasswordComponent,
    },

    { path: '**', pathMatch: 'full', redirectTo: '/splash' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
