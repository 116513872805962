<mat-dialog-content class="animate__animated animate__fadeIn">
    <div [formGroup]="pendingCallFormData" class="animate__animated animate__fadeIn">
        <div>
            <div style="margin-top: 5px; margin-bottom: 30px; margin-left: 5px; display: flex;">                        
                <p style="font-size: large;">Datos de llamada</p>
                <div style="flex: 2"></div>
                <button
                    mat-flat-button
                    aria-label="Phone"
                    style="margin-top: 0px; margin-right: 10px"
                    matTooltip="Llamar a cliente"
                    (click)="changeStatus()"
                    >
                    <mat-icon color="primary">phone</mat-icon>
                    <span style="margin-left: 10px">Se ha llamado</span>
                </button>
                <button
                    mat-flat-button
                    aria-label="Phone"
                    style="margin-top: 0px; margin-right: 10px"
                    matTooltip="Ver tarea"
                    (click)="openTask()"
                    >
                    <mat-icon color="primary">assignment</mat-icon>
                    <span style="margin-left: 10px">Ver tarea</span>
                </button>
            </div> 
            <div class="container">
                <!-- Row 1: Phone Number, Pending Status, Last Event -->
                <div class="row-content">
                    <mat-form-field style="width: 200px" appearance="fill">
                        <mat-label>Teléfono</mat-label>
                        <input
                            formControlName="phone_number"
                            matInput
                            type="text"
                            placeholder="Teléfono"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field style="width: 150px" appearance="fill">
                        <mat-label>Pendiente de llamar</mat-label>
                        <input
                            [value]="pendingCallFormData.get('is_pending')?.value ? 'Sí' : 'No'"
                            matInput
                            type="text"
                            placeholder="Pendiente de llamar"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field style="width: 140px" appearance="fill">
                        <mat-label>Último Evento</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker_last_event_time"
                            formControlName="last_event_time"
                            placeholder="Fecha de Último Evento"
                            readonly
                        />
                        <mat-datepicker #picker_last_event_time></mat-datepicker>
                    </mat-form-field>
                </div>
                <!-- Row 2: Address -->
                <div class="row-content">
                    <mat-form-field appearance="fill" style="flex: 1">
                        <mat-label>Dirección</mat-label>
                        <textarea
                            matInput
                            formControlName="address"
                            placeholder="Dirección..."
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2"
                            readonly
                        >
                        </textarea>
                    </mat-form-field>
                </div>

                <!-- Row 3: comments -->
                <div class="row-content">
                    <mat-form-field appearance="fill" style="flex: 1">
                        <mat-label>Comentarios</mat-label>
                        <textarea
                            matInput
                            formControlName="comments"
                            placeholder="Comentarios..."
                            cdkTextareaAutosize
                            #autosize="cdkTextareaAutosize"
                            cdkAutosizeMinRows="2"
                            readonly
                            >
                        </textarea>
                    </mat-form-field>
                </div>
                
                <!-- Row 4: -->
                <div class="row-content">
                    <mat-form-field style="width: 200px" appearance="fill">
                        <mat-label>Número de Abonado</mat-label>
                        <input
                            formControlName="Numero_de_ABONADO"
                            matInput
                            type="text"
                            placeholder="Número de Abonado"
                            readonly
                        />
                    </mat-form-field>
                    <mat-form-field style="width: 140px" appearance="fill">
                        <mat-label>Fecha Modificación</mat-label>
                        <input
                            matInput
                            [matDatepicker]="picker_date_time_modified"
                            formControlName="date_time_modified"
                            placeholder="Fecha Modificación"
                            readonly
                        />
                        <mat-datepicker #picker_date_time_modified></mat-datepicker>
                    </mat-form-field>
                </div>
            </div>
        </div>
    </div>

    <ngx-spinner
        [bdColor]="pendingCall ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
        size="large"
        color="#368DCE"
        type="ball-scale-multiple"
        [fullScreen]="false"
        name="pendingCallSpinner"
    >
    </ngx-spinner>
</mat-dialog-content>

<mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="pendingCall">Aceptar</button>
</mat-dialog-actions>
