import { Component, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';

export interface MultipleOptionsDialogData {
    placeHolderText: string;
    options: string[];
    currentOptionsSelected: string[];
    enableSearch: boolean;
}

@Component({
    selector: 'app-multiple-selector',
    templateUrl: './multiple-selector.component.html',
    styleUrls: ['./multiple-selector.component.scss'],
})
export class MultipleSelectorComponent implements OnInit {
    @Input() placeHolderText = '';
    @Input() options: string[] = [];
    originalOptions: string[] = [];
    enableSearch = false;
    cleaned = false;

    selectedOptions: string[] = [];
    previousSelectedOptions: string[] = [];

    formGroup = new FormGroup({
        currentOptionsSelected: new FormControl(),
        search_value: new FormControl(),
    });

    /**
     * Constructs an instance of MultipleSelectorComponent.
     * 
     * @param data - The data injected into the dialog, containing the following properties:
     *   - placeHolderText: The placeholder text to display.
     *   - options: The list of options available for selection.
     *   - currentOptionsSelected: The list of options currently selected.
     *   - enableSearch: A boolean indicating whether the search functionality is enabled.
     * 
     * Initializes the component with the provided data, sets up the form controls, and subscribes to the search value changes if search is enabled.
     */
    constructor(@Inject(MAT_DIALOG_DATA) public data: MultipleOptionsDialogData) {
        this.placeHolderText = data.placeHolderText;
        this.options = data.options;
        this.originalOptions = [...data.options];
        this.selectedOptions = [...data.currentOptionsSelected];
        this.previousSelectedOptions = [...data.currentOptionsSelected];
        this.enableSearch = data.enableSearch;

        this.formGroup.controls['currentOptionsSelected'].setValue(this.selectedOptions);

        if (this.enableSearch) {
            this.formGroup.controls['search_value'].valueChanges.subscribe((value: string) => {
                this.applyFilter(value);
            });
        }
    }

    /**
     * A lifecycle hook that is called after Angular has initialized all data-bound properties of a directive.
     * Implement this interface to execute custom initialization logic after your directive's data-bound properties are initialized.
     * This method is called once, after the first `ngOnChanges`.
     */
    ngOnInit(): void {}

    /**
     * Filters the options based on the provided value.
     * 
     * @param value - The string value to filter the options by. If the value is not empty,
     *                the options will be filtered to include only those that contain the value
     *                (case insensitive). If the value is empty, all original options will be restored.
     */
    applyFilter(value: string) {
        this.cleaned = true;
        if (value) {
            this.options = this.originalOptions.filter((option: string) =>
                option.toLowerCase().includes(value.toLowerCase())
            );
        }
        else this.options = [...this.originalOptions];
    }

    /**
     * Handles the selection change event for the multiple selector component.
     * 
     * This method is triggered when the user selects or deselects options in the multiple selector.
     * It ensures that previously selected options that are no longer available in the current options
     * are retained in the selection.
     * 
     * @param event - The selection change event containing the new selection value.
     */
    onSelectedOption(event: MatSelectChange): void {//+
        const currentSelected = event.value as string[];
        for (const previousSelectedOption of this.previousSelectedOptions) {
            if(!this.options.includes(previousSelectedOption)) {
                currentSelected.push(previousSelectedOption);
            }
        }
        this.previousSelectedOptions = [...currentSelected];
        this.selectedOptions = currentSelected;
    }
}
