<mat-dialog-content [formGroup]="formGroup">
    <div *ngIf="enableSearch" class="input_search">
        <mat-form-field>
            <mat-label>Filtrar</mat-label>
            <input
                matInput
                formControlName="search_value"
                color="accent"
                placeholder="Filtrar lista..."
            />
        </mat-form-field>
    </div>
    <div style="display: flex;">
        <mat-form-field style="flex: 3;" appearance="fill" [matTooltip]="placeHolderText">
            <mat-label>{{ placeHolderText }}</mat-label>
            <mat-select name="option" required #selectedOption>
                <mat-option *ngFor="let option of options" [value]="option" [matTooltip]="option">
                    {{ option }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-button mat-dialog-close>Cancelar</button>
    <button mat-button 
        [mat-dialog-close]="selectedOption.value"
        [disabled]="!(selectedOption.value)">
    Aceptar
    </button>
</mat-dialog-actions>
