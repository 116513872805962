/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
    faMobile,
    faSignOutAlt,
    faBuilding,
    faFilter,
    faHome,
    faHouseUser,
    faUserCog,
    faAsterisk,
    faUsers,
    faBarcode,
    faMapMarkedAlt,
    faMap,
    faIdBadge,
    faCalendar,
    faBorderNone,
    faPhoneAlt,
    faSortNumericAsc,
    faCheckCircle,
    faShoppingCart,
    faSync,
    faToolbox,
    faUserGroup,
    faTools,
    faReply,
    faUser,
    faCircleExclamation,
    faHeadset,
    faCalendarDays,
    faCalendarCheck,
    faEye,
    faChartLine,
    faList,
    faUserClock,
    faDrawPolygon,
    faMobileScreenButton,
    faCircleQuestion,
    faListCheck,
    faSquareCheck,
} from '@fortawesome/free-solid-svg-icons';
import { Company } from 'src/app/interfaces/company';
import { Manager } from 'src/app/interfaces/manager';
import { ApiService } from 'src/app/services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessagingService } from 'src/app/services/messaging.service';
import { Subscription } from 'rxjs';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { Message } from '../../../services/messaging.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, AfterViewInit {
    @Input() userImageUrl: string = '';
    @Input() marginImage: boolean = false;
    @Input() showCleanFilter: boolean = false;
    @Input() showFilter: boolean = false;
    @Input() showActivity: boolean = false;
    @Input() showBackButton: boolean = false;
    @Input() showCalendar: boolean = false;
    @Input() taskTable: boolean = false;

    @Output() sendLoading: EventEmitter<boolean> = new EventEmitter();
    @Output() sendSearchValue: EventEmitter<string> = new EventEmitter();
    @Output() sendToggleDrawer: EventEmitter<void> = new EventEmitter();
    @Output() sendCompanyAndGestorChanged: EventEmitter<void> = new EventEmitter();
    @Output() sendFilter: EventEmitter<string> = new EventEmitter();
    @Output() sendSelectAll: EventEmitter<void> = new EventEmitter();
    @Output() sendShowUserActivity: EventEmitter<string> = new EventEmitter();
    @Output() sendShowCalendar: EventEmitter<string> = new EventEmitter();
    @Output() sendPreviousView: EventEmitter<void> = new EventEmitter();

    toolbarGroup: FormGroup = new FormGroup({
        search_value: new FormControl(),
    });

    notificationCount = 0;
    faCalendarCheck = faCalendarCheck;
    faSignOutAlt = faSignOutAlt;
    faBuilding = faBuilding;
    faList = faList;
    faUserClock = faUserClock;
    faFilter = faFilter;
    faChartLine = faChartLine;
    faMobileScreenButton = faMobileScreenButton;
    faSquareCheck = faSquareCheck;
    faEye = faEye;
    faSync = faSync;
    faBorderNone = faBorderNone;
    faReply = faReply;
    faHome = faHome;
    faHouseUser = faHouseUser;
    faToolbox = faToolbox;
    faTools = faTools;
    faUserCog = faUserCog;
    faUsers = faUsers;
    faBarcode = faBarcode;
    faMapMarkedAlt = faMapMarkedAlt;
    faMap = faMap;
    faDrawPolygon = faDrawPolygon;
    faIdBadge = faIdBadge;
    faUser = faUser;
    faUserGroup = faUserGroup;
    faAsterisk = faAsterisk;
    faCalendar = faCalendar;
    faCalendarDays = faCalendarDays;
    faPhoneAlt = faPhoneAlt;
    faHeadset = faHeadset;
    faListCheck = faListCheck;
    faMobile = faMobile;
    faCircleQuestion = faCircleQuestion;
    faSortNumericAsc = faSortNumericAsc;
    faCheckCircle = faCheckCircle;
    faShoppingCart = faShoppingCart;
    faCircleExclamation = faCircleExclamation;

    inputStyle = 'font-size: .7em; height: 20px; margin-top: -5px; margin-right: 35px;';
    buttonStyle = 'margin-top: -5px;';
    iconStyle = 'font-size: 50px; margin-top: 0px; margin-right: 20px;';
    cleanFilterStyle = 'margin-top: 15px; background-color: transparent';
    filterStyle = 'max-width: 54px; max-height: 54px; margin-top: 15px; background-color: transparent;';

    messagingSubscription?: Subscription;

    /**
     * Constructs a new instance of the ToolbarComponent.
     * @param utilsService - The UtilsService instance.
     * @param _apiService - The ApiService instance.
     * @param router - The Router instance.
     * @param route - The ActivatedRoute instance.
     * @param _messagingService - The MessagingService instance.
     */
    constructor(
        public utilsService: UtilsService,
        private _apiService: ApiService,
        private router: Router,
        private route: ActivatedRoute,
        private _messagingService: MessagingService
    ) {
        this.messagingSubscription = this._messagingService.sendedMessage$.subscribe(
            async (message: Message) => {
                if (message.text == 'User image changed') this.reload();
                else if(message.text == 'Open notifications') await this.showNotifications();
                else if(message.text == 'Update notification count') await this.getNotificationCount();
            }
        );
    }
    
    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is used to perform any initialization logic for the component.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit(): Promise<void> {
        this.searchActions();
        this.setDefautStyle();
        await this.checkCompanyAndManager();
    }

    /**
     * Lifecycle hook that is called after a component's view has been fully initialized.
     * It is called after the `ngAfterContentInit` hook.
     * 
     * @returns A promise that resolves when the `getNotificationCount` method has completed.
     */
    async ngAfterViewInit(): Promise<void> {
        await this.getNotificationCount();
    }

    /**
     * Performs search actions based on the provided search .
     */
    async searchActions() {
        if(this.taskTable) {
            const term = this.utilsService.getValueFieldFromFilter(
                this.utilsService.filterTasks!,'term');
            if(term) this.toolbarGroup.controls['search_value'].setValue(term);
        }
        this.toolbarGroup.controls['search_value'].valueChanges.subscribe((term) => {
            this.sendSearchValue.emit(term);
        });
    }

    /**
     * Checks if the company and manager IDs are present in the local storage.
     * If not, prompts the user to select a company and manager, and retrieves teams and users.
     * If the IDs are present, emits an event indicating that the company and manager have changed.
     */
    async checkCompanyAndManager(): Promise<void> {
        const companyId = localStorage.getItem('company');
        const managerId = localStorage.getItem('manager');

        if (!companyId || !managerId) {
            try {
                const alreadyRequested = sessionStorage.getItem('selecting company');
                if (alreadyRequested && alreadyRequested == 'true') return;
                sessionStorage.setItem('selecting company', 'true');
                await this.selectCompanyAndGestor();
                await this._apiService.getTeams();
                await this._apiService.getUsers(['administrador', 'operario']);
            } catch (err) {
                console.log('************* err *************');
                console.log(err);
            }
        } else this.sendCompanyAndGestorChanged.emit();
    }

    /**
     * Sets the default styles for the toolbar component.
     */
    setDefautStyle(): void {
        try {
            if (this.marginImage) {
                this.inputStyle = 'font-size: .7em; height: 20px; margin-top: 7px; margin-right: 35px;';
                this.buttonStyle = 'margin-top: 5px;';
                this.iconStyle = 'font-size: 50px; margin-top: 5px; margin-right: 20px;';
            }
            else {
                this.buttonStyle = 'margin-top: 2px;';
                this.inputStyle = 'font-size: .7em; height: 20px; margin-top: 0px; margin-right: 35px; margin-left: 25px;';
                this.cleanFilterStyle = 'margin-top: 0px; background-color: transparent';
                this.filterStyle = 'margin-top: 2px; background-color: transparent';
            }
            const photo = this._apiService.getUserImage();
            if (photo) this.userImageUrl = photo;
        } catch (err) {}
    }

    /**
     * Returns the CSS style for the icon.
     * @returns The CSS style for the icon.
     */
    getIconStyle(): string {
        if(this.taskTable){
            return 'max-width: 54px; max-height: 54px; margin-top: 15px; background-color: transparent;';
        }
        else return 'max-width: 54px; max-height: 54px; background-color: transparent;';
    }
    
    /**
     * Retrieves the count of new notifications asynchronously.
     * @returns {Promise<void>} A promise that resolves when the notification count is retrieved.
     */
    async getNotificationCount(): Promise<void> {
        this.notificationCount = await this._apiService.getNewNotificationCount();
    }

    /**
     * Shows the notifications by opening the notification dialog.
     * @returns {Promise<void>} A promise that resolves when the notification dialog is opened.
     */
    async showNotifications(): Promise<void> {
        this.utilsService.openNotificationDialog();
    }

    /**
     * Lifecycle hook that is called when the component is destroyed.
     * It is used to perform any necessary cleanup logic before the component is removed from the DOM.
     */
    ngOnDestroy(): void {
        this.messagingSubscription?.unsubscribe();
    }

    /**
     * Opens the calendar.
     * 
     * @param type - The type of calendar to open.
     */
    openCalendar(type: string): void {
        this.sendShowCalendar.emit(type);
    }

    /**
     * Shows the user activity.
     * Emits an event to notify the parent component.
     */
    showUserActivity(type: string): void {
        this.sendShowUserActivity.emit(type);
    }

    /**
     * Clears the filter and reloads the data.
     */
    clearFilter(): void {
        this.utilsService.clearFiltersAndOrders();
        this.utilsService.openSnackBar('Se ha eliminado el filtro configurado');
        this.reload();
    }

    /**
     * Reloads the current route.
     */
    reload(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Selects all items in the table.
     */
    selectAllTable(): void {
        this.sendSelectAll.emit();
    }

    /**
     * Navigates to the previous view.
     */
    previousView(): void {
        this.sendPreviousView.emit();
    }

    /**
     * Filters the data based on the specified filter type.
     * @param filterType - The type of filter to apply.
     */
    filter(filterType: string) {
        this.sendFilter?.emit(filterType);
    }

    /**
     * Toggles the drawer.
     */
    toggleDrawer(): void {
        this.sendToggleDrawer.emit();
    }

    /**
     * Logs out the user.
     * @returns A promise that resolves when the user is logged out.
     */
    async logout(): Promise<void> {
        const res = await this.utilsService.openQuestionDialog(
            'Confirmación',
            '¿Desea cerrar al sesión?'
        );
        if (res) {
            await this._apiService.logout();
            localStorage.setItem('company', '');
            localStorage.setItem('manager', '');
            this.router.navigate(['/login']);
        }
    }

    /**
     * Selects a company and a manager for the logged-in user.
     * Emits a loading event, selects the company and manager, updates the storage,
     * and handles any errors that occur during the process.
     * 
     * @returns A promise that resolves when the company and manager are successfully selected and updated.
     */
    async selectCompanyAndGestor(): Promise<void> {
        this.sendLoading?.emit(true);
        try {
            const user = this.utilsService.getLoggedInUser();
            if (user) {
                const company: Company = await this.selectCompany(user);
                const manager: Manager = await this.selectManager(company, user);
                await this.updateStorage(company, manager);
            }
        } catch (error) {
            this.utilsService.openSnackBar('No se seleccionó empresa y gestor', 'warning');
            this.sendLoading?.emit(false);
            sessionStorage.removeItem('selecting company');
        }
    }

    /**
     * Selects a company based on the provided user.
     * @param user - The user object.
     * @returns A promise that resolves to the selected company.
     */
    async selectCompany(user: MiRutaUser): Promise<Company> {
        let companies: Company[] = await this._apiService.getCompanies();
        let companiesFromUser = [];
        for (const c of companies) {
            if (user.companies.find((company) => company.id == c.id)) {
                companiesFromUser.push(c);
            }
        }
        let companyNameSelected = '';
        if (companiesFromUser.length > 1) {
            companyNameSelected = await this.utilsService.openSelectorDialog(
                'Seleccione empresa',
                companiesFromUser.map((company) => company.nombre_empresa!)
            );
        } else companyNameSelected = companiesFromUser[0].nombre_empresa!;

        const company: any = companies.find((c: Company) => c.nombre_empresa == companyNameSelected);
        return company;
    }

    /**
     * Selects a manager from the given company based on the user's managers.
     * @param company - The company object.
     * @param user - The user object.
     * @returns A Promise that resolves to the selected Manager object.
     */
    async selectManager(company: Company, user: MiRutaUser): Promise<Manager> {
        await this._apiService.getManagers();
        let managers: any[] = [];
        if(company.managers && company.managers.length){
            for (const m of company.managers!) {
                if (user.managers.find((mg) => mg.gestor == m.gestor)) managers.push(m);
            }
        }
        let managerNameSelected: string = '';
        if (managers.length > 1) {
            managerNameSelected = await this.utilsService.openSelectorDialog(
                'Seleccione gestor',
                managers.map((manager) => manager.gestor!)
            );
        } else managerNameSelected = managers[0].gestor!;
        
        const manager: Manager = managers.find(
            (manager: Manager) => manager.gestor == managerNameSelected
        );
        return manager;
    }

    /**
     * Updates the storage with the provided company and manager.
     * Clears filters, orders, and specific items from local storage.
     * Sends events and messages to notify changes.
     * Retrieves teams from the API.
     * 
     * @param company - The company object to update the storage with.
     * @param manager - The manager object to update the storage with.
     * @returns A promise that resolves when the storage is updated.
     */
    async updateStorage(company: Company, manager: Manager): Promise<void> {
        if (company && manager) {
            this.utilsService.clearFiltersAndOrders();
            this.utilsService.clearLocalStorage([
                'access_token',
                'user',
                'displayedColumns_tasks',
                'displayedColumns_itacs',
                'displayedColumns_counters',
                'displayedColumns_waterRoutes',
                'displayedColumns_radiusModules',
            ]);
            this.updateLocalStorage(company, manager);

            this.sendLoading?.emit(false);
            this.sendCompanyAndGestorChanged.emit();
            this._messagingService.sendMessage('Company and Manager changed');
            localStorage.removeItem('teams');
            await this._apiService.getTeams();
        } else {
            this.utilsService.openSnackBar('No se seleccionó empresa y gestor', 'warning');
            this.sendLoading?.emit(false);
        }
    }

    /**
     * Updates the local storage with the provided company and manager information.
     * @param company - The company object to be stored in the local storage.
     * @param manager - The manager object to be stored in the local storage.
     */
    updateLocalStorage(company: Company, manager: Manager): void {
        localStorage.removeItem('last_status');
        localStorage.setItem('company', company.id!.toString());
        localStorage.setItem('manager', manager.id!.toString());
        sessionStorage.removeItem('selecting company');
        localStorage.setItem('companyJson', JSON.stringify(company));
        localStorage.setItem('managerJson', JSON.stringify(manager));
        localStorage.setItem('managers', JSON.stringify(company.managers));
    }
}
