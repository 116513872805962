/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/02/2025.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */

import { Component, Inject, OnInit, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { faPhone, faFilePdf, faUser, faComment } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { ApiService } from '../../../services/api.service';
import { UtilsService } from 'src/app/services/utils.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { MessagingService } from 'src/app/services/messaging.service';
import * as moment from 'moment';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CallLog, getCallLogFormControls } from 'src/app/interfaces/call-log';

@Component({
    selector: 'app-call-log',
    templateUrl: './call-log.component.html',
    styleUrls: ['./call-log.component.scss'],
})
export class CallLogComponent implements OnInit, OnDestroy {
    faFilePdf = faFilePdf;
    faPhone = faPhone;
    faUser = faUser;
    faComment = faComment;
    loading: boolean = false;
    callLogId: string = '';
    callLog?: CallLog;

    callLogFormData: FormGroup = getCallLogFormControls();
    callLogSubscription$?: Subscription;

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        private _apiService: ApiService,
        private _utilsService: UtilsService,
        private spinner: NgxSpinnerService,
        private _messagingService: MessagingService
    ) {
        this.callLogId = data.callLogId;
    }

    async ngOnInit(): Promise<void> {
        if (this.callLogId) {
            this.showLoading(true);

            this.callLogSubscription$ = this._apiService
                .getDocument('call_log', this.callLogId)
                .subscribe(async (doc: any) => {
                    if (!doc) {
                        this._utilsService.openSnackBar('Error obteniendo datos de la llamada', 'error');
                        this.showLoading(false);
                        return;
                    }
                    const callLog = doc as CallLog;
                    callLog.id = parseInt(this.callLogId);
                    this.callLog = callLog;

                    const callLogFormData = getCallLogFormControls();
                    Object.keys(callLog).forEach((key) => {
                        let value: any = callLog[key as keyof CallLog];
                        if (value && this._utilsService.isValidDate(value)) {
                            value = value;
                        }
                        callLogFormData.controls[key].setValue(value);
                    });
                    this.callLogFormData = callLogFormData;

                    this.showLoading(false);
                });
        }
    }

    ngOnDestroy(): void {
        this.callLogSubscription$?.unsubscribe();
    }

    showLoading(state: boolean) {
        this.loading = state;
        state ? this.spinner.show('callLogSpinner') : this.spinner.hide('callLogSpinner');
    }

    saveFormData() {
        const keys = Object.keys(this.callLogFormData.controls);
        let callLog: any = {};
        keys.forEach((key) => {
            let value = this.callLogFormData.controls[key].value;
            if (moment.isMoment(value)) {
                value = value.toDate();
            }
            callLog[key as keyof CallLog] = value;
        });
        callLog['date_time_modified'] = new Date();
        this.callLog = callLog;
    }

    async saveChanges() {
        this.showLoading(true);
        this.saveFormData();
        if (this.callLogId) {
            const result: boolean = await this._apiService.updateDocument('call_log', this.callLogId, this.callLog);
            this._utilsService.openSnackBar(result ? 'Llamada actualizada correctamente' : 'Error actualizando llamada', result ? 'success' : 'error');
        } else {
            try {
                const callLogId = await this._apiService.addDocument('call_log', this.callLog);
                if (callLogId) {
                    this.callLog!.id = callLogId;
                    this._utilsService.openSnackBar('Llamada añadida correctamente');
                } else {
                    this._utilsService.openSnackBar('Error añadiendo llamada', 'error');
                }
            } catch (error) {
                this._utilsService.openSnackBar('Error añadiendo llamada', 'error');
            }
        }
        this.showLoading(false);
        this._utilsService.closeCallLogDialog(this.callLog!);
    }

    async makeCall() {

    }
}
