import { FormGroup, FormControl } from '@angular/forms';

export interface PendingCall {
    ID?: number;
    id?: number;
    phone_number?: string;
    is_pending?: boolean;
    last_event_time?: Date;
    created_at?: Date;
    task_id?: number;
    address?: string;
    comments?: string;
    Numero_de_ABONADO?: string;
    manager?: number;
    company?: number;
    date_time_modified?: Date;
}

export function getPendingCallDisplayColumns(): string[] {
    const displayedColumns: string[] = [
        'ID',
        'Teléfono',
        'Pendiente de llamar',
        'Último evento',
        'Creado el',
        'ID de tarea',
        'Dirección',
        'Número de abonado',
        'Gestor',
        'Empresa',
        'Fecha modificación',
    ];
    return displayedColumns;
}

export function getPendingCallField(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
            value = 'ID';
            break;
        case 'id':
            value = 'id';
            break;
        case 'Teléfono':
            value = 'phone_number';
            break;
        case 'Pendiente de llamar':
            value = 'is_pending';
            break;
        case 'Último evento':
            value = 'last_event_time';
            break;
        case 'Creado el':
            value = 'created_at';
            break;
        case 'ID de tarea':
            value = 'task_id';
            break;
        case 'Dirección':
            value = 'address';
            break;
        case 'Comentarios':
            value = 'comments';
            break;
        case 'Número de abonado':
            value = 'Numero_de_ABONADO';
            break;
        case 'Gestor':
            value = 'manager';
            break;
        case 'Empresa':
            value = 'company';
            break;
        case 'Fecha modificación':
            value = 'date_time_modified';
            break;
    }
    return value;
}

export function getPendingCallFieldName(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'ID':
        value = 'ID';
        break;
        case 'id':
        value = 'id';
        break;
        case 'phone_number':
        value = 'Teléfono';
        break;
        case 'is_pending':
        value = 'Pendiente de llamar';
        break;
        case 'last_event_time':
        value = 'Último evento';
        break;
        case 'created_at':
        value = 'Creado el';
        break;
        case 'task_id':
        value = 'ID de tarea';
        break;
        case 'address':
        value = 'Dirección';
        break;
        case 'comments':
        value = 'Comentarios';
        break;
        case 'Numero_de_ABONADO':
        value = 'Número de abonado';
        break;
        case 'manager':
        value = 'Gestor';
        break;
        case 'company':
        value = 'Empresa';
        break;
        case 'date_time_modified':
        value = 'Fecha modificación';
        break;
    }
    return value;
}

export function getPendingCallFormControls(): FormGroup {
    const formControls: FormGroup = new FormGroup({
        id: new FormControl(),
        phone_number: new FormControl(),
        is_pending: new FormControl(),
        last_event_time: new FormControl(),
        created_at: new FormControl(),
        task_id: new FormControl(),
        address: new FormControl(),
        comments: new FormControl(),
        Numero_de_ABONADO: new FormControl(),
        manager: new FormControl(),
        company: new FormControl(),
        date_time_modified: new FormControl(),
    });
    return formControls;
}

export function getPendingCallFieldType(field_name: string): string {
    let value: string = '';
    switch (field_name) {
        case 'id':
        value = 'number';
        break;
        case 'phone_number':
        value = 'string';
        break;
        case 'is_pending':
        value = 'boolean';
        break;
        case 'last_event_time':
        value = 'Date';
        break;
        case 'created_at':
        value = 'Date';
        break;
        case 'task_id':
        value = 'number';
        break;
        case 'address':
        value = 'string';
        break;
        case 'comments':
        value = 'string';
        break;
        case 'Numero_de_ABONADO':
        value = 'string';
        break;
        case 'manager':
        value = 'number';
        break;
        case 'company':
        value = 'number';
        break;
        case 'date_time_modified':
        value = 'Date';
        break;
    }
    return value;
}

export function getPendingCallExcelFieldName(excel_field: string): string {
    let value: string = '';
    switch (excel_field.trim().toLowerCase()) {
        case 'id':
        value = 'id';
        break;
        case 'teléfono':
        value = 'phone_number';
        break;
        case 'Pendiente de llamar':
        value = 'is_pending';
        break;
        case 'último evento':
        value = 'last_event_time';
        break;
        case 'creado el':
        value = 'created_at';
        break;
        case 'id de tarea':
        value = 'task_id';
        break;
        case 'dirección':
        value = 'address';
        break;
        case 'comentarios':
        value = 'comments';
        break;
        case 'número de abonado':
        value = 'Numero_de_ABONADO';
        break;
        case 'gestor':
        value = 'manager';
        break;
        case 'Empresa':
        value = 'company';
        break;
        case 'fecha modificación':
        value = 'date_time_modified';
        break;
    }
    return value;
}

export function getPendingCallExcelExportColumns(): string[] {
    const columns = [
        'ID',
        'Teléfono',
        'Pendiente de llamar',
        'Último evento',
        'Creado el',
        'ID de tarea',
        'Dirección',
        'Número de abonado',
        'Gestor',
        'Empresa',
        'Fecha modificación',
    ];
    return columns;
}
