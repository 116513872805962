<input class="input-hidden" type="file" (change)="uploadPhoto($event)" #input_file />

<mat-drawer-container [hasBackdrop]="true" class="animate__animated animate__fadeIn">
    <mat-drawer color="primary" #drawer [mode]="'over'" class="drawer-background">
        <app-drawer
            (sendOnFileChange)="fileEvent($event)"
            (sendOpenSettings)="openSettings()"
            (sendInformTasks)="informTasks()"
            (sendMigrateTaskFiles)="migrateTaskData()"
            (sendMigrateItacFiles)="migrateItacData()"
            (sendMigrateTables)="migrateTables()"
            (sendMigrateItacTables)="migrateItacTables()"
            (sendCheckInServices)="checkInServices()"
            (sendExportAqualia)="exportAqualia()"
            >fileEvent
        </app-drawer>
    </mat-drawer>

    <mat-drawer-content>
        <app-toolbar
            [showCleanFilter]="true"
            [showFilter]="true"
            [showActivity]="true"
            [showCalendar]="true"
            [showBackButton]="true"
            [taskTable]="true"
            [marginImage]="true"
            (sendLoading)="showLoading($event)"
            (sendSearchValue)="searchValue($event)"
            (sendToggleDrawer)="drawer.toggle()"
            (sendCompanyAndGestorChanged)="getTasks()"
            (sendFilter)="customFilterBy($event)"
            (sendShowCalendar)="showCalendar($event)"
            (sendSelectAll)="selectAll()"
            (sendPreviousView)="previousView()"
            (sendShowUserActivity)="showUserActivity($event)"
        >
        </app-toolbar>

        <mat-tab-group
            [selectedIndex]="selected.value"
            mat-align-tabs="center"
            backgroundColor="primary"
            (selectedIndexChange)="onTabClicked($event)"
        >
            <mat-tab *ngFor="let tab of tabs; let index = index" [label]="tab">
                <ng-template mat-tab-label>
                    <span
                        [matBadge]="length"
                        matBadgeOverlap="false"
                        [matBadgeHidden]="!(selected.value == index) || loading || length >= 1000"
                        >{{ tab }}
                    </span>
                    <div *ngIf="loading && selected.value == index" class="spinner-status">
                        <mat-spinner diameter="20"></mat-spinner>
                    </div>
                    <mat-chip-list *ngIf="!loading && length >= 1000 && selected.value == index">
                        <mat-chip backgroundColor="white" selected>
                            {{ length }}
                        </mat-chip>
                    </mat-chip-list>
                </ng-template>
                <div
                    *ngIf="selected.value == index && !loading && dataSource!.data.length > 0"
                    class="animate__animated animate__fadeIn hide_scroll"
                    infiniteScroll
                    [infiniteScrollDistance]="1"
                    [infiniteScrollThrottle]="rowsLimit"
                    (scrolled)="onScroll()"
                    [scrollWindow]="false"
                >
                    <app-table
                        [dataSource]="dataSource"
                        [tableName]="tableName"
                        [displayedColumns]="displayedColumns!"
                        [displayedColumnsField]="displayedColumnsField"
                        [clickedRows]="clickedRows"
                        [fromTasksOrItacs]="true"
                        [fromTask]="true"
                        [tableStyle]="
                            _utilsService.isClientUser() ? 'width: 2600px;' : 'width: 11500px;'
                        "
                        [menuOptions]="menuOptions"
                        [additionalMenuOptions]="additionalMenuOptions"
                        (sendMenuOptionSelected)="onMenuOptionSelected($event)"
                        (sendFilterBy)="filterBy($event)"
                        (sendOrderBy)="orderBy($event)"
                        (sendDoubleClickedRow)="doubleClickedRow($event)"
                        (sendClickedRow)="clickedRow($event)"
                        (sendSelectedRows)="selectedRows($event)"
                        (sendReload)="reload()"
                    >
                    </app-table>
                </div>
            </mat-tab>
        </mat-tab-group>

        <app-footer
            [imageLogo]="imageLogo"
            [length]="length"
            [pageSize]="pageSize"
            [pageIndex]="lastPageIndex"
            [pageSizeOptions]="pageSizeOptions"
            (sendOpenMaps)="openMaps()"
            (sendExportTable)="exportTasksInTable()"
            (sendDelete)="deleteTasks()"
            (sendUpdate)="updateTasks()"
            (sendAddRow)="addNewRow($event)"
            (sendPageEvent)="pageEvent($event)"
        >
        </app-footer>

        <div *ngIf="!loading && dataSource.data && dataSource.data.length == 0" class="center-child">
            <div style="text-align: center">
                <fa-icon style="font-size: 80px; color: #777777" [icon]="faInbox"></fa-icon>
            </div>
            <div style="margin: 15px">
                <span style="font-size: 20px; color: #777777">No hay tareas para mostrar</span>
            </div>
        </div>

        <ngx-spinner
            bdColor="rgba(0, 0, 0, 0)"
            size="large"
            color="#368DCE"
            type="ball-scale-multiple"
            [fullScreen]="false"
            name="innerSpinner"
        >
            <div
                *ngIf="_utilsService.resposeError"
                class="reload-loading-button animate__animated animate__fadeInUp"
                (click)="reload()"
            >
                <button mat-icon-button matTooltip="Reintentar" color="primary">
                    <mat-icon style="font-size: 25px; color: white">refresh</mat-icon>
                </button>
            </div>
            <div
                *ngIf="_utilsService.resposeError"
                class="close-loading-button animate__animated animate__fadeInDown"
                (click)="showLoading(false)"
            >
                <button mat-icon-button matTooltip="Cancelar petición" color="primary">
                    <mat-icon style="font-size: 25px; color: white">close</mat-icon>
                </button>
            </div>
        </ngx-spinner>
    </mat-drawer-content>
</mat-drawer-container>

<ngx-spinner
    bdColor="rgba(0, 0, 0, 0.1)"
    size="large"
    color="#368DCE"
    type="square-jelly-box"
    [fullScreen]="true"
>
    <p class="loading-text-background" style="margin-top: 50px">{{ loadingText }}</p>
</ngx-spinner>
