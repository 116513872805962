/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, OnInit, ViewChild } from '@angular/core';

//Copilot token -> {vscode://vscode.github-authentication/did-authenticate?windowid=1&code=3577f1b849acc251e185&state=bee0e284-4925-414e-a2d5-dbf2a616d60a}
import { Router, ActivatedRoute } from '@angular/router';
import {
    getClientDisplayColumns,
    getDisplayColumns,
    getExcelExportColumns,
    getExcelExtendedExportColumns,
    getExcelExtendedExportHeaders,
    getExcelExtendedExportHeadersColumnName,
    getExcelFieldName,
    getField,
    getFieldName,
    getFieldType,
    getFilterDateTypes,
    getPhotoFields,
    getWaterTaskColumns,
    isDateField,
    task_status,
} from 'src/app/interfaces/water-task';
import { ApiService } from 'src/app/services/api.service';
import * as XLSX from 'xlsx';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
    faFilter,
    faSortAmountDown,
    faSortAmountUpAlt,
    faLock,
    faInbox,
} from '@fortawesome/free-solid-svg-icons';
import { UtilsService } from 'src/app/services/utils.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FilterComponent } from '../share/filter/filter.component';
import { WindowRefService } from 'src/app/services/window-ref.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Moment } from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import * as moment from 'moment';
import { Info } from '../../interfaces/info';
import { Itac } from 'src/app/interfaces/itac';
import { WaterRoute } from 'src/app/interfaces/water-route';
import { Team } from 'src/app/interfaces/team';
import { MiRutaUser } from 'src/app/interfaces/mi-ruta-user';
import { FileSaverService } from 'src/app/services/file-saver.service';
import { IpcService } from 'src/app/services/ipc.service';
import { Manager } from 'src/app/interfaces/manager';
import { Company } from 'src/app/interfaces/company';
import { MySqlService } from 'src/app/services/mysql.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { getItacFieldType, convertFromOldItacStructure } from '../../interfaces/itac';
import { MyLatLng } from '../../interfaces/lat-lng';
import { Counter, counter_status } from 'src/app/interfaces/counter';
import { LoraResponse } from 'src/app/interfaces/lora-response';
import { getExcelFieldNameExportation, myLatLng, WaterTask, MAX_SIZE_WARNING, getPhotoFieldsToUpload } from '../../interfaces/water-task';
import { WaterTaskUpdate } from '../../interfaces/water-task-update';
import { JsonTask, JsonTaskState, JsonTasks, Ots } from 'src/app/interfaces/json-tasks';
import { FotoRequested, JsonImage } from 'src/app/interfaces/json-image';
import { SearchParam, deleteFilterField } from 'src/app/interfaces/mi-ruta-filter';
import { ItelazpiDevice, ItelazpiTags } from 'src/app/interfaces/itelazpi-device';
import { RadiusModule } from 'src/app/interfaces/radius-module';
import { MessagingService } from '../../services/messaging.service';
import { TaskLocationInfo } from '../../interfaces/task-location-info';
import { FileData } from '../../interfaces/file-date';
import { Planning } from '../../interfaces/planning';
import { PlanningDetailExtras } from 'src/app/interfaces/planning_detail_extras';
import { UserValidation } from '../../interfaces/user-validation';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    // encapsulation: ViewEncapsulation.None,
})
export class HomeComponent implements OnInit {
    @ViewChild('input_file') inputFile: any;
    @ViewChild('drawer') homeDrawer?: any;

    photoColumn: string = '';
    
    // @ViewChild(MatDrawerContainer, { static: false }) matDrawerContainer!: MatDrawerContainer;
    filterDialogRef!: MatDialogRef<FilterComponent, any>;

    // tasksInPage: WaterTask[] = [];
    loadingText = 'Cargando...';
    faFilter = faFilter;
    faSortAmountDown = faSortAmountDown;
    faSortAmountUpAlt = faSortAmountUpAlt;
    faLock = faLock;
    faInbox = faInbox;
    tableName: string = 'tasks';

    length = 0; //task count in current table
    pageSize = 50; //limit of query
    scrollOffset: number = 50;
    rowsLimit: number = 50;
    lastPageIndex = 0;
    pageSizeOptions: number[] = [10, 30, 50, 100, 200, 500];

    tabs: string[] = [];
    selected = new FormControl(0);

    fixedColumns = ['Columns fixed here'];
    displayedColumns: string[] = [];
    displayedColumnsField: string[] = [];
    clickedRows = new Set<WaterTask>();
    allSelected = false;
    loading = true;
    timerInputChanged: any;
    // dataSource: TableVirtualScrollDataSource<WaterTask> = new TableVirtualScrollDataSource(); //infiniteScroll
    dataSource: MatTableDataSource<WaterTask> = new MatTableDataSource();
    lastSelectedRow: number = -1;

    filteredColumn?: string;
    orderedColumn?: string;

    lastSelectedDate: Date = new Date();

    lastSlice = 0;
    sliceSize = 40;

    previousViewData: any[] = [];

    currentStatus?: number;
    additionalStatus: number = -1; //to add absents and dates to where clause

    imageLogo?: string;

    menuOptions: string[] = [];
    additionalMenuOptions: string[] = [];

    waterTasks: WaterTask[] = [];

    repeated: string[] = [];
    
    form: FormGroup;

    /**
     * Constructs a new instance of the HomeComponent.
     * 
     * @param http - The HttpClient service used for making HTTP requests.
     * @param _apiService - The ApiService service used for interacting with the API.
     * @param _mySqlService - The MySqlService service used for interacting with MySQL database.
     * @param _utilsService - The UtilsService service used for utility functions.
     * @param _fileSaverService - The FileSaverService service used for saving files.
     * @param windowRefService - The WindowRefService service used for interacting with the browser window.
     * @param filterDialog - The MatDialog service used for displaying filter dialog.
     * @param _spinner - The NgxSpinnerService service used for displaying spinners.
     * @param _electronService - The IpcService service used for interacting with Electron.
     * @param _messagingService - The MessagingService service used for sending and receiving messages.
     * @param router - The Router service used for navigating between routes.
     * @param route - The ActivatedRoute service used for accessing route parameters.
     */
    constructor(
        private readonly http: HttpClient,
        private readonly _apiService: ApiService,
        private readonly _mySqlService: MySqlService, //MysqlService  BigQueryService
        public _utilsService: UtilsService,
        private readonly _fileSaverService: FileSaverService,
        private readonly windowRefService: WindowRefService,
        public filterDialog: MatDialog,
        private readonly _spinner: NgxSpinnerService,
        private readonly _electronService: IpcService,
        private readonly _messagingService: MessagingService,
        private readonly router: Router,
        private readonly route: ActivatedRoute,
        public formBuilder: FormBuilder
    ) {
        this.form = this.formBuilder.group({ image: [null] });
        this.pageSize = parseInt(localStorage.getItem('waterTask_pageSize') ?? `${this.rowsLimit}`);
        this.orderColumns();
        this.checkIfUpdateViewNeeded();
        this.setMenuOptions();
    }

    /**
     * @brief This function is call at page enter, reload or on gestor and manager changed.
     * Gets all the task of the selected status
     */
    async getTasks(): Promise<void> {
        this._messagingService.sendMessage('Update notification count');
        const managerSelected = JSON.parse(localStorage.getItem('managerJson') ?? '{}');
        this.imageLogo = managerSelected.photo;

        const last_status = localStorage.getItem('last_status');
        if (last_status) {
            const index = localStorage.getItem('last_selected_index') ?? '0';
            this.changedTab(parseInt(index), false);
        } else {
            this.changedTab(0, true);
        }
    }

    /**
     * Retrieves the company and manager information.
     * If the company selection is already requested, it waits for 4 seconds and calls itself again.
     * Otherwise, it checks if the company and manager IDs are already set in the local storage.
     * If not, it sets a flag in the session storage indicating that the company selection is in progress,
     * and then calls the `selectCompanyAndGestor` method to select the company and manager.
     */
    async getCompanyAndManager(){
        const alreadyRequested = sessionStorage.getItem('selecting company');
        if (alreadyRequested) {
            setTimeout(async () => this.getCompanyAndManager(), 4000);
        }
        else {
            const companyId = localStorage.getItem('company');
            const managerId = localStorage.getItem('manager');
            if (companyId && managerId) {
                await this._apiService.getAgrupations();  
                await this.getPipeData();
                return;
            }
            try {
                sessionStorage.setItem('selecting company', 'true');
                await this.selectCompanyAndGestor();
            } catch (err) {}
        }
    }

    /**
     * Initializes the component.
     * This method is called after the component has been created and initialized.
     * It is used to perform any initialization logic that needs to be executed when the component is first loaded.
     * @returns A promise that resolves when the initialization is complete.
     */
    async ngOnInit() {
        this.showLoading(true);
        setTimeout(async () => this.getCompanyAndManager(), 4000);
        const companyId = localStorage.getItem('company');
        const managerId = localStorage.getItem('manager');
        if (companyId && managerId){
            await this._apiService.getAgrupations();        
            await this.getPipeData();
        }
        //? signal sendCompanyAndGestorChanged in HTML makes the first request to get tasks
    }

    /**
     * Lifecycle hook that is called when the component is about to be destroyed.
     * Use this hook to perform any necessary cleanup tasks before the component is removed from the DOM.
     */
    ngOnDestroy(): void {}

    /**
     * Retrieves pipe data by calling the necessary API services.
     * This method first calls the `getTeams` API service, followed by the `getUsers` API service.
     * The `getTeams` and `getUsers` methods are awaited to ensure the data is fetched before proceeding.
     * 
     * @returns A promise that resolves when the pipe data is successfully retrieved.
     */
    async getPipeData(): Promise<void> {
        await this._apiService.getTeams();
        await this._apiService.getUsers(['administrador', 'operario']);
    }

    /**
     * Selects a company and manager for the logged-in user.
     * 
     * @returns A Promise that resolves when the company and manager are successfully selected.
     */
    async selectCompanyAndGestor(): Promise<void> {
        try {
            const user = this._utilsService.getLoggedInUser();
            if (user) {
                const company: Company = await this.selectCompany(user);
                const manager: Manager = await this.selectManager(company, user);
                if (company && manager) {
                    
                    await this._apiService.getAgrupations();  
                    await this.getPipeData(); 

                    this._utilsService.clearFiltersAndOrders();
                    this._utilsService.clearLocalStorage(['access_token', 'user']);
                    this.imageLogo = manager.photo;
                    await this.updateLocalStorage(company, manager);
                    await this.getTasks();
                } else {
                    this._utilsService.openSnackBar('No se seleccionó empresa y gestor', 'warning');
                    sessionStorage.removeItem('selecting company');
                }
            }
        } catch (error) {
            this._utilsService.openSnackBar('No se seleccionó empresa y gestor', 'warning');
            sessionStorage.removeItem('selecting company');
        }
    }

    /**
     * Selects a company based on the user's information.
     * 
     * @param user - The user object containing the company information.
     * @returns A promise that resolves to the selected company.
     */
    async selectCompany(user: MiRutaUser): Promise<Company> {
        let companies: Company[] = await this._apiService.getCompanies();
        let companiesFromUser = [];
        for (const c of companies) {
            if (user.companies.find((company) => company.id == c.id)) {
                companiesFromUser.push(c);
            }
        }
        let companyNameSelected = '';
        if (companiesFromUser.length > 1) {
            companyNameSelected = await this._utilsService.openSelectorDialog(
                'Seleccione empresa',
                companiesFromUser.map((company) => company.nombre_empresa!)
            );
        } else companyNameSelected = companiesFromUser[0].nombre_empresa!;

        const company: any = companies.find((c: Company) => c.nombre_empresa == companyNameSelected);
        return company;
    }

    /**
     * Selects a manager based on the given company and user.
     * @param company - The company object.
     * @param user - The user object.
     * @returns A Promise that resolves to the selected manager.
     */
    async selectManager(company: Company, user: MiRutaUser): Promise<Manager> {
        await this._apiService.getManagers();
        let managers: any[] = [];
        if(company.managers && company.managers.length){
            for (const m of company.managers!) {
                if (user.managers.find((mg) => mg.gestor == m.gestor)) managers.push(m);
            }
        }
        let managerNameSelected: string = '';
        if (managers.length > 1) {
            managerNameSelected = await this._utilsService.openSelectorDialog(
                'Seleccione gestor',
                managers.map((manager) => manager.gestor!)
            );
        } else managerNameSelected = managers[0].gestor!;
        
        const manager: Manager = managers.find(
            (manager: Manager) => manager.gestor == managerNameSelected
        );
        return manager;
    }

    /**
     * Updates the local storage with the provided company and manager information.
     * @param company - The company object to be stored in the local storage.
     * @param manager - The manager object to be stored in the local storage.
     * @returns A promise that resolves when the local storage is updated.
     */
    async updateLocalStorage(company: Company, manager: Manager): Promise<void> {
        localStorage.setItem('company', company.id!.toString());
        localStorage.setItem('manager', manager.id!.toString());
        localStorage.setItem('companyJson', JSON.stringify(company));
        localStorage.setItem('managerJson', JSON.stringify(manager));
        localStorage.setItem('managers', JSON.stringify(company.managers));
        localStorage.removeItem('last_status');
        sessionStorage.removeItem('selecting company');
        localStorage.removeItem('teams');
        await this._apiService.getTeams();
    }

    /**
     * Handles the scroll event and updates the data source.
     * @returns A Promise that resolves to void.
     */
    async onScroll(): Promise<void> {
        this.scrollOffset += this.rowsLimit;
        if (this.scrollOffset > this.pageSize) return;
        this.dataSource.data = [];
        this.dataSource.data = [...this.waterTasks.slice(0, this.scrollOffset)];
    }

    /**
     * Orders the columns based on the user type.
     */
    orderColumns(): void {
        if (this._utilsService.isClientUser()) {
            this.tabs = ['Todas', 'Requeridas', 'Abiertas', 'Informadas'];
            this.displayedColumns = getClientDisplayColumns();
        } else {
            this.tabs = this._utilsService.getTabs();
            this.displayedColumns = this._utilsService.setDisplayColumns(
                this.displayedColumns,
                this.tableName,
                getDisplayColumns
            );
        }
        this.displayedColumnsField = this.displayedColumns.map((displayedColumn: string) =>
            getField(displayedColumn)
        );
    }

    /**
     * Updates the view by fetching the latest tasks from the MySQL service and populating the table.
     * @returns A Promise that resolves when the view is updated.
     */
    async updateView(): Promise<void> {
        this.dataSource.data = [];
        this.waterTasks = [];

        this.scrollOffset = 50;
        this.showLoading(true);

        const { waterTasks, count } = await this._mySqlService.getLastTasksPage();
        this.length = count;
        this.setTasksInTable(waterTasks);
    }

    /**
     * Checks if an update to the view is needed when the visibility of the document changes.
     */
    async checkIfUpdateViewNeeded(): Promise<void> {
        document.addEventListener('visibilitychange', async () => {
            if (!document.hidden) { //isShown
                const updateNeeded = localStorage.getItem('taskUpdateNeeded');
                if (updateNeeded == 'true') {
                    localStorage.setItem('taskUpdateNeeded', 'false');

                    if (this.isOpenedFromDoubleClick()) {
                        const setted = await this.searchAndSetLastTaskLoaded();
                        if (setted) return;
                    }
                    if (this._electronService.isElectronApp()) await this.updateView();
                    else this.reload();
                }
            }
        });
    }

    /**
     * Sets the menu options based on the user type.
     */
    setMenuOptions(): void {
        if (this._utilsService.isClientUser()) {
            this.menuOptions = ['Mostrar en Mapa', 'Descagar Fotos'];
        } else {
            this.additionalMenuOptions = [
                'Mostrar en Mapa',
                'Asignar region en mapa',
                'Enviar Mensaje',
                'Asignar ID Orden',
                'Resumen de Tareas',
                'Eliminar',
            ];
            this.menuOptions = [
                'Marcar como revisadas',
                'Mostrar portales',
                'Activar contadores',
                'Activar Itelazpi',
                'Descagar Fotos',
                'Descagar Fotos de servidor',
                'Ver actualizaciones',
                'Asignar campos',
                'Asignar operario',
                'Desasignar operarios',
                'Asignar equipo',
                'Pasar a citas',
                'Eliminar citas',
            ];
            const developerOptions = [
                'Rollback from develop',
                'Rollback field',
                'Rollback planning',
                'Get deleted',
                'Fix excel fields',
                'Ir al 100%',
                'Add zeros',
            ];
            if(this._utilsService.isDeveloper()) this.menuOptions = this.menuOptions.concat(developerOptions);
        }
    }

    /**
     * Displays a warning message if no rows are selected.
     * @returns {boolean} - Returns true if no rows are selected, otherwise returns false.
     */
    nothingSelectedWarning(): boolean {
        if (this.clickedRows.size == 0) {
            this._utilsService.openSnackBar('Debe seleccionar al menos una tarea', 'warning');
            return true;
        }
        return false;
    }

    /**
     * Checks if only one task is selected.
     * If more than one task is selected, it displays a warning message and returns true.
     * Otherwise, it returns false.
     * 
     * @returns A boolean indicating whether only one task is selected.
     */
    checkIfNotOnlyOneTaskSelected(): boolean {
        if (this.clickedRows.size != 1) {
            this._utilsService.openSnackBar('Debe seleccionar solo una tarea', 'warning');
            return true;
        }
        return false;
    } 

    /**
     * Assigns a region to tasks.
     * 
     * @returns A Promise that resolves to void.
     */
    async assignRegionToTasks(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        const path = await this._utilsService.openMapZoneAssignation();
        this.showLoading(true, true);
        if (path) {
            const bounds = this._utilsService.getBoundsFromPath(path);
            await this.assignRandomLocations(bounds);
        }
        this.showLoading(false, true);
    }

    /**
     * Assigns random locations to water tasks within the specified bounds.
     * @param bounds - The LatLngBounds object representing the bounds within which the random locations should be assigned.
     * @returns A Promise that resolves when all the locations have been assigned.
     */
    async assignRandomLocations(bounds: google.maps.LatLngBounds) {
        this.loadingText = `Asignando localizaciones ...`;
        const size = this.clickedRows.size;
        const userId = this._apiService.getLoggedUserId();

        var lngSpan = bounds.getNorthEast().lng() - bounds.getSouthWest().lng();
        var latSpan = bounds.getNorthEast().lat() - bounds.getSouthWest().lat();

        let coordsHashes: string[] = [];
        const waterTasks = Array.from(this.clickedRows);

        for (let [index, waterTask] of waterTasks.entries()) {
            this.loadingText = `Asignando localizaciones ${index} de ${size}...`;

            const coords = this._utilsService.getRandomLocation(bounds, lngSpan, latSpan);
            if (coords) {
                if (coordsHashes.includes(coords.geohash)) continue;
                coordsHashes.push(coords.geohash);
                await this._apiService.assignLocationTask(waterTask, coords, userId);
            }
        }
    }

    /**
     * Handles the selection of a menu option.
     * @param option - The selected menu option.
     * @returns A Promise that resolves when the menu option is processed.
     */
    async onMenuOptionSelected(option: string): Promise<void> {
        if (option == 'Enviar Mensaje') {
            await this.sendWhatsappMessage();
        } else if (option == 'Asignar region en mapa') {
            await this.assignRegionToTasks();
        } else if (option == 'Mostrar en Mapa') {
            this.openMaps(this.clickedRows);
        } else if (option == 'Activar contadores') {
            await this.createAndActivateCounters();
        } else if (option == 'Marcar como revisadas') {
            await this.markAsReviewed();
        } else if (option == 'Activar Itelazpi') {
            await this.activateCountersItelazpi();
        } else if (option == 'Descagar Fotos') {
            await this.downloadPhotos();
        } else if (option == 'Descagar Fotos de servidor') {
            await this.downloadServerFolderImages();
        } else if (option == 'Asignar ID Orden') {
            await this.assignIdOrderToTasks();
        } else if (option == 'Ver actualizaciones') {
            await this.getTaskUpdates();
        } else if (option == 'Resumen de Tareas') {
            await this.getSummary();
        } else if (option == 'Asignar campos') {
            await this.assignFields();
        } else if (option == 'Asignar operario') {
            await this.assignOperatorToTasks();
        }  else if (option == 'Desasignar operarios') {
            await this.deassignOperatorToTasks();
        } else if (option == 'Asignar equipo') {
            await this.assignTeamToTasks();
        } else if (option == 'Eliminar') {
            await this.deleteTasks();
        } else if (option == 'Pasar a citas') {
            await this.tryPassToDates();
        }  else if (option == 'Eliminar citas') {
            await this.deleteDates();
        }  else if (option == 'Mostrar portales') {
            await this.showPortals();
        } else if (option == 'Ir al 100%') {
            // await this.updateTasksToPreviousUpdate(); //!be carefull
        } else if (option == 'Rollback planning') {
            // await this.rollbackPlanning(); //!be carefull
        } else if (option == 'Rollback field') {
            await this.rollbackField(); //!be carefull
        } else if (option == 'Rollback from develop') {
            await this.rollbackFieldFromDevelop(); //!be carefull
        } else if (option == 'Get deleted') {
            // await this.getDeletedTask(); //!be carefull
        } else if (option == 'Add zeros') {
            // await this.addCaliberToTasks(); //!be carefull
        } 
    }

    /**
     * Displays portals based on a selected date range.
     * @returns A Promise that resolves when the portals are displayed.
     */
    async showPortals(): Promise<void> { 
        const dateRange = await this._utilsService.openDateRangeSelectorDialog('Seleccione rango de fechas');
        if (dateRange) {
            const values = this._utilsService.getDateRangeString(dateRange);
            let column = 'date_modification_android';
            this.processFilter(values, column);

            this.showLoading(true);
            const tasks = await this.getTasksInCurrentFilter();
            const emplacement_codes = tasks.map((t: WaterTask) => t.codigo_de_geolocalizacion);
            const valid_codes = emplacement_codes.filter((c) => this._utilsService.isFieldValid(c));

            this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, column);
            this.applyFilter(valid_codes, 'codigo_de_geolocalizacion');
        }
    }

    /**
     * Adds caliber to tasks.
     * 
     * @returns A promise that resolves when the task is completed.
     */
    async addCaliberToTasks(): Promise<void> { 
        this.showLoading(true, true);
        const waterTasks: WaterTask[] = await this.getTasksInCurrentFilter();
        let i = 0;
        for (let waterTask of waterTasks) { 
            if (waterTask.seriedv) {
                this.loadingText = `Actualizando tareas (${++i} de ${waterTasks.length}) ...`;
                const filter = this._utilsService.filterCounters;
                const search_params: SearchParam[] = [{ search_type: 'inside', inside: [waterTask.seriedv], active: true }];
                filter!.fields = [{ field_name: 'numero_serie_contador', active: true, field_type: 'string', search_params: search_params }]
                let where_clause = undefined;
                if (filter && filter.fields) where_clause = this._utilsService.getWhereClauseFromFilter(filter);
                const counters = await this._mySqlService.getCounters(undefined, where_clause);
                if(counters && counters.length > 0) await this._apiService.updateTask(waterTask.id!, { CALIBREDV: counters[0].calibre }, false);
            }
        }
        this.showLoading(false, true);
    }

    /**
     * Updates the tasks to the previous update.
     * If no task is selected, displays a warning message.
     * Retrieves the task updates for each selected task and updates the task with the previous update.
     */
    async updateTasksToPreviousUpdate(): Promise<void> {
        if (this.clickedRows.size < 1) {
            this._utilsService.openSnackBar('Debe seleccionar solo una tarea', 'warning');
            return;
        }
        this.showLoading(true, true);
        let i = 0;
        for (let waterTask of this.clickedRows) {
            const taskUpdates: WaterTaskUpdate[] = await this._apiService.getTaskUpdates(
                waterTask.id,
                100
            ); //183908
            try {
                if (taskUpdates.length && taskUpdates[0]) {
                    //previous last update
                    const waterTask: WaterTask = JSON.parse(taskUpdates[0].data);
                    if (waterTask.id) {
                        this.loadingText = `Actualizando tareas (${++i}/${
                            this.clickedRows.size
                        })...`;
                        const updateOk = await this._apiService.updateTask(
                            waterTask.id.toString(),
                            waterTask
                        );
                    }
                }
            } catch (err) {}
        }
        this.showLoading(false, true);
    }

    /**
     * Retrieves the task update line log.
     * 
     * @param waterTaskUpdate - The water task update object.
     * @param maxLength - The maximum length for the log.
     * @returns The task update line log.
     */
    getTaskUpdateLineLog(waterTaskUpdate: WaterTaskUpdate, maxLength: number) {
        const length = waterTaskUpdate.data.length;
        const date: string = this._utilsService.tableDataPipe(
            new Date(waterTaskUpdate.update_date)
        );
        const info = ((length / maxLength) * 100).toFixed(2) + '%  - ' + date + ' - ';
        return waterTaskUpdate.user ? info + waterTaskUpdate.user : info + '?';
    }

    /**
     * Updates tasks to 100.
     * 
     * @remarks
     * This method updates the tasks to 100 by retrieving task updates from the API service,
     * finding the task update that contains '100' in the line log, and updating the task
     * with the new data.
     * 
     * @returns A Promise that resolves when all tasks have been updated.
     */
    async updateTasksTo100(): Promise<void> {
        if (this.checkIfNotOnlyOneTaskSelected()) return;
        this.showLoading(true, true);
        let i = 0;
        for (let waterTask of this.clickedRows) {
            const taskUpdates: WaterTaskUpdate[] = await this._apiService.getTaskUpdates(
                waterTask.id,
                10
            );
            let maxLength: number = 0;
            for (const taskUpdate of taskUpdates) {
                if (maxLength < taskUpdate.data.length) maxLength = taskUpdate.data.length;
            }
            try {
                const taskUpdate = taskUpdates.find((taskUpdate: WaterTaskUpdate) =>
                    this.getTaskUpdateLineLog(taskUpdate, maxLength).includes('100')
                );
                if (taskUpdate) {
                    const task = taskUpdate.data;
                    const waterTask: WaterTask = JSON.parse(task);
                    if (waterTask.id) {
                        this.loadingText = `Asignando tareas (${++i}/${this.clickedRows.size})...`;
                        const updateOk = await this._apiService.updateTask(
                            waterTask.id.toString(),
                            task
                        );
                    }
                }
            } catch (err) {}
        }
        this.showLoading(false, true);
    }

    /**
     * Rolls back the planning of selected water tasks.
     * 
     * This method performs the following steps:
     * 1. Checks if any tasks are selected. If none are selected, it returns early.
     * 2. Shows a loading spinner.
     * 3. Iterates over the selected tasks and retrieves their updates from the API.
     * 4. Updates the planning of each task if valid planning data is found.
     * 5. Hides the loading spinner once all tasks are processed.
     * 
     * @returns {Promise<void>} A promise that resolves when the rollback process is complete.
     */
    async rollbackPlanning(): Promise<void> {
        this.showLoading(true, true);
        this.loadingText = `Actualizando planning de tareas ...`;
        let i = 0;
        const waterTasks = await this.getTasksInCurrentFilter();
        for (let waterTask of waterTasks) {
            if (this._utilsService.isFieldValid(waterTask.planning)
                && (waterTask.planning?.includes('MR23') || waterTask.planning?.includes('MR22'))) {
                const taskUpdates: WaterTaskUpdate[] = await this._apiService.getTaskUpdates(
                    waterTask.id,
                    10
                );
                this.loadingText = `Actualizando planning de tareas (${++i}/${waterTasks.length})...`;
                try {
                    if (taskUpdates && taskUpdates.length) {
                        console.log('============= taskUpdates =============');
                        console.log(taskUpdates);
                        for (const taskUpdate of taskUpdates) { 
                            if (taskUpdate && taskUpdate.data) { 
                                const task = taskUpdate.data;
                                const w: WaterTask = JSON.parse(task);
                                if (w && w.id) { 
                                    if (this._utilsService.isFieldValid(w.planning)) {
                                        console.log('============= planning: waterTask.planning =============');
                                        console.log(w.planning);
                                        console.log(w.id);
                                        const updateOk = await this._apiService.updateTask(
                                            w.id.toString(),
                                            { planning: w.planning }
                                        );
                                        break;
                                    }
                                }
                            }
                        }
                    }
                } catch (err) {}
            }
        }
        this.showLoading(false, true);
    }

    /**
     * Rolls back a specific field of water tasks based on user input.
     * 
     * This method performs the following steps:
     * 1. Prompts the user to select a display column.
     * 2. Validates the selected field.
     * 3. Prompts the user to input a search text.
     * 4. Validates the search text.
     * 5. Shows a loading spinner and updates the loading text.
     * 6. Retrieves the tasks based on the current filter.
     * 7. Iterates through the tasks and checks if the field matches the search text.
     * 8. Retrieves the task updates for each matching task.
     * 9. Iterates through the task updates and checks if the field is valid.
     * 10. Updates the task with the valid field value.
     * 11. Hides the loading spinner.
     * 
     * @returns {Promise<void>} A promise that resolves when the rollback operation is complete.
     */
    async rollbackField(): Promise<void> {
        try {
            const displayColumn = await this._utilsService.openSelectorDialog('Ingrese campo', getDisplayColumns());
            const field = getField(displayColumn) as keyof WaterTask;
            if (this._utilsService.isFieldNotValid(field)) return;
            const searchText = await this._utilsService.openInputSelectorDialog('Texto a buscar', '');
            if (this._utilsService.isFieldNotValid(searchText)) return;
            
            this.showLoading(true, true);
            this.loadingText = `Rollback ${displayColumn} de tareas ...`;
            let i = 0;
            let updateCount = 0;
            const waterTasks = await this.getTasksInCurrentFilter();
            for (let waterTask of waterTasks) {
                if (waterTask[field] && waterTask[field] == searchText) {
                    const taskUpdates: WaterTaskUpdate[] = await this._apiService.getTaskUpdates(waterTask.id, 10);
                    this.loadingText = `Rollback ${displayColumn} de tareas (${++i}/${waterTasks.length})...`;
                    try {
                        if (taskUpdates && taskUpdates.length) {
                            for (const taskUpdate of taskUpdates) { 
                                if (taskUpdate && taskUpdate.data) { 
                                    const task = taskUpdate.data;
                                    const waterTask: WaterTask = JSON.parse(task);
                                    if (waterTask && waterTask.id) { 
                                        if (this._utilsService.isFieldValid(waterTask[field])) {
                                            const updateOk = await this._apiService.updateTask(
                                                waterTask.id.toString(),
                                                { [field]: waterTask[field] }
                                            );
                                            if(updateOk) updateCount++;
                                            break;
                                        }
                                    }
                                }
                            }
                        }
                    } catch (err) {}
                }
            }
            console.log('============= updateCount =============');
            console.log(updateCount);
            this.showLoading(false, true);
        } catch (err) {}
    }

    /**
     * Rolls back a specific field of water tasks from the development server to the production server.
     * 
     * This method performs the following steps:
     * 1. Prompts the user to select a display column and a search text.
     * 2. Shows a loading spinner and updates the loading text.
     * 3. Retrieves the current filter tasks.
     * 4. Logs into the development server and retrieves the necessary tokens.
     * 5. Iterates over the water tasks and checks if the field matches the search text.
     * 6. If a match is found, retrieves the task from the development server and updates the task on the production server.
     * 7. Restores the original user session and hides the loading spinner.
     * 
     * @returns {Promise<void>} A promise that resolves when the rollback process is complete.
     */
    async rollbackFieldFromDevelop(): Promise<void> {
        try {
            const displayColumn = await this._utilsService.openSelectorDialog('Ingrese campo', getDisplayColumns());
            const field = getField(displayColumn) as keyof WaterTask;
            if (this._utilsService.isFieldNotValid(field)) return;
            const searchText = await this._utilsService.openInputSelectorDialog('Texto a buscar', '');
            if (this._utilsService.isFieldNotValid(searchText)) return;
            
            this.showLoading(true, true);
            this.loadingText = `Rollback ${displayColumn} de tareas ...`;
            let i = 0;
            const waterTasks = await this.getTasksInCurrentFilter();
    
            const prodToken = localStorage.getItem('access_token');
            const prodUser = this._utilsService.getLoggedInUser();
    
            const isLogDev = await this._apiService.loginDevServer();
            const devToken = localStorage.getItem('access_token');
    
            console.log('============= prodToken =============');
            console.log(prodToken);
            console.log('============= devToken =============');
            console.log(devToken);
    
            if (isLogDev && prodToken && devToken) { 
                for (let waterTask of waterTasks) {
                    if (waterTask[field] && waterTask[field] == searchText) {
    
                        localStorage.setItem('access_token', devToken);
                        const taskDev: WaterTask = await this._apiService.getTaskSyncDevServer(waterTask.id!);
    
                        this.loadingText = `Rollback ${displayColumn} de tareas (${++i}/${waterTasks.length})...`;
                        try {
                            if (taskDev && taskDev.id) { 
                                localStorage.setItem('access_token', prodToken);
                                console.log('============= taskDev[field] =============');
                                console.log(taskDev[field]);
                                const updateOk = await this._apiService.updateTask(taskDev.id, { [field]: taskDev[field] });
                            }
                        } catch (err) {}
                    }
                }
            }
            sessionStorage.setItem('user', JSON.stringify(prodUser));
            this.showLoading(false, true);
            this.reload();
        } catch (err) {}
    }

    /**
     * Retrieves a deleted task by prompting the user for a task ID, fetching task updates,
     * and re-adding the task if it is found.
     * 
     * @returns {Promise<void>} A promise that resolves when the task retrieval and re-addition process is complete.
     */
    async getDeletedTask(): Promise<void> {
        const taskId = await this._utilsService.openInputSelectorDialog('Ingrese ID de tarea', '');
        if (taskId) {
            this.showLoading(true, true);
            this.loadingText = `Recuperando tarea ...`;
            const taskUpdates: WaterTaskUpdate[] = await this._apiService.getTaskUpdates(+taskId, 5);
            if (taskUpdates && taskUpdates.length) {
                console.log('============= taskUpdates =============');
                console.log(taskUpdates);
                for (const taskUpdate of taskUpdates) { 
                    if (taskUpdate && taskUpdate.data && taskUpdate.type == 'UPDATE') { 
                        const taskString = taskUpdate.data;
                        const task: WaterTask = JSON.parse(taskString);
                        console.log('============= task =============');
                        console.log(task);
                        const res = this._apiService.addTask(task);
                        console.log('============= create response =============');
                        console.log(res);
                        break;
                    }
                } 
            }
            this.showLoading(false, true);
        }
    }

    /**
     * Retrieves task updates for the selected water tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the task updates are retrieved and modifications are requested.
     */
    async getTaskUpdates(): Promise<void> {
        try {
            if (this.checkIfNotOnlyOneTaskSelected()) return;
            for (let waterTask of this.clickedRows) { //* only one task
                const { taskUpdates, options, maxLength } = await this.getTaskUpdateOptions(waterTask);
                const question = '¿Desea ver solo algunos campos?';
                const option = await this._utilsService.openQuestionDialog('Seleccione', question, 'Algunos', 'Todos');
                if(option) {
                    await this.selectFieldUpdates(taskUpdates);
                }
                else await this.requestModifications(taskUpdates, options, maxLength);
            }
        } catch (err) {}
    }

    /**
     * Opens a dialog to select fields for updates and processes the selected fields.
     * 
     * @param {WaterTaskUpdate[]} updates - An array of WaterTaskUpdate objects to be processed.
     * @returns {Promise<any[]>} - A promise that resolves to an array of JSON objects representing the selected fields.
     */
    async selectFieldUpdates(updates: WaterTaskUpdate[]): Promise<any[]>{
        const columns = getWaterTaskColumns();
        const fields = await this._utilsService.openMultipleOptionsDialog('Seleccione campos', columns, []);

        const jsons = this.getJsonArrayFields(updates, fields);
        if (jsons && jsons.length) {
            let texts = [];
            for(const json of jsons){
                texts.push(JSON.stringify(json, null, 4));
            }
            this._utilsService.openInformationJsonDialog('Actualizaciones', JSON.stringify(jsons, null, 2));
        }
        return jsons;
    }

    /**
     * Extracts specified fields from a list of WaterTaskUpdate objects and returns an array of objects containing those fields.
     *
     * @param updates - An array of WaterTaskUpdate objects, each containing a JSON string in the `data` property.
     * @param fields - An array of strings representing the field names to extract from each WaterTask object.
     * @returns An array of objects, each containing the specified fields from the corresponding WaterTask object.
     */
    getJsonArrayFields(updates: WaterTaskUpdate[], fields: string[]): any[] {
        const allSelectedFieldsData = [];
        if (fields && fields.length) {
            for (const update of updates) {
                const task: WaterTask = JSON.parse(update.data);
                let data: any = {};
                for (const field of fields) {
                    if(task.hasOwnProperty(field)) data[field] = task[field as keyof WaterTask];
                }
                if (data) allSelectedFieldsData.push(data);
            }
        }
        return allSelectedFieldsData;
    }

    /**
     * Requests modifications for the given task updates.
     * @param {WaterTaskUpdate[]} updates - The task updates to request modifications for.
     * @param {string[]} options - The available modification options.
     * @param {number} maxLength - The maximum length of the task update line log.
     * @returns {Promise<void>} - A promise that resolves when the modifications are requested.
     */
    async requestModifications(updates: WaterTaskUpdate[], options: string[], maxLength: number): Promise<void> {
        try {
            const opt = await this._utilsService.openSelectorDialog('Seleccione modificación', options);
            const update = updates.find((tu: WaterTaskUpdate) => this.getTaskUpdateLineLog(tu, maxLength) == opt);
            let success = true;
            if (update) success = await this.requestReplaceInfo(update, opt);
            if (!success) await this.requestModifications(updates, options, maxLength);
        } catch (err) { }
    }

    /**
     * Retrieves the task update options for a given water task.
     * @param waterTask - The water task for which to retrieve the update options.
     * @returns An object containing the task updates, options, and maximum length.
     */
    async getTaskUpdateOptions(waterTask: WaterTask) {
        this.showLoading(true, true);
        this.loadingText = 'Descargando actualizaciones...';
        const taskUpdates: WaterTaskUpdate[] = await this._apiService.getTaskUpdates(waterTask.id, 100);
        this.showLoading(false, true);
        let maxLength: number = 0;
        for (const tu of taskUpdates) if (maxLength < tu.data.length) maxLength = tu.data.length;
        const options = taskUpdates.map((tu: WaterTaskUpdate) => this.getTaskUpdateLineLog(tu, maxLength));
        return { taskUpdates, options, maxLength };
    }

    /**
     * Requests to replace information for a given task update.
     * @param taskUpdate - The task update object.
     * @param optionSelected - The selected option for the update.
     * @returns A boolean indicating whether the information was replaced or not.
     */
    async requestReplaceInfo(taskUpdate: WaterTaskUpdate, optionSelected: any) { 
        const res = await this._utilsService.openDataDialog('Actualización: ' + optionSelected, taskUpdate.data, 'Remplazar');
        if (res) {
            const text = '¿Seguro desea remplazar información de la tarea con esta actualización? --> ' + optionSelected;
            const confirmation = await this._utilsService.openQuestionDialog('Remplazando...', text, 'Remplazar');
            const task = taskUpdate.data;
            const waterTask: WaterTask = JSON.parse(task);
            if (confirmation && waterTask.id) return await this.replaceInfo(waterTask);
        }
        return false;
    }

    /**
     * Replaces the information of a water task.
     * @param waterTask - The water task object to be updated.
     * @returns A boolean indicating whether the information was replaced successfully.
     */
    async replaceInfo(waterTask: WaterTask) {
        this.showLoading(true);
        const updateOk = await this._apiService.updateTask(waterTask.id!.toString(), waterTask, true);
        this.showLoading(false);
        this.reload();
        if (updateOk) { 
            this._utilsService.openSnackBar('Información remplazada correctamente');
            return true;
        }
        else this._utilsService.openSnackBar('Error remplazando información', 'error');
        return false;
    }

    /**
     * Marks the selected rows as reviewed by updating their last_modification_android property.
     * Displays a success or error message based on the result of the update.
     * Reloads the data after marking the rows as reviewed.
     */
    async markAsReviewed() {
        if (this.nothingSelectedWarning()) return;
        try {
            const data = { last_modification_android: false };
            this.showLoading(true, true);
            this.loadingText = `Marcando como revisadas ...`;
            let ids: number[] = [];
            for (let row of this.clickedRows) if (row.id) ids.push(row.id);                        
            try {
                const result = await this._apiService.updateTasks(ids, data, false);
                if (result) this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
                else this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
            } catch (err) {
                this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
            }
            this.showLoading(false, true);
            this.reload();
        } catch (err) {}
    }

    /**
     * Assigns fields to tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the fields are assigned to tasks.
     */
    async assignFields(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            let json = await this._utilsService.openTaskAssignDialog();
            const keys = Object.keys(json);
            if (json && keys.length) {
                if(keys.includes('image_type')) return this.assignImages(json); 
                if(keys.includes('itac_image_type')) return this.assignItacImages(json); 
                await this.assignNormalFields(json, keys);
            }
        } catch (err) {}
    }

    /**
     * Assigns ITAC images to the selected rows.
     * 
     * This method processes the selected rows and assigns ITAC images based on the provided JSON data.
     * It first checks if there are any selected rows. If there are no selected rows, the method returns immediately.
     * 
     * The method then sets the `photoColumn` property to the `itac_image_type` from the JSON data.
     * It iterates over the selected rows and for each row, it checks if the `codigo_de_geolocalizacion` field is valid.
     * If the field is valid, it fetches the ITAC data using the `_apiService` and downloads the ITAC image.
     * 
     * @param json - The JSON data containing the ITAC image type.
     * @returns A promise that resolves when the ITAC images have been assigned.
     */
    async assignItacImages(json: any): Promise<void> {
        if(this.clickedRows.size === 0) return;

        const waterTasks = Array.from(this.clickedRows.values());
        
        if(this.isMaxSize(waterTasks, true)) return;

        for(let waterTask of waterTasks) {    
            if(this._utilsService.isFieldValid(waterTask.codigo_de_geolocalizacion)) {
                const itac = await this._apiService.getItacWithCode(waterTask.codigo_de_geolocalizacion!);
                await this.downloadItacImage(itac, json.itac_image_type);
                break;
            }
        }
    }

    /**
     * Downloads an image from the specified ITAC object and uploads it after processing.
     *
     * @param {Itac} itac - The ITAC object containing the image URL.
     * @param {string} photoField - The field name in the ITAC object that contains the image URL.
     * @returns {Promise<void>} A promise that resolves when the image has been downloaded and uploaded.
     *
     * @throws Will not throw an error, but will silently fail if any step in the process fails.
     */
    async downloadItacImage(itac: Itac, photoField: string): Promise<void> {
        try {
            if(!itac) return;
            const imageUrl = photoField ? itac[photoField as keyof Itac] : '';

            if(this._utilsService.isFieldValid(imageUrl)) {
                const blob = await this._fileSaverService.downloadFileAndGetBlob(imageUrl as string);
                const filename = this._utilsService.getImageName(imageUrl as string);
                const file = new File([blob], filename, { type: blob.type });
                this.photoColumn = await this._utilsService.openSelectorDialog('Campo de tarea a asignar', getPhotoFieldsToUpload());
                await this.executeUploadPhoto(file, Array.from(this.clickedRows.values()));
            }
        } catch (err) {}
    }

    /**
     * Assigns images based on the provided JSON object.
     * 
     * This method checks if the input file element is available, and if so,
     * sets the `photoColumn` property to the `image_type` from the JSON object
     * and triggers a click event on the input file element.
     * 
     * @param json - The JSON object containing image data.
     * @returns A promise that resolves when the operation is complete.
     */
    async assignImages(json: any): Promise<void> {
        if (this.inputFile?.nativeElement) {
            this.photoColumn = json.image_type;
            this.inputFile.nativeElement.click();
        }
    }
    /**
     * Handles the photo upload event, assigns tasks, and exports any tasks that failed to be assigned.
     * 
     * @param event - The event triggered by the file input element.
     * @returns A promise that resolves when the photo upload and task assignment process is complete.
     */
    async uploadPhoto(event: any): Promise<void> {
        const file = event.target.files.item(0);
        if (!file) return;
        const text = '¿Exportar solo las tareas seleccionadas?';
        const result = await this._utilsService.openQuestionDialog('Seleccione', text, 'Seleccionadas', 'Todas');

        let waterTasks: WaterTask[];
        if (result) {
            waterTasks = Array.from(this.clickedRows.values());
            if(this.isMaxSize(waterTasks, true)) return;
        }
        else {
            if (this.isMaxSizeCurrentFiltered(this.length, true))  return;
            this.showLoading(true, true);
            waterTasks = await this.getTasksInCurrentFilter();
            this.showLoading(false, true);
        }
        
        await this.executeUploadPhoto(file, waterTasks);
    }

    /**
     * Executes the upload of a photo and assigns tasks to water tasks.
     * Displays a loading spinner during the process and updates the loading text.
     * If any task fails, it collects the error tasks and exports them.
     * Finally, hides the spinner and reloads the component.
     *
     * @param file - The file to be uploaded.
     * @param waterTasks - An array of WaterTask objects to which the photo will be assigned.
     * @returns A promise that resolves when the upload and assignment process is complete.
     */
    async executeUploadPhoto(file: any, waterTasks: WaterTask[]): Promise<void> {
        let errorTasks: WaterTask[] = [];
        let index = 0;
        this.showLoading(true, true);
        for (let task of waterTasks) {
            this.loadingText = `Asignando tareas ${++index} de ${this.clickedRows.size}`;
            const res = await this.uploadImage(file, task, this.photoColumn);
            if(!res) errorTasks.push(task);
        }
        this.exportErrors(errorTasks);
        this.showLoading(false, true);
        this.reload();
    }

    /**
     * Exports the given error tasks to an Excel file.
     * 
     * @param errorTasks - An array of WaterTask objects representing the tasks that encountered errors.
     */
    exportErrors(errorTasks: WaterTask[]): void {
        if(errorTasks.length) {
            const filename = `Tareas_no_asignadas_${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
            this.exportExcel(errorTasks, getExcelExportColumns(), filename, getExcelFieldNameExportation);
        }
    }
    
    /**
     * Uploads an image and optionally associates it with a water task and photo type.
     *
     * @param file - The image file to be uploaded.
     * @param waterTask - (Optional) The water task to associate the image with.
     * @param photoType - (Optional) The type of photo being uploaded.
     * @returns A promise that resolves to a boolean indicating whether the upload was successful.
     */
    async uploadImage(file: any, waterTask?: WaterTask, photoType?: string): Promise<boolean> {
        this.form.patchValue({ image: file });
        this.form?.get('image')?.updateValueAndValidity();

        if (!waterTask || !photoType) return false;

        try {
            let formData: any = this._utilsService.getFormImageData(this.form, waterTask, photoType);
            const taskData = await this._apiService.uploadTaskImage(formData, waterTask);
            if(taskData.id) return true;
        } catch (err) {}
        
        return false;
    }
    
    /**
     * Checks if the number of water tasks exceeds the maximum size warning threshold.
     * If the number of tasks exceeds the threshold, a confirmation dialog is shown to the user.
     * 
     * @param waterTasks - An array or set of WaterTask objects to be checked.
     * @returns A promise that resolves to a boolean indicating whether the operation should continue.
     *          Returns `true` if the number of tasks does not exceed the threshold or if the user confirms the operation.
     *          Returns `false` if the user cancels the operation.
     */
    async isMaxSizeWarning(waterTasks: WaterTask[] | Set<WaterTask>): Promise<boolean> {
        try {
            let restoreLoading = this.loading;
            this.showLoading(false, true);

            const taskCount = Array.isArray(waterTasks) ? waterTasks.length : waterTasks.size;
            if (this.isMaxSize(waterTasks)) {
                const text = `Esta apunto de modificar ${taskCount} tareas ¿Desea continuar?`;
                const res = await this._utilsService.openQuestionDialog('Modificación en lote', text, 'Continuar', 'No');
                if (res) return false;
                else {
                    if (restoreLoading) this.showLoading(true, true);
                    return true;
                }
            }
            if (restoreLoading) this.showLoading(true, true);
        } catch (err) {
            return true;
        }
        return false; //para continuar en la funcion en que se llama isMaxSizeWarning
    }
    
    /**
     * Checks if the number of water tasks exceeds the maximum size warning.
     *
     * @param waterTasks - An array or set of WaterTask objects.
     * @returns `true` if the number of water tasks exceeds the maximum size warning, otherwise `false`.
     */
    isMaxSize(waterTasks: WaterTask[] | Set<WaterTask>, showWarning?: boolean): boolean {
        const taskCount = Array.isArray(waterTasks) ? waterTasks.length : waterTasks.size;
        if(taskCount > MAX_SIZE_WARNING) {
            const text = `El número de tareas seleccionadas (${taskCount}) excede el límite permitido (${MAX_SIZE_WARNING})`;
            if (showWarning) this._utilsService.openSnackBar(text, 'warning');
            return true; //para salir de funcion en que se llama isMaxSize
        }
        return false;
    }

    /**
     * Checks if the given length exceeds the maximum allowed size and optionally shows a warning.
     *
     * @param length - The number of tasks selected.
     * @param showWarning - Optional parameter to determine if a warning should be shown when the length exceeds the limit.
     * @returns `true` if the length exceeds the maximum allowed size, otherwise `false`.
     */
    isMaxSizeCurrentFiltered(length: number, showWarning?: boolean): boolean {
        if(length > MAX_SIZE_WARNING) {
            const text = `El número de tareas seleccionadas (${length}) excede el límite permitido (${MAX_SIZE_WARNING})`;
            if (showWarning) this._utilsService.openSnackBar(text, 'warning');
            return true; //para salir de funcion en que se llama isMaxSizeCurrentFiltered
        }
        return false;
    }
    
    /**
     * Checks if the number of tasks exceeds the maximum size warning threshold and prompts the user for confirmation if it does.
     * 
     * @param length - The number of tasks to be modified.
     * @returns A promise that resolves to `true` if the operation can proceed, or `false` if the user cancels the operation.
     * 
     * @throws Will catch and ignore any errors that occur during the execution.
     */
    async isMaxSizeWarningCurrentFiltered(length: number): Promise<boolean> {
        try {
            let restoreLoading = this.loading;
            this.showLoading(false, true);

            if(length > MAX_SIZE_WARNING) {
                const text = `Esta apunto de modificar ${length} tareas ¿Desea continuar?`;
                const res = await this._utilsService.openQuestionDialog('Modificación en lote', text, 'Continuar', 'No');
                if (res) return false;
                else {
                    if (restoreLoading) this.showLoading(true, true);
                    return true;
                }
            }
            if (restoreLoading) this.showLoading(true, true);
        } catch (err) {
            return true;
        }
        return false; //para continuar en la funcion en que se llama isMaxSizeWarningCurrentFiltered
    }
    
    /**
     * Assigns normal fields to a water task and handles potential errors.
     *
     * @param {WaterTask} waterTask - The water task to which fields will be assigned.
     * @param {string[]} keys - The keys representing the fields to be assigned.
     * @returns {Promise<void>} A promise that resolves when the assignment is complete.
     *
     * @remarks
     * - If the `waterTask` has an `OPERARIO` property, it will be deleted and `deassignUsers` will be set to true.
     * - Displays a loading text indicating the assignment process.
     * - If any of the specified keys are in the list of special fields, the `updateOneByOne` method is called.
     * - Otherwise, the `updateMultiple` method is called.
     * - If there are errors during the assignment, an error message is displayed using `_utilsService.openSnackBar`.
     * - If the assignment is successful, a success message is displayed using `_utilsService.openSnackBar`.
     * - Hides the spinner and reloads the component after the assignment process.
     */
    async assignNormalFields(waterTask: WaterTask, keys: string[]): Promise<void> {
        let errorIds: number[] = [];
        let deassignUsers = false;
        if (waterTask.OPERARIO) {
            deassignUsers = true;
            delete waterTask.OPERARIO;
        }
        this.loadingText = `Asignando campos a tareas ...`;
        if (keys.includes('causa_origen') 
            || keys.includes('ANOMALIA') 
            || keys.includes('OBSERVADV') 
            || keys.includes('MENSAJE_LIBRE')
            || keys.includes('servicios')
            || keys.includes('suministros')
            || keys.includes('planning_detail_extras')
        ) {
            await this.updateOneByOne(waterTask, keys, errorIds, deassignUsers);
        } 
        else await this.updateMultiple(waterTask, keys, errorIds, deassignUsers);
        
        if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
        else this._utilsService.openSnackBar(`Finalizado`);
        
        this.showLoading(false, true);
        this.reload();
    }

    /**
     * Updates multiple water tasks one by one.
     * 
     * @param waterTask - The water task to update.
     * @param keys - The keys of the properties to update in the water task.
     * @param errorIds - An array to store the IDs of the tasks that failed to update.
     */
    async updateOneByOne(waterTask: WaterTask, keys: any, errorIds: any[], deassignUsers: boolean = false) { 
        if(await this.isMaxSizeWarning(this.clickedRows)) return;

        await this._apiService.getCauses();

        if (deassignUsers) await this.deassignUsersInPage();

        let res = true;
        if (this.allSelected) {
            const question = 'Desea asignar solo a la página actual o a todas las tareas del filtro seleccionado';
            res = await this._utilsService.openQuestionDialog('Tipo de asignación', question, 'Página', 'Todas');
        }
        this.showLoading(true, true);
        let waterTasks = res ? Array.from(this.clickedRows.values()): await this.getTasksInCurrentFilter();

        await this.executeUpdateOneByOne(waterTask, waterTasks, keys, errorIds);
    }

    /**
     * Executes an update operation on a list of water tasks one by one.
     * 
     * @param waterTask - The water task object containing the update information.
     * @param tasks - The list of water tasks to be updated.
     * @param keys - The keys of the fields to be updated in each task.
     * @param errorIds - An array to store the IDs of tasks that failed to update.
     * 
     * @returns A promise that resolves when all tasks have been processed.
     */
    async executeUpdateOneByOne(waterTask: WaterTask, tasks: WaterTask[], keys: any, errorIds: any[]) { 
        let updateCount = 0;

        for (let task of tasks) {
            this.loadingText = `Asignando tareas ${++updateCount} de ${tasks.length}`;
            if (keys.includes('causa_origen') || keys.includes('ANOMALIA')) {
                try {
                    let anomaly;
                    if(keys.includes('causa_origen')) anomaly= waterTask.causa_origen!.split('-')[0].trim();
                    else anomaly = waterTask.ANOMALIA;
                    const caliber = task.CALIBRE;
                    const mark = task.MARCA;
                    if (anomaly) {
                        waterTask.ANOMALIA = anomaly;
                        waterTask.CALIBRE = caliber;
                        waterTask.MARCA = mark;
                        waterTask = this._utilsService.autocompleteCauseFields(waterTask);
                    }
                } catch (err) {}
            }
            if (await this._apiService.updateTask(task.id!, waterTask)) {
                const index = this.waterTasks.indexOf(task, 0);
                if (index > -1) {
                    for (let key of keys) {
                        const keyIndex = key as keyof WaterTask;
                        let value = waterTask[keyIndex]!; //this.task[key as keyof WaterTask];
                        task[keyIndex] = value as any;
                    }
                    this.waterTasks[index] = task;
                }
            } 
            else errorIds.push(task.id!);
        }
    }

    /**
     * Updates multiple water tasks based on the provided parameters.
     * 
     * @param waterTask - The water task to be updated.
     * @param keys - The keys associated with the water task.
     * @param errorIds - An array to collect error IDs if any errors occur during the update.
     * @param deassignUsers - A boolean flag indicating whether to deassign users. Defaults to false.
     * @returns A promise that resolves when the update is complete.
     */
    async updateMultiple(waterTask: WaterTask, keys: any, errorIds: any[], deassignUsers: boolean = false): Promise<void> {
        let res = true;
        if (this.allSelected) {
            const question = 'Desea asignar solo a la página actual o a todas las tareas del filtro seleccionado';
            res = await this._utilsService.openQuestionDialog('Tipo de asignación', question, 'Página', 'Todas');
        }
        this.showLoading(true, true);
        if (res) {
            if (deassignUsers) await this.deassignUsersInPage();
            await this.updateMultipleInPage(waterTask, keys, errorIds);
            return;
        } 
        try {
            if (deassignUsers) await this.deassignAllUsers();
            await this.updateMultipleAll(waterTask, keys, errorIds);
        } catch (err) {
            if (this.waterTasks.length > 0) errorIds.push(this.waterTasks[0]);
        }
    }

        /**
     * Updates multiple tasks on the current page.
     *
     * This method collects the IDs of the clicked rows and attempts to update the tasks
     * associated with those IDs using the provided `waterTask` object. If the update is
     * successful, it updates the actual page information. If the update fails, it assigns
     * the IDs to the `errorIds` array.
     *
     * @param waterTask - The task object containing the updated information.
     * @param keys - Additional keys or parameters required for updating the page info.
     * @param errorIds - An array to store the IDs of the tasks that failed to update.
     * @returns A promise that resolves when the update operation is complete.
     */
    async updateMultipleInPage(waterTask: WaterTask, keys: any, errorIds: any[]): Promise<void> {
        if(await this.isMaxSizeWarning(this.clickedRows)) return;

        let ids: number[] = [];
        for (let row of this.clickedRows) if (row.id) ids.push(row.id);                        
        try {
            const result = await this._apiService.updateTasks(ids, waterTask, false);
            if (result) this.updateActualPageInfo(waterTask, keys);
            else errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
    }

    /**
        let res = true; tasks based on the provided filter criteria and water task details.
     *
     * @param waterTask - The water task details to be updated.
     * @param keys - An object containing keys related to the tasks.
     * @param errorIds - An array to store IDs of tasks that failed to update.
     * @returns A promise that resolves when the update operation is complete.
     */
    async updateMultipleAll(waterTask: WaterTask, keys: any, errorIds: any[]): Promise<void> { 
        if(await this.isMaxSizeWarningCurrentFiltered(this.length)) return;

        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        const result = await this._apiService.updateMultipleTasks(where_clause, waterTask, false);

        if (result) this.updateActualPageInfo(waterTask, keys);
        else if (this.waterTasks.length > 0) errorIds.push(this.waterTasks[0]);
    }

    /**
     * Updates the actual page information for the specified water task and keys.
     * @param waterTask - The water task object to update.
     * @param keys - The keys to update in the water task object.
     */
    updateActualPageInfo(waterTask: any, keys: any): void {
        for (let row of this.clickedRows) {
            const index = this.waterTasks.indexOf(row, 0);
            for (let key of keys) {
                const keyIndex = key as keyof WaterTask;
                let value = waterTask[keyIndex]!; //this.task[key as keyof WaterTask];
                row[keyIndex] = value;
            }
            this.waterTasks[index] = row;
            if(this.dataSource.data.length > index) this.dataSource.data[index] = row;
        }
    }

    /**
     * Reloads the current route.
     */
    reload(): void {
        this.clearLastTaskClicked();

        this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate(['./'], { relativeTo: this.route });
    }

    /**
     * Clears the session storage entries related to the last task clicked.
     * Specifically, it removes the 'openedFromDoubleClick' and 'lastTaskIdClicked' keys
     * from the session storage.
     *
     * @returns {void} This method does not return a value.
     */
    clearLastTaskClicked(): void {
        sessionStorage.removeItem('openedFromDoubleClick');
        sessionStorage.removeItem('lastTaskIdClicked');
    }

    /**
     * Retrieves the summary of tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the summary is retrieved.
     */
    async getSummary(): Promise<void> {
        this.showLoading(true, true);
        this.loadingText = 'Cargando resumen ...';
        let ids: number[] = [];
        for (let waterTask of this.clickedRows) ids.push(waterTask.id!);
        let result;
        if(ids.length) result = await this._mySqlService.getTaskTypeSummary(ids);
        else {
            result = await this._mySqlService.getTaskTypeSummaryWhere(this.getCurrentWhereClause());
        }
        this.showLoading(false, true);
        await this._utilsService.openInformationDialog('Resumen de tareas', result);
    }

    /**
     * Retrieves the current WHERE clause for filtering tasks.
     * 
     * @returns The current WHERE clause as a string.
     */
    getCurrentWhereClause(): string {
        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        where_clause = this._mySqlService.getCompanyManagerWhereClause(where_clause);
        return where_clause
    }

    /**
     * Sends a WhatsApp message for each selected water task.
     * If no phone numbers are available for the selected tasks, displays an information dialog.
     */
    async sendWhatsappMessage(): Promise<void> {
        let noPhone = true;
        for (let waterTask of this.clickedRows) {
            if (waterTask.telefono1 || waterTask.telefono2) {
                await this._utilsService.openWhatsappMessageDialog(waterTask);
                noPhone = false;
            }
        }
        if (noPhone){
            const mess = 'No hay telefonos en las tareas seleccionadas';
            this._utilsService.openInformationDialog('Información', [mess]);
        }
    }

    /**
     * Passes selected tasks to dates.
     * @returns {Promise<void>} A promise that resolves when the tasks are passed to dates.
     */
    async tryPassToDates(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const tomorrow: Date = moment().add(1, 'day').toDate();
            const date = await this._utilsService.openDateSelectorDialog('Seleccione dia de pase', tomorrow);
            if(date) {
                const data = this.getPassToDateData(date);
                let taskWithDates: WaterTask[] = [];
                let ids: number[] = [];
                for (let waterTask of this.clickedRows) {
                    if(waterTask.cita_pendiente) taskWithDates.push(waterTask);
                    else ids.push(waterTask.id!);
                }
                await this.passToDates(taskWithDates, ids, data);
            }
        } catch (err) {}
    }

    /**
     * Processes tasks with dates and updates tasks without dates to have dates.
     * 
     * @param {WaterTask[]} taskWithDates - An array of tasks that already have dates.
     * @param {number[]} ids - An array of task IDs that need to be updated with dates.
     * @param {any} data - The data to be used for updating the tasks.
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async passToDates(taskWithDates: WaterTask[], ids: number[], data: any): Promise<void> {
        if(taskWithDates.length) this.exportTaskWithDates(taskWithDates);
        if(ids && ids.length) {
            this.showLoading(true, true);
            this.loadingText = `Pasando a citas ...`;
            const success = await this._apiService.updateTasks(ids, data);
            if(success) this._utilsService.openSnackBar('Pasadas a citas correctamente');
            else this._utilsService.openSnackBar('Error pasando a citas', 'error');
            this.showLoading(false, true);
            this.reload();
        }
        else this._utilsService.openSnackBar('No hay tareas sin citas para pasar a citas', 'warning');
    }

    /**
     * Exports the given water tasks to an Excel file with dates.
     * @param waterTasks - The array of water tasks to export.
     */
    exportTaskWithDates(waterTasks: WaterTask[]): void {
        const now = moment().format('YYYY-MM-DD_HH-mm-ss');
        const filename = `Tareas_con_citas_${now}.xlsx`;
        this.exportExcel(waterTasks, getExcelExportColumns(), filename, getExcelFieldNameExportation);
        const mess = 'Hay tareas que ya tienen con citas, se descargará un excel con el listado';
        this._utilsService.openSnackBar(mess, 'warning');
    }

    /**
     * Retrieves the date data for a given date.
     * @param date - The date for which to retrieve the data.
     * @returns An object containing the date data.
     */
    getPassToDateData(date: Date): any {
        const userId = this._apiService.getLoggedUserId();
        const startDate = moment(date).set({ hour: 21, minute: 0 });
        const endDate = moment(date).set({ hour: 21, minute: 20 });
        const data = {
            fecha_hora_cita: startDate.toDate(),
            fecha_hora_cita_end: endDate.toDate(),
            nuevo_citas: this._utilsService.getNewDateString(startDate, endDate), 
            cita_pendiente: true,
            ultima_modificacion: userId
        }
        return data;
    }

    /**
     * Deletes the selected dates from tasks.
     * 
     * @returns A Promise that resolves to void.
     */
    async deleteDates(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        this.showLoading(true, true);
        this.loadingText = `Eliminando citas de tareas...`;
        let ids: number[] = [];
        for (let waterTask of this.clickedRows) {
            ids.push(waterTask.id!);
        }
        const userId = this._apiService.getLoggedUserId();
        const data = {
            fecha_hora_cita: null,
            fecha_hora_cita_end: null,
            nuevo_citas: null,
            cita_pendiente: null,
            ultima_modificacion: userId
        }
        const success = await this._apiService.updateTasks(ids, data);
        if(success) this._utilsService.openSnackBar('Citas eliminadas correctamente');
        else this._utilsService.openSnackBar('Error eliminando citas', 'error');
        this.showLoading(false, true);
        this.reload();
    }

    /**
     * Downloads photos for the selected tasks.
     * @returns {Promise<void>} A promise that resolves when the photos are downloaded successfully.
     */
    async downloadPhotos(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const company: Company = JSON.parse(localStorage.getItem('companyJson') || '');
            const manager: Manager = JSON.parse(localStorage.getItem('managerJson') || '');

            await this.executeDownloadPhotos(company, manager);

            if (this._electronService.isElectronApp()) this.openElectronFolder(company, manager);
            
        } catch (err) {
            this._utilsService.openSnackBar(`Hubo errores descargando fotos`, 'error');
        }
    }

    /**
     * Executes the download of photos for the tasks in `clickedRows`.
     * Displays a loading indicator and updates the loading text during the process.
     * Once all photos are downloaded, a success message is shown.
     *
     * @param {Company} company - The company for which the photos are being downloaded.
     * @param {Manager} manager - The manager responsible for the tasks.
     * @returns {Promise<void>} A promise that resolves when the download process is complete.
     */
    async executeDownloadPhotos(company: Company, manager: Manager): Promise<void> {
        let i = 0;

        this.showLoading(true, true);
        this.loadingText = `Descargando fotos de tareas ...`;
        
        for (let task of this.clickedRows) {
            this.loadingText = `Descargando fotos de tareas ${i++} de ${this.clickedRows.size}...`;
            await this.downloadPhoto(task, company, manager);
        }
        
        this._utilsService.openSnackBar(`Fotos descargadas correctamente`);
        this.showLoading(false, true);
        
    }

    /**
     * Downloads photos associated with a given task, company, and manager.
     *
     * @param task - The task containing the photos to be downloaded.
     * @param company - The company associated with the task.
     * @param manager - The manager overseeing the task.
     * @returns A promise that resolves when all photos have been downloaded.
     */
    async downloadPhoto(task: WaterTask, company: Company, manager: Manager): Promise<void> {
        try {
            const photos: any = this.getDownloadablePhotos(task);
            for (let photo of photos) {
                this._fileSaverService.downloadPhoto(photo, task, company, manager);
            }
        } catch (err) {}
    }

    async downloadServerFolderImages(): Promise<void> { 
        if (this.nothingSelectedWarning()) return;
        try {
            this.showLoading(true, true);
            this.loadingText = `Descargando fotos del servidor de tareas ...`;
            let i = 0;
            for (let task of this.clickedRows) {
                this.loadingText = `Descargando del servidor fotos de tareas ${i++} de ${this.clickedRows.size}...`;
                
                const images = await this._apiService.getServerFolderImages(task);
                if (images.length) {
                    for (const image of images) await this.downloadServerFolderImage(task, image);
                }
            }
            this.showLoading(false, true);
        } catch (err) {
            
        }
    }    
    
    /**
     * Downloads an image associated with the current task.
     * 
     * This method retrieves the selected company and manager information from local storage,
     * constructs the filename for the image, and then uses the `fileSaverService` to download the image.
     * 
     * @param image - The URL or path of the image to be downloaded.
     * 
     * @remarks
     * - The method assumes that `this.task` is defined.
     * - The company and manager information are expected to be stored in local storage under the keys 'companyJson' and 'managerJson' respectively.
     * - The `utilsService` is used to generate the image filename.
     * - The `fileSaverService` handles the actual downloading of the image.
     */
    async downloadServerFolderImage(task: WaterTask, image: string): Promise<void> { 
        if (task) {
            const companySelected = JSON.parse(localStorage.getItem('companyJson') || '');
            const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '');
            const filename = this._utilsService.getImageName(image);
            const photo = { 'photo': image, 'downloadName': filename };
            await this._fileSaverService.downloadPhoto(photo, task, companySelected, managerSelected);
        }
    }

    /**
     * Opens the Electron folder for the selected company and manager.
     * @param companySelected - The selected company object.
     * @param managerSelected - The selected manager object.
     */
    openElectronFolder(companySelected: any, managerSelected: any): void {
        const path = `C:\\Mi_Ruta\\Empresas\\${
            companySelected.nombre_empresa || companySelected.id
        }\\Gestores\\${managerSelected.gestor || managerSelected.id}\\fotos_tareas\\`;
        this._electronService.sendMessage('open-folder', {
            path: path,
        });
    }

    /**
     * Retrieves the downloadable photos from the given WaterTask object.
     * 
     * @param waterTask - The WaterTask object containing the photos.
     * @returns An array of objects representing the downloadable photos, each containing the photo URL and download name.
     */
    getDownloadablePhotos(task: WaterTask): any[] {
        let photos: any[] = [];
        this.getDownloadablePhotoType(task, photos, 'firma_cliente');
        this.getDownloadablePhotoType(task, photos, 'foto_antes_instalacion');
        this.getDownloadablePhotoType(task, photos, 'foto_despues_instalacion');
        this.getDownloadablePhotoType(task, photos, 'foto_incidencia_1');
        this.getDownloadablePhotoType(task, photos, 'foto_incidencia_2');
        this.getDownloadablePhotoType(task, photos, 'foto_incidencia_3');
        this.getDownloadablePhotoType(task, photos, 'foto_lectura');
        this.getDownloadablePhotoType(task, photos, 'foto_numero_serie');
        this.getDownloadablePhotoType(task, photos, 'foto_entorno');
        this.getDownloadablePhotoType(task, photos, 'audio_detalle');
        return photos;
    }

    /**
     * Checks if the given photo type in the WaterTask object is a server image.
     *
     * This function determines if the photo associated with the specified photo type
     * in the WaterTask object is a server image by checking if the photo URL contains
     * 'http' and if there is a corresponding server path for the photo type.
     *
     * @param waterTask - The WaterTask object containing the photo information.
     * @param photoType - The type of photo to check within the WaterTask object.
     * @returns `true` if the photo is a server image, otherwise `false`.
     */
    isServerImage(waterTask: WaterTask, photoType: string) {
        const type = photoType as keyof WaterTask;
        const photo = waterTask[type] as string;
        if (photo?.includes('http') && waterTask[`${type}_server_path` as keyof WaterTask]) {
            return true;
        }
        return false;
    }
    
    /**
     * Retrieves a downloadable photo from a WaterTask object and adds it to the provided photos array.
     * The photo is identified by the specified photoType.
     *
     * @param waterTask - The WaterTask object containing the photo information.
     * @param photos - An array to which the downloadable photo information will be added.
     * @param photoType - The type of photo to retrieve from the WaterTask object.
     */
    getDownloadablePhotoType(waterTask: WaterTask, photos: any[], photoType: string): void {
        const type = photoType as keyof WaterTask;
        const photo = waterTask[type] as string;

        if (this.isServerImage(waterTask, photoType)) {
            const idOrdenPrefix = waterTask.idOrdenCABB ? waterTask.idOrdenCABB + '_' : '';
            const split = photo.split('/');

            let downloadName = split[split.length - 1];
            downloadName = `${idOrdenPrefix}${downloadName}`;
            
            photos.push({ photo: waterTask[type], downloadName: downloadName });
        }
    }

    /**
     * Assigns an ID order to selected tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the ID order is assigned to the tasks.
     */
    async assignIdOrderToTasks(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            let updateCount = 0;
            let errorIds = [];
            let updateIdOrder = false;
            const info = await this._apiService.getInfo();
            this._utilsService.zones = await this._apiService.getZones();
            let idOrderStart = 0;
            if (info) {
                idOrderStart = info.lastIDOrden!;
                idOrderStart++;
            }
            let idOrden = await this._utilsService.openInputSelectorDialog(
                'Inserte ID de orden inicial...',
                idOrderStart.toString(),
                'number'
            );

            if (idOrden >= idOrderStart) {
                updateIdOrder = true;
            }
            const userId = this._apiService.getLoggedUserId();
            if (idOrden) {
                this.showLoading(true, true);
                this.loadingText = `Asignando ID de orden a tareas ...`;

                const size = this.clickedRows.size;
                for (let row of this.clickedRows) {
                    const index = this.waterTasks.indexOf(row, 0);
                    if (index > -1) {
                        this.loadingText = `Asignando ID de orden a tareas ${++updateCount} de ${size}`;
                        if (
                            !(await this._apiService.updateTask(row.id!, {
                                idOrdenCABB: idOrden,
                                ID_SAT: idOrden,
                                ultima_modificacion: userId,
                            }))
                        ) {
                            idOrden++;
                            errorIds.push(row.id);
                        } else {
                            row['idOrdenCABB'] = idOrden;
                            row['ID_SAT'] = idOrden;
                            row['ultima_modificacion'] = userId;
                            this.waterTasks[index] = row;
                            idOrden++;
                        }
                    }
                }
                if (errorIds.length > 0) {
                    this._utilsService.openSnackBar(`Hubo errores asignando id a tareas`, 'error');
                } else {
                    this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
                }
                if (updateIdOrder) {
                    if (info) await this.updateIdOrder(info, idOrden - 1); //? I subtract 1 because of the last wrong increment
                } else {
                }
                this.showLoading(false, true);
            }
        } catch (err) {}
    }

    /**
     * Updates the ID order in the provided `info` object.
     * 
     * @param info - The `Info` object to update.
     * @param idOrden - The new ID order value.
     */
    async updateIdOrder(info: Info, idOrden: number): Promise<void> {
        info.lastIDOrden = idOrden;
        info.lastIDSAT = idOrden;
        // const company = localStorage.getItem('company');
        await this._apiService.updateDocument('info', info.id!, info);
    }

    /**
     * Updates the ID export for the given info object.
     * @param info - The info object to update.
     * @param idExport - The new ID export value.
     * @returns A promise that resolves when the update is complete.
     */
    async updateIdExport(info: Info, idExport: number): Promise<void> {
        info.lastIDExportacion = idExport;
        await this._apiService.updateDocument('info', info.id!, info);
    }

    /**
     * Assigns operators to tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the assignment is complete.
     */
    async assignOperatorToTasks(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            let errorIds = [];
            const company = localStorage.getItem('company');
            let users = await this._apiService.getUsers(['operario']);

            users = users.filter((user) =>
                this._utilsService.checkCompany(user.companies, company || '')
            );

            const usersSelectedName = await this._utilsService.openMultipleOptionsDialog(
                'Seleccione operarios',
                users.map((user: MiRutaUser) => this._utilsService.userPipe(user.id)),
                []
            );

            const userId = this._apiService.getLoggedUserId();
            if (usersSelectedName && usersSelectedName.length > 0) {
                const miRutaUsers = users.filter((user: MiRutaUser) =>
                    usersSelectedName.includes(this._utilsService.userPipe(user.id))
                );
                if (miRutaUsers) {
                    this.showLoading(true, true);
                    this.loadingText = `Asignando operarios a tareas ...`;
                    let ids: number[] = [];
                    for (let row of this.clickedRows) if (row.id) ids.push(row.id);
                    try {
                        const result = await this._apiService.updateTasks(
                            ids, { OPERARIO: miRutaUsers, ultima_modificacion: userId }, false
                        );
                        if (result) {
                            for (let row of this.clickedRows) {
                                const index = this.waterTasks.indexOf(row, 0);
                                row['OPERARIO'] = miRutaUsers;
                                this.waterTasks[index] = row;
                            }
                        } else errorIds = ids;
                    } catch (err) {
                        errorIds = ids;
                    }
                    if (errorIds.length > 0) {
                        this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
                    } else {
                        this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
                    }
                    this.showLoading(false, true);
                }
            }
        } catch (err) {}
    }

    /**
     * @brief Asynchronously deassigns operators from selected tasks.
     *
     * This function removes operators from selected tasks and updates the task information.
     * It displays a success message if the operation is successful, or an error message if there are any issues.
     *
     * @return {Promise<void>} A Promise that resolves when the operation is complete.
     */
    async deassignOperatorToTasks(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const confirmation = await this._utilsService.openQuestionDialog(
                'Confirmación',
                '¿Desea desasignar operarios de las tareas seleccionadas?'
            );
            if(confirmation) {
                let onlyPage = true;
                if (this.allSelected) {
                    onlyPage = await this._utilsService.openQuestionDialog(
                        'Tipo de desasignación',
                        'Desea desasignar solo a la página actual o a todas las tareas del filtro seleccionado',
                        'Página',
                        'Todas'
                    );
                }
                this.showLoading(true, true);
                this.loadingText = `Desasignando operarios a tareas ...`;
                if(onlyPage) await this.deassignUsersInPage();
                else await this.deassignAllUsers();
                this.showLoading(false, true);
                this.reload();
            }
        } catch (err) {}
    }

    /**
     * Deassigns tasks for a user.
     * @param ids - An array of task IDs to deassign.
     * @returns A Promise that resolves when the tasks are deassigned.
     */
    async deassignTasksUser(ids: number[]): Promise<void> {
        let errorIds = [];
        try {
            const userId = this._apiService.getLoggedUserId();
            const data = { OPERARIO: [], ultima_modificacion: userId };
            const result = await this._apiService.updateTasks(ids, data, false);
            if (result) {
                for (let row of this.clickedRows) {
                    const index = this.waterTasks.indexOf(row, 0);
                    row['OPERARIO'] = [];
                    this.waterTasks[index] = row;
                }
            } else errorIds = ids;
        } catch (err) {
            errorIds = ids;
        }
        if (errorIds.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores desasignando tareas`, 'error');
        } else {
            this._utilsService.openSnackBar(`Tareas desasignadas correctamente`);
        }
    }

    /**
     * Deassigns all users from the tasks in the current filter.
     * @returns {Promise<void>} A promise that resolves when all users have been deassigned.
     */
    async deassignAllUsers(): Promise<void> {
        const waterTasks =  await this.getTasksInCurrentFilter();
        
        if(await this.isMaxSizeWarning(waterTasks)) return;
        
        let ids: number[] = [];
        for (let row of waterTasks) if (row.id) ids.push(row.id);
        await this.deassignTasksUser(ids);
    }

    /**
     * Deassigns users in the current page.
     * Retrieves the IDs of the clicked rows and calls the `deassignTasksUser` method to deassign tasks for those users.
     */
    async deassignUsersInPage(): Promise<void> {
        if(await this.isMaxSizeWarning(this.clickedRows)) return;
        
        let ids: number[] = [];
        for (let row of this.clickedRows) if (row.id) ids.push(row.id);
        await this.deassignTasksUser(ids);
    }

    /**
     * Assigns a team to tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the team is assigned to the tasks.
     */
    async assignTeamToTasks(): Promise<void> {
        if (this.nothingSelectedWarning()) return;
        try {
            const teams = await this._apiService.getTeams();
            if (!teams || teams.length == 0) {
                this._utilsService.openSnackBar('No hay equipos para esta empresa y gestor', 'warning');
                return;
            }
            const teamSelectedName = await this._utilsService.openSelectorDialog(
                'Seleccione equipo',
                teams.map((team: Team) => team.equipo_operario)
            );
            if (teamSelectedName) {
                const teamId = teams.find((team: Team) => team.equipo_operario == teamSelectedName)!.id!;
                await this.executeAssingTeam(teamSelectedName, teamId);
            }
        } catch (err) {}
    }

    /**
     * Asynchronously assigns a team to tasks based on the selected team name and ID.
     * If all tasks are selected, prompts the user to choose between assigning to the current page or all filtered tasks.
     * Displays a loading indicator and updates the loading text during the assignment process.
     * Shows a success or error message upon completion and reloads the component.
     *
     * @param {string} teamSelectedName - The name of the selected team.
     * @param {number} teamId - The ID of the selected team.
     * @returns {Promise<void>} A promise that resolves when the assignment process is complete.
     */
    async executeAssingTeam(teamSelectedName: string, teamId: number): Promise<void> {
        if (teamId) {
            let now = new Date();
            let onlyPage = true;
            if (this.allSelected) {
                const text = 'Desea asignar solo a la página actual o a todas las tareas del filtro seleccionado';
                onlyPage = await this._utilsService.openQuestionDialog('Tipo de asignación', text, 'Página', 'Todas');
            }
            this.showLoading(true, true);
            this.loadingText = `Asignando ${teamSelectedName} a tareas ...`;
            const userId = this._apiService.getLoggedUserId();
            const data = { equipo: teamId, date_time_modified: now, ultima_modificacion: userId };

            let success: boolean = false;
            if (onlyPage) success = await this.assingTeamToPage(data);
            else success = await this.assingAllTeam(data);
            
            if (success) this._utilsService.openSnackBar(`Tareas asignadas correctamente`);
            else this._utilsService.openSnackBar(`Hubo errores asignando tareas`, 'error');
            
            this.showLoading(false, true);
            this.reload();
        }
    }

    /**
     * Assigns a team to the page.
     * 
     * @param data - The data to be assigned to the page.
     * @returns A promise that resolves when the team is assigned successfully.
     */
    async assingTeamToPage(data: any): Promise<boolean> {
        let ids: number[] = [];
        let itacIds: string[] = [];
        for (let t of this.clickedRows) {
            if (t.id) {
                ids.push(t.id);
                if(t.codigo_de_geolocalizacion) itacIds.push(t.codigo_de_geolocalizacion);
            }
        }
        try {
            const result = await this._apiService.updateTasks(ids, data, false);
            if (result) {
                await this.updateItacsDataWithCodes(itacIds, data);
                return true;
            }
        } catch (err) {}
        return false;
    }

    /**
     * Assigns data to all team members.
     * 
     * @param data - The data to be assigned.
     * @returns A promise that resolves to a boolean indicating whether the assignment was successful.
     */
    async assingAllTeam(data: any): Promise<boolean> {
        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        const res = await this._apiService.updateMultipleTasks(where_clause, data, false); 
        if (res) {
            await this.updateItacsData(where_clause, data);
            return true;
        }
        return false;
    }

    /**
     * Updates the ITACS data based on the provided tasks where clause and data.
     * @param tasks_where_clause - The WHERE clause for filtering tasks.
     * @param data - The data to update the ITACS with.
     */
    async updateItacsData(tasks_where_clause: string, data: any) {
        let selectJson: any = {};
        selectJson['fields'] = [`codigo_de_geolocalizacion`];
        selectJson['distincts'] = [`codigo_de_geolocalizacion`];
        const jsonString = JSON.stringify(selectJson);
        const query_result_codes = await this._mySqlService.getTasksFieldValues(
            jsonString,
            tasks_where_clause
        );
        const itacCodes = query_result_codes.map((json) => json.codigo_de_geolocalizacion!);
        await this.updateItacsDataWithCodes(itacCodes, data);
    }

    /**
     * Updates the data of ITACs (Individual Task Assignment Codes) with the specified codes.
     * 
     * @param itacCodes - An array of ITAC codes.
     * @param data - The data to update the ITACs with.
     * @returns Promise<void>
     */
    async updateItacsDataWithCodes(itacCodes: string[], data: any) {
        if (itacCodes && itacCodes.length > 0) {
            this.loadingText = `Buscando Itacs para asignar equipo`;
            const where_clause_itacs = this._utilsService.getWhereClauseFromFilter(
                this._utilsService.processFilter(
                    {},
                    itacCodes,
                    'codigo_itac',
                    getItacFieldType('codigo_itac'),
                    this._mySqlService.itacsTableName,
                    false
                )
            );
            let selectJson: any = {};
            selectJson['fields'] = [`id`];
            selectJson['distincts'] = [`id`];
            const jsonString = JSON.stringify(selectJson);
            const query_result_ids = await this._mySqlService.getItacsFieldValues(
                jsonString,
                where_clause_itacs
            );
            const itacIds = query_result_ids.map((json) => json.id!);
            if (itacIds && itacIds.length > 0) {
                const res = await this._apiService.updateItacs(itacIds, data, false);
                if (res) this._utilsService.openSnackBar('Equipo asignado a itacs de tareas');
                else this._utilsService.openSnackBar('Error asignando equipo a itacs de tareas');
            }
        }
    }

    /**
     * Deletes the selected tasks.
     * 
     * @remarks
     * This method prompts the user for confirmation before deleting the tasks. If the user confirms,
     * it removes the selected tasks from the data source and sends delete requests to the API for each task.
     * If any errors occur during the deletion process, it displays an error message.
     * 
     * @returns A Promise that resolves when the tasks are successfully deleted.
     */
    async deleteTasks() {
        if (this.nothingSelectedWarning()) return;
        let errorIds = [];
        const text = '¿Desea eliminar las tareas seleccionadas?';
        const result = await this._utilsService.openQuestionDialog('Confirmación', text);
        if (result) {
            this.showLoading(true, true);
            this.loadingText = `Eliminando tareas ...`;
            let rowIndexes = new Set<number>();
            const oldDataSource = this.waterTasks;
            this.clickedRows.forEach(async (row) => {
                const index = this.waterTasks.indexOf(row, 0);
                if (index > -1) rowIndexes.add(index);
            });
            let tasks = [];
            let deleteCount = 0;
            for (let i = 0; i < oldDataSource.length; i++) {
                if (!rowIndexes.has(i)) tasks.push(oldDataSource![i]);
                else {
                    this.loadingText = `Eliminando tareas ${++deleteCount} de ${rowIndexes.size}`;
                    if (!(await this._apiService.deleteTask(oldDataSource[i].id!))) {
                        errorIds.push(oldDataSource[i].id);
                    }
                }
            }
            if (errorIds.length > 0) this._utilsService.openSnackBar(`Hubo errores eliminando tareas`, 'error');
            else this._utilsService.openSnackBar(`Tareas eliminadas correctamente`);

            this.length -= rowIndexes.size;
            this.setTasksInTable(tasks);
            this.allSelected = false;
            this.showLoading(false, true);
        }
    }

    // SELECT Numero_de_ABONADO, COUNT(Numero_de_ABONADO) FROM `waterTask` GROUP BY Numero_de_ABONADO HAVING COUNT(Numero_de_ABONADO) > 1;

    //SELECT NUMIN, COUNT(NUMIN) FROM `water_tasks` GROUP BY NUMIN HAVING COUNT(NUMIN) > 1 //get repeated NUMINs
    async uploadMissing(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const QUERY_URL = 'https://mi-ruta-backend.web.app/api/v1/tareas/custom_query';
            const postParams = {
                empresa: 'GECONTA',
                where: "(GESTOR  LIKE  'CABB')",
                // empresa: 'mr_aaguas_s_l_',
                // where: "(GESTOR  LIKE  'AQUALIA')",
                // limit: '5',
                order: 'id',
                select: 'NUMIN',
            };

            const headers = {
                'x-token':
                    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7Il9pZCI6MSwiY29kaWdvX2FkbWluaXN0cmFkb3IiOiIwMDEiLCJhZG1pbmlzdHJhZG9yIjoiTWljaGVsIn0sImlhdCI6MTYzNTcyMzM2OCwiZXhwIjoxNjM4MzE1MzY4fQ.QZ2f4JCw2EXOjGy8dlp2iQSWvD96sMWdojp4_yxGHjE',
            };
            const requestOptions = {
                headers: new HttpHeaders(headers),
            };
            this.http.post(QUERY_URL, postParams, requestOptions).subscribe(async (data: any) => {
                const numinsOldDatabase = data['data'].map((e: any) => e['NUMIN']);

                const query_result = await this._mySqlService.getTasksFieldValues(
                    ` DISTINCT NUMIN `
                );
                const numins = query_result.map((data: any) => data['NUMIN']);

                let missingTasksOldServer: string[] = [];
                let missingTasks: string[] = [];
                for (const numin of numinsOldDatabase) {
                    if (!numins.includes(numin) && !missingTasks.includes(numin)) {
                        missingTasks.push(numin);
                    }
                }
                for (const numin of numins) {
                    if (
                        !numinsOldDatabase.includes(numin) &&
                        !missingTasksOldServer.includes(numin)
                    ) {
                        missingTasksOldServer.push(numin);
                    }
                }
                let notFoundInFirebase = [];
                let c = 0;
                for (const missingTask of missingTasks) {
                    this.loadingText = `Searching ${++c} of ${missingTasks.length}`;
                    const tasks = await this._apiService.getTasks(
                        [['NUMIN', '==', missingTask]],
                        1
                    );
                    if (tasks.length > 0) {
                        for (let task of tasks) {
                            let obj: any = {};
                            const keys = Object.keys(task);
                            for (const key of keys) {
                                let value = task[key as keyof WaterTask];
                                obj[key] = value ? value : null;
                            }
                            await this._apiService.updateTask(task.id!, obj);
                        }
                    } else {
                        notFoundInFirebase.push(missingTask);
                    }
                }
                resolve();
            });
        });
    }
    async updateTasks() {
        this.loadingText = `Cargando ...`;
        this.showLoading(true, true);
        await this.uploadMissing();
        this.showLoading(false, true);
    }

    async uploadTasks(tasks: WaterTask[], already_upload: number, total: number) {
        let count = 0;
        for (let task of tasks) {
            const existance = await this._mySqlService.getTasksCount(
                `Numero_de_ABONADO LIKE '${task.Numero_de_ABONADO}'`
            );
            if (existance === 0) {
                let obj: any = {};
                const keys = Object.keys(task);
                for (const key of keys) {
                    let value = task[key as keyof WaterTask];
                    obj[key] = value ? value : null;
                }
                await this._apiService.updateTask(task.id!, obj);
            } else if (existance > 0) {
                if (existance >= 2) {
                    this.repeated.push(task.Numero_de_ABONADO!);
                }
            }
            this.loadingText = `Actualizando tareas ${++count + already_upload} de ${total} ...`;
        }
    }

    /**
     * Searches for a value asynchronously.
     * @param term - The search term to be used.
     * @returns A Promise that resolves when the search is complete.
     */
    async searchValue(term: string): Promise<void> {
        clearTimeout(this.timerInputChanged);
        this.timerInputChanged = setTimeout(async () => {
            await this.searchTerm(term);
        }, 1000);
    }

    /**
     * Searches for tasks based on the provided term.
     * @param term - The search term to filter tasks.
     * @returns A Promise that resolves when the tasks are filtered and set in the table.
     */
    async searchTerm(term: string): Promise<void> {
        this._utilsService.deleteFieldFromFilter(this._utilsService.filterTasks!, 'term');
        if(term) {
            this._utilsService.processFilter(
                this._utilsService.filterTasks!, term, 'term', 'string',
                this._mySqlService.tasksTableName, true, false, false, null, true
            );
        }
        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        this.lastPageIndex = 0;
        localStorage.setItem('lastPageIndex', '');
        this.showLoading(true);
        this.setTasksInTable(await this.filterTask(where_clause));
    }

    /**
     * Sets the loading state and shows or hides the spinner accordingly.
     * @param state - A boolean value indicating whether to show or hide the loading spinner.
     */
    showLoading(state: boolean, withText?: boolean): void {
        this.loading = state;
        if (state) {
            if(withText) this._spinner.show();
            else {
                this._spinner.show('innerSpinner', {
                    type: this._utilsService.getRandomNgxSpinnerType(),
                });
            }
        } else {
            if(withText) this._spinner.hide();
            else this._spinner.hide('innerSpinner');
        }
    }

    /**
     * Handles the event when a tab is changed.
     * 
     * @param index - The index of the selected tab.
     * @param initIndex - Optional parameter to indicate if the index should be initialized.
     * @returns A Promise that resolves with the tasks in the table.
     */
    async changedTab(index: number, initIndex: boolean = true) {
        if (this.selected.value != index) this.selected.setValue(index);

        if (initIndex) {
            this.lastPageIndex = 0;
            localStorage.setItem('lastPageIndex', '');
        }
        this.scrollOffset = 50;
        if (this.isOpenedFromDoubleClick()) {
            const setted = await this.searchAndSetLastTaskLoaded();
            if (setted) return;
        }
        const tasks = await this.selectTasks(this.tabs[this.selected.value]);
        this.setTasksInTable(tasks);
    }

    /**
     * Handles the event when a tab is clicked.
     *
     * @param index - The index of the tab that was clicked.
     * @param initIndex - A boolean indicating whether to initialize the index. Defaults to `true`.
     * @returns A promise that resolves when the tab change operation is complete.
     */
    async onTabClicked(index: number, initIndex: boolean = true) {
        this.clearLastTaskClicked();
        await this.changedTab(index, initIndex);
    }

    async searchAndSetLastTaskLoaded(): Promise<boolean> {
        sessionStorage.removeItem('openedFromDoubleClick');
        const tasksString = localStorage.getItem('lastTasksLoaded');
        if (tasksString) {
            await this.searchLastTaskLoaded(tasksString);
            return true;
        }
        return false;
    }

    /**
     * Searches for the last task loaded from the session storage and updates it with the latest data from the server.
     * 
     * @param tasksString - A JSON string representing an array of tasks.
     * @returns A promise that resolves when the task search and update is complete.
     * 
     * This method performs the following steps:
     * 1. Parses the tasksString into an array of tasks.
     * 2. Retrieves the last task ID clicked from the session storage.
     * 3. If a task ID is found, it fetches the latest task data from the server using the task ID.
     * 4. Finds the index of the task in the tasks array and updates it with the latest data from the server.
     * 5. Calls the setLastTaskLoaded method to update the tasks.
     */
    async searchLastTaskLoaded(tasksString: string): Promise<void> {
        const tasks = JSON.parse(tasksString);
        const idString = sessionStorage.getItem('lastTaskIdClicked');
        let taskIndex: number = -1;
        if (idString) {
            const serverTask = await this._apiService.getTaskSync(parseInt(idString));
            taskIndex = tasks.findIndex((t: WaterTask) => t.id === parseInt(idString));

            if (serverTask && taskIndex !== -1) tasks[taskIndex] = serverTask;
        }
        this.setLastTaskLoaded(tasks);
        if (taskIndex !== -1) this.clickedRows.add(tasks[taskIndex]);
    }

    /**
     * Checks if the current session was opened from a double-click action.
     *
     * This method retrieves the value of 'openedFromDoubleClick' from the session storage
     * and returns `true` if it is set to 'true', otherwise returns `false`.
     *
     * @returns {boolean} `true` if the session was opened from a double-click, otherwise `false`.
     */
    isOpenedFromDoubleClick(): boolean {
        const openedWithDoubleClick = sessionStorage.getItem('openedFromDoubleClick') === 'true';
        return openedWithDoubleClick;
    }

    /**
     * Sets the last loaded task information from local storage and updates the task table.
     * 
     * @param tasks - An array of `WaterTask` objects to be set in the table.
     * 
     * This method retrieves the following information from local storage:
     * - `currentStatus`: The current status of the task.
     * - `additionalStatus`: Any additional status information.
     * - `lastIndex`: The index of the last page.
     * - `lastLength`: The length of the last task list.
     * 
     * The retrieved values are parsed as integers and assigned to the corresponding properties.
     * Finally, the tasks are set in the table using `setTasksInTable`.
     */
    setLastTaskLoaded(tasks: WaterTask[]) {
        const currentStatus = localStorage.getItem('currentStatus');
        const additionalStatus = localStorage.getItem('additionalStatus');
        const lastPageIndex = localStorage.getItem('lastIndex');
        const length = localStorage.getItem('lastLength');

        if (currentStatus) this.currentStatus = parseInt(currentStatus);
        if (additionalStatus) this.additionalStatus = parseInt(additionalStatus);
        if (lastPageIndex) this.lastPageIndex = parseInt(lastPageIndex);
        if (length) this.length = parseInt(length);
        this.setTasksInTable(tasks);
    }

    /**
     * Returns the string representation of the current status.
     * @returns {string} The string representation of the current status.
     */
    getStringStatusFromCurrent(): string {
        let status = 'Todas';

        if (this.currentStatus == task_status.IDLE && this.additionalStatus == 1) status = 'Ausentes'; 
        else if (this.currentStatus == task_status.IDLE && this.additionalStatus == 2) status = 'Citas';
        else if (this.currentStatus == task_status.IDLE) status = 'Abiertas';
        else if (this.currentStatus == task_status.DONE) status = 'Ejecutadas';
        else if (this.currentStatus == task_status.CLOSED) status = 'Cerradas';
        else if (this.currentStatus == task_status.INFORMED) status = 'Informadas';
        else if (this.currentStatus == task_status.INCIDENCE) status = 'Incidencias';
        else if (this.currentStatus == task_status.REQUIRED) status = 'Requeridas';
        else if (this.currentStatus == task_status.TRASHED) status = 'Trastero';

        return status;
    }

    /**
     * Converts a string status to its corresponding integer value.
     * @param status - The string status to convert.
     * @returns The corresponding integer value of the status.
     */
    getIntStatusFromString(status?: string) {
        let value = -1;
        if (status == 'Todas') {
            value = -1;
        } else if (status == 'Abiertas') {
            value = task_status.IDLE;
        } else if (status == 'Ausentes') {
            value = task_status.IDLE;
        } else if (status == 'Citas') {
            value = task_status.IDLE;
        } else if (status == 'Ejecutadas') {
            value = task_status.DONE;
        } else if (status == 'Cerradas') {
            value = task_status.CLOSED;
        } else if (status == 'Informadas') {
            value = task_status.INFORMED;
        } else if (status == 'Incidencias') {
            value = task_status.INCIDENCE;
        } else if (status == 'Requeridas') {
            value = task_status.REQUIRED;
        } else if (status == 'Trastero') {
            value = task_status.TRASHED;
        }
        return value;
    }

    /**
     * Retrieves tasks based on the specified status.
     * 
     * @param statusString - Optional. The status string to filter tasks. If not provided, all tasks will be retrieved.
     * @returns A Promise that resolves to an array of tasks.
     */
    async selectTasks(statusString?: string): Promise<WaterTask[]> {
        this.showLoading(true);

        this.setLocalItems(statusString);

        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);

        const order = this._utilsService.orderTasks;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);

        const { waterTasks, count } = await this._mySqlService.getTasks(
            undefined,
            where_clause,
            order_clause,
            undefined,
            this.getOffset(),
            this.pageSize.toString()
        );
        this.length = count;
        localStorage.setItem('lastIndex', this.lastPageIndex.toString());
        localStorage.setItem('lastLength', this.length.toString());
        return waterTasks;
    }

    /**
     * Sets local storage items based on the provided status string.
     * 
     * This method retrieves the status and additional status from the utility service,
     * updates the component's status properties, and stores them in local storage.
     * It also handles the restart filters flag and updates other relevant local storage items.
     * 
     * @param {string} [statusString] - An optional status string to determine the current status.
     * 
     * @returns {void}
     */
    setLocalItems(statusString?: string): void {
        const { status, additionalStatus } = this._utilsService.getStatus(statusString);

        this.additionalStatus = additionalStatus;
        this.currentStatus = status;

        const restartFilters = localStorage.getItem('restartFilters');
        if (restartFilters == 'true') this._utilsService.clearFiltersAndOrders();

        localStorage.setItem('currentStatus', this.currentStatus.toString());
        localStorage.setItem('additionalStatus', this.additionalStatus.toString());

        localStorage.setItem('last_status', statusString || '');
        localStorage.setItem('last_selected_index', this.selected.value.toString());
    }

    /**
     * Retrieves the offset value based on the last page index stored in local storage.
     * If a last page index is found, it resets the stored value and calculates the offset
     * based on the page size. If no last page index is found, it returns '0'.
     *
     * @returns {string} The calculated offset as a string.
     */
    getOffset(): string {
        let offset = '0';
        const lastPageIndex = localStorage.getItem('lastPageIndex');
        if (lastPageIndex) {
            localStorage.setItem('lastPageIndex', '');
            this.lastPageIndex = parseInt(lastPageIndex);
            offset = (this.lastPageIndex * this.pageSize).toString();
        }
        return offset;
    }

    /**
     * Applies a filter to the tasks based on the provided values and column.
     * 
     * @param values - The filter values.
     * @param column - The column to filter on.
     * @param not_empty - Optional. Indicates whether to include only non-empty values. Default is false.
     * @param empties_checked - Optional. Indicates whether to include empty values. Default is false.
     * @param custom_join_json - Optional. Custom join JSON for filtering. Default is null.
     */
    async applyFilter(
        values: any,
        column: string,
        not_empty: boolean = false,
        empties_checked: boolean = false,
        custom_join_json: any = null,
    ) {
        this.modifyFilter(values, column, not_empty, empties_checked, custom_join_json);
        this.executeFilter();
    }

    /**
     * Modifies the filter for tasks based on the provided parameters.
     *
     * @param values - The values to filter by.
     * @param column - The column to apply the filter on.
     * @param not_empty - Whether to filter out empty values (default: false).
     * @param empties_checked - Whether empty values are checked (default: false).
     * @param custom_join_json - Custom join JSON for advanced filtering (default: null).
     */
    modifyFilter(
        values: any,
        column: string,
        not_empty: boolean = false,
        empties_checked: boolean = false,
        custom_join_json: any = null,
    ): void {
        this._utilsService.filterTasks = this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            values,
            column,
            getFieldType(column),
            this._mySqlService.tasksTableName,
            true,
            not_empty,
            empties_checked,
            custom_join_json,
        );
    }

    /**
     * Executes the filter operation for tasks.
     * 
     * This method constructs a where clause for filtering tasks using utility services,
     * resets the last page index, shows a loading indicator, and sets the filtered tasks
     * in the table.
     * 
     * @returns {Promise<void>} A promise that resolves when the filter operation is complete.
     */
    async executeFilter(): Promise<void> {
        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        this.lastPageIndex = 0;
        localStorage.setItem('lastPageIndex', '');
        this.showLoading(true);
        this.setTasksInTable(await this.filterTask(where_clause));
    }

    /**
     * Sets the tasks in the table.
     * 
     * @param tasks - An array of WaterTask objects representing the tasks to be set in the table.
     */
    setTasksInTable(tasks: WaterTask[]) {
        this.clickedRows.clear();
        this.waterTasks = [...tasks];
        for (let [index, task] of this.waterTasks.entries()) task.ID = index + 1;
        
        this.dataSource.data = [...this.waterTasks.slice(0, this.scrollOffset)];

        const saveView = localStorage.getItem('saveView');
        if (saveView == 'false') localStorage.setItem('saveView', '');
        else this.saveCurrentView();

        localStorage.setItem('lastTasksLoaded', JSON.stringify(tasks));
        this.showLoading(false);
    }

    /**
     * Orders the tasks based on the specified column and order type.
     * 
     * @param event - The event object containing the column and order type.
     */
    async orderBy(event: any) {
        const column = event.column;
        const orderType = event.orderType;

        const orderedColumn = getField(column);
        const order_clause = this._utilsService.getOrderClauseFromOrder(
            this._utilsService.processOrder(
                this._mySqlService.tasksTableName,
                this._utilsService.orderTasks,
                orderedColumn,
                orderType
            )
        );

        let where_clause = this._utilsService.getTasksFilterWhereString();

        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        this.showLoading(true);
        const { waterTasks } = await this._mySqlService.getTasks(
            undefined,
            where_clause,
            order_clause,
            undefined,
            '0',
            this.pageSize.toString()
        );
        this.lastPageIndex = 0;
        localStorage.setItem('lastPageIndex', '');
        this.setTasksInTable(waterTasks);
    }

    /**
     * Shows user activity.
     * Retrieves a list of users with the role 'operario' from the API service,
     * displays a selector dialog to choose an operator, and shows their task updates for a selected day.
     */
    async showUserActivity(type: string): Promise<void> {
        if (type == 'chart') await this._utilsService.openUserActivityChartDialog();
        else if(type == 'list') await this.showUserActivityList();
        else if(type == 'actions') await this.showUserActions();
        else if(type == 'validations') await this.showUserValidations();
    }

    /**
     * Asynchronously shows user validations by performing the following steps:
     * 1. Displays a snackbar notification indicating the start of the process.
     * 2. Fetches a list of users with the role 'operario' from the API service.
     * 3. Maps the user IDs to their respective names using a utility service.
     * 4. Opens a selector dialog for the user to choose an 'operario' from the list.
     * 5. Finds the selected user from the list of fetched users.
     * 6. If a user is selected, opens a date selector dialog for the user to choose a day.
     * 7. Shows a loading indicator while fetching user validations for the selected user and day.
     * 8. Hides the loading indicator once the validations are fetched.
     * 9. Displays the fetched validations using a utility function.
     * 
     * @returns {Promise<void>} A promise that resolves when the process is complete.
     * @throws Will catch and handle any errors that occur during the process.
     */
    async showUserValidations() {
        this._utilsService.openSnackBar('showUserValidations');
        try {
            const users = await this._apiService.getUsers(['operario']);
            const userNames = users.map((user) => this._utilsService.userPipe(user.id));
            const selected = await this._utilsService.openSelectorDialog('Seleccione operario', userNames, true);
            const user = users.find((u) => this._utilsService.userPipe(u.id) == selected);
            if (user) {
                const day = await this._utilsService.openDateSelectorDialog('Seleccione día');
                this.showLoading(true);
                const validations: UserValidation[] = await this._apiService.getUserValidations(user.id, day, 1000);
                this.showLoading(false);
                this.showValidations(validations, selected, day);
            }
        } catch (err) {}
    }

    /**
     * Displays validation information in a dialog.
     *
     * @param {UserValidation[]} validations - Array of user validation objects.
     * @param {string} selected - The selected validation category.
     * @param {Date} day - The date for which validations are being shown.
     * @returns {void}
     */
    showValidations(validations: UserValidation[], selected: string, day: Date): void {
        if(validations && validations.length) {
            const rights = validations.filter(validation => validation.validation_status === 'RIGHT');
            const wrong = validations.filter(validation => validation.validation_status === 'WRONG');
            const skipped = validations.filter(validation => validation.validation_status === 'SKIPPED');
            let info: string[] = [];
            info.unshift('✅ Validaciones correctas   - ' + rights.length);
            info.unshift('⛔ Validaciones saltadas    - ' + skipped.length);
            info.unshift('❌ Validaciones incorrectas - ' + wrong.length);
            this._utilsService.openInformationDialog(`Validaciones de ${selected} - ${moment(day).format('DD/MM/YYYY')}`, info);
        }
        else {
            const text = [`${selected} no tiene validaciones el día ${moment(day).format('DD/MM/YYYY')}`];
            this._utilsService.openInformationDialog('Sin validaciones', text);
        }
    }

    /**
     * Shows user actions.
     * Retrieves a list of users with the role 'operario' from the API service,
     * displays a selector dialog to choose an operator, and navigates to the user's location actions page.
     * If the selected user is not found, displays a warning message.
     */
    async showUserActions() {
        try {
            const users = await this._apiService.getUsers(['operario']);
            const userNames = users.map((user) => this._utilsService.userPipe(user.id));
            const selected = await this._utilsService.openSelectorDialog('Seleccione operario', userNames, true);
            const user = users.find((u) => this._utilsService.userPipe(u.id) == selected);
            if (user) {
                localStorage.removeItem('user_actions_map_data');
                this.router.navigate(['/user-location', user.id, 'actions']);
            }
            else this._utilsService.openSnackBar('Usuario no valido', 'warning');
        } catch (err) {}
    }

    /**
     * Shows the user activity list.
     * Retrieves a list of users with the role 'operario' from the API service,
     * displays a selector dialog to choose an operator, and then retrieves the
     * task updates for the selected operator on a specific day. Finally, it
     * displays the task updates in an information dialog.
     */
    async showUserActivityList() {
        try {
            const users = await this._apiService.getUsers(['operario']);
            const userNames = users.map((user) => this._utilsService.userPipe(user.id));
            const selected = await this._utilsService.openSelectorDialog('Seleccione operario', userNames, true);
            const user = users.find((u) => this._utilsService.userPipe(u.id) == selected);
            if (user) {
                const day = await this._utilsService.openDateSelectorDialog('Seleccione día');
                this.showLoading(true);
                const updates: WaterTaskUpdate[] = await this._apiService.getTaskUpdatesUser(user.username, day, 1000);
                this.showLoading(false);
                this.showActivityList(updates, selected, day);
            }
        } catch (err) {}
    }

    /**
     * Displays a list of activities based on the provided updates, selected string, and date.
     *
     * @param {WaterTaskUpdate[]} updates - An array of WaterTaskUpdate objects containing the updates.
     * @param {string} selected - A string representing the selected item.
     * @param {Date} day - The date for which the activities are being displayed.
     * @returns {void}
     */
    showActivityList(updates: WaterTaskUpdate[], selected: string, day: Date): void {
        if(updates && updates.length) {
            let info = updates.filter((u)=>this._utilsService.isFieldValid(u.Numero_de_ABONADO))
            .map((u)=> this.getUpdateLineActivity(u));
            info.unshift('📅');
            info.unshift('Hora - Abonado - Dirección');
            this._utilsService.openInformationDialog(`${selected} - ${moment(day).format('DD/MM/YYYY')}`, info);
        }
        else {
            const text = [`${selected} no tiene actividad el día ${moment(day).format('DD/MM/YYYY')}`];
            this._utilsService.openInformationDialog('Sin actividad', text);
        }
    }

    /**
     * Returns a formatted string representing the update line activity.
     * @param u - The WaterTaskUpdate object.
     * @returns A string in the format "{time} - {abonado} - {taskDir}".
     */
    getUpdateLineActivity(u: WaterTaskUpdate): string {
        const date = moment(u.update_date).format('HH:mm');
        return `⏱️ ${date} - ${u.Numero_de_ABONADO}  -  🏠 ${this._utilsService.getDirOfTask(u)}`;
    }


    /**
     * Shows the calendar based on the specified type.
     * @param type - The type of calendar to show.
     * @returns A promise that resolves when the calendar is shown.
     */
    async showCalendar(type: string): Promise<void> {
        try {
            if(type == 'user_week') {
                const user = await this._apiService.selectUserForWeekCalendar();
                if(user) this.router.navigate(['/calendar']);
            }
            if(type == 'calendar_dates') this.router.navigate(['/calendar-table']);
        } catch (err) {}
    }

    /**
     * Selects all the tasks in the waterTasks array and updates the clickedRows set.
     */
    async selectAll(): Promise<void> {
        this.clickedRows.clear();
        const tasks = this.waterTasks;
        for (const task of tasks) {
            if (!this.clickedRows.has(task)) {
                this.clickedRows.add(task);
            }
        }
        this.allSelected = true;
        this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
    }

    /**
     * Filters the team based on user selection.
     * Retrieves the list of teams from the API service and opens a selector dialog for the user to choose a team.
     * Applies the selected team as a filter to the component.
     */
    async filterTeam(): Promise<void> {
        const teams = await this._apiService.getTeams();
        const teamSelected = await this._utilsService.openSelectorDialog(
            'Seleccione equipo',
            teams.map((team) => team.equipo_operario)
        );
        const teamSelectedId = teams.find((team) => team.equipo_operario == teamSelected)?.id;
        if (teamSelectedId) {
            this.applyFilter([teamSelectedId], 'equipo');
        }
    }

    /**
     * Filters the operator based on user selection and applies the filter to the tasks.
     * @returns {Promise<void>} A promise that resolves when the filter is applied.
     */
    async filterOperator(): Promise<void> {
        const users = await this._apiService.getUsers(['operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione operario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) {
            this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, 'OPERARIO');
            this.applyFilter([userSelectedId], 'OPERARIO');
        }
    }

    /**
     * Filters the sector based on user selection.
     * Retrieves the list of sectors from the API service,
     * opens a selector dialog for the user to choose a sector,
     * and applies the selected sector as a filter.
     */
    async filterSector(): Promise<void> {
        const sectors = await this._apiService.getSectors();
        const sectorSelected = await this._utilsService.openSelectorDialog(
            'Seleccione perímetro',
            sectors.map((sector) => sector.name)
        );
        const sectorSelectedId = sectors.find((sector) => sector.name == sectorSelected)?.id;
        if (sectorSelectedId) {
            this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, 'sectors');
            this.applyFilter([sectorSelectedId], 'sectors');
        }
    }

    /**
     * Filters the tasks by removing the 'telefonos_cliente' filter field and applying a new filter.
     * The new filter is applied to the 'telefonos_cliente' field and matches the pattern '%Nº correcto%'.
     */
    async filterCorrectPhone(): Promise<void> {
        this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, 'telefonos_cliente');
        this.applyFilter(['%Nº correcto%'], 'telefonos_cliente');
    }

    /**
     * Filters the planning detail extras by removing the existing filter field,
     * fetching the planning detail extras from the API, and then opening a selector
     * dialog for the user to choose a detail. The selected detail is then applied
     * as a filter.
     *
     * @returns {Promise<void>} A promise that resolves when the filtering is complete.
     */
    async filterPlanningDetailExtra(): Promise<void> {
        this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, 'planning_detail_extras');
        const details = (await this._apiService.getPlanningDetailExtras()).map(detail => detail.detail);
        const detail = await this._utilsService.openSelectorDialog('Seleccione detalle extra', details, true);
        this.applyFilter([detail], 'planning_detail_extras');
    }

    /**
     * Filters the last operator modifier based on user selection.
     * Retrieves a list of users with the role 'operario' and opens a selector dialog to choose an operator.
     * Applies a filter based on the selected operator's ID.
     */
    async filterLastOperatorModifier(): Promise<void> {
        const users = await this._apiService.getUsers(['operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione operario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) {
            this.applyFilter([userSelectedId.toString()], 'last_modification_operator_uid');
        }
    }

    /**
     * Filters the data based on the selected user's modification.
     * Retrieves a list of users and prompts the user to select one.
     * Applies the selected user's ID as a filter for the 'ultima_modificacion' field.
     */
    async filterModifier(): Promise<void> {
        const users = await this._apiService.getUsers(['administrador', 'operario']);
        const userSelected = await this._utilsService.openSelectorDialog(
            'Seleccione usuario',
            users.map((user) => this._utilsService.userPipe(user.id))
        );
        const userSelectedId = users.find(
            (user) => this._utilsService.userPipe(user.id) == userSelected
        )?.id;
        if (userSelectedId) this.applyFilter([userSelectedId.toString()], 'ultima_modificacion');
    }

    /**
     * Filters the service based on user selection.
     * @returns {Promise<void>} A promise that resolves when the filtering is complete.
     */
    async filterService(): Promise<void> {
        try {
            const services = this._utilsService._services;
            const serviceSelected = await this._utilsService.openSelectorDialog(
                'Seleccione servicio',
                services
            );
            if (serviceSelected) {
                this.applyFilter([serviceSelected], 'last_service');
            }
        } catch (err) {}
    }

    /**
     * Filters the supplier based on user selection.
     * @returns {Promise<void>} A promise that resolves when the filtering is complete.
     */
    async filterSupplier(): Promise<void> {
        try {
            const suplies = this._utilsService._suplies;
            const supplySelected = await this._utilsService.openSelectorDialog(
                'Seleccione suministro',
                suplies
            );
            if (supplySelected) {
                this._utilsService.filterTasks = deleteFilterField(this._utilsService.filterTasks!, 'suministros');
                this.applyFilter([supplySelected], 'suministros');
            }
        } catch (err) {}
    }

    /**
     * Filters the data by date type.
     * @returns A Promise that resolves when the filtering is complete.
     */
    async filterDateType(): Promise<void> {
        const dateTypes: any = getFilterDateTypes();
        const keys = Object.keys(dateTypes);
        try {
            const option = await this._utilsService.openSelectorDialog('Seleccione tipo de fecha', keys);
            if (option) await this.filterBy(getFieldName(dateTypes[option]));
        } catch (err) {}
    }

    /**
     * Filters the cause based on user input.
     * Opens a filter dialog to get the filter criteria for 'ANOMALIA' and 'CALIBRE'.
     * Processes the filter criteria and applies the filter.
     * Orders the results by 'CALIBRE' and 'ANOMALIA' in ascending order.
     */
    async filterCause(): Promise<void> {
        let result = await this.openFilterDialog('ANOMALIA', 'ANOMALIA');
        if (result && result.data) {
            this.processFilter(result.data, result.column);
            result = await this.openFilterDialog('CALIBRE', 'CALIBRE');
            if (result && result.data) {
                this.processOrder('CALIBRE', 'ASC');
                this.processOrder('ANOMALIA', 'ASC');
                this.applyFilter(
                    result.data,
                    result.column,
                    result.not_empty,
                    result.empties_checked
                );
            }
        }
    }

    /**
     * Filters the address based on user input.
     * Opens filter dialogs for 'MUNICIPIO', 'CALLE', and 'NUME' columns.
     * Processes the filter data and applies the filter to the address.
     * Orders the address by 'MUNICIPIO', 'CALLE', 'NUME', 'BIS', 'PISO', 'MANO'.
     */
    async filterAddress(): Promise<void> {
        let res = await this.openFilterDialog('MUNICIPIO', 'MUNICIPIO');
        if (res && res.data) {
            this.processFilter(res.data, res.column);
            res = await this.openFilterDialog('CALLE', 'CALLE');
            if (res && res.data) {
                this.processFilter(res.data, res.column);
                res = await this.openFilterDialog('NUME', 'NUME');
                if (res && res.data) {
                    // Order by MUNICIPIO, CALLE, NUME, BIS, PISO, MANO
                    this.processOrder('MANO','ASC');
                    this.processOrder('PISO','ASC');
                    this.processOrder('BIS','ASC');
                    this.processOrder('NUME','ASC');
                    this.processOrder('CALLE','ASC');
                    this.processOrder('MUNICIPIO','ASC');
                    this.applyFilter(res.data, res.column, res.not_empty, res.empties_checked);
                }
            }
        }
    }

    /**
     * Applies a custom filter to the specified column.
     * @param column - The name of the column to apply the filter to.
     */
    async applyCustomFilter(column: string): Promise<void> {
        let result = await this.openFilterDialog(getFieldName(column), column);
        if (result && result.data) {
            this.processOrder(column, 'ASC');
            this.applyFilter(
                result.data,
                result.column,
                result.not_empty,
                result.empties_checked
            );
        }
    }

    /**
     * Applies a custom filter based on the given filter type.
     * @param filterType - The type of filter to apply.
     */
    async customFilterBy(filterType: string): Promise<void> {
        try {
            if (filterType === 'Dirección') await this.filterAddress();
            else if (filterType === 'Teléfono correcto') await this.filterCorrectPhone();
            else if (filterType === 'Detalle extra planificación') await this.filterPlanningDetailExtra();
            else if (filterType === 'Abonado') await this.applyCustomFilter('Numero_de_ABONADO');
            else if (filterType === 'Sin revisar') await this.applyFilter([1], 'last_modification_android');
            else if (filterType === 'Mal localizadas') await this.applyFilter([1], 'pendent_location');
            else if (filterType === 'Incidencias') await this.applyFilter([1], 'incidence');
            else if (filterType === 'Titular') await this.applyCustomFilter('NOMBRE_ABONADO');
            else if (filterType === 'Serie') await this.applyCustomFilter('SERIE');
            else if (filterType === 'Equipo') await this.filterTeam();
            else if (filterType === 'Operario') await this.filterOperator();
            else if (filterType === 'last_modification_operator_uid') await this.filterLastOperatorModifier();
            else if (filterType === 'ultima_modificacion') await this.filterModifier();
            else if (filterType === 'Servicio') await this.filterService();
            else if (filterType === 'Suministros') await this.filterSupplier();
            else if (filterType === 'Sector P') await this.applyCustomFilter('zona');
            else if (filterType === 'Código de emplazamiento') await this.applyCustomFilter('codigo_de_geolocalizacion');
            else if (filterType === 'Fecha') await this.filterDateType();
            else if (filterType === 'Causa Origen') await this.filterCause();
            else if (filterType === 'Perímetro') await this.filterSector();
            else await this.applyCustomFilter(filterType);
        } catch (err) {}
    }

    /**
     * Opens a filter dialog for the specified column.
     * @param column_name - The name of the column.
     * @param column - The column to filter.
     * @returns A promise that resolves to the result of the filter dialog.
     */
    async openFilterDialog(column_name: string, column: string): Promise<any> {
        return await this._utilsService.openFilterDialog(
            column_name,
            column,
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
    }

    /**
     * Applies a filter to the tasks based on the provided values and column.
     * 
     * @param values - The values to filter the tasks by.
     * @param column - The column to filter the tasks on.
     * @returns void
     */
    processFilter(values: any, column: string): void {
        this._utilsService.filterTasks = this._utilsService.processFilter(
            this._utilsService.filterTasks!,
            values,
            column,
            getFieldType(column),
            this._mySqlService.tasksTableName
        );
    }

    /**
     * Processes the order of tasks based on the specified field and direction.
     * 
     * @param field - The field to order the tasks by.
     * @param dir - The direction of the ordering (ascending or descending).
     */
    processOrder(field: string, dir: string): void {
        this._utilsService.processOrder(
            this._mySqlService.tasksTableName,
            this._utilsService.orderTasks,
            field,
            dir
        );
    }

    /**
     * Filters data by dates and times.
     * Opens a dialog to select a date range and optionally a time range.
     * Calls the `onDateSelected` method with the selected dates and times (if provided).
     */
    async filterByDates(): Promise<void> {
        try {
            const dates = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione rango de fechas'
            );
            try {
                const times = await this._utilsService.openTimeRangeSelectorDialog(
                    'Seleccione rango de horas'
                );
                this.onDateSelected(dates, times);
            } catch (err) {
                this.onDateSelected(dates);
            }
        } catch (err) {}
    }

    /**
     * Filters the data based on the specified column.
     * @param column - The name of the column to filter by.
     */
    async filterBy(column: string): Promise<void> {
        this.filteredColumn = getField(column);
        if (getFieldType(this.filteredColumn) == 'Date') {
            await this.filterByDates();
        } else {
            const res = await this.openFilterDialog(column, this.filteredColumn);
            if (res && res.data){
                this.applyFilter(res.data, res.column, res.not_empty, res.empties_checked);
            }
        }
    }

    /**
     * Handles the event when a date is selected.
     * @param dateRange - An array of selected dates.
     * @param timeRange - An optional array of selected times.
     */
    async onDateSelected(dateRange: Date[], timeRange?: Date[]): Promise<void> {
        if (dateRange) {
            if (dateRange && dateRange.length) this.lastSelectedDate = dateRange[0];
            const values = this._utilsService.getDateRangeString(dateRange, timeRange);
            if(this._utilsService.isFieldJoinNeeded(this.filteredColumn!)) {
                const json = this._utilsService.getFieldJoinJson(this.filteredColumn!);
                const replace = this._utilsService.getReplaceFieldValue(this.filteredColumn!);
                await this.applyFilter(values, replace, false, false, json);
            }
            else await this.applyFilter(values, this.filteredColumn!);
        } else {
            this._utilsService.openSnackBar('Rango fechas inválido', 'error');
        }
    }

    /**
     * Filters tasks based on the provided criteria.
     * 
     * @param where - Optional criteria to filter the tasks.
     * @returns A promise that resolves to an array of filtered tasks.
     */
    async filterTask(where?: string): Promise<WaterTask[]> {
        const order = this._utilsService.orderTasks;
        let order_clause = undefined;
        if (order.length) order_clause = this._utilsService.getOrderClauseFromOrder(order);

        const { waterTasks, count } = await this._mySqlService.getTasks(
            undefined,
            where,
            order_clause,
            undefined,
            '0',
            this.pageSize.toString()
        );
        this.length = count;
        return waterTasks;
    }

    /**
     * Handles the click event on a row.
     * 
     * @param receivedEvent - The event object containing information about the clicked row.
     */
    clickedRow(receivedEvent: any) {
        const row = receivedEvent.row;
        const event = receivedEvent.event;
        const rowIndex = receivedEvent.rowIndex;

        const previousRow = this.lastSelectedRow;
        this.lastSelectedRow = rowIndex;

        if (event.button === 0) {
            if (!event.ctrlKey && !event.shiftKey) {
                this.allSelected = false;
                this.clickedRows.clear();
                this.toggleRow(row);
            } else if (event.ctrlKey) {
                this.toggleRow(row);
            }
            if (event.shiftKey) {
                this.selectRowsBetweenIndexes(previousRow, rowIndex);
            }
        }
        if (this.windowRefService.nativeWindow.getSelection) {
            //remove selection in table with shift
            if (this.windowRefService.nativeWindow.getSelection().empty) {
                // Chrome
                this.windowRefService.nativeWindow.getSelection().empty();
            } else if (this.windowRefService.nativeWindow.getSelection().removeAllRanges) {
                // Firefox
                this.windowRefService.nativeWindow.getSelection().removeAllRanges();
            }
        }
    }

    saveCurrentView() {
        let previousViewData = JSON.parse(localStorage.getItem('previousViewData') || '[]');

        const currentViewData = {
            previous_filter: { ...this._utilsService.filterTasks },
            previous_orderTasks: [...this._utilsService.orderTasks],
            previous_limit: this.rowsLimit,
            previous_lastPageIndex: localStorage.getItem('lastPageIndex') || '0',
            previous_pageSize: this.pageSize,
            previous_currentStatus: this.currentStatus,
            previous_additionalStatus: this.additionalStatus,
            previous_last_selected_index: parseInt(
                localStorage.getItem('last_selected_index') || '0'
            ),
        };
        previousViewData.push(currentViewData);

        localStorage.setItem('previousViewData', JSON.stringify(previousViewData));
    }

    async loadPreviousView(): Promise<boolean> {
        try {
            let previousViewData = JSON.parse(localStorage.getItem('previousViewData') ?? '[]');

            if (previousViewData.length > 1) {
                previousViewData.pop();

                const currentViewData = previousViewData[previousViewData.length - 1];

                if (currentViewData) {
                    this._utilsService.filterTasks = { ...currentViewData.previous_filter };
                    this._utilsService.orderTasks = [...currentViewData.previous_orderTasks];
                    this.rowsLimit = currentViewData.previous_limit;
                    this.pageSize = currentViewData.previous_pageSize;
                    this.currentStatus = currentViewData.previous_currentStatus;
                    this.additionalStatus = currentViewData.previous_additionalStatus;
                    const last_tab_index = currentViewData.previous_last_selected_index;

                    let event: any = {};
                    event.pageIndex = this.lastPageIndex;
                    event.pageSize = this.pageSize;

                    localStorage.setItem(
                        'lastPageIndex',
                        currentViewData.previous_lastPageIndex || '0'
                    );

                    localStorage.setItem('saveView', 'false');
                    if (last_tab_index != this.selected.value)
                        this.selected.setValue(last_tab_index);
                    else
                        this.setTasksInTable(await this.selectTasks(this.tabs[this.selected.value]));
                }
                localStorage.setItem('previousViewData', JSON.stringify(previousViewData));

                return true;
            }
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        return false;
    }

    /**
     * Asynchronously loads the previous view and displays a snackbar message based on the result.
     * 
     * @returns {Promise<void>} A promise that resolves when the operation is complete.
     */
    async previousView(): Promise<void> {
        if (await this.loadPreviousView()) {
            this._utilsService.openSnackBar('Vista anterior cargada correctamente', 'info', 1500);
        }
        else this._utilsService.openSnackBar('No hay vista anterior', 'warning', 2000);
    }

    /**
     * Handles the selection of rows in the component.
     * Clears the previously clicked rows and selects the rows between the given indexes.
     *
     * @param event - An array containing the start and end indexes of the selected rows.
     */
    selectedRows(event: any) {
        this.clickedRows.clear();
        this.selectRowsBetweenIndexes(event[0], event[1], false);
    }

    /**
     * Selects rows between two indexes.
     * 
     * @param lastSelectedRow - The index of the last selected row.
     * @param rowIndex - The index of the current row.
     * @param showSnackBar - Optional parameter to indicate whether to show a snackbar. Default is true.
     */
    selectRowsBetweenIndexes(
        lastSelectedRow: number,
        rowIndex: number,
        showSnackBar: boolean = true
    ) {
        this.allSelected = false;
        let start, end;
        if (rowIndex > lastSelectedRow) {
            start = lastSelectedRow;
            end = rowIndex;
        } else {
            end = lastSelectedRow;
            start = rowIndex;
        }
        for (let i = start; i <= end; i++) {
            this.clickedRows.add(this.waterTasks[i]);
        }
        if (this.clickedRows.size > 1 && showSnackBar) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
        }
    }

    /**
     * Toggles the clicked state of a row and displays a snackbar message if multiple rows are selected.
     * @param row - The row to toggle.
     */
    toggleRow(row: any) {
        if (this.clickedRows.has(row)) this.clickedRows.delete(row);
        else this.clickedRows.add(row);
        
        if (this.clickedRows.size > 1) {
            this._utilsService.openSnackBar(`Seleccionadas ${this.clickedRows.size} tareas`);
        }
    }

    /**
     * Adds a new row and performs the necessary actions based on the environment.
     * @param event - The event object.
     * @returns A Promise that resolves when the new row is added.
     */
    async addNewRow(event: any) {
        localStorage.setItem('lastPageIndex', this.lastPageIndex.toString());
        const sameTab = this._utilsService.getLocalStorageBoolean('openTaskInSameTab');
        if (this._electronService.isElectronApp() || sameTab) {
            this.router.navigate(['/task', 'new']);
        } else {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/task', 'new']));
            window.open(url, '_blank');
        }
    }

    /**
     * Handles the double click event on a row.
     * @param row - The row that was double clicked.
     */
    doubleClickedRow(row: any) {
        this.saveClickedRowData(row);

        const sameTab = this._utilsService.getLocalStorageBoolean('openTaskInSameTab');

        if (this._electronService.isElectronApp() || sameTab) {
            this.router.navigate(['/task', row.id]);
        } else {
            const url = this.router.serializeUrl(this.router.createUrlTree(['/task', row.id]));
            window.open(url, '_blank');
        }
    }

    saveClickedRowData(row: any): void {
        localStorage.setItem('lastPageIndex', this.lastPageIndex.toString());
        sessionStorage.setItem('openedFromDoubleClick', 'true'); 
        sessionStorage.setItem('lastTaskIdClicked', row.id.toString());
    }

    /**
     * Opens the Google Maps page with optional tasks.
     * 
     * @param tasks - Optional set of WaterTask objects.
     */
    openMaps(tasks?: Set<WaterTask>) {
        let ids = [];
        if (tasks) {
            for (let waterTask of tasks) {
                ids.push(waterTask.id);
            }
            const where_clause = this._utilsService.getWhereClauseFromFilter(
                this._utilsService.processFilter(
                    this._utilsService.filterTasks!,
                    ids,
                    'id',
                    getFieldType('id'),
                    this._mySqlService.tasksTableName
                )
            );
            sessionStorage.setItem('currentWhereTasksSelected', where_clause);
        } else {
            sessionStorage.setItem('currentWhereTasksSelected', '{}');
        }
        sessionStorage.setItem('currentTaskStatus', this.currentStatus!.toString());
        sessionStorage.setItem('additionalTaskStatus', this.additionalStatus!.toString());

        this.router.navigate(['/google_maps']);
    }

    /**
     * Activates the counters for Itelazpi.
     * 
     * @returns {Promise<void>} A promise that resolves when the counters are activated.
     */
    async activateCountersItelazpi(): Promise<void> { 
        this.showLoading(true);
        let waterTasks: WaterTask[] = await this.getCurrentTasksOrSelected();
        try {
            let responses: any[] = await this.activateItelazpi(waterTasks);
            console.log('============= responses =============');
            console.log(responses);
            if(responses.length) this._utilsService.openInformationDialog('Error activando radios', responses);
            else this._utilsService.openSnackBar('Radios activadas correctamente');
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        this.showLoading(false);
    }

    /**
     * Activates Itelazpi radios for the given water tasks.
     * @param waterTasks - An array of water tasks.
     * @returns An array of strings representing the RFID tags of devices that encountered errors during activation.
     */
    async activateItelazpi(waterTasks: WaterTask[]): Promise<string[]> {
        let responsesError: string[] = [];
        if (waterTasks.length) {
            const radiusModules = await this.getRadiusModules(waterTasks);
            const devices = this.getDevices(waterTasks, radiusModules);
            console.log('============= devices =============');
            console.log(devices);
            if(devices.length == 0) {
                this._utilsService.openSnackBar('No se encontraron radios de dispositivos a activar', 'warning');
                return [''];
            }
            let count = 0;
            for (let device of devices) {
                this.loadingText = `Activando radios de itelazpi ${++count} de ${devices.length} ...`;
                const success = await this._apiService.activateItelazpiModule(device);
                if(!success){
                    responsesError.push(device.tags.RFID);
                }
            }
        }
        return responsesError;
    }

    /**
     * Retrieves the RadiusModules associated with the given WaterTasks.
     * @param watertasks - An array of WaterTasks.
     * @returns An array of RadiusModules.
     */
    async getRadiusModules(watertasks: WaterTask[]) {
        const radius_module_hrids = watertasks.map((w: WaterTask)=> w.numero_serie_modulo);
        let radius_modules: RadiusModule[] = [];
        for (let i = 0; i < radius_module_hrids.length; i += 500) {
            let whereJson = { field: 'radius_module_hrid', value: radius_module_hrids.slice(i, i + 500), type: 'AND' };
            let whereJsonArray = [];
            whereJsonArray.push(whereJson);
            try {
                const i = await this._mySqlService.getRadiusModules(undefined, JSON.stringify(whereJsonArray))
                radius_modules = radius_modules.concat(i);
            } catch (err) {}
        }   
        return radius_modules;
    }

    /**
     * Retrieves devices based on water tasks and radius modules.
     * @param waterTasks - An array of water tasks.
     * @param radiusModules - An array of radius modules.
     * @returns An array of devices.
     */
    getDevices(waterTasks: WaterTask[], radiusModules: RadiusModule[]) {
        const devices = [];
        for(let w of waterTasks) {
            const radiusModule = radiusModules.find(module => module.radius_module_hrid == w.numero_serie_modulo); 
            if(!radiusModule) continue;
            const device = this.getDeviceFromWaterTask(w, radiusModule);
            devices.push(device);
        }
        return devices;
    }

    /**
     * Retrieves the device information from a water task.
     * @param w - The water task object.
     * @param radiusModule - The radius module object.
     * @returns The device information.
     */
    getDeviceFromWaterTask(w: WaterTask, radiusModule: RadiusModule) {
        const tags: ItelazpiTags = { RFID: w.numero_serie_modulo!, nSerie: w.seriedv!, Cliente: 'CABB' };
        const device: ItelazpiDevice = {
            deveui: radiusModule.deveui!, 
            appeui: radiusModule.appeui!,
            comment: this._utilsService.getDirOfTask(w),
            latitude: w.codigo_de_localizacion!.lat,
            longitude: w.codigo_de_localizacion!.lng,
            groups: `${w.MARCADV}, ${w.MUNICIPIO}`,
            tags: tags,
            device_profile_uuid: this.getDeviceProfileUuid(w),
            service_profile_uuid: 'C5A9599B-3910-446B-8E76-913F165FFA9', //? always the same value
            activated: true,
            appkey: radiusModule.appkey!,
        };
        return device;
    }

    /**
     * Retrieves the device profile UUID based on the given water task.
     * @param w - The water task object.
     * @returns The device profile UUID.
     */
    getDeviceProfileUuid(w: WaterTask) {
        const mark = w.MARCADV!.toUpperCase(); 
        if(mark.includes('DIEHL_V42')) return 'aaa29f35-0e90-4409-9da3-230d3f4dbb0c'
        if(mark.includes('DIEHL')) return '55feba50-c0ef-4c56-b742-0ece6a5659d2'
        if(mark.includes('HONEYWELL') || mark.includes('SAGEMCOM')) return 'b8355af7-1327-41ea-a855-e2b2b271bb81'
        if(mark.includes('ITRON') 
            || mark.includes('CONTHIDRA') 
            || mark.includes('AXIOMA')) {
            return 'c2fe4c3b-97ab-48c9-a7fe-3c693c630ebc'
        }
        return '';
    }

    /**
     * Creates and activates counters based on user-selected agrupation and water tasks.
     * 
     * @returns {Promise<void>} A promise that resolves when the counters are created and activated.
     */
    async createAndActivateCounters(): Promise<void> {
        const agrupationId = await this._apiService.selectAgrupdationId();
        if (agrupationId) {
            this.loadingText = `Obteniendo tareas para activación ...`;
            this.showLoading(true, true);
            let waterTasks: WaterTask[] = await this.getCurrentTasksOrSelected();
            
            let counters: Counter[] = await this.getCountersFromWaterTasks(agrupationId, waterTasks);
            this.loadingText = `Activando contadores ...`;
            await this.activateCounters(counters);
            this.showLoading(false, true);
        }
    }

    /**
     * Retrieves counters from water tasks.
     * 
     * @param agrupationId - The ID of the agrupation.
     * @param waterTasks - An array of water tasks.
     * @returns A promise that resolves to an array of counters.
     */
    async getCountersFromWaterTasks(agrupationId: number, waterTasks: WaterTask[]): Promise<Counter[]>  {
        let counters: Counter[] = [];
        let invalidLoras: LoraResponse[] = [];
        for (const [i, waterTask] of waterTasks.entries()) {
            const lora = this._utilsService.checkIfCanActivateCounterAndCause(waterTask);
            if (lora.status) {
                let counter = this.getCounterFromTask(waterTask, agrupationId);

                this.loadingText = `Creando contadores (${i} de ${waterTasks.length}) ...`;
                const counterId = await this._apiService.addCounter(counter);
                if (counterId) {
                    counter.id = counterId;
                    counters.push(counter);
                }
            }
            else invalidLoras.push(lora);
        }
        if (invalidLoras.length) {
            this._utilsService.openSnackBar('Hay información de contadores inválida, revise fichero', 'error');
            const dateString = moment(new Date()).format('yyyy_MM_DD__HH_mm_ss');
            this._utilsService.exportExcel(invalidLoras, `informacion_de_activación_invalida_${dateString}`);
        }
        return counters;
    }

    /**
     * Activates the counters by making an API call.
     * @param counters - An array of counters to be activated.
     * @returns Promise<void>
     */
    async activateCounters(counters: Counter[]) {
        let errors = false;
        let loraResponses: LoraResponse[] = await this._apiService.activateCounters(counters);
        if (loraResponses && loraResponses.length > 0) {
            for (let loraResponse of loraResponses) if (!loraResponse.status) errors = true;
        }
        else errors = true;
        if (errors) { 
            this._utilsService.openSnackBar('Error activando contadores', 'error');
            const dateString = moment(new Date()).format('yyyy_MM_DD__HH_mm_ss');
            this._utilsService.exportExcel(loraResponses, `errores_de_activacion_${dateString}`);
        }
        else this._utilsService.openSnackBar('Contadores activados');
    }

    /**
     * Retrieves a Counter object from a WaterTask object.
     * @param waterTask - The WaterTask object to extract the Counter from.
     * @param agrupationId - The ID of the agrupation.
     * @returns The Counter object extracted from the WaterTask.
     */
    getCounterFromTask(waterTask: WaterTask, agrupationId: number) {
        let counter: Counter = {};
        counter.numero_serie_contador = waterTask.seriedv;
        counter.numero_serie_modulo = waterTask.numero_serie_modulo;
        counter.geolocalizacion = waterTask.codigo_de_localizacion;
        counter.calibre = waterTask.CALIBREDV;
        counter.ruedas = waterTask.RUEDASDV;
        counter.longitud = waterTask.LONGDV;
        counter.tipo_fluido = waterTask.TIPOFLUIDO_DEVUELTO;
        counter.tipo_radio = waterTask.TIPORADIO_DEVUELTO;
        counter.lectura_inicial = waterTask.LECTURA_CONTADOR_NUEVO;
        counter.status_contador = counter_status.INSTALLED;
        counter.last_modification_operator_uid = waterTask.last_modification_operator_uid;
        counter.activate_in_field_enabled = true;
        counter.fecha_instalado = waterTask.F_INST;
        counter.date_time_modified = new Date();
        counter.activation_pendent = true;
        counter.agrupationId = agrupationId;
        counter.company = waterTask.company;
        counter.manager = waterTask.GESTOR;
        counter.gestor = waterTask.GESTOR!.toString();
        counter.image = waterTask.foto_despues_instalacion;
        return counter;
    }

    /**
     * Exports tasks in a table format.
     * Retrieves the selected company and manager from local storage,
     * generates a timestamp, and exports the tasks in various formats (Excel, CSV).
     * If no tasks are selected, it exports all tasks based on the current filter.
     */
    async exportTasksInTable() {
        const companySelected = JSON.parse(localStorage.getItem('companyJson') || '{}');
        const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '{}');

        const companyName = companySelected.nombre_empresa;
        const managerName = managerSelected.gestor;

        const momentNowString = moment().format('yyyy_MM_DD__HH_mm_ss');

        let waterTasks: WaterTask[] = [];
        let result: boolean = false;
        if (this.clickedRows.size) {
            result = await this._utilsService.openQuestionDialog(
                'Seleccione',
                '¿Exportar solo las tareas seleccionadas?',
                'Seleccionadas',
                'Todas'
            );
            if (result) waterTasks = Array.from(this.clickedRows.values());
        }
        if (!result) {
            this.loadingText = `Descargando para exportación ...`;
            this.showLoading(true, true);
            waterTasks = await this.getTasksInCurrentFilter();
        }

        this.exportExcel(
            waterTasks,
            getExcelExportColumns(),
            `Trabajo_Exportado_${companyName}_${managerName}_${momentNowString}.xlsx`,
            getExcelFieldNameExportation
        );
        this.exportExcel(
            waterTasks,
            getExcelExtendedExportColumns(),
            `Trabajo_Exportado_Extendido_${companyName}_${managerName}_${momentNowString}.xlsx`,
            getExcelFieldNameExportation,
            true
        );
        this.exportCSVFile(
            waterTasks,
            getExcelExtendedExportColumns(),
            `Trabajo_Exportado_Extendido_Sin_Cabecera_${companyName}_${managerName}_${momentNowString}.csv`,
            getExcelFieldNameExportation,
            true
        );
        this.showLoading(false, true);
    }

    /**
     * Retrieves the count of tasks in the current filter.
     * @returns A Promise that resolves to the number of tasks.
     */
    async getTasksCountInCurrentFilter(): Promise<number> {
        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);
        return await this._mySqlService.getTasksCount(where_clause);
    }

    /**
     * Retrieves the tasks in the current filter.
     * 
     * @returns A promise that resolves to an array of WaterTask objects.
     */
    async getTasksInCurrentFilter(): Promise<WaterTask[]> {
        const savedOffset = this._mySqlService.last_offset;
        let waterTasks: WaterTask[] = [];

        let where_clause = this._utilsService.getTasksFilterWhereString();
        where_clause = this._utilsService.addStatusToWhereClause(where_clause);

        this.loadingText = `Obteniendo cantidad de tareas ...`;
        
        const count = await this.getTasksCountInCurrentFilter();
        const order = this._utilsService.orderTasks;
        let order_clause = undefined;
        if (order.length > 0) order_clause = this._utilsService.getOrderClauseFromOrder(order);
        let limit: number = 1000;
        for (let offset = 0; offset < count; offset += limit) {
            this.loadingText = `Obteniendo tareas (${offset} de ${count}) ...`;
            const response = await this._mySqlService.getTasks(
                undefined,
                where_clause,
                order_clause,
                undefined,
                offset.toString(),
                limit.toString()
            );
            waterTasks = waterTasks.concat(response.waterTasks);
        }
        this._mySqlService.last_offset = savedOffset;
        return waterTasks;
    }

    /**
     * Exports the given water tasks data to a CSV file.
     *
     * @param waterTasks - The array of water tasks to export.
     * @param columns - The array of column names to include in the CSV file.
     * @param filename - The name of the CSV file to save.
     * @param functionGetFieldName - The function used to get the field name for each column.
     * @param specialDate - Optional. Indicates whether special date formatting should be applied.
     */
    exportCSVFile(
        waterTasks: any,
        columns: string[],
        filename: string,
        functionGetFieldName: Function,
        specialDate?: boolean
    ) {
        let rows: string[] = [];
        for (let waterTask of waterTasks) {
            let values: string[] = [];
            columns.forEach((column) => {
                const columnName = functionGetFieldName(column);
                let value = this._utilsService.tableDataPipe(waterTask[columnName]);
                value = value
                    .replace(';', '.')
                    .replace('\n', ' ')
                    .replace('\r', ' ')
                    .replace('\t', ' ');
                if (this._utilsService.notNormalFilterFields.includes(columnName)) {
                    try {
                        if (Array.isArray(waterTask[columnName])) {
                            value = waterTask[columnName]
                                .map((element: any) => element.value)
                                .toString();
                        }
                    } catch (err) {}
                }
                if (
                    value.includes('-') &&
                    !value.includes('http') &&
                    columnName != 'ubicacion_en_bateria'
                ) {
                    value = value.split('-')[0].trim();
                }
                if (columnName == 'EMPLAZADV' && waterTask['contador_bateria'] == true) {
                    value = `${value}${waterTask['RESTO_EM']}`;
                }
                if (specialDate && isDateField(columnName) && waterTask[columnName]) {
                    let dateMoment = moment(waterTask[columnName].toISOString());
                    dateMoment = dateMoment.set({ hour: 0, minute: 0, second: 0 });
                    const dateString = dateMoment.format('DDMMYYYY');
                    value = dateString;
                }
                values.push(value);
            });
            rows.push(values.join('; '));
        }
        const csvArray = rows.join('\r\n');
        this._utilsService.saveTextTofile(csvArray, filename, true);
    }

    /**
     * Exports the provided water tasks to an Excel file.
     * 
     * @param waterTasks - The water tasks to export.
     * @param columns - The columns to include in the exported Excel file.
     * @param filename - The name of the exported Excel file.
     * @param functionGetFieldName - A function that returns the field name for a given column.
     * @param specialDate - Optional. Indicates whether to use special date formatting.
     */
    exportExcel(
        waterTasks: any,
        columns: string[],
        filename: string,
        functionGetFieldName: Function,
        specialDate?: boolean
    ) {
        let excelFormatTasks = this.getFormatedTasks(
            waterTasks,
            columns,
            functionGetFieldName,
            specialDate
        );
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(excelFormatTasks);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, filename);
    }

    /**
     * Formats the given water tasks into an Excel-compatible format.
     * 
     * @param tasks - The array of water tasks to be formatted.
     * @param columns - The array of column names to include in the formatted tasks.
     * @param functionGetFieldName - The function used to get the field name for each column.
     * @param specialDate - Optional. Indicates whether special date formatting should be applied.
     * @returns An array of tasks formatted in Excel-compatible format.
     */
    getFormatedTasks(tasks: WaterTask[], columns: string[], functionGetFieldName: Function, specialDate?: boolean) {
        const managerId = localStorage.getItem('manager')!;
        let excelFormatTasks = [];
        for (let waterTask of tasks) {
            let task: any = {};
            columns.forEach((column) => {
                this.getFormatedTask(task, managerId, waterTask, column, functionGetFieldName, specialDate);
            });
            excelFormatTasks.push(task);
        }
        return excelFormatTasks;
    }

    /**
     * Formats a task object by processing and transforming specific fields from a WaterTask object.
     *
     * @param task - The task object to be formatted.
     * @param managerId - The ID of the manager.
     * @param waterTask - The WaterTask object containing the data to be formatted.
     * @param column - The column name to be formatted.
     * @param functionGetFieldName - A function that returns the field name for a given column.
     * @param specialDate - Optional boolean indicating if special date formatting is required.
     */
    getFormatedTask(
        task: any,
        managerId: string,
        waterTask: WaterTask,
        column: string,
        functionGetFieldName: Function,
        specialDate?: boolean
    ) {
        const columnName = functionGetFieldName(column) as keyof WaterTask;
        let value = this._utilsService.tableDataPipe(waterTask[columnName], false);
        if (this._utilsService.notNormalFilterFields.includes(columnName)) {
            try {
                if (Array.isArray(waterTask[columnName])) {
                    value = (waterTask[columnName] as any).map((element: any) => element.value as any).toString();
                }
            } catch (err) {}
        }
        if (
            managerId == '7' //CABB
            && value.includes('-') 
            && !value.includes('http') 
            && columnName != 'ubicacion_en_bateria'
            && columnName != 'SERIE'
            && columnName != 'codigo_de_geolocalizacion'
        ) {
            value = value.split('-')[0].trim();
        }
        if (columnName == 'planning' && waterTask.contador_bateria == true) {
            let extras: string = '';
            if(waterTask.planning_detail_extras && waterTask.planning_detail_extras.length) {
                extras = waterTask.planning_detail_extras.map((extra: PlanningDetailExtras) => extra.value).join('\n');
            } 
            value = `${value}\n${waterTask.planning_details}\n${extras}`;
        }
        if (columnName == 'EMPLAZADV' && waterTask.contador_bateria == true) {
            value = `${value}${waterTask.RESTO_EM}`;
        }
        if (specialDate && isDateField(columnName) && waterTask[columnName]) {
            let dateMoment = moment((waterTask[columnName] as any).toISOString());
            dateMoment = dateMoment.set({ hour: 0, minute: 0, second: 0 });
            const dateString = dateMoment.format('DDMMYYYY');
            value = dateString;
        }
        task[`${column}`] = value;
    }

    /**
     * Handles the file event and performs different actions based on the selected file option and type.
     * @param fileData - The file event object.
     */
    async fileEvent(fileData: FileData): Promise<void> {
        try {
            if (fileData['file_option'] == 'Importar tareas') {
                if (fileData['file_type'] == 'Excel' && fileData['task_type']) {
                    await this.processExcelFile(fileData);
                } else if (fileData['file_type'] == 'DAT' && fileData['task_type']) {
                    await this.processDatFile(fileData);
                }
            } else if (fileData['file_option'] == 'Importar ficheros de tareas') {
                await this.processTasksFile(fileData);
            } else if (fileData['file_option'] == 'Importar ficheros de itacs') {
                await this.processItacsFile(fileData);
            } else if (fileData['file_option'] == 'Importar GeoJson') {
                await this.processGeoJsonFile(fileData);
            } else if (fileData['file_option'] == 'Buscar tareas de excel') {
                await this.searchExcelFile(fileData);
            } else if (fileData['file_option'] == 'Fix excel fields') {
                await this.getExcelFileDataToFixFields(fileData);
            }
        } catch (err) {} 
        this.cleanFileInput(fileData);
    }

    cleanFileInput(fileData: FileData) {
        if(fileData && fileData['file'] && fileData['file'].target){
            const targetElement = fileData['file'].target as HTMLInputElement;
            targetElement.value = '';
        }
    }

    async processGeoJsonFile(event: any) {
        this.homeDrawer?.toggle();

        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');

        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            if (reader.result) {
                this.showLoading(true, true);
                this.loadingText = `Cargando ...`;
                if (typeof reader.result == 'string') {
                    const geoJson: any = JSON.parse(reader.result);
                    const keys = Object.keys(geoJson);
                    if (keys.includes('type') && keys.includes('features')) {
                        if (geoJson['type'] == 'FeatureCollection') {
                            let i = 0;
                            let jsonFeatures: any[] = geoJson['features'];
                            jsonFeatures = jsonFeatures.reverse();
                            for (const feature of jsonFeatures) {
                                const geometry = feature['geometry'];
                                const properties = feature['properties'];
                                const coordinates = geometry['coordinates'];
                                const x_pos = coordinates[1];
                                const y_pos = coordinates[0];
                                let description = properties['description'];
                                let subscriber = properties['name'];
                                subscriber = subscriber.replace('CONTRATO:', '').trim();

                                try {
                                    const tasks = await this._apiService.getTasks([
                                        ['Numero_de_ABONADO', '==', subscriber],
                                        ['company', '==', company],
                                        ['GESTOR', '==', manager],
                                    ]);
                                    i++;
                                    this.loadingText = `Actualizando ${i} de ${jsonFeatures.length} ...`;
                                    for (let waterTask of tasks) {
                                        let data: any = {};
                                        const pos: MyLatLng | null = this._utilsService.createLatLng(x_pos, y_pos);
                                        const url = this._utilsService.getGeolocationUrl(pos);
                                        data = { COMENTARIOS: description };
                                        if(pos) {
                                            data['codigo_de_localizacion'] = pos;
                                            data['pendent_location'] = false;
                                            data['geolocalizacion'] = pos;
                                            data['url_geolocalizacion'] = url;
                                        }
                                        await this._apiService.updateTask(waterTask.id!, data);
                                    }
                                } catch (err) {
                                    console.log('============= err =============');
                                    console.log(err);
                                }
                            }
                        }
                    }
                }
                this.showLoading(false, true);
            }
        };
        reader.readAsBinaryString(file);
    }

    async processTasksFile(event: any) {
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            if (reader.result) {
                this.showLoading(true);
                this.loadingText = `Cargando ...`;

                if (typeof reader.result == 'string') {
                    const arrayTasks: WaterTask[] = JSON.parse(reader.result);
                    const errorIds: number[] = [];
                    let i: number = 0;
                    if (arrayTasks) {
                        for (const waterTask of arrayTasks) {
                            if (waterTask.id) {
                                const serverTask: WaterTask = await this._apiService.getTaskSync(
                                    waterTask.id.toString()
                                );
                                console.log('============= serverTask =============');
                                console.log(serverTask);
                                if (serverTask) {
                                    if (serverTask.date_time_modified! < waterTask.date_time_modified!) {
                                        this.loadingText = `Actualizando ${i} de ${arrayTasks.length} tareas...`;
                                        const res = await this._apiService.updateTask(
                                            waterTask.id.toString(),
                                            waterTask,
                                            false
                                        );
                                        if (!res) {
                                            errorIds.push(waterTask.id!);
                                        }
                                        i++;
                                    } else {
                                        console.log('============= not update =============');
                                    }
                                }
                                else {
                                    const res = await this._apiService.addTask(waterTask);
                                    console.log('============= insert respose =============');
                                    console.log(res);
                                }
                            }
                        }
                    }
                    if (errorIds) {
                        console.log('============= errorIds =============');
                        console.log(errorIds);
                    }
                }
                this.showLoading(false);
            }
        };
        reader.readAsText(file);
    }

    async processItacsFile(event: any) {
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            if (reader.result) {
                this.showLoading(true);
                this.loadingText = `Cargando ...`;

                if (typeof reader.result == 'string') {
                    const arrayItacs: Itac[] = JSON.parse(reader.result);
                    const errorIds: number[] = [];
                    let i: number = 0;
                    if (arrayItacs) {
                        for (const itac of arrayItacs) {
                            if (itac.id) {
                                const serverItac: Itac = await this._apiService.getItacSync(
                                    itac.id.toString()
                                );
                                if (serverItac) {
                                    if (serverItac.date_time_modified! < itac.date_time_modified!) {
                                        this.loadingText = `Actualizando ${i} de ${arrayItacs.length} itacs...`;
                                        const res = await this._apiService.updateItac(
                                            itac.id.toString(),
                                            itac,
                                            false
                                        );
                                        if (!res) errorIds.push(itac.id!);
                                        i++;
                                    }
                                }
                                else {
                                    const res = await this._apiService.addItac(itac);
                                    console.log('============= insert respose =============');
                                    console.log(res);
                                }
                            }
                        }
                    }
                    if (errorIds) {
                        console.log('============= errorIds =============');
                        console.log(errorIds);
                    }
                }
                this.showLoading(false);
            }
        };
        reader.readAsText(file);
    }

    /**
     * Retrieves the last ID order and prompts the user to input a new initial order ID.
     * @returns A promise that resolves to an object containing the retrieved info and the new initial order ID.
     */
    async getLastIdOrder(): Promise<any> {
        const info = await this._apiService.getInfo();
        let idOrderStart = 0;
        if (info) {
            idOrderStart = info.lastIDOrden!;
            idOrderStart++;
        }
        const text = 'Inserte ID de orden inicial...';
        let idOrden;
        try {
            idOrden = await this._utilsService.openInputSelectorDialog(text, idOrderStart.toString(), 'number' );
        } catch (err) {}
        if (idOrden) {
            idOrderStart = idOrden;
            return { info, idOrderStart };
        }
        else return { info: null, idOrderStart: null };
    }

    /**
     * Reads and processes an Excel file selected by the user.
     * @param event - The event object containing the selected file.
     */
    async searchExcelFile(event: any) { 
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this.homeDrawer?.toggle();
            this.showLoading(true, true);
            this.loadingText = `Procesando tareas ...`;
            const { jsonData, sheets } = this._utilsService.getExcelSheetAndData(reader);
            this.showLoading(false, true);

            const { appFields, excelColumns } = await this.getSearchField(jsonData, sheets); 
            if (!appFields || !excelColumns) return;
            if (excelColumns.length && excelColumns.length == appFields.length) { 
                const jsonArrayValues = this._utilsService.getTaskExcelValues(jsonData, sheets, excelColumns, appFields);
                for (let jsonValues of jsonArrayValues) { 
                    const appField = Object.keys(jsonValues)[0];
                    this.modifyFilter(jsonValues[appField], appField);
                }
                await this.executeFilter();
                if(this.length) await this.requestUpdateFieldsFromExcel(jsonArrayValues, jsonData, sheets);
            }
        };
        reader.readAsBinaryString(file);
    }

    /**
     * Requests to update the fields of tasks with values from an Excel file.
     * @param searchField - The field to search for in the tasks.
     * @param searchValues - The values to search for in the tasks.
     * @param jsonData - The JSON data representing the Excel file.
     * @param sheets - The names of the sheets in the Excel file.
     * @returns A promise that resolves when the fields have been updated.
     */
    async requestUpdateFieldsFromExcel(jsonArrayValues: any[], jsonData: any, sheets: string[]): Promise<void> {
        const question =  '¿Desea actualizar los campos de las tareas con los valores del excel?';
        try {
            const res = await this._utilsService.openQuestionDialog('Actualizar campos', question, 'Sí', 'No');
            if(await this.isMaxSizeWarningCurrentFiltered(this.length)) return; 
            if(res) {
                const { appFields, excelColumns } = await this.getSearchField(jsonData, sheets);
                const jsonArrayUpdateValues = this._utilsService.getTaskExcelValues(jsonData, sheets, excelColumns, appFields);
                await this.updateFieldsFromExcel(jsonArrayValues, jsonArrayUpdateValues);
                this.reload();
            }
        } catch (err) {
            this._utilsService.handleError(err);
        }
    }

    /**
     * Updates fields in the database based on values from Excel file data with multiple search conditions.
     * 
     * @param jsonArrayValues - An array of objects mapping search fields to arrays of values from the Excel data.
     * @param jsonArrayUpdateValues - An array of objects mapping update fields to arrays of update values.
     */
    async updateFieldsFromExcel(jsonArrayValues: any[], jsonArrayUpdateValues: any[]) {
        this.showLoading(true, true);
        const numberOfRecords = jsonArrayValues[0][Object.keys(jsonArrayValues[0])[0]].length;
        for (let i = 0; i < numberOfRecords; i++) {
            let where = this.getWhereConditionsForExcelUpdate(jsonArrayValues, i);
            if (this._utilsService.isFieldNotValid(where)) continue;
            const data = this.getDataForExcelUpdate(jsonArrayUpdateValues, i);

            if (data && Object.keys(data).length) {
                this.loadingText = `Actualizando tareas ${i + 1} de ${numberOfRecords}`;
                await this._apiService.updateMultipleTasks(where, data);
            }
        }
        this.showLoading(false, true);
    }

    /**
     * Processes an array of update values and returns the appropriate data for an Excel update.
     *
     * @param jsonArrayUpdateValues - An array of objects containing update values. Each object should have a single key-value pair where the key is the field to update and the value is an array of update values.
     * @param index - The index of the value to retrieve from each update values array.
     * @returns An object containing the processed update data for Excel.
     */
    getDataForExcelUpdate(jsonArrayUpdateValues: any[], index: number): any{
        let updateData: any = {};
        for (let updateObject of jsonArrayUpdateValues) {
            const updateField = Object.keys(updateObject)[0];  // Obtiene el campo a actualizar (ej. "SERIE", "MARCA")
            const updateValues = updateObject[updateField];    // Obtiene los valores para ese campo
            if (this._utilsService.isFieldNotValid(updateValues[index])) continue;
            updateData[updateField] = updateValues[index];
        }
        return this.getRightDataForExcelUpdate(updateData);
    }

    /**
     * Generates a JSON string representing the where conditions for an Excel update operation.
     * 
     * @param jsonArrayValues - An array of objects where each object contains key-value pairs representing search fields and their corresponding values.
     * @param index - The index to access the value within each search field's array.
     * @returns A JSON string representing the where conditions, formatted as an array of objects with "field", "value", and "type" properties.
     * 
     * @example
     * ```typescript
     * const jsonArrayValues = [
     *   { "Numero_de_ABONADO": ["123", "456"] },
     *   { "SERIE": ["ABC", "DEF"] }
     * ];
     * const index = 0;
     * const result = getWhereConditionsForExcelUpdate(jsonArrayValues, index);
     * // result: '[{ "field": "Numero_de_ABONADO", "value": "123", "type": "AND" }, { "field": "SERIE", "value": "ABC", "type": "AND" }]'
     * ```
     */
    getWhereConditionsForExcelUpdate(jsonArrayValues: any[], index: number) {
        let whereConditions = [];
        for (let searchObject of jsonArrayValues) {
            const searchField = Object.keys(searchObject)[0];  // Campo de búsqueda (por ejemplo, "Numero_de_ABONADO" o "SERIE")
            const searchValue = searchObject[searchField][index];  // Valor de búsqueda para este campo en la iteración actual
            if (this._utilsService.isFieldNotValid(searchValue)) continue;
            whereConditions.push(`{ "field": "${searchField}", "value": "${searchValue}", "type": "AND" }`);
        }
        return whereConditions.length ? `[${whereConditions.join(", ")}]` : '';
    }

    /**
    * Processes the update fields and values to generate the appropriate data for Excel update.
    * 
    * @param updateData - An object containing fields and their corresponding values to be updated.
    * @returns An object containing the processed data for the Excel update. If any field contains 
    *          'codigo_de_localizacion' and the value is a valid angle coordinate, the returned object 
    *          will contain latitude and longitude. Otherwise, it will contain the update fields and 
    *          values as key-value pairs.
    */
    getRightDataForExcelUpdate(updateData: { [key: string]: any }) {
        let data: any = {};
        for (let updateField in updateData) {
            const updateValue = updateData[updateField];
            if (updateField && updateValue) {
                if (updateField == 'codigo_de_localizacion'
                    || updateField == 'geolocalizacion'
                    || updateField == 'url_geolocalizacion') {
                    if (this._utilsService.isAngleCoords(updateValue)) {
                        const coords = this._utilsService.parseAngleCoordinates(updateValue);
                        if (coords) data = this._utilsService.assignMyLatLng(data, coords);
                    }
                    else data = this._utilsService.getMyLatLngFromGeolocationUrl(updateValue);
                }
                else data[updateField] = updateValue;
            }
        }
        return data;
    }


    /**
     * Retrieves the search field and excel column based on the provided JSON data and sheet names.
     * @param jsonData The JSON data used to retrieve the excel keys.
     * @param sheets An array of sheet names.
     * @returns An object containing the search field and excel column.
     */
    async getSearchField(jsonData: any, sheets: string[]): Promise<any> {
        const keys = this._utilsService.getExcelKeys(jsonData, sheets);
        let appFields: string[] = [], excelColumns: string[] = [];
        try {
            excelColumns = await this._utilsService.openMultipleOptionsDialog('Columna en excel', keys, []);
            if (excelColumns && excelColumns.length) appFields = excelColumns.map((c) => getExcelFieldName(c));
            if (appFields && appFields.length == excelColumns.length) return { appFields, excelColumns };
            const text = 'A continuación seleccione la columna que le corresponde en la tabla de tareas';
            await this._utilsService.openInformationDialog('Columna no registrada', [text]);
            const columns = await this._utilsService.openMultipleOptionsDialog('Columna en aplicación', getDisplayColumns(), []);
            appFields = columns.map(() => getField(columns));
        } catch (err) { }
        return { appFields, excelColumns };
    }

    /**
     * Processes the Excel file selected by the user.
     * @param event - The event object containing the selected file and task type.
     */
    async processExcelFile(event: any) {
        const orderSelected = event['task_type'];
        this._utilsService.zones = await this._apiService.getZones();
        this.showLoading(true, true);
        this.loadingText = `Cargando ...`;

        if (orderSelected) {
            await this._apiService.getCauses();
            const reader = new FileReader();
            const file = event['file'].target.files[0];
            reader.onload = async (_) => {
                this.homeDrawer?.toggle();
                await this.processExcelFileData(reader, orderSelected);
            };
            reader.readAsBinaryString(file);
        }
        this.showLoading(false, true);
    }

    /**
     * Processes the data from an Excel file.
     * 
     * @param reader - The FileReader object used to read the Excel file.
     * @param orderSelected - The selected order.
     * @returns A Promise that resolves when the data is processed.
     */
    async processExcelFileData(reader: FileReader, orderSelected: string){
        let poblations: string[] = [];
        let emplacement_codes: string[] = [];
        let arrayTasks: WaterTask[] = [];
        let { info, idOrderStart } = await this.getLastIdOrder();

        if(idOrderStart){
            this.showLoading(true, true);
            this.loadingText = `Procesando tareas ...`;
            const { jsonData, sheets } = this._utilsService.getExcelSheetAndData(reader);
            
            for (let sheet of sheets) {
                for (let jsonTask of jsonData[sheet]) {
                    idOrderStart = this.parseJsonTask(jsonTask, arrayTasks, emplacement_codes, poblations, orderSelected, idOrderStart); 
                }
            }
            emplacement_codes.forEach((code) => {
                if (code.includes('-')) code = code.split('-')[0].trim();
                poblations.push(code);
            });
            await this.assingLocationInfo(arrayTasks, emplacement_codes, poblations);
            await this.addPlanning(arrayTasks, emplacement_codes);
            const result = await this.importTasks(arrayTasks);
            if (result && info) await this.updateIdOrder(info, idOrderStart - 1);
            this.showLoading(false, true);
        }
    }

    /**
     * Assigns location information to an array of WaterTask objects.
     * 
     * @param arrayTasks - The array of WaterTask objects to assign location information to.
     * @param emplacement_codes - An array of emplacement codes.
     * @param poblations - An array of poblations.
     */
    async assingLocationInfo(arrayTasks: WaterTask[], emplacement_codes: string[], poblations: string[]) {
        let itacs: Itac[] = await this.getCodesEmplacements(emplacement_codes);
        let routes: WaterRoute[] = await this.getPoblationsEmplacements(poblations);

        const itacCodes = itacs.map(itac => itac.codigo_itac);
        emplacement_codes = emplacement_codes.filter(code => !itacCodes.includes(code));
        let tasksLocationInfo: TaskLocationInfo[] = await this.getLocationInfo(emplacement_codes);

        for (let i = 0; i < arrayTasks.length; i++) {
            arrayTasks[i] = this.assignItacFieldToTask(arrayTasks[i], itacs);
            arrayTasks[i] = await this.assignWaterRouteFieldToTask(arrayTasks[i], routes);
            arrayTasks[i] = this.assignTaskLocationInfo(arrayTasks[i], tasksLocationInfo);
        }
    }

    /**
     * Retrieves location information for the given codes.
     * @param codes - An array of codes for which location information needs to be retrieved.
     * @returns A promise that resolves to an array of TaskLocationInfo objects containing the location information.
     */
    async getLocationInfo(codes: string[]) { 
        let tasksLocationInfo: TaskLocationInfo[] = [];
        for (let i = 0; i < codes.length; i += 1000) {
            let whereJson = { field: 'codigo_de_geolocalizacion', value: codes.slice(i, i + 1000), type: 'AND' };
            let whereJsonArray = [];
            whereJsonArray.push(whereJson);
            try {
                const data = await this._mySqlService.getTasksLocationInfo(JSON.stringify(whereJsonArray))
                tasksLocationInfo = tasksLocationInfo.concat(data);
            } catch (err) {}
        }
        return tasksLocationInfo;
    }

    /**
     * Retrieves the Itac objects based on the provided codes.
     * @param codes - An array of string codes.
     * @returns An array of Itac objects.
     */
    async getCodesEmplacements(codes: string[]) { 
        let  itacs: Itac[] = [];
        for (let i = 0; i < codes.length; i += 500) {
            let whereJson = { field: 'codigo_itac', value: codes.slice(i, i + 500), type: 'AND' };
            let whereJsonArray = [];
            whereJsonArray.push(whereJson);
            try {
                const data = await this._mySqlService.getItacs(undefined, JSON.stringify(whereJsonArray))
                itacs = itacs.concat(data);
            } catch (err) {}
        }
        return itacs;
    }

    /**
     * Retrieves water routes for the given list of populations.
     * @param poblations - An array of population names.
     * @returns An array of WaterRoute objects representing the retrieved water routes.
     */
    async getPoblationsEmplacements(poblations: string[]){
        let routes: WaterRoute[] = [];
        for (let i = 0; i < poblations.length; i += 500) {
            let whereRoutesJson = { field: 'codigo_ruta', value: poblations.slice(i, i + 500), type: 'AND' };
            let whereRoutesJsonArray = [];
            whereRoutesJsonArray.push(whereRoutesJson);
            try {
                const r = await this._mySqlService.getWaterRoutes(undefined, JSON.stringify(whereRoutesJsonArray))
                routes = routes.concat(r);
            } catch (err) {}
        }
        return routes;
    }

    /**
     * Adds a normal field to the task object based on the provided value, field, and task.
     * @param value - The value to be added to the task object.
     * @param field - The field name to which the value will be added.
     * @param task - The task object to which the value will be added.
     * @returns void
     */
    addNormalField(value: any, field: string, task: any): void {
        const res = this._utilsService.getTaskValue(value, field);
        if(res != null) task[`${field}`] = res;
    }

    /**
     * Parses the address from a JSON task object.
     * 
     * @param task - The task object to which the address will be added.
     * @param value - The address value to parse.
     * @returns The updated task object with the new address.
     */
    parseJsonTaskAddress(task: any, value: any): any {
        if (value.split(',').length > 0) task[`CALLE`]= value.split(',')[0].trim();
        if (value.split(',').length > 1) task[`NUME`] = value.split(',')[1].trim();
        if (value.split(',').length > 2) task[`BIS`]  = value.split(',')[2].trim();
        if (value.split(',').length > 3) task[`PISO`] = value.split(',')[3].trim();
        if (value.split(',').length > 4) task[`MANO`] = value.split(',')[4].trim();
        return task;
    }

    /**
     * Parses a JSON task object and converts it into a WaterTask object.
     * 
     * @param jsonTask - The JSON task object to parse.
     * @param arrayTasks - An array of WaterTask objects.
     * @param emplacement_codes - An array of emplacement codes.
     * @param poblations - An array of poblations.
     * @param orderSelected - The selected order.
     * @param idOrderStart - The starting ID for the order.
     * @returns The updated value of idOrderStart.
     */
    parseJsonTask(
        jsonTask: any,
        arrayTasks: WaterTask[],
        emplacement_codes: string[],
        poblations: string[],
        orderSelected: string,
        idOrderStart: number
    ) {
        let task: any = {};
        let latitude;
        let longitude;
        let lastName1;
        let lastName2;
        Object.keys(jsonTask).forEach((key) => {
            let field: string = getExcelFieldName(key.trim());
            if (this._utilsService.isFieldValid(field)) {
                this.addNormalField(jsonTask[key], field, task);
            } 
            else {
                let value = jsonTask[key];
                if (key && key.toUpperCase() == 'DIRECCION' || key.toUpperCase() == 'DIRECCIÓN') {
                    this.parseJsonTaskAddress(task, value);
                } 
                else if (key && (key.toUpperCase().includes('COORDENADA') && this._utilsService.isAngleCoords(value))) {
                    const coords = this._utilsService.parseAngleCoordinates(value);
                    if(coords) this._utilsService.assignMyLatLng(task, coords);
                } 
                else if (key && (key.toUpperCase() == 'LATITUD' || key.toUpperCase() == 'LATITUDE')) {
                    if (typeof value == 'number') latitude = value;
                    else if(value) latitude = parseFloat(value.trim().replace(',', '.'));
                } 
                else if (key && (key.toUpperCase() == 'LONGITUDE' || key.toUpperCase() == 'LONGITUD')) {
                    if (typeof value == 'number') longitude = value;
                    else if(value) longitude = parseFloat(value.trim().replace(',', '.'));
                } 
                else if (key == 'APELLIDO 1') lastName1 = value;
                else if (key == 'APELLIDO 2') lastName2 = value;
            }
        });
        if (lastName1 && lastName2 && task['NOMBRE_ABONADO']) {
            task['NOMBRE_ABONADO'] += ` ${lastName1 || ''} ${lastName2 || ''}`.trim();
        }
        if (latitude && longitude) {
            this._utilsService.assignPoinToTask(task, latitude, longitude);
        }
        let waterTask = task as WaterTask;
        const address = this._utilsService.getDirOfTask(waterTask, true);
        const validDir = this._utilsService.isFieldValid(address);
        if (waterTask && validDir) {
            waterTask = this.fullFillTask(waterTask, orderSelected, idOrderStart);
            this.addEmplacementCode(waterTask.codigo_de_geolocalizacion, emplacement_codes);
            if (waterTask.MUNICIPIO && !poblations.includes(waterTask.MUNICIPIO)) {
                poblations.push(waterTask.MUNICIPIO);
            }
            arrayTasks.push(waterTask);
            idOrderStart++;
        }
        return idOrderStart;
    }

    /**
     * Processes the DAT file selected by the user.
     * 
     * @param event - The event object containing the selected DAT file.
     * @returns A Promise that resolves when the DAT file has been processed.
     */
    async processDatFile(event: any) {
        const orderSelected = event['task_type'];

        const info: Info | undefined = await this._apiService.getInfo();
        this._utilsService.zones = await this._apiService.getZones();
        let idOrderStart = 0;
        if (info) {
            idOrderStart = info.lastIDOrden!;
            idOrderStart++;
        }
        let idOrden = await this._utilsService.openInputSelectorDialog(
            'Inserte ID de orden inicial...',
            idOrderStart.toString(),
            'number'
        );
        if (idOrden) idOrderStart = idOrden;

        this.showLoading(true, true);
        this.loadingText = `Cargando ...`;

        if (orderSelected) {
            this.homeDrawer?.toggle();
            this.showLoading(true, true);
            this.loadingText = `Procesando tareas ...`;
            await this._apiService.getCauses();
            const reader = new FileReader();
            const file = event['file'].target.files[0];

            reader.onload = async (_) => {
                this.showLoading(true, true);
                await this.processDatFileData(reader, orderSelected, idOrderStart, info);
                this.showLoading(false, true);
            };
            reader.readAsText(file);
        }
        this.showLoading(false, true);
    }

    /**
     * Retrieves the lines from a FileReader object.
     * 
     * @param reader - The FileReader object containing the data.
     * @returns An array of lines extracted from the FileReader object.
     */
    getDatFileLines(reader: FileReader): Array<string> {
        let lines: string[] = [];
    
        if (reader.result) {
            const result = typeof reader.result === 'string' 
                ? reader.result 
                : new TextDecoder("windows-1252").decode(reader.result); 
            lines = result.split(/\r?\n/);
        }
        return lines;
    }
    
    /**
     * Removes the company and manager information from the given code.
     * 
     * @param code - The code to process.
     * @returns The code without the company and manager information.
     */
    getCodeWithOutCompanyAndManager(code: string): string {
        if (code && code.includes('_')) {
            const split = code.split('_');
            if (split.length > 1) code = split[split.length - 1].trim();
        }
        return code;
    }

    /**
     * Adds an emplacement code to the given array of emplacement codes.
     * 
     * @param code - The emplacement code to add.
     * @param emplacement_codes - The array of emplacement codes.
     */
    addEmplacementCode(code: string | undefined, emplacement_codes: string[]): void {
        if (code) {
            if (!emplacement_codes.includes(code)) emplacement_codes.push(code);
            code = this.getCodeWithOutCompanyAndManager(code);
            if (!emplacement_codes.includes(code)) emplacement_codes.push(code);
        }
    }

    /**
     * Processes the data from a FileReader for a DAT file.
     * 
     * @param reader - The FileReader object used to read the DAT file.
     * @param orderSelected - The selected order.
     * @param idOrderStart - The starting ID for the order.
     * @param info - Additional information.
     * @returns A Promise that resolves when the processing is complete.
     */
    async processDatFileData(reader: FileReader, orderSelected: string, idOrderStart: number, info?: Info): Promise<void> {
        let poblations: string[] = [];
        let emplacement_codes: string[] = [];
        let arrayTasks: WaterTask[] = [];

        let lines = this.getDatFileLines(reader); 
        lines.forEach((line) => {
            let waterTask = this.parseLineToTask(line);
            waterTask = this.fullFillTask(waterTask, orderSelected, idOrderStart);
            this.addEmplacementCode(waterTask.codigo_de_geolocalizacion, emplacement_codes);
            if (waterTask.MUNICIPIO && !poblations.includes(waterTask.MUNICIPIO)) {
                poblations.push(waterTask.MUNICIPIO);
            }
            arrayTasks.push(waterTask);
            idOrderStart++;
        });
        await this.addEmplacementsAndImportDatTasks(emplacement_codes, poblations, arrayTasks, idOrderStart, info);
    }

    /**
     * Adds emplacements and imports data tasks.
     * 
     * This method processes emplacement codes, assigns location information,
     * adds planning, imports tasks, and updates the order ID if necessary.
     * 
     * @param emplacement_codes - An array of emplacement codes.
     * @param poblations - An array of population names.
     * @param arrayTasks - An array of WaterTask objects.
     * @param idOrderStart - The starting ID order number.
     * @param info - Optional information object for updating the order ID.
     * 
     * @returns A promise that resolves when all tasks are completed.
     */
    async addEmplacementsAndImportDatTasks(
        emplacement_codes: string[], 
        poblations: string[], 
        arrayTasks: WaterTask[], 
        idOrderStart: number, 
        info?: Info
    ) {
        let codes: any = [];
        emplacement_codes.forEach((code) => {
            if (code.includes('-')) code = code.split('-')[0].trim();
            codes.push(code);
        });
        await this.assingLocationInfo(arrayTasks, emplacement_codes, poblations);
        await this.addPlanning(arrayTasks, emplacement_codes);
        const result = await this.importTasks(arrayTasks);
        if (result && info) await this.updateIdOrder(info, idOrderStart - 1); // I substract 1 because of the last wrong increment
    }

    /**
     * Adds planning information to tasks based on emplacement codes.
     * 
     * @param {WaterTask[]} tasks - The list of tasks to be processed.
     * @param {string[]} emplacement_codes - The list of emplacement codes to be checked.
     * @returns {Promise<void>} A promise that resolves when the planning information has been added.
     * 
     * @description
     * This method fetches the planning information for 'MR1' and assigns it to tasks that match the given emplacement codes.
     * It maintains a count of tasks for each emplacement code and assigns the planning information to tasks when the count reaches 5.
     * Tasks with a `contador_bateria` value are skipped.
     */
    async addPlanningMR1(tasks: WaterTask[], emplacement_codes: string[]): Promise<void> { 
        const planningMR1: string = await this._apiService.getPlanning('MR1');
        const emplacementCodeCountMap: Map<string, number> = new Map();
        for (let task of tasks) {
            const emplacementCode = task.codigo_de_geolocalizacion;
            if (emplacementCode && emplacement_codes.includes(emplacementCode)) {
                if (task.contador_bateria) continue;
                
                let count = emplacementCodeCountMap.get(emplacementCode) || 0;
                count++;
                emplacementCodeCountMap.set(emplacementCode, count);
                
                if (count >= 5) {
                    let founded = tasks.filter(t => t.codigo_de_geolocalizacion === emplacementCode);
                    founded.forEach(t => t.planning = planningMR1);
                }
            }
        }
    }

    /**
     * Adds planning information to tasks based on the 'MR12' planning code.
     * 
     * @param {WaterTask[]} tasks - An array of tasks to be processed.
     * @param {string[]} emplacement_codes - An array of emplacement codes to be checked against task geolocation codes.
     * @returns {Promise<void>} A promise that resolves when the planning information has been added to the tasks.
     * 
     * @remarks
     * This method fetches the 'MR12' planning code from the API service and assigns it to tasks that meet the following criteria:
     * - The task's geolocation code is included in the provided emplacement codes.
     * - The task has a non-null `contador_bateria` property.
     * - The number of tasks with the same geolocation code reaches or exceeds 5.
     * 
     * The method maintains a count of tasks for each geolocation code and updates the `planning` property of tasks that meet the criteria.
     */
    async addPlanningMR12(tasks: WaterTask[], emplacement_codes: string[]): Promise<void> { 
        const planningMR12: string = await this._apiService.getPlanning('MR12');
        const emplacementCodeCountMap: Map<string, number> = new Map();
        for (let task of tasks) {
            const emplacementCode = task.codigo_de_geolocalizacion;
            if (emplacementCode && emplacement_codes.includes(emplacementCode)) {
                if (!task.contador_bateria) continue;
                
                let count = emplacementCodeCountMap.get(emplacementCode) || 0;
                count++;
                emplacementCodeCountMap.set(emplacementCode, count);

                if (count >= 5) {
                    let founded = tasks.filter(t => t.codigo_de_geolocalizacion === emplacementCode);
                    founded.forEach(t => t.planning = planningMR12);
                }
            }
        }
    }

    /**
     * Adds planning information to the given tasks.
     * 
     * This method first retrieves the default planning information for 'MR13' and assigns it to each task.
     * Then, it calls additional methods to add specific planning information for 'MR1' and 'MR12'.
     * 
     * @param tasks - An array of WaterTask objects to which planning information will be added.
     * @param emplacement_codes - An array of emplacement codes used for additional planning.
     * @returns A promise that resolves when all planning information has been added.
     */
    async addPlanning(tasks: WaterTask[], emplacement_codes: string[]) {
        const planningMR13: string = await this._apiService.getPlanning('MR13');

        for (let task of tasks) task.planning = planningMR13; //default planning

        await this.addPlanningMR1(tasks, emplacement_codes);
        await this.addPlanningMR12(tasks, emplacement_codes);
    }

    /**
     * Cleans and replaces specific characters in a given text.
     *
     * @param text - The input text which can be a string or an ArrayBuffer.
     *               If it's an ArrayBuffer, it will be decoded using "windows-1252" encoding.
     * @returns The cleaned text with specific replacements:
     *          - Replaces "�" with "º" if it appears after "N" and before a number or space.
     *          - Replaces "�" with "º" if it appears after a number and before a space or letter.
     *          - Replaces "�" with "Ñ" in all other cases.
     */
    cleanDatLineText(text: string | ArrayBuffer): string {
        if (text instanceof ArrayBuffer) text = new TextDecoder("windows-1252").decode(text);
        if (!text) return "";
    
        return text.replace(/�/g, (_, offset, fullText) => {
            const prevChar = fullText[offset - 1] || "";
            const nextChar = fullText[offset + 1] || "";
    
            // Caso 1: Si "�" está después de "N" y antes de un número o espacio (ej. "N� 7" -> "Nº 7")
            if (/N/i.test(prevChar) && (/\d/.test(nextChar) || /\s/.test(nextChar))) return "º";
    
            // Caso 2: Si "�" está después de un número y antes de un espacio o letra (ej. "1� A" -> "1º A")
            if (/\d/.test(prevChar) && /\s|\w/.test(nextChar)) return "º";

            // Caso 3: Cualquier otro caso, reemplazar con "Ñ" (ej. "BA�ALES" -> "BAÑALES")
            return "Ñ";
        }).trim();
    }
    
    
    
    /**
     * Parses a line of text or an ArrayBuffer and returns a WaterTask object.
     * @param line - The line of text or ArrayBuffer to parse.
     * @returns The parsed WaterTask object.
     */
    parseLineToTask(line: string | ArrayBuffer): WaterTask {
        let pos: number = 0;
        let waterTask: WaterTask = {};
        
        line = this.cleanDatLineText(line);

        //A realizar------------------------------------------------------------------------------------------------------------
        waterTask.AREALIZAR = (line.slice(pos, (pos += 12)) as string).trim();
        //Intervención------------------------------------------------------------------------------------------------------------
        waterTask.INTERVENCI = (line.slice(pos, (pos += 27)) as string).trim();
        //Reparación (reparacion)------------------------------------------------------------------------------------------------------------
        waterTask.REPARACION = (line.slice(pos, (pos += 3)) as string).trim();
        //Propiedad (propiedad)------------------------------------------------------------------------------------------------------------
        waterTask.PROPIEDAD = (line.slice(pos, (pos += 1)) as string).trim();
        //Contador (numero_serie_contador)------------------------------------------------------------------------------------------------------------
        const prefix_serial = (line.slice(pos, (pos += 13)) as string).trim();
        waterTask.CONTADOR = this._utilsService.getPrefixFromSerialNumber(prefix_serial);
        waterTask.SERIE = prefix_serial.replace(/\s/g, '').trim(); //? replace all empty spaces
        //Marca (marca_contador)------------------------------------------------------------------------------------------------------------
        waterTask.MARCA = (line.slice(pos, (pos += 12)) as string).trim();
        //Calibre (calibre_toma)------------------------------------------------------------------------------------------------------------
        waterTask.CALIBRE = (line.slice(pos, (pos += 4)) as string).trim();
        //Ruedas (ruedas)------------------------------------------------------------------------------------------------------------
        waterTask.RUEDAS = (line.slice(pos, (pos += 2)) as string).trim();
        //Fecha de Instalación de contador anterior------------------------------------------------------------------------------------------------------------
        let dateString = (line.slice(pos, (pos += 10)) as string).trim();
        const instalationDate = moment(dateString, 'DD/MM/YYYY');
        if (instalationDate.isValid()) {
            waterTask.FECINST = instalationDate.toDate();
        }
        //Actividad (actividad)------------------------------------------------------------------------------------------------------------
        waterTask.ACTIVI = (line.slice(pos, (pos += 27)) as string).trim();
        //Emplazamiento (emplazamiento)------------------------------------------------------------------------------------------------------------
        waterTask.EMPLAZA = (line.slice(pos, (pos += 22)) as string).trim();
        //Acceso (acceso)------------------------------------------------------------------------------------------------------------
        waterTask.ACCESO = (line.slice(pos, (pos += 40)) as string).trim();
        //Calle (calle)------------------------------------------------------------------------------------------------------------
        waterTask.CALLE = (line.slice(pos, (pos += 20)) as string).trim();
        //Número Portal (numero_portal)------------------------------------------------------------------------------------------------------------
        waterTask.NUME = (line.slice(pos, (pos += 3)) as string).trim();
        //Bis (letra_edificio)------------------------------------------------------------------------------------------------------------
        waterTask.BIS = (line.slice(pos, (pos += 3)) as string).trim();
        //Piso (piso)------------------------------------------------------------------------------------------------------------
        waterTask.PISO = (line.slice(pos, (pos += 3)) as string).trim();
        //Mano (mano)------------------------------------------------------------------------------------------------------------
        waterTask.MANO = (line.slice(pos, (pos += 3)) as string).trim();
        //Municipio (poblacion)------------------------------------------------------------------------------------------------------------
        waterTask.MUNICIPIO = (line.slice(pos, (pos += 12)) as string).trim();
        //Nombre Abonado (nombre_cliente)------------------------------------------------------------------------------------------------------------
        waterTask.NOMBRE_ABONADO = (line.slice(pos, (pos += 60)) as string).trim();
        //Número Abonado (numero_abonado)------------------------------------------------------------------------------------------------------------
        waterTask.Numero_de_ABONADO = (line.slice(pos, (pos += 9)) as string).trim();
        //Código Ruta (ruta)------------------------------------------------------------------------------------------------------------
        waterTask.ruta = (line.slice(pos, (pos += 8)) as string).trim();
        //Fecha emision ------------------------------------------------------------------------------------------------------------
        dateString = (line.slice(pos, (pos += 10)) as string).trim();
        const emisionDate = moment(dateString, 'DD/MM/YYYY');
        if (emisionDate.isValid()) {
            waterTask.FECEMISIO = emisionDate.toDate();
        }
        //Fecha generado parte (?)------------------------------------------------------------------------------------------------------------
        dateString = (line.slice(pos, (pos += 10)) as string).trim();
        const fecultrepDate = moment(dateString, 'DD/MM/YYYY');
        if (fecultrepDate.isValid()) {
            waterTask.FECULTREP = fecultrepDate.toDate();
        }
        //Anomalía ------------------------------------------------------------------------------------------------------------
        waterTask.ANOMALIA = (line.slice(pos, (pos += 3)) as string).trim();
        //Número Interno (numero_interno)------------------------------------------------------------------------------------------------------------
        waterTask.NUMIN = (line.slice(pos, (pos += 12)) as string).trim();
        //Observaciones del CABB (OBSERVA)------------------------------------------------------------------------------------------------------------
        waterTask.OBSERVA = (line.slice(pos, (pos += 30)) as string).trim();
        return waterTask;
    }

    /**
     * Assigns the relevant fields from the matching ITAC to the given WaterTask.
     * @param waterTask - The WaterTask object to assign the fields to.
     * @param itacs - The array of ITAC objects to search for a match.
     * @returns The updated WaterTask object with assigned fields.
     */
    assignItacFieldToTask(waterTask: WaterTask, itacs: Itac[]): WaterTask {
        if (waterTask.codigo_de_geolocalizacion) {
            const itac = itacs.find(
                (itac) => itac.codigo_itac === waterTask.codigo_de_geolocalizacion
            );
            if (itac && itac.geolocalizacion) {
                waterTask.geolocalizacion = itac.geolocalizacion;
                waterTask.codigo_de_localizacion = itac.geolocalizacion;
                waterTask.url_geolocalizacion = this._utilsService.getGeolocationUrl(
                    itac.geolocalizacion
                );
                waterTask.zona = itac.zona;
            }
        }
        return waterTask;
    }

    /**
     * Assigns task location information to a WaterTask object.
     * 
     * @param waterTask - The WaterTask object to assign the location information to.
     * @param tasksLocationInfo - An array of TaskLocationInfo objects containing the location information.
     * @returns The updated WaterTask object with the assigned location information.
     */
    assignTaskLocationInfo(waterTask: WaterTask, tasksLocationInfo: TaskLocationInfo[]): WaterTask {
        if (waterTask.codigo_de_geolocalizacion) {
            const taskLocationInfo = tasksLocationInfo.find(
                (itac) => itac.codigo_de_geolocalizacion === waterTask.codigo_de_geolocalizacion
            );
            if (taskLocationInfo) {
                const coords = this._utilsService.getRightCoordinates(taskLocationInfo);
                waterTask.geolocalizacion = taskLocationInfo.geolocalizacion;
                waterTask.codigo_de_localizacion = coords;
                waterTask.url_geolocalizacion = this._utilsService.getGeolocationUrl(coords);
                waterTask.zona = taskLocationInfo.zona;
            }
        }
        return waterTask;
    }

    /**
     * Assigns water route field to a water task based on the provided routes.
     * @param waterTask - The water task to assign the route field to.
     * @param routes - The array of water routes to search for the route code.
     * @returns A promise that resolves to the updated water task.
     */
    async assignWaterRouteFieldToTask(
        waterTask: WaterTask,
        routes: WaterRoute[]
    ): Promise<WaterTask> {
        let code = waterTask.codigo_de_geolocalizacion;
        if (code) {
            if (code.includes('-')) {
                code = code.split('-')[0].trim();
                if (code && code.includes('_')) {
                    const split = code.split('_');
                    if (split.length > 1) {
                        code = split[split.length - 1].trim();
                    }
                }
            }
        }
        let route = routes.find((route) => route.codigo_ruta === code);
        if (!route) {
            route = routes.find((route) => route.municipio === waterTask.MUNICIPIO);
        }
        if (route) {
            if (!waterTask.zona) {
                waterTask.zona = route.barrio;
            }
            waterTask.ruta = route.ruta;
            waterTask.tipoRadio = route.radio_portal;
            waterTask.bloque = this._utilsService.getDayOfZona(route.barrio!);
        }
        return waterTask;
    }

    /**
     * Fulfills a water task by updating its properties and returning the modified task.
     * @param waterTask - The water task to be fulfilled.
     * @param orderSelected - The selected order for the task.
     * @param idOrderStart - The ID of the starting order.
     * @returns The modified water task after fulfillment.
     */
    fullFillTask(waterTask: WaterTask, orderSelected: string, idOrderStart: number): WaterTask {
        const timestamp = new Date();
        waterTask.date_time_modified = timestamp;
        waterTask.TIPORDEN = this._utilsService.getTaskOrderFromString(orderSelected);
        waterTask = this._utilsService.fullFillTask(waterTask, idOrderStart);
        waterTask = this._utilsService.fullFillTaskLocationWithURLGeo(waterTask);
        return waterTask;
    }

    /**
     * Imports an array of water tasks.
     * 
     * @param waterTasks - An array of WaterTask objects to import.
     * @returns A boolean indicating whether the import was successful or not.
     */
    async importTasks(waterTasks: WaterTask[]) {
        this.showLoading(true, true);
        this.loadingText = `Añadiendo tareas ...`;
        
        const startTime = new Date().getTime(); // Start time

        // let tasksErr: WaterTask[] = await this.importMultipleTasks(waterTasks);

        let i = 0, tasksErr: WaterTask[] = [];
        for (let waterTask of waterTasks) {
            this.loadingText = `Añadiendo tareas ${++i} de ${waterTasks.length}`;
            try {
            const taskId = await this._apiService.addTask(waterTask);
            if (!taskId) tasksErr.push(waterTask);
            } catch (err) { tasksErr.push(waterTask); }
        }
        
        const endTime = new Date().getTime(); // End time
        const executionTime = endTime - startTime; // Execution time in milliseconds

        this.showLoading(false, true);
        if (tasksErr.length > 0) {
            this._utilsService.openSnackBar(`Hubo errores añadiendo tareas`, 'error');
            this.exportExcel(tasksErr, getExcelExportColumns(), `Tareas_no_importadas.xlsx`, getExcelFieldNameExportation);
        }
        else this._utilsService.openSnackBar(`Ha finalizado la importación`);
        await this.getTasks();

        console.log(`Execution time: ${executionTime} milliseconds`);

        return !(tasksErr.length > 0);
    }
    
    /**
     * Imports multiple water tasks.
     * 
     * @param waterTasks - An array of WaterTask objects to import.
     * @returns An array of WaterTask objects that encountered errors during the import process.
     */
    async importMultipleTasks(waterTasks: WaterTask[]) { 
        let tasksErr: WaterTask[] = [];
        const batchSize = 50;
        const totalBatches = Math.ceil(waterTasks.length / batchSize);
        for (let batchIndex = 0; batchIndex < totalBatches; batchIndex++) {
            const startIndex = batchIndex * batchSize;
            const endIndex = Math.min(startIndex + batchSize, waterTasks.length);
            const batchTasks = waterTasks.slice(startIndex, endIndex);
            this.loadingText = `Añadiendo tareas ${startIndex + 1} - ${endIndex} de ${waterTasks.length}`;
            try {
                const response = await this._apiService.addMultipleTasks(batchTasks);
                if (response && response.length > 0) {
                    for (let i = 0; i < response.length; i++) if (response[i].error) tasksErr.push(batchTasks[i]);
                }
                else tasksErr.push(...batchTasks);
            } catch (err) {
                tasksErr.push(...batchTasks);
            }
        }
        return tasksErr;
    }
    
    /**
     * Handles the page event triggered by the pagination component.
     * @param event - The page event object containing information about the page index and page size.
     */
    async pageEvent(event: any) {
        if (this.lastPageIndex != event.pageIndex) {
            this.showLoading(true);
            localStorage.setItem('lastPageIndex', event.pageIndex.toString());
            if (this.lastPageIndex < event.pageIndex) {
                const { waterTasks } = await this._mySqlService.getNextTasksPage(event.pageIndex - this.lastPageIndex);
                this.setTasksInTable(waterTasks);
            } else {
                const { waterTasks } = await this._mySqlService.getPreviousTasksPage(this.lastPageIndex - event.pageIndex);
                this.setTasksInTable(waterTasks);
            }
            this.lastPageIndex = event.pageIndex;
        }
        if (this.pageSize != event.pageSize) {
            this.pageSize = event.pageSize;
            localStorage.setItem('waterTask_pageSize', this.pageSize.toString());

            const taskStatus = this.getStringStatusFromCurrent();
            const waterTask = await this.selectTasks(taskStatus);
            this.setTasksInTable(waterTask);
        }
        this.scrollOffset = 50;
    }

    /**
     * Logs out the user and navigates to the login page.
     */
    async logout() {
        await this._apiService.logout();
        this.router.navigate(['/login']);
    }

    /**
     * Opens the settings dialog for filter configuration.
     * 
     * @returns {Promise<void>} A promise that resolves when the settings dialog is closed.
     */
    async openSettings(): Promise<void> {
        await this._utilsService.openFilterConfigurationDialog(
            this._mySqlService.tasksTableName,
            this._utilsService.filterTasks
        );
    }

    /**
     * Asynchronously informs tasks based on user-selected order type and closing date.
     * @returns A Promise that resolves to void.
     */
    async informTasks(): Promise<void> {
        try {
            const orderSelectedString = await this._utilsService.openSelectorDialog(
                'Seleccione orden',
                ['DIARIAS', 'MASIVAS', 'ESPECIALES']
            );
            if (orderSelectedString) {
                const orderType: number =
                    this._utilsService.getTaskOrderFromString(orderSelectedString);
                const dateSelected = await this._utilsService.openDateSelectorDialog(
                    'Seleccione fecha de cierre'
                );
                if (dateSelected) {
                    this.homeDrawer?.close();
                    const dateMoment = moment(dateSelected);
                    dateMoment.set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 0,
                    });
                    this.showLoading(true, true);
                    this.loadingText = `Informando tareas ...`;

                    const startDate: Moment = dateMoment;
                    const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss.zzz');
                    let endDate: Moment = dateMoment;
                    if (!endDate) {
                        endDate = startDate;
                    }
                    const endDateString = endDate.add(1, 'days').format('YYYY-MM-DD HH:mm:ss.zzz'); //plus one to filter range
                    endDate.add(-1, 'days'); //restore date

                    const companyId = localStorage.getItem('company');
                    const managerId = localStorage.getItem('manager');

                    const where =
                        `[{"field":"FECH_CIERRE","value":"${startDateString}","field_type":"DATE","operation":">=", "date_type":"start_at"},` +
                        `{"field":"FECH_CIERRE","value":"${endDateString}","field_type":"DATE","operation":"<=","type":"AND", "date_type":"end_at"},` +
                        `{"field":"TIPORDEN","value":${orderType}, "type": "AND"},` +
                        `{"field":"status_tarea","value":${task_status.CLOSED}, "type": "AND"},` +
                        `{"field":"company","value":${companyId}, "type": "AND"},` +
                        `{"field":"GESTOR","value":${managerId},"type":"AND"}]`;

                    const waterTasks = await this._apiService.getTasks(undefined, undefined, where);

                    const size = waterTasks.length;

                    if (size > 0) {
                        let waterTask: any = {};
                        let ids: number[] = [];
                        const info = await this._apiService.getInfo();
                        let idexport = 0;

                        if (info) {
                            idexport = info.lastIDExportacion!;
                            idexport++;
                        }
                        for (let i = 0; i < size; i++) {
                            waterTasks[i].idexport = idexport;
                            if (waterTasks[i].id) ids.push(waterTasks[i].id!);
                        }

                        this.loadingText = `Informando tareas ...`;
                        waterTask['idexport'] = idexport;
                        waterTask['status_tarea'] = task_status.INFORMED;
                        // const result = await this._apiService.updateTasks(ids, waterTask, false);

                        if (true) {
                            if (info) await this.updateIdExport(info, idexport);

                            for (let i = 0; i < size; i++) {
                                waterTasks[i]['IDCENTRO' as keyof WaterTask] = 3 as any;
                            }

                            this.createInformExcel(waterTasks, idexport);
                            this._utilsService.openSnackBar('Tareas informadas correctamente');
                            this.reload();
                        } else {
                            this._utilsService.openSnackBar(
                                'Hubo errores informando tareas',
                                'error'
                            );
                        }
                    } else {
                        this._utilsService.openSnackBar(
                            `No hay tareas ${orderSelectedString.toLowerCase()} cerradas de esta fecha`,
                            'warning'
                        );
                    }
                    this.showLoading(false, true);
                }
            }
        } catch (err) {
            console.log('========= error ========');
            console.error(err);
        }
    }

    /**
     * Assigns locations to water tasks.
     * 
     * @returns A Promise that resolves when the locations are assigned.
     */
    async assignLocations(): Promise<void> {
        this.showLoading(true, true);
        let waterTasks: WaterTask[] = await this.getCurrentTasksOrSelected();
        if(await this.isMaxSizeWarning(waterTasks)) return;

        let i = 0,
            size = waterTasks.length;

        for (const waterTask of waterTasks) {
            this.loadingText = `Actualizando localizacion de tareas (${i++} de ${size})...`;
            const dir = this._utilsService.getDirOfTaskForMap(waterTask);
            const predictions = await this._apiService.searchPrediction(dir);

            if (predictions.length) {
                const prediction = predictions[0];
                const latLng = await this._apiService.getLocationWithPrediction(prediction);

                if(latLng) {
                    const googleLocation = this._utilsService.createGoogleLocation(
                        this._utilsService.getLoggedInUser()!.id,
                        waterTask.id!,
                        0,
                        dir,
                        this._utilsService.getTextOptionsWithPredictions(predictions),
                        prediction.description,
                        latLng
                    );
                    const data = {
                        url_geolocalizacion: this._utilsService.getGeolocationUrl(latLng),
                        codigo_de_localizacion: latLng,
                        geolocalizacion: latLng,
                        pendent_location: false,
                    };
                    await this._apiService.addGoogleLocation(googleLocation);
                    await this._apiService.updateTask(waterTask.id!.toString(), data, false);
                }
            }
        }
        this.showLoading(false, true);
    }

    /**
     * Exports tasks and images to Aqualia based on the selected manager.
     * If the manager has a valid Aqualia service code, it prompts the user to select an option:
     * - "Exportar tareas": exports tasks and images to Aqualia.
     * - "Ver dias exportados": shows the exported days.
     * If the manager does not have a valid Aqualia service code, it displays a warning message.
     * If no manager is selected, it displays a warning message.
     */
    async exportAqualia(): Promise<void> {
        try {
            const manager: Manager = JSON.parse(localStorage.getItem('managerJson') || '{}');
            if(manager && manager.id) {
                if(manager.aqualia_codigo_servicio) {
                    const option = await this._utilsService.openSelectorDialog('Seleccione', [
                        'Ver dias exportados',
                        'Exportar tareas',
                    ]);
                    if (option == 'Exportar tareas') {
                        if (await this.checkIfExportIsValid()) await this.exportTasksAndImagesToAqualia();
                    } else if (option == 'Ver dias exportados') await this.showExportedDays();
                }
                else this._utilsService.openSnackBar('Este gestor no tiene código de servicio', 'warning');
            }
            else this._utilsService.openSnackBar('Debe seleccionar el gestor para exportar', 'warning');
        } catch (err) {}
    }

    /**
     * Displays the last 15 exported days in an information dialog.
     * Retrieves the already uploaded days from the API service, converts them to Date objects,
     * sorts them in descending order, and displays them in the information dialog.
     * The dates are formatted in Spanish locale.
     * @returns {Promise<void>} A promise that resolves when the information dialog is closed.
     */
    async showExportedDays() {
        const alreadyUploadDays = await this._apiService.alreadyUploadDays();
        let days: Date[] = alreadyUploadDays.map((day) => moment(day, 'YYYYMMDD').toDate());
        days = days.slice(-15).sort((a, b) => b.getTime() - a.getTime());
        await this._utilsService.openInformationDialog(
            'Últimos 15 dias exportados',
            days.map((day) => moment(day).locale('es').format('ddd DD [de] MMM [del] YYYY'))
        );
    }

    /**
     * Checks if the export is valid for the selected company.
     * @returns {Promise<boolean>} A promise that resolves to true if the export is valid, false otherwise.
     */
    async checkIfExportIsValid(): Promise<boolean> {
        const company = localStorage.getItem('company');
        if (company != '12') {
            await this._utilsService.openInformationDialog('Aqualia no seleccionada', [
                'Debe seleccionar la empresa Aqualia para exportar sus tareas',
            ]);
            return false;
        }
        return true;
    }

    /**
     * Retrieves the count of either the selected rows or the tasks in the current filter.
     * If there are selected rows, it returns the count of selected rows.
     * Otherwise, it retrieves the count of tasks in the current filter.
     * @returns A promise that resolves to the count of selected rows or tasks in the current filter.
     */
    async getCurrentTasksOrSelectedCount() {
        if (this.clickedRows.size > 0) return this.clickedRows.size;
        return await this.getTasksCountInCurrentFilter();
    }
    
    /**
     * Generates a where clause string for Aqualia based on the given date string.
     * @param dateString - The date string in the format 'YYYYMMDD'.
     * @returns The generated where clause string.
     */
    getWhereClauseForAqualia(dateString: string): string { 
        const day = moment(dateString, 'YYYYMMDD').format('YYYY-MM-DD');
        const where_clause = `[{"field":"F_INST","value":"${day} 00:00:00.",`
                            + `"field_type":"DATE","operation":">=","date_type":"start_at"},`
                            + `{"field":"F_INST","value":"${day} 23:59:59.","field_type":"DATE",`
                            + `"operation":"<","date_type":"end_at","type":"AND"},`
                            + `{"field":"status_tarea","value":[
                                ${task_status.CLOSED},${task_status.INFORMED},${task_status.INCIDENCE}
                            ],"type":"AND"}]`;
        return where_clause;
    }

    /**
     * Retrieves tasks for Aqualia based on the provided date string.
     * 
     * @param dateString - The date string to filter the tasks.
     * @returns A promise that resolves to an array of WaterTask objects.
     */
    async getTasksForAqualia(dateString: string): Promise<WaterTask[]> {
        const savedOffset = this._mySqlService.last_offset;
        let waterTasks: WaterTask[] = [];
        let where_clause = this.getWhereClauseForAqualia(dateString);
        const count = await this.getTasksCountForAqualia(dateString);
        
        const order = this._utilsService.orderTasks;
        let order_clause = (order.length > 0)? this._utilsService.getOrderClauseFromOrder(order): undefined;
        let limit: number = 1000;
        for (let offset = 0; offset < count; offset += limit) {
            this.loadingText = `Obteniendo tareas (${offset} de ${count}) ...`;
            let response = await this._mySqlService.getTasks(
                undefined, 
                where_clause, 
                order_clause, 
                undefined, 
                offset.toString(), 
                limit.toString()
            );
            waterTasks = waterTasks.concat(response.waterTasks);
        }
        this._mySqlService.last_offset = savedOffset;
        return waterTasks;
    }
    
    /**
     * Retrieves the count of tasks for Aqualia based on the provided date string.
     * @param dateString - The date string to filter the tasks.
     * @returns A promise that resolves to the number of tasks.
     */
    async getTasksCountForAqualia(dateString: string): Promise<number> {
        this.showLoading(true);
        let where_clause = this.getWhereClauseForAqualia(dateString);
        const count = await this._mySqlService.getTasksCount(where_clause);
        this.showLoading(false);
        return count;
    }

    /**
     * Displays a not available message.
     * 
     * This method opens an information dialog with a message indicating that the export file is not yet available for the selected date.
     * 
     * @returns {Promise<void>} A promise that resolves when the dialog is closed.
     */
    async showNotAvailableMesage(): Promise<void> {
        await this._utilsService.openInformationDialog('No disponible', [
            'Aún se está creando el archivo de exportación para esta fecha.',
            'Intente de nuevo en unos minutos',
        ]);
    }

    /**
     * Exports tasks and images to Aqualia.
     * 
     * @returns A promise that resolves to void.
     */
    async exportTasksAndImagesToAqualia(): Promise<void> {
        try {
            const dateString = await this.selectAqualiaExportDate();
            if (dateString) {
                const available = await this._apiService.isAvailableZipCreation(dateString);
                if (available) {
                    const tasksCount = await this.getTasksCountForAqualia(dateString);
                    const res = await this._utilsService.openQuestionDialog(
                        'Confirmación',
                        `¿Seguro desea exportar las tareas (${tasksCount}) a Aqualia?`
                    );
                    if(res) await this.startUploading(dateString);
                }
                else await this.showNotAvailableMesage();
            }
        } catch (err) {}
    }

    /**
     * Selects an Aqualia export date.
     * 
     * @returns A promise that resolves to a string representing the selected date in the format 'YYYYMMDD', or an empty string if no date is selected.
     */
    async selectAqualiaExportDate(): Promise<string> {
            const alreadyUploadDays = await this._apiService.alreadyUploadDays();
            const date = await this._utilsService.openDateSelectorDialog(
                'Seleccione fecha de subida',
                this.lastSelectedDate
            );
            if (date) {
                const dateString = moment(date).format('YYYYMMDD');
                const dateTextString = moment(date).format('DD/MM/YYYY');
                if (alreadyUploadDays.includes(dateString)) {
                    const res = await this._utilsService.openQuestionDialog(
                        'Confirmación',
                        `Ya existe información del ${dateTextString}. ¿Desea remplazarla?`
                    );
                    if (res) return dateString;
                } else return dateString;
            }
        return '';
    }

    /**
     * Retrieves the current tasks or the selected tasks.
     * If any rows are clicked, it returns the selected tasks.
     * Otherwise, it retrieves the tasks based on the current filter.
     * 
     * @returns A promise that resolves to an array of WaterTask objects.
     */
    async getCurrentTasksOrSelected(): Promise<WaterTask[]> {
        if (this.clickedRows.size > 0) return Array.from(this.clickedRows.values());
        return await this.getTasksInCurrentFilter();
    }

    /**
     * Starts the uploading process by retrieving tasks for export, exporting tasks and images,
     * creating a zip file, and displaying the end uploading message.
     * 
     * @param dateString - The date string used to retrieve tasks and create the zip file.
     * @returns A promise that resolves when the uploading process is complete.
     */
    async startUploading(dateString: string): Promise<void> {
        this.showLoading(true, true);
        this.loadingText = `Obteniendo tareas para exportación ...`;
        let waterTasks: WaterTask[] = await this.getTasksForAqualia(dateString);

        await this.exportTasksForAqualia(waterTasks, dateString);
        await this.exportImagesForAqualiaSplitFiles(waterTasks, dateString);

        const res = await this._apiService.createZipFile(dateString);
        this.showLoading(false, true);
        
        await this.showEndUploadingMessage(res, waterTasks);
    }

    /**
     * Shows the end uploading message based on the result and water tasks.
     * @param res - The result of the uploading process.
     * @param waterTasks - The water tasks to be exported.
     * @returns A promise that resolves when the message is shown.
     */
    async showEndUploadingMessage(res: boolean, waterTasks: WaterTask[]): Promise<void> {
        if (res) {
            const filename = `Trabajo_Enviado_Aqualia_${moment().format('yyyy_MM_DD__HH_mm_ss')}.xlsx`;
            this.exportExcel(waterTasks, getExcelExportColumns(), filename, getExcelFieldNameExportation);
            this._utilsService.openInformationDialog('Finalizado', ['En unos minutos estará disponible la exportación para Aqualia']);
        } else {
            this._utilsService.openInformationDialog('Error', ['Hubo un fallo en la disponibilidad de la exportación para Aqualia']);
        }
    }

    /**
     * Exports water tasks for Aqualia.
     * 
     * @param waterTasks - The array of water tasks to export.
     * @param dateString - The date string to include in the exported file name.
     * @returns A promise that resolves when the export is complete.
     */
    async exportTasksForAqualia(waterTasks: WaterTask[], dateString: string): Promise<void> {
        const jsonTasks: JsonTasks = await this.getJsonTasksFromWaterTasks(waterTasks);
        await this.uploadAqualiaFile(jsonTasks, `data_${dateString}.txt`, dateString, true);
    }

    /**
     * Exports images for Aqualia split files.
     * 
     * @param waterTasks - An array of WaterTask objects.
     * @param dateString - A string representing the date.
     * @returns A Promise that resolves to void.
     */
    async exportImagesForAqualiaSplitFiles(waterTasks: WaterTask[], dateString: string): Promise<void> {
        let error: boolean = false;
        let size = waterTasks.length;
        for (const [index, waterTask] of waterTasks.entries()) {
            this.loadingText = `Subiendo fotos de tareas (${index + 1} de ${size})...`;
            const jsonImgs: JsonImage[] = await this.getJsonImagesFromWaterTask(waterTask);
            const filename = `images_${waterTask.Numero_de_ABONADO}_${dateString}.txt`;
            const res = await this.uploadAqualiaFile(jsonImgs, filename, dateString, false, false);
            if (!res) error = true;
        }
        let infoText;
        if (error) infoText = 'Han ocurrido errores subiendo los archivos de imágenes al servidor';
        else infoText = 'Se han subido los archivos de imágenes al servidor correctamente';
        this._utilsService.openInformationDialog('Finalizado', [infoText]);
    }

    /**
     * Uploads an Aqualia file.
     * 
     * @param json - The JSON data to be saved as a file.
     * @param filename - The name of the file to be saved.
     * @param dateString - The date string associated with the file.
     * @param download - A boolean indicating whether the file should be downloaded.
     * @param showNotification - A boolean indicating whether to show a notification after uploading the file. Default is true.
     * @returns A Promise that resolves to a boolean indicating whether the upload was successful.
     */
    async uploadAqualiaFile(
        json: any,
        filename: string,
        dateString: string,
        download: boolean,
        showNotification: boolean = true
    ): Promise<boolean> {
        try {
            const file = this._utilsService.saveJsonTofile(json, filename, download, 2);
            const res = await this._apiService.uploadAqualiaFile(file, dateString);
            if (showNotification) {
                if (res) {
                    this._utilsService.openSnackBar('Información subida correctamente');
                    return true;
                } else {
                    const text = 'Hubo un fallo subiendo información de la empresa';
                    this._utilsService.openInformationDialog('Error', [text]);
                    return false;
                }
            }
        } catch (err) {
            const text = 'Hubo un fallo subiendo información de la empresa';
            if (showNotification) this._utilsService.openInformationDialog('Error', [text]);
            return false;
        }
        return true;
    }

    /**
     * Retrieves an array of JSON images from a given water task.
     * @param waterTask - The water task to retrieve JSON images from.
     * @returns A promise that resolves to an array of JSON images.
     */
    async getJsonImagesFromWaterTask(waterTask: WaterTask): Promise<JsonImage[]> {
        const jsonImages: JsonImage[] = [];
        let photosRequested: FotoRequested[] = await this.getPhotosRequestedFromWaterTask(
            waterTask
        );
        for (const photoRequested of photosRequested) {
            const jsonImage: JsonImage = {
                FotoRequested: photoRequested,
                idusuario: 'MORALESM230',
                latitud: 0,
                longitud: 0,
                uid: 'INTEGRACION_MR_AGUAS',
            };
            jsonImages.push(jsonImage);
        }
        return jsonImages;
    }

    /**
     * Retrieves JSON images from water tasks.
     * @param waterTasks - An array of WaterTask objects.
     * @returns A promise that resolves to an array of JsonImage objects.
     */
    async getJsonImagesFromWaterTasks(waterTasks: WaterTask[]): Promise<JsonImage[]> {
        //!Not using this function
        const jsonImages: JsonImage[] = [];
        let i = 0,
            size = waterTasks.length;
        for (const waterTask of waterTasks) {
            this.loadingText = `Obteniendo fotos de tareas (${i++} de ${size})...`;
            let photosRequested: FotoRequested[] = await this.getPhotosRequestedFromWaterTask(
                waterTask
            );
            for (const photoRequested of photosRequested) {
                const jsonImage: JsonImage = {
                    FotoRequested: photoRequested,
                    idusuario: 'MORALESM230',
                    latitud: 0,
                    longitud: 0,
                    uid: 'INTEGRACION_MR_AGUAS',
                };
                jsonImages.push(jsonImage);
            }
        }
        return jsonImages;
    }

    /**
     * Retrieves the requested photos from a water task.
     * @param waterTask - The water task object.
     * @returns A promise that resolves to an array of requested photos.
     */
    async getPhotosRequestedFromWaterTask(waterTask: WaterTask): Promise<FotoRequested[]> {
        let photosRequested: FotoRequested[] = [];
        const photoFields: string[] = getPhotoFields();
        for (const photoField of photoFields) {
            const photoLink: string = waterTask[photoField as keyof WaterTask] as string;
            if (photoLink) {
                const photoRequested: FotoRequested | null = await this.getFotoRequestedFromLink(
                    waterTask.Numero_de_ABONADO!,
                    photoLink,
                    waterTask.codigo_de_localizacion
                );
                if (photoRequested) photosRequested.push(photoRequested);
            }
        }
        return photosRequested;
    }

    /**
     * Retrieves a requested photo from a given link.
     * 
     * @param subscriber - The subscriber associated with the photo.
     * @param link - The link to the photo.
     * @param geolocation - Optional geolocation information for the photo.
     * @returns A Promise that resolves to a FotoRequested object if the photo is found, or null otherwise.
     */
    async getFotoRequestedFromLink(
        subscriber: string,
        link: string,
        geolocation?: MyLatLng
    ): Promise<FotoRequested | null> {
        if(this._utilsService.checkIfServerImage(link)){
            const split = link.split('/');
            const filename = split[split.length - 1];
            try {
                const base64Image = await this._fileSaverService.getBase64FromUrl(link);
                if (base64Image) return this.getFotoRequested(base64Image, subscriber, filename, geolocation);
                else this._utilsService.saveTextTofile(`Not found ${link}`, 'photos_error.txt', true);
            } catch (err) {
                this._utilsService.saveTextTofile(`Not found ${link}`, 'photos_error.txt', true);
            }
        } else this._utilsService.saveTextTofile(`Not found ${link}`, 'photos_error.txt', true);
        return null;
    }

    /**
     * Creates a FotoRequested object with the provided parameters.
     * @param base64Image - The base64 representation of the image.
     * @param subscriber - The subscriber code.
     * @param filename - The name of the file.
     * @param geolocation - Optional geolocation information.
     * @returns A FotoRequested object.
     */
    getFotoRequested(base64Image: string, subscriber: string, filename: string, geolocation?: MyLatLng): FotoRequested {
        return {
            idusuario: 'MORALESM230',
            base64: base64Image,
            codigoot: parseInt(subscriber),
            nombre: filename,
            primera: false,
            ultima: false,
            adicional: false,
            latitud: geolocation?.lat || 0,
            longitud: geolocation?.lng || 0,
            forceconflict: 0,
        };
    }

    /**
     * Retrieves the JSON tasks from the given water tasks.
     * @param waterTasks - An array of water tasks.
     * @returns A promise that resolves to the JSON tasks.
     */
    async getJsonTasksFromWaterTasks(waterTasks: WaterTask[]): Promise<JsonTasks> {
        const manager: Manager = JSON.parse(localStorage.getItem('managerJson') || '{}');
        let serviceCode = 490;
        if(manager && manager.id && manager.aqualia_codigo_servicio) serviceCode = manager.aqualia_codigo_servicio;
        const ots: Ots = await this.getOtsFromWaterTasks(waterTasks);
        let jsonTasks: JsonTasks = {
            ots: ots,
            codservicios: [serviceCode],
            fsincro: new Date().toISOString(),
            idusuario: 'MORALESM230',
            uid: 'INTEGRACION_MR_AGUAS',
        };
        return jsonTasks;
    }

    /**
     * Retrieves the Ots object from an array of WaterTasks.
     * @param waterTasks - An array of WaterTasks.
     * @returns A Promise that resolves to an Ots object.
     */
    async getOtsFromWaterTasks(waterTasks: WaterTask[]): Promise<Ots> {
        let attributes: any = {};
        for (let waterTask of waterTasks) {
            if (waterTask.Numero_de_ABONADO)
                attributes[waterTask.Numero_de_ABONADO] = this.getJsonTaskFromWaterTask(waterTask);
        }
        const subscribers = waterTasks.map((w) => parseInt(w.Numero_de_ABONADO!));
        let ots: Ots = { create: [], update: subscribers, delete: [], attributes: attributes };
        return ots;
    }

    /**
     * Converts a WaterTask object to a JsonTask object.
     * @param w - The WaterTask object to convert.
     * @returns The converted JsonTask object.
     */
    getJsonTaskFromWaterTask(w: WaterTask): JsonTask {
        const jsonTaskMiddleState: JsonTaskState = {
            estado: 4,
            timestamp: w.FECEMISIO!.toISOString(),
        };
        let jsonTaskFinalState: JsonTaskState = {
            estado: 6,
            timestamp: w.F_INST!.toISOString(),
        };
        if (this.isIncidenceTask(w)) jsonTaskFinalState = { estado: 5, timestamp: w.F_INST!.toISOString() };
        let jsonTask: JsonTask = {
            estados: [jsonTaskMiddleState, jsonTaskFinalState],
            codigoot: parseInt(w.Numero_de_ABONADO!),
            asignada: 0,
            asignadapor: 0,
            estado: this.isIncidenceTask(w) ? 5 : 6,
            descestado: this.isIncidenceTask(w) ? 'INTERRUMPIDA' : 'RESUELTA',
            fecha: moment(w.F_INST).format('DD/MM/YYYY'),
            hora: moment(w.F_INST).format('HH:mm'),
            urgencia: 0,
            codtiposolucion: 1,
            descsolucion: '',
            firma: '',
            latitud: w.codigo_de_localizacion?.lat || 0,
            longitud: w.codigo_de_localizacion?.lng || 0,
            fcreacionreg: w.FechImportacion!.toISOString(),
            fmodificacionreg: w.F_INST!.toISOString(),
            forceconflict: -1,
            R_Precinto: parseInt(w.numero_precinto!),
            R_FechaPrecinto: moment(w.F_INST).format('DD/MM/YYYY'),
            CN_FechaLect: moment(w.F_INST).format('DD/MM/YYYY'),
            CN_Lectura: w.LECTURA_CONTADOR_NUEVO || 0,
            R_idMotCambCont: 23, 
            CN_NumCont: this._utilsService.isOnlyModuleInstalationTask(w)
                ? null
                : (w.seriedv) ? w.seriedv.trim(): null,
            MN_NumModulo: (w.numero_serie_modulo)? w.numero_serie_modulo.trim(): null,
            CA_FechaLect: moment(w.F_INST).format('DD/MM/YYYY'),
            CA_Lectura: w.LECT_LEV!,
        };
        if (this.isIncidenceTask(w)) jsonTask.MotivoIncidencia = w.observaciones || '';
        return jsonTask;
    }

    /**
     * Checks if a water task is an incidence task.
     * @param waterTask - The water task to check.
     * @returns A boolean indicating whether the water task is an incidence task.
     */
    isIncidenceTask(waterTask: WaterTask): boolean {
        return waterTask.incidence || waterTask.status_tarea === task_status.INCIDENCE;
    }

    /**
     * Performs the check-in services operation.
     * This method opens a date range selector dialog, retrieves water tasks based on the selected date range,
     * and generates an Excel file with the summary of services, supplies, and supplies with marks.
     * @returns {Promise<void>} A promise that resolves when the check-in services operation is completed.
     */
    async checkInServices(): Promise<void> {
        try {
            const dateRange = await this._utilsService.openDateRangeSelectorDialog(
                'Seleccione fechas'
            );
            const mapServices = new Map<string, number>();
            const mapSupplies = new Map<string, number>();
            const mapSuppliesPlusMarks = new Map<string, number>();
            if (dateRange) {
                let endDate: Moment = moment(dateRange[1]);
                if (!endDate) {
                    endDate = moment(dateRange[0]);
                }
                const waterTasks = await this._apiService.getTasks([
                    ['FECH_CIERRE', '>=', dateRange[0]],
                    ['FECH_CIERRE', '<', endDate.add(1, 'days').toDate()],
                ]);
                waterTasks.forEach((waterTask: WaterTask) => {
                    if (waterTask.servicios) {
                        waterTask.servicios.forEach((service) => {
                            const servicePlusCaliber = `${service} - ${
                                waterTask.CALIBREDV || waterTask.CALIBRE || '?'
                            }`;
                            if (mapServices.has(servicePlusCaliber)) {
                                mapServices.set(
                                    servicePlusCaliber,
                                    mapServices.get(servicePlusCaliber)! + 1
                                );
                            } else {
                                mapServices.set(servicePlusCaliber, 1);
                            }
                        });
                    }
                    if (waterTask.suministros) {
                        waterTask.suministros.forEach((supply) => {
                            const supplyPlusMark = `${supply} - ${
                                waterTask.MARCADV || waterTask.MARCA || '?'
                            }`;
                            if (mapSuppliesPlusMarks.has(supplyPlusMark)) {
                                mapSuppliesPlusMarks.set(
                                    supplyPlusMark,
                                    mapSuppliesPlusMarks.get(supplyPlusMark)! + 1
                                );
                            } else {
                                mapSuppliesPlusMarks.set(supplyPlusMark, 1);
                            }
                        });
                    }
                    if (waterTask.suministros) {
                        waterTask.suministros.forEach((supplyType) => {
                            const supply = supplyType.value;
                            if (mapSupplies.has(supply)) {
                                mapSupplies.set(supply, mapSupplies.get(supply)! + 1);
                            } else {
                                mapSupplies.set(supply, 1);
                            }
                        });
                    }
                });

                let columns: any[] = ['Servicios + calibre', 'TOTAL'];
                let rowsServices: any[] = [];
                let rowsSupplies: any[] = [];
                let rowsSuppliesPlusMarks: any[] = [];
                mapServices.forEach((value, key) => {
                    let row = {
                        'SERVICIOS + CALIBRE': key,
                        TOTAL: value,
                    };
                    rowsServices.push(row);
                });
                mapSupplies.forEach((value, key) => {
                    let row = {
                        SUMINISTROS: key,
                        TOTAL: value,
                    };
                    rowsSupplies.push(row);
                });
                mapSuppliesPlusMarks.forEach((value, key) => {
                    let row = {
                        'SUMINISTROS + MARCA': key,
                        TOTAL: value,
                    };
                    rowsSuppliesPlusMarks.push(row);
                });
                rowsServices.sort(
                    (firstRow: any, secondRow: any) => firstRow.TOTAL - secondRow.TOTAL
                );
                rowsSupplies.sort(
                    (firstRow: any, secondRow: any) => firstRow.TOTAL - secondRow.TOTAL
                );
                rowsSuppliesPlusMarks.sort(
                    (firstRow: any, secondRow: any) => firstRow.TOTAL - secondRow.TOTAL
                );

                const wsServices: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rowsServices);
                const wsSupplies: XLSX.WorkSheet = XLSX.utils.json_to_sheet(rowsSupplies);
                const wsSuppliesPlusMarks: XLSX.WorkSheet =
                    XLSX.utils.json_to_sheet(rowsSuppliesPlusMarks);
                /* generate workbook and add the worksheet */
                const wb: XLSX.WorkBook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, wsServices, 'Servicios');
                XLSX.utils.book_append_sheet(wb, wsSupplies, 'Suministros');
                XLSX.utils.book_append_sheet(wb, wsSuppliesPlusMarks, 'Suministros + Marca');
                /* save to file */
                XLSX.writeFile(wb, `Facturacion__${moment().format('DD_MM_YYYY')}.xlsx`);
            }
        } catch (err) {
            return;
        }
    }

    /**
     * Migrates task data.
     * Sends a message to the Electron service to select a folder for uploading.
     * @returns A promise that resolves when the migration is complete.
     */
    async migrateTaskData(): Promise<void> {
        this._electronService.sendMessage('select-folder-to-upload', {
            message: 'Initializing messaging',
        });
    }

    /**
     * Migrates the ITAC data.
     * Sends a message to the Electron service to select a folder for uploading ITAC data.
     * @returns A promise that resolves when the migration is complete.
     */
    async migrateItacData(): Promise<void> {
        this._electronService.sendMessage('select-folder-to-upload-itac', {
            message: 'Initializing messaging',
        });
    }

    /**
     * Composes a number expression by padding it with leading zeros.
     * 
     * @param num - The number to be converted to a string and padded.
     * @returns The number expression with leading zeros.
     */
    composeNumExp(num: number): string {
        let numreturn: string = num.toString();
        while (numreturn.length < 5) {
            numreturn = '0' + numreturn;
        }
        return numreturn;
    }

    /**
     * Creates an Excel file with the given water tasks and exports it.
     * @param waterTasks - The array of water tasks to include in the Excel file.
     * @param idexport - The ID of the export.
     * @returns void
     */
    createInformExcel(waterTasks: WaterTask[], idexport: number): void {
        const filename = 'GCT' + this.composeNumExp(idexport) + '.xlsx';
        this.exportExcel(
            waterTasks,
            getExcelExtendedExportHeaders(),
            filename,
            getExcelExtendedExportHeadersColumnName
        );
    }

    /**
     * Reads an Excel file and fixes fields based on the data in the file.
     * @param event - The event object containing the file to be processed.
     * @returns A Promise that resolves when the fields are fixed.
     */
    async getExcelFileDataToFixFields(event: any): Promise<void> {
        //! this is just for fixing fields
        this.showLoading(true, true);
        this.loadingText = `Cargando ...`;

        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const reader = new FileReader();
        const file = event['file'].target.files[0];
        reader.onload = async (_) => {
            this.homeDrawer?.toggle();
            this.showLoading(true, true);
            this.loadingText = `Obteniendo tareas ...`;
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary' });
            jsonData = workBook.SheetNames.reduce((initial: any, name) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
            }, {});
            let sheets: string[] = [];
            let arrayTasks: WaterTask[] = [];

            Object.keys(jsonData).forEach((key: string) => {
                sheets.push(key);
            });
            for (let sheet of sheets) {
                for (let jsonTask of jsonData[sheet]) {
                    let task: any = {};
                    let latitud;
                    let longitude;
                    let lastName1;
                    let lastName2;
                    Object.keys(jsonTask).forEach((key) => {
                        let field: string = getExcelFieldName(key);
                        if (this._utilsService.isFieldValid(field)) {
                            let value = jsonTask[key];
                            try {
                                if (typeof value === 'number') {
                                    if (getFieldType(field) == 'number') {
                                        task[`${field}`] = value;
                                    } else {
                                        task[`${field}`] = value.toString().trim();
                                    }
                                } else if (
                                    typeof value === 'string' &&
                                    this._utilsService.isFieldValid(value)
                                ) {
                                    if (getFieldType(field) == 'string') {
                                        task[`${field}`] = value.trim();
                                    } else {
                                        task[`${field}`] = parseInt(value);
                                    }
                                }
                            } catch (error) {}
                        } else {
                            let value = jsonTask[key];
                            if (key == 'DIRECCION' || key == 'DIRECCIÓN') {
                                if (value.split(',').length > 0) {
                                    task[`CALLE`] = value.split(',')[0].trim();
                                }
                                if (value.split(',').length > 1) {
                                    task[`NUME`] = value.split(',')[1].trim();
                                }
                                if (value.split(',').length > 2) {
                                    task[`BIS`] = value.split(',')[2].trim();
                                }
                                if (value.split(',').length > 3) {
                                    task[`PISO`] = value.split(',')[3].trim();
                                }
                                if (value.split(',').length > 4) {
                                    task[`MANO`] = value.split(',')[4].trim();
                                }
                            } else if (key.toUpperCase() == 'LATITUD' || key.toUpperCase() == 'LATITUDE') {
                                if (typeof value == 'number') {
                                    latitud = value;
                                } else if(value) {
                                    latitud = parseFloat(value.trim().replace(',', '.'));
                                }
                            } else if (key.toUpperCase() == 'LONGITUDE' || key.toUpperCase() == 'LONGITUD') {
                                if (typeof value == 'number') {
                                    longitude = value;
                                } else if(value) {
                                    longitude = parseFloat(value.trim().replace(',', '.'));
                                }
                            } else if (key == 'APELLIDO 1') {
                                lastName1 = value;
                            } else if (key == 'APELLIDO 2') {
                                lastName2 = value;
                            }
                        }
                    });
                    if (lastName1 && lastName2) {
                        if (task['NOMBRE_ABONADO']) {
                            task['NOMBRE_ABONADO'] += ` ${lastName1 || ''} ${
                                lastName2 || ''
                            }`.trim();
                        }
                    }
                    if (latitud && longitude) {
                        const point = this._utilsService.createLatLng(latitud, longitude);
                        task['geolocalizacion'] = point;
                        task['codigo_de_localizacion'] = point;
                        task['url_geolocalizacion'] = this._utilsService.getGeolocationUrl(point);
                        task['pendent_location'] = false;
                    }
                    let waterTask = task as WaterTask;

                    arrayTasks.push(waterTask);
                }
            }
            console.log('============= arrayTasks =============');
            console.log(arrayTasks);

            let i = 0;

            const filterTasks = await this.getTasksInCurrentFilter();
            for (const filterTask of filterTasks) { 
                this.loadingText = `Actualizando tareas ${++i} de ${filterTasks.length}...`;
                const excelTask = arrayTasks.find(task => task.Numero_de_ABONADO == filterTask.Numero_de_ABONADO);
                if (excelTask) {
                    if (filterTask.url_geolocalizacion == 'https://maps.google.com/?q=41,3') {
                        const data = {  //fields to fix from excel
                            geolocalizacion: excelTask.geolocalizacion,
                            codigo_de_localizacion: excelTask.codigo_de_localizacion,
                            url_geolocalizacion: excelTask.url_geolocalizacion,
                            pendent_location : excelTask.pendent_location,                       
                        };
                        await this._apiService.updateTask(filterTask.id!, data, false);
                    }
                }
            }
            // for (const waterTask of arrayTasks) { // Code to fix locations only for develop
            //     if(this._utilsService.isFieldNotValid(waterTask.url_geolocalizacion)) continue;
            //     const tasks = await this._apiService.getTasks([
            //         [
            //             'Numero_de_ABONADO',
            //             '==',
            //             waterTask['Numero_de_ABONADO'],
            //             ['company', '==', company],
            //             ['GESTOR', '==', manager],
            //         ],
            //     ]);
            //     if (tasks && tasks.length > 0) {
            //         if (tasks[0].url_geolocalizacion == 'https://maps.google.com/?q=41,3') {
            //             const data = {  //fields to fix from excel
            //                 geolocalizacion: waterTask.geolocalizacion,
            //                 codigo_de_localizacion: waterTask.codigo_de_localizacion,
            //                 url_geolocalizacion: waterTask.url_geolocalizacion,
            //                 pendent_location : waterTask.pendent_location,                       
            //             };
            //             console.log('============= tasks[0].id =============');
            //             console.log(tasks[0].id);
            //             console.log('============= tasks[0].abonado =============');
            //             console.log(tasks[0].Numero_de_ABONADO);
            //             await this._apiService.updateTask(tasks[0].id!, data, false);
            //         }
            //     }
            // }
            this.showLoading(false, true);
        };
        reader.readAsBinaryString(file);
        this.showLoading(false, true);
    }

    /**
     * Makes an asynchronous HTTP POST request.
     * @param QUERY_URL - The URL to send the POST request to.
     * @param postParams - The parameters to include in the POST request.
     * @param requestOptions - The options for the POST request.
     * @returns A Promise that resolves with the response data from the POST request.
     */
    async makeRequest(QUERY_URL: any, postParams: any, requestOptions: any): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            try {
                this.http
                    .post(QUERY_URL, postParams, requestOptions)
                    .subscribe(async (data: any) => {
                        resolve(data);
                    });
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        });
    }

    /**
     * Retrieves the migration count for a given date.
     * @param date - The date for which to retrieve the migration count.
     * @returns A Promise that resolves to the migration count.
     */
    async getMigrationCount(date: Date): Promise<any> {
        try {
            const QUERY_URL_COUNT =
                'https://mywateroute.com/Mi_Ruta/get_tareas_amount_custom_query.php';
            let dateMoment = moment(date.toISOString());
            dateMoment = dateMoment.set({ hour: 0, minute: 0, second: 0 });
            const dateString = dateMoment.format('YYYY-MM-DD HH:mm:ss');
            const postParams = new FormData();
            postParams.append('empresa', 'GECONTA');
            postParams.append(
                'query',
                `((GESTOR  LIKE  'CABB') AND (STR_TO_DATE(date_time_modified, '%Y-%m-%d %H:%i:%s') >= '${dateString}'))`
            );

            const requestOptions = {
                headers: new HttpHeaders({
                    Accept: '*/*',
                }),
                responseType: 'text' as 'text',
            };
            this.showLoading(true, true);
            this.loadingText = `Buscando cantidad de tareas ...`;

            const res = await this.makeRequest(QUERY_URL_COUNT, postParams, requestOptions);
            const jsonResult = JSON.parse(res);
            return jsonResult;
        } catch (err) {}
        this.showLoading(false, true);
        return {};
    }

    /**
     * Migrates tables by retrieving tasks from a remote server and updating them in the local database.
     * This method prompts the user for input, makes API requests, and performs data conversions.
     * @returns A Promise that resolves to void.
     */
    async migrateTables(): Promise<void> {
        try {
            const company = localStorage.getItem('company')!;
            const manager = localStorage.getItem('manager')!;

            const idStart = await this._utilsService.openInputSelectorDialog(
                'Inserte idStart',
                '0',
                'number'
            );
            console.log('============= idStart =============');
            console.log(typeof idStart);
            console.log(idStart);

            const idEnd = await this._utilsService.openInputSelectorDialog(
                'Inserte idEnd',
                '0',
                'number'
            );
            console.log('============= idEnd =============');
            console.log(typeof idEnd);
            console.log(idEnd);
            try {
                parseInt(idStart);
                parseInt(idEnd);
            } catch (err) {
                await this._utilsService.openInformationDialog('Error', [
                    'El idStart e idEnd deben ser un número entero',
                ]);
                return;
            }
            const QUERY_URL =
                'https://mywateroute.com/Mi_Ruta/get_tareas_with_limit_custom_query.php';
            const limit = '500';
            const date = await this._utilsService.openDateSelectorDialog(
                'Seleccione fecha de modificación'
            );
            let dateMoment = moment(date.toISOString());
            dateMoment = dateMoment.set({ hour: 0, minute: 0, second: 0 });
            const dateString = dateMoment.format('YYYY-MM-DD HH:mm:ss');

            const requestOptions = {
                headers: new HttpHeaders({
                    Accept: '*/*',
                }),
                responseType: 'text' as 'text',
            };
            this.showLoading(true, true);
            this.loadingText = `Buscando cantidad de tareas ...`;

            const jsonResult = await this.getMigrationCount(date);

            console.log('============= jsonResult =============');
            console.log(jsonResult);

            // const count = parseInt(jsonResult.count_tareas);

            // console.log('============= start date =============');
            // console.log(new Date());

            // let error = false;
            // const idEndInt = parseInt(idEnd);
            // for (let i = parseInt(idStart); i < count; i += 500) {
            //     const id_step = jsonResult[`id_${i == 0 ? '1' : i.toString()}`];
            //     if (idEndInt <= i) break;
            //     console.log('============= id_step =============');
            //     console.log(id_step);
            //     const postParams = new FormData();
            //     postParams.append('empresa', 'GECONTA');
            //     postParams.append(
            //         'query',
            //         `((GESTOR  LIKE  'CABB') AND (STR_TO_DATE(date_time_modified, '%Y-%m-%d %H:%i:%s') >= '2020-01-01 00:00:00'))`
            //     );
            //     postParams.append('LIMIT', '500');
            //     postParams.append('id_start', `${id_step}`);

            //     this.loadingText = `Descargando tareas ${i + 500} de ${count}...`;
            //     const data = await this.makeRequest(QUERY_URL, postParams, requestOptions);
            //     const tasks = JSON.parse(data);
            //     if (tasks) {
            //         let c = 0;
            //         for (const task of tasks) {
            //             let taskCount = i + ++c;
            //             this.loadingText = `Actualizando tareas ${taskCount} de ${count}...`;
            //             const waterTask = convertFromOldStructure(
            //                 task,
            //                 parseInt(company),
            //                 parseInt(manager)
            //             );
            //             const res = await this._apiService.addTask(waterTask);
            //             if (!res) error = true;
            //         }
            //     }
            // }
            // console.log('============= end date =============');
            // console.log(new Date());
            // if (error) console.log('=============  ended with errors =============');
            this.showLoading(false, true);
        } catch (err) {}
    }
    

    async getItacMigrationCount(date: Date): Promise<any> {
        console.log('============= getItacMigrationCount =============');
        try {
            const QUERY_URL_COUNT =
                'https://mywateroute.com/Mi_Ruta/get_itacs_amount_custom_query.php';
            let dateMoment = moment(date.toISOString());
            dateMoment = dateMoment.set({ hour: 0, minute: 0, second: 0 });
            const dateString = dateMoment.format('YYYY-MM-DD HH:mm:ss');
            const postParams = new FormData();
            postParams.append('empresa', 'GECONTA');
            postParams.append(
                'query',
                `((GESTOR  LIKE  'CABB') AND (STR_TO_DATE(date_time_modified, '%Y-%m-%d %H:%i:%s') >= '${dateString}'))`
            );

            const requestOptions = {
                headers: new HttpHeaders({
                    Accept: '*/*',
                }),
                responseType: 'text' as 'text',
            };
            this.showLoading(true, true);
            this.loadingText = `Buscando cantidad de itacs ...`;

            const res = await this.makeRequest(QUERY_URL_COUNT, postParams, requestOptions);
            console.log('============= jsonResult =============');
            console.log(res);
            const jsonResult = JSON.parse(res);
            return jsonResult;
        } catch (err) {}
        this.showLoading(false, true);
        return {};
    }

    async migrateItacTables(): Promise<void> {
        console.log('============= migrateItacTables =============');
        try {
            const company = localStorage.getItem('company')!;
            const manager = localStorage.getItem('manager')!;

            const idStart = await this._utilsService.openInputSelectorDialog(
                'Inserte idStart',
                '0',
                'number'
            );
            console.log('============= idStart =============');
            console.log(typeof idStart);
            console.log(idStart);

            const idEnd = await this._utilsService.openInputSelectorDialog(
                'Inserte idEnd',
                '0',
                'number'
            );
            console.log('============= idEnd =============');
            console.log(typeof idEnd);
            console.log(idEnd);
            try {
                parseInt(idStart);
                parseInt(idEnd);
            } catch (err) {
                await this._utilsService.openInformationDialog('Error', [
                    'El idStart e idEnd deben ser un número entero',
                ]);
                return;
            }
            const QUERY_URL =
                'https://mywateroute.com/Mi_Ruta/get_itacs_with_limit_custom_query.php';
            const limit = '500';
            const date = await this._utilsService.openDateSelectorDialog(
                'Seleccione fecha de modificación'
            );
            let dateMoment = moment(date.toISOString());
            dateMoment = dateMoment.set({ hour: 0, minute: 0, second: 0 });
            const dateString = dateMoment.format('YYYY-MM-DD HH:mm:ss');

            const requestOptions = {
                headers: new HttpHeaders({
                    Accept: '*/*',
                }),
                responseType: 'text' as 'text',
            };
            this.showLoading(true, true);
            this.loadingText = `Buscando cantidad de itacs ...`;

            const jsonResult = await this.getItacMigrationCount(date);

            console.log('============= jsonResult =============');
            console.log(jsonResult);

            const count = parseInt(jsonResult.count_itacs);

            console.log('============= count =============');
            console.log(count);

            console.log('============= start date =============');
            console.log(new Date());

            let error = false;
            const idEndInt = parseInt(idEnd);
            for (let i = parseInt(idStart); i < count; i += 500) {
                const id_step = jsonResult[`id_${i == 0 ? '1' : i.toString()}`];
                if (idEndInt <= i) break;
                console.log('============= id_step =============');
                console.log(id_step);
                const postParams = new FormData();
                postParams.append('empresa', 'GECONTA');
                postParams.append(
                    'query',
                    `((GESTOR  LIKE  'CABB') AND (STR_TO_DATE(date_time_modified, '%Y-%m-%d %H:%i:%s') >= '2019-01-01 00:00:00'))`
                );
                postParams.append('LIMIT', '500');
                postParams.append('id_start', `${id_step}`);

                this.loadingText = `Descargando itacs ${i + 500} de ${count}...`;
                const data = await this.makeRequest(QUERY_URL, postParams, requestOptions);
                const itacs = JSON.parse(data);
                console.log('============= itacs =============');
                console.log(itacs);
                if (itacs) {
                    let c = 0;
                    for (const itac of itacs) {
                        let itacCount = i + ++c;
                        this.loadingText = `Actualizando itacs ${itacCount} de ${count}...`;
                        try {
                            const it = convertFromOldItacStructure(
                                itac,
                                parseInt(company),
                                parseInt(manager)
                            );
                            const res = await this._apiService.addItac(it);
                            if (!res) error = true;
                        } catch (err) {
                            console.log('============= err =============');
                            console.log(err);
                        }
                    }
                }
            }
            console.log('============= end date =============');
            console.log(new Date());
            if (error) console.log('=============  ended with errors =============');
            this.showLoading(false, true);
        } catch (err) {}
    }
}
