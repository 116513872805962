/**
 * Created by Ing. Luis Alejandro Reyes Morales on 01/04/2021.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Injectable } from '@angular/core';
import {
    FilterField,
    MiRutaFilter,
    FilterNode,
    SearchParamBetween,
    SearchParam,
    SearchParamBetweenJoin,
} from '../interfaces/mi-ruta-filter';
import {
    getFieldName,
    getFormControls,
    priority_status,
    WaterTask,
    task_status,
    order_status,
    defaultTaskType,
    defaultTaskIntervention,
    defaultTaskOrderAction,
    defaultTaskToDo,
    defaultAnomaly,
    getFieldType,
    taskStatusFromString,
    getPhotoFields,
    myLatLng,
    myLatLngFromString,
    priorityFromString 
} from '../interfaces/water-task';
import {
    MatSnackBar,
    MatSnackBarHorizontalPosition,
    MatSnackBarVerticalPosition,
} from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { QuestionDialogComponent } from '../components/share/question-dialog/question-dialog.component';
import { SelectorComponent } from '../components/share/selector/selector.component';
import { Company } from '../interfaces/company';
import { Manager } from '../interfaces/manager';
import { DateRangeSelectorComponent } from '../components/share/date-range-selector/date-range-selector.component';
import { TimeRangeSelectorComponent } from '../components/share/time-range-selector/time-range-selector.component';
import { TimeSelectorComponent } from '../components/share/time-selector/time-selector.component';
import { DateSelectorComponent } from '../components/share/date-selector/date-selector.component';
import { WhatsappMessageComponent } from '../components/share/whatsapp-message/whatsapp-message.component';
import { CountryCode } from '../interfaces/country-code';
import { ServicesInformComponent } from '../components/share/services-inform/services-inform.component';
import { AbstractControl, FormGroup } from '@angular/forms';
import { getItacFieldName, getItacFormControls, Itac, getItacPhotoFields } from '../interfaces/itac';
import { Section1Component } from '../components/itac-sections/section1/section1.component';
import { Section2Component } from '../components/itac-sections/section2/section2.component';
import { Section3Component } from '../components/itac-sections/section3/section3.component';
import { Section4Component } from '../components/itac-sections/section4/section4.component';
import { Section5Component } from '../components/itac-sections/section5/section5.component';
import { FilterComponent } from '../components/share/filter/filter.component';
import {
    getCounterFieldName,
    Counter,
    getCounterFormControls,
    counter_status,
} from '../interfaces/counter';
import {
    getWaterRouteFieldName,
    getWaterRouteFormControls,
    WaterRoute,
} from '../interfaces/water-route';
import { CounterComponent } from '../components/counter/counter.component';
import { WaterRouteComponent } from '../components/water-route/water-route.component';
import { DatePipe } from '@angular/common';
import { CaliberComponent } from '../components/tables/caliber/caliber.component';
import { Caliber } from '../interfaces/caliber';
import { Cause } from '../interfaces/cause';
import { CauseComponent } from '../components/tables/cause/cause.component';
import { ClassCounterComponent } from '../components/tables/class-counter/class-counter.component';
import { ClassCounter } from '../interfaces/class-counter';
import { Emplacement } from '../interfaces/emplacement';
import { EmplacementComponent } from '../components/tables/emplacement/emplacement.component';
import { LongitudeComponent } from '../components/tables/longitude/longitude.component';
import { Longitude } from '../interfaces/longitude';
import { MarkComponent } from '../components/tables/mark/mark.component';
import { Mark } from '../interfaces/mark';
import { ObservationComponent } from '../components/tables/observation/observation.component';
import { Observation } from '../interfaces/observation';
import { PartComponent } from '../components/tables/part/part.component';
import { Part } from '../interfaces/part';
import { Result } from '../interfaces/result';
import { ResultComponent } from '../components/tables/result/result.component';
import { TypeCounter } from '../interfaces/type-counter';
import { TypeCounterComponent } from '../components/tables/type-counter/type-counter.component';
import { HelpComponent } from '../components/share/help/help.component';
import * as moment from 'moment';
import { Moment } from 'moment';
import { ZoneComponent } from '../components/tables/zone/zone.component';
import { Zone } from '../interfaces/zone';
import { CompanyComponent } from '../components/tables/company/company.component';
import { ManagerComponent } from '../components/tables/manager/manager.component';
import { InfoComponent } from '../components/tables/info/info.component';
import { Info } from '../interfaces/info';
import { TeamComponent } from '../components/tables/team/team.component';
import { Team } from '../interfaces/team';
import { MiRutaUser } from '../interfaces/mi-ruta-user';
import { UserComponent } from '../components/user/user.component';
import { MultipleSelectorComponent } from '../components/share/multiple-selector/multiple-selector.component';
import { InputSelectorComponent } from '../components/share/input-selector/input-selector.component';
import { InformationDialogComponent } from '../components/share/information-dialog/information-dialog.component';
import { FilterConfigurationComponent } from '../components/share/filter-configuration/filter-configuration.component';
import { TaskAssignComponent } from '../components/share/task-assign/task-assign.component';
import { PartsSelectionComponent } from '../components/share/parts-selection/parts-selection.component';
import * as JsBarcode from 'jsbarcode';
import { BarcodeDialogComponent } from '../components/share/barcode-dialog/barcode-dialog.component';
import { CounterAssignComponent } from '../components/share/counter-assign/counter-assign.component';
import { MySqlService } from './mysql.service';
import { PhoneStatusAnswers, PhoneStatusComponent } from '../components/share/phone-status/phone-status.component';
import { MyLatLng } from '../interfaces/lat-lng';
import { DateTimeSelectorComponent } from '../components/share/date-time-selector/date-time-selector.component';
import { ItacAssignComponent } from '../components/share/itac-assign/itac-assign.component';
import { EditTextListComponent } from '../components/share/edit-text-list/edit-text-list.component';
import { environment } from '../../environments/environment';
import { FillCounterComponent } from '../components/share/fill-counter/fill-counter.component';
import { SerialNumberOptionSelectorComponent } from '../components/share/serial-number-option-selector/serial-number-option-selector.component';
import { TypeRadius } from '../interfaces/type-radius';
import { TypeRadiusComponent } from '../components/tables/type-radius/type-radius.component';
import { UserFilterComponent } from '../components/share/user-filter/user-filter.component';
import { Agrupation } from '../interfaces/agrupation';
import { AgrupationComponent } from '../components/tables/agrupation/agrupation.component';
import { ActivationLog, getActivationLogFieldName } from 'src/app/interfaces/activation-log';
import { ActivationLogComponent } from '../components/tables/activation-log/activation-log.component';
import { IpcService } from 'src/app/services/ipc.service';
import { DataDialogComponent } from '../components/share/data-dialog/data-dialog.component';
import * as saveAs from 'file-saver';
import { GoogleTextOption } from '../interfaces/google-text-option';
import { GoogleLocation } from '../interfaces/google-location';
import { Prediction } from '../interfaces/place-predictions';
import { AssignZoneLocationComponent } from '../components/share/assign-zone-location/assign-zone-location.component';
import { RadiusModule, getRadiusModuleFieldName } from '../interfaces/radius-module';
import { RadiusModuleComponent } from '../components/tables/radius-module/radius-module.component';
import { IntegrationItelazpi, getIntegrationItelazpiFieldName } from '../interfaces/integration-itelazpi';
import { IntegrationItelazpiComponent } from '../components/tables/integration-itelazpi/integration-itelazpi.component';
import { CommonServicesInformComponent } from '../components/share/common-services-inform/common-services-inform.component';
import { NotificationCardComponent } from '../components/share/notification-card/notification-card.component';
import { SnackNotificationData } from '../interfaces/snack-notification-data';
import { MessagingService } from './messaging.service';
import { ClientPhoneStatus } from '../interfaces/client-phone-status';
import { TaskLocationInfo } from '../interfaces/task-location-info';
import { getSideFieldName } from '../interfaces/side';
import { DateStatus } from '../interfaces/date-status';
import { WaterTaskUpdate } from '../interfaces/water-task-update';
import * as XLSX from 'xlsx';
import { UserActivityComponent } from '../components/share/statistics/user-activity/user-activity.component';
import { PlanningComponent } from '../components/tables/planning/planning.component';
import { Planning } from '../interfaces/planning';
import { ManagerMessageComponent } from '../components/tables/manager-message/manager-message.component';
import { ManagerMessage } from '../interfaces/manager-message';
import { Sector } from '../interfaces/sector';
import { LoraResponse } from '../interfaces/lora-response';
import { ActivationData } from '../interfaces/activation-data';
import { PlanningDetail } from '../interfaces/planning-detail';
import { PlanningDetailComponent } from '../components/tables/planning-detail/planning-detail.component';
import { UserColumnsOrder } from '../interfaces/user-columns-order';
import { PlanningDetailExtraComponent } from '../components/tables/planning-detail-extra/planning-detail-extra.component';
import { PlanningDetailExtra } from '../interfaces/planning-detail-extra';
import { InformationJsonDialogComponent } from '../components/share/information-json-dialog/information-json-dialog.component';

var nodeBase64 = require('nodejs-base64-converter');

@Injectable({
    providedIn: 'root',
})
/**
 * Utility service for common functions.
 */
export class UtilsService {
    app_version: string = '2.5.40';
    
    private notificationQueue: SnackNotificationData[] = [];
    private isNotificationOpen = false;

    serialNumberOptionSelectorDialogRef!: MatDialogRef<SerialNumberOptionSelectorComponent, any>;
    fillCounterDialogRef!: MatDialogRef<FillCounterComponent, any>;
    editTextListRef!: MatDialogRef<EditTextListComponent, any>;
    notificationDialogRef!: MatDialogRef<NotificationCardComponent, any>;
    mapDialogRef!: MatDialogRef<AssignZoneLocationComponent, any>;
    filterDialogRef!: MatDialogRef<FilterComponent, any>;
    userFilterDialogRef!: MatDialogRef<UserFilterComponent, any>;
    filterConfigurationDialogRef!: MatDialogRef<FilterConfigurationComponent, any>;
    userActivityRef!: MatDialogRef<UserActivityComponent, any>;
    dialogRef!: MatDialogRef<SelectorComponent, any>;
    inputSelectorDialogRef!: MatDialogRef<InputSelectorComponent, any>;
    multipleOptionsDialogRef!: MatDialogRef<MultipleSelectorComponent, any>;
    dateRangeSelectorDialogRef!: MatDialogRef<DateRangeSelectorComponent, any>;
    dateSelectorDialogRef!: MatDialogRef<DateSelectorComponent, any>;
    timeRangeSelectorDialogRef!: MatDialogRef<TimeRangeSelectorComponent, any>;
    dateTimeRangeSelectorDialogRef!: MatDialogRef<DateTimeSelectorComponent, any>;
    timeSelectorDialogRef!: MatDialogRef<TimeSelectorComponent, any>;
    whatsappMessageDialogRef!: MatDialogRef<WhatsappMessageComponent, any>;
    counterDialogRef!: MatDialogRef<CounterComponent, any>;
    counterAssignDialogRef!: MatDialogRef<CounterAssignComponent, any>;
    itacAssignDialogRef!: MatDialogRef<ItacAssignComponent, any>;
    waterRouteDialogRef!: MatDialogRef<WaterRouteComponent, any>;
    radiusModuleDialogRef!: MatDialogRef<RadiusModuleComponent, any>;
    caliberDialogRef!: MatDialogRef<CaliberComponent, any>;
    causeDialogRef!: MatDialogRef<CauseComponent, any>;
    classCounterDialogRef!: MatDialogRef<ClassCounterComponent, any>;
    emplacementDialogRef!: MatDialogRef<EmplacementComponent, any>;
    longitudeDialogRef!: MatDialogRef<LongitudeComponent, any>;
    markDialogRef!: MatDialogRef<MarkComponent, any>;
    observationDialogRef!: MatDialogRef<ObservationComponent, any>;
    partDialogRef!: MatDialogRef<PartComponent, any>;
    planningDialogRef!: MatDialogRef<PlanningComponent, any>;
    planningDetailDialogRef!: MatDialogRef<PlanningDetailComponent, any>;
    planningDetailExtraDialogRef!: MatDialogRef<PlanningDetailExtraComponent, any>;
    managerMessageDialogRef!: MatDialogRef<ManagerMessageComponent, any>;
    resultDialogRef!: MatDialogRef<ResultComponent, any>;
    typeRadiusDialogRef!: MatDialogRef<TypeRadiusComponent, any>;
    typeCounterDialogRef!: MatDialogRef<TypeCounterComponent, any>;
    zoneDialogRef!: MatDialogRef<ZoneComponent, any>;
    companyDialogRef!: MatDialogRef<CompanyComponent, any>;
    activationLogDialogRef!: MatDialogRef<ActivationLogComponent, any>;
    integrationItelazpiDialogRef!: MatDialogRef<IntegrationItelazpiComponent, any>;
    managerDialogRef!: MatDialogRef<ManagerComponent, any>;
    agrupationDialogRef!: MatDialogRef<AgrupationComponent, any>;
    infoDialogRef!: MatDialogRef<InfoComponent, any>;
    teamDialogRef!: MatDialogRef<TeamComponent, any>;
    miRutaUserDialogRef!: MatDialogRef<UserComponent, any>;

    informationDialogRef!: MatDialogRef<InformationDialogComponent, any>;
    informationDialogJsonRef!: MatDialogRef<InformationJsonDialogComponent, any>;
    barcodeDialogRef!: MatDialogRef<BarcodeDialogComponent, any>;

    taskAssignDialogRef!: MatDialogRef<TaskAssignComponent, any>;

    partsSelectionDialogRef!: MatDialogRef<PartsSelectionComponent, any>;
    phoneStatusDialogRef!: MatDialogRef<PhoneStatusComponent, any>;

    helpDialogRef!: MatDialogRef<HelpComponent, any>;

    commonServicesInformDialogRef!: MatDialogRef<CommonServicesInformComponent, any>;
    
    servicesInformDialogRef!: MatDialogRef<ServicesInformComponent, any>;

    section1DialogRef!: MatDialogRef<Section1Component, any>;
    section2DialogRef!: MatDialogRef<Section2Component, any>;
    section3DialogRef!: MatDialogRef<Section3Component, any>;
    section4DialogRef!: MatDialogRef<Section4Component, any>;
    section5DialogRef!: MatDialogRef<Section5Component, any>;

    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';

    resposeError: boolean = false;

    filterTasks?: MiRutaFilter = {};
    filterSides?: MiRutaFilter = {};
    filterItacs?: MiRutaFilter = {};
    filterCounters?: MiRutaFilter = {};
    filterWaterRoutes?: MiRutaFilter = {};
    filterActivationLogs?: MiRutaFilter = {};
    filterIntegrationItelazpis?: MiRutaFilter = {};
    filterRadiusModules?: MiRutaFilter = {};

    orderIntegrationItelazpis: any[] = [];
    orderActivationLogs: any[] = [];
    orderTasks: any[] = [];
    orderItacs: any[] = [];
    orderSides: any[] = [];
    orderCounters: any[] = [];
    orderWaterRoutes: any[] = [];
    orderRadiusModules: any[] = [];

    private _itacSelected?: Itac;
    private _counterSelected?: Counter;
    // private _routeSelected?: WaterRoute;

    private _causes: Cause[] = [];
    private _planningDetails: PlanningDetail[] = [];
    private _planningDetailExtras: PlanningDetailExtra[] = [];
    private _results: Result[] = [];
    private _plannings: Planning[] = [];
    private _marks: Mark[] = [];
    private _emplacements: Emplacement[] = [];
    private _typesRadius: TypeRadius[] = [];
    private _sectors: Sector[] = [];
    private _dateStatus: DateStatus[] = [];
    private _typesCounters: TypeCounter[] = [];
    private _zones: Zone[] = [];
    private _agrupations: Agrupation[] = [];
    private _managers: Manager[] = [];
    private _companies: Company[] = [];
    private _teams: Team[] = [];
    private _users: MiRutaUser[] = [];

    /**
     * Utility service for common functions.
     */
    constructor(
        public datepipe: DatePipe,
        private _snackBar: MatSnackBar,
        private _mySqlService: MySqlService,
        public filterDialog: MatDialog,
        public dialog: MatDialog,
        public questionDialog: MatDialog,
        public notificationDialog: MatDialog,
        public _messagingService: MessagingService,
    ) {}

    /**
     * Opens the notifications.
     */
    openNotifications() {
        this._messagingService.sendMessage('Open notifications');
    }

    
    /**
     * Checks if the given filter is empty.
     * @param filter - The filter to check.
     * @returns A boolean indicating whether the filter is empty or not.
     */
    checkIfFilterIsEmpty(filter: MiRutaFilter | undefined): boolean{
        return !this.checkIfFilterNotEmpty(filter);
    }

    /**
     * Checks if the filter is not empty.
     * @param filter - The filter to check.
     * @returns `true` if the filter is not empty, `false` otherwise.
     */
    checkIfFilterNotEmpty(filter: MiRutaFilter | undefined): boolean {
        if(!filter || !filter.fields || !filter.fields.length) {
            return false;
        }
        return true;
    }

    /**
     * Selects a date range and returns the start and end dates.
     * @param show_date_status - A boolean indicating whether to show the date status.
     * @param currentDateTask - An optional parameter representing the current water task.
     * @returns A Promise that resolves to an object containing the start and end dates.
     */
    async selectDateRange(show_date_status: boolean = false, currentDateTask?: WaterTask, is_date_range?: boolean): Promise<any> {
        const data: any[] = await this.openDateTimeRangeSelectorDialog(
            'Seleccione fecha de cita',
            'Hora inicial',
            'Hora final',
            show_date_status,
            currentDateTask,
            is_date_range
        );
        const startDate: Moment = moment(data[0]);
        startDate.set({
            hour: data[0].getHours(),
            minute: data[0].getMinutes(),
            second: 0,
            millisecond: 0,
        });
        const endDate: Moment = moment(data[1]);
        endDate.set({
            hour: data[1].getHours(),
            minute: data[1].getMinutes(),
            second: 0,
            millisecond: 0,
        });
        if(show_date_status) {
            const dateStatus = data[2];
            return { startDate, endDate, dateStatus };
        } 
        return { startDate, endDate };
    }
    
    /**
     * Returns a formatted string representing the start and end date.
     * @param startDate - The start date.
     * @param endDate - The end date.
     * @returns A formatted string representing the start and end date.
     */
    getNewDateString(startDate: Moment, endDate: Moment){
        moment.locale('es');
        const startDateString = startDate
            .locale('es')
            .format('dddd, D [de] MMMM [del] YYYY, [Entre las] HH:mm');
        const endDateString = endDate.locale('es').format(' [y las] HH:mm');
        return `${startDateString} ${endDateString}`;
    }
    
    /**
     * Validates the value of a form control and removes any invalid characters.
     * If any invalid characters are found, the value will be corrected automatically.
     * 
     * @param control - The form control to validate.
     * @returns A boolean indicating whether the value was corrected or not.
     */
    validateValue(control: AbstractControl): boolean {
        const value = this.removeInvalidPhotoUrlCharacters(control.value);
        if(value !== control.value){
            control.setValue(value);
            const m = 'Este campo no puede tener caracteres inválidos, se correjirá automáticamente';
            this.openSnackBar(m, 'warning');
            return true;
        }
        return false;
    }
    
    /**
     * Validates a string value for JSON by removing any invalid characters.
     * If any invalid characters are found, the method corrects the value automatically.
     * 
     * @param control - The AbstractControl representing the value to be validated.
     * @returns A boolean indicating whether the value was corrected or not.
     */
    validateStringForJsonValue(control: AbstractControl): boolean {
        const value = this.removeInvalidJsonCharacters(control.value);
        if(value !== control.value){
            control.setValue(value);
            const m = 'El filtro no puede tener caracteres inválidos, se correjirá automáticamente';
            this.openSnackBar(m, 'warning');
            return true;
        }
        return false;
    }

    /**
     * Retrieves an array of tabs.
     * 
     * @returns An array of strings representing the tabs.
     */
    getTabs(): string[] {
        return [ 'Todas', 'Abiertas', 'Ausentes', 'Citas', 'Ejecutadas', 
            'Cerradas', 'Informadas', 'Incidencias', 'Requeridas', 'Trastero',
        ];
    }

    /**
     * Retrieves the WHERE clause string for filtering tasks.
     * @returns The WHERE clause string.
     */
    getTasksFilterWhereString() {
        let where_clause = '';
        let filter = this.getTasksFilter();
        if (filter && filter.fields) {
            where_clause = this.getWhereClauseFromFilter(filter);
        }
        return where_clause;
    }

    /**
     * Retrieves the WHERE clause string based on the sides filter.
     * @returns The WHERE clause string.
     */
    getSidesFilterWhereString() {
        let where_clause = '';
        let filter = this.getSidesFilter();
        if (filter && filter.fields) {
            where_clause = this.getWhereClauseFromFilter(filter);
        }
        return where_clause;
    }

    /**
     * Gets the WHERE clause string based on the filter conditions.
     * @returns The WHERE clause string.
     */
    getCountersFilterWhereString() {
        let where_clause = '';
        let filter = this.getCountersFilter();
        if (filter && filter.fields) {
            where_clause = this.getWhereClauseFromFilter(filter);
        }
        return where_clause;
    }

    /**
     * Retrieves the tasks filter based on the logged-in user's permissions.
     * If the user is a client user, the filter is modified based on the user's permissions.
     * @returns The tasks filter object.
     */
    getTasksFilter() {
        let filter = Object.assign(this.filterTasks || {});

        if (this.isClientUser()) {
            const user = this.getLoggedInUser();
            if (user && user.permisos) {
                const userFilter: MiRutaFilter = JSON.parse(user.permisos);
                if (userFilter) {
                    if (userFilter.Or_field) {
                        if (filter.Or_field) {
                            filter.Or_field = [...filter.Or_field, ...userFilter.Or_field];
                        } else filter.Or_field = userFilter.Or_field;
                    }
                    if (userFilter.And_field) {
                        if (filter.And_field) {
                            filter.And_field = [...filter.And_field, ...userFilter.And_field];
                        } else filter.And_field = userFilter.And_field;
                    }
                    if (userFilter.fields) {
                        if (filter.fields) {
                            filter.fields = [...filter.fields, ...userFilter.fields];
                        } else filter.fields = userFilter.fields;
                    }
                }
            }
        }
        return filter;
    }

    /**
     * Retrieves the sides filter.
     * 
     * @returns The sides filter object.
     */
    getSidesFilter() {
        let filter = Object.assign(this.filterSides || {});
        return filter;
    }

    /**
     * Retrieves the counters filter.
     * 
     * @returns The counters filter object.
     */
    getCountersFilter() {
        let filter = Object.assign(this.filterCounters || {});
        return filter;
    }

    /**
     * Compares two arrays of strings to check if they have the same elements.
     * 
     * @param columns1 - The first array of strings to compare.
     * @param columns2 - The second array of strings to compare.
     * @returns `true` if the arrays have the same elements, `false` otherwise.
     */
    compareColumns(columns1: string[], columns2: string[]): boolean {
        if(columns1.length != columns2.length) return false;
        const columns = columns1.filter(column => !columns2.includes(column));
        if(columns.length) return false;
        return true;
    }

    /**
     * Returns the difference between two arrays of columns.
     * @param classDisplayColumns - The array of columns from the class.
     * @param userDisplayColumns - The array of columns from the user.
     * @returns The columns that are present in `classDisplayColumns` but not in `userDisplayColumns`.
     */
    getColumnsDifference(classDisplayColumns: string[], userDisplayColumns: string[]): string[] {
        return classDisplayColumns.filter(column => !userDisplayColumns.includes(column));
    }

    /**
     * Removes the columns from `userDisplayColumns` that are not present in `classDisplayColumns`.
     * @param classDisplayColumns - The array of columns to compare against.
     * @param userDisplayColumns - The array of columns to filter.
     * @returns The filtered array of columns.
     */
    removeNotExistingColumns(classDisplayColumns: string[], userDisplayColumns: string[]): string[] {
        return userDisplayColumns.filter((column: string) => classDisplayColumns.includes(column));
    }

    /**
     * Sets the display columns for a table.
     * 
     * @param classDisplayColumns - The array of display columns to be set.
     * @param tableName - The name of the table.
     * @param displayFunction - The function that returns the default display columns.
     * @returns The updated array of display columns.
     */
    
    setDisplayColumns(classDisplayColumns: string[], tableName: string, displayFunction: Function): string[] {
        classDisplayColumns = displayFunction();
        let displayedColumns = localStorage.getItem('displayedColumns_' + tableName);
        const miRutaUser = this.getLoggedInUser();
        if (miRutaUser) {
            const field_name: string = `columns_${tableName}`;
            const columns: any | string = miRutaUser.user_columns_order[field_name as keyof UserColumnsOrder];
            if (columns) {
                try {
                    let userDisplayColumns = JSON.parse(columns);
                    if (this.compareColumns(classDisplayColumns, userDisplayColumns)) {
                        classDisplayColumns = userDisplayColumns;
                    }
                    else {
                        const difference = this.getColumnsDifference(classDisplayColumns, userDisplayColumns);
                        userDisplayColumns = this.removeNotExistingColumns(classDisplayColumns, userDisplayColumns);
                        classDisplayColumns = [...userDisplayColumns, ...difference];
                    }
                    localStorage.setItem('displayedColumns_' + tableName, JSON.stringify(classDisplayColumns));
                } catch (err) {}
            }
        }
        return this.setColumns(classDisplayColumns, displayedColumns, tableName, displayFunction);
    }

    /**
     * Sets the columns for a table.
     * 
     * @param classDisplayColumns - The array of class display columns.
     * @param displayedColumns - The displayed columns as a JSON string or null.
     * @param tableName - The name of the table.
     * @param displayFunction - The function to generate the display columns if they are not valid.
     * @returns The updated array of class display columns.
     */
    setColumns(classDisplayColumns: string[], displayedColumns: string | null, tableName: string, displayFunction: Function) {
        if (displayedColumns) {
            let columns;
            try {
                columns = JSON.parse(displayedColumns);
            } catch (err) {}
            if (this.compareColumns(classDisplayColumns, columns)) {
                try {
                    if (Array.isArray(columns)) classDisplayColumns = columns;
                    else classDisplayColumns = displayFunction();
                    return classDisplayColumns;
                } catch (err) {
                    classDisplayColumns = displayFunction();
                    return classDisplayColumns;
                }
            }
        }
        localStorage.setItem('displayedColumns_' + tableName, JSON.stringify(classDisplayColumns));
        return classDisplayColumns;
    }

    /**
     * Encodes a string value to Base64.
     * 
     * @param value - The string value to encode.
     * @returns The Base64 encoded string.
     */
    encodeBase64(value: string) {
        return nodeBase64.encode(value);
    }

    /**
     * Decodes a base64 encoded string.
     * 
     * @param value - The base64 encoded string to decode.
     * @returns The decoded string.
     */
    decodeBase64(value: string) {
        return nodeBase64.decode(value);
    }

    /**
     * Determines if highlighting is needed for phone1 in a given WaterTask.
     * @param task - The WaterTask object.
     * @returns A boolean indicating whether highlighting is needed for phone1.
     */
    isNeededHighlightPhone1(task: WaterTask): boolean {
        return this.isRightPhone1(task);
    }

    /**
     * Determines if the phone number 2 of a given water task needs to be highlighted.
     * 
     * @param task - The water task object.
     * @returns A boolean value indicating whether the phone number 2 needs to be highlighted.
     */
    isNeededHighlightPhone2(task: WaterTask): boolean {
        return this.isRightPhone2(task);
    }

    /**
     * Checks if the given string is contained in any of the client phone statuses of a WaterTask.
     * @param task - The WaterTask object to check.
     * @param str - The string to search for in the client phone statuses.
     * @returns True if the string is found in any of the client phone statuses, false otherwise.
     */
    containsPhoneStatus(task: WaterTask, str: string): boolean {
        if(task && task.telefonos_cliente){
            const statuses: ClientPhoneStatus[] = task.telefonos_cliente;
            for(const status of statuses) {
                if(status.value && status.value.includes(str)){
                    return true;
                }
            }
        }
        return false;
    }

    /**
     * Checks if the phone number of a given WaterTask is correct.
     * 
     * @param task - The WaterTask to check.
     * @returns A boolean indicating whether the phone number is correct or not.
     */
    isRightPhone1(task: WaterTask): boolean {
        if (this.containsPhoneStatus(task, 'TEL1 Nº correcto')) return true;
        return false;
    }

    /**
     * Checks if the phone number of a given WaterTask is marked as 'TEL1 Nº incorrecto'.
     * 
     * @param task - The WaterTask object to check.
     * @returns True if the phone number is marked as 'TEL1 Nº incorrecto', false otherwise.
     */
    isWrongPhone1(task: WaterTask): boolean {
        if (this.containsPhoneStatus(task, 'TEL1 Nº incorrecto')) return true;
        return false;
    }

    /**
     * Checks if the phone number in the given WaterTask is correct.
     * 
     * @param task - The WaterTask object to check.
     * @returns A boolean indicating whether the phone number is correct.
     */
    isRightPhone2(task: WaterTask): boolean {
        if (this.containsPhoneStatus(task, 'TEL2 Nº correcto')) return true;
        return false;
    }

    /**
     * Checks if the given task has a wrong phone number 2 status.
     * @param task - The WaterTask object to check.
     * @returns True if the task has a 'TEL2 Nº incorrecto' phone status, false otherwise.
     */
    isWrongPhone2(task: WaterTask): boolean {
        if (this.containsPhoneStatus(task, 'TEL2 Nº incorrecto')) return true;
        return false;
    }
        
    /**
     * Sets the field data in the given JSON object based on the specified key and field.
     * 
     * @param {any} json - The JSON object.
     * @param {any} data - The data object where the field value will be set.
     * @param {string} key - The key to access the value in the JSON object.
     * @param {any} field - The field name in the data object where the value will be set.
     * @param {Function} fieldTypeFunction - The function to convert the value to the desired field type.
     */
    setJsonFieldData(json: any, data: any, key: string, field: any, fieldTypeFunction: Function){
        let value = json[key];
        try {
            if (typeof value === 'number') {
                data[`${field}`] = this.getFieldValueFromNumber(field, value, fieldTypeFunction);
            } else if (typeof value === 'string' && this.isFieldValid(value)) {
                data[`${field}`] = this.getFieldValueFromString(field, value, fieldTypeFunction);
            }
        } catch (error) {}
    }

    /**
     * Converts a string value to the specified field type based on the provided fieldTypeFunction.
     * @param field - The field name.
     * @param value - The string value to be converted.
     * @param fieldTypeFunction - A function that returns the field type based on the field name.
     * @returns The converted value based on the field type.
     */
    getFieldValueFromString(field: string, value: string, fieldTypeFunction: Function) {
        if (fieldTypeFunction(field) == 'number') return parseInt(value);
        else if (fieldTypeFunction(field) == 'string') return value.trim();
        else if (fieldTypeFunction(field) == 'boolean') return value.trim().toUpperCase() == 'SI' ? true : false;
        else if (fieldTypeFunction(field) == 'Date') return new Date(value);
        else return value;
    }

    /**
     * Retrieves the field value based on the provided number value and field type.
     * 
     * @param field - The field name.
     * @param value - The number value.
     * @param fieldTypeFunction - The function that determines the field type.
     * @returns The field value based on the provided number value and field type.
     */
    getFieldValueFromNumber(field: string, value: number, fieldTypeFunction: Function) {
        if (fieldTypeFunction(field) == 'number') return value;
        else if (fieldTypeFunction(field) == 'string') return value.toString().trim();
        else if (fieldTypeFunction(field) == 'boolean') return value ? true : false;
        else if (fieldTypeFunction(field) == 'Date') return new Date(value);        
        else return value;
    }

    /**
     * Processes the value based on the given key and returns the processed value.
     * @param key - The key to determine the processing logic.
     * @param value - The value to be processed.
     * @returns The processed value.
     */
    getProcessedValue(key: string, value: any) {
        try {
            if (key.toLowerCase() === 'operario') {
            } else if (key.toLowerCase() === 'date_status') {
            } else if (Array.isArray(value) && value.length > 0) {
                let values = [];
                for (const v of value) {
                    if (typeof v == 'string') values.push({ value: v }); //for structs with string values (services, supplies)
                    else if(v && v.value && this.isValidDate(v.value)) values.push(v); //for structs with date values (fechas_tocado, fechas_no_contesta)
                }
                value = values;
            } 
            else if (moment.isMoment(value)) value = value.toDate();
            else if (getFieldType(key) === 'number') if (!value) value = 0;
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        return value;
    }

    /**
     * Retrieves the company and manager information from local storage and returns a formatted text.
     * If the company and manager information is not available, it returns a default message.
     * @returns A string containing the company and manager information, or a default message if not available.
     */
    getCompanyAndManagerText(): string {
        const companyString = localStorage.getItem('companyJson');
        const managerString = localStorage.getItem('managerJson');
        let companyJson: Company = {};
        let managerJson: Manager = {};
        if (companyString) companyJson = JSON.parse(companyString);
        if (managerString) managerJson = JSON.parse(managerString);
        let text = 'No hay empresa y gestor seleccionados';
        if (companyJson && managerJson){
            text = `EMPRESA: ${companyJson.nombre_empresa}  -  GESTOR: ${managerJson.gestor}`;
        }
        return text;
    }

    /**
     * Checks if the given photo URL is a server image.
     * @param photo - The photo URL to check.
     * @returns Returns true if the photo URL starts with 'https://', otherwise returns false.
     */
    checkIfServerImage(photo: any): boolean {
        if (photo && photo.includes('https://')) return true;
        return false
    }

    /**
     * Checks if the given water task has server images.
     * 
     * @param waterTask - The water task to check for server images.
     * @returns A boolean indicating whether the task has server images or not.
     */
    checkIfTaskHasServerImages(waterTask: WaterTask): boolean {
        const photoFields = getPhotoFields();
        for (const photoType of photoFields) {
            const photo: any = waterTask[photoType as keyof WaterTask];
            if (this.checkIfServerImage(photo)) return true;
        }    
        return false;
    }

    /**
     * Checks if the given ITAC object has server images.
     * 
     * @param itac - The ITAC object to check.
     * @returns A boolean indicating whether the ITAC has server images or not.
     */
    checkIfItacHasServerImages(itac: Itac): boolean {
        const photoFields = getItacPhotoFields();
        for (const photoType of photoFields) {
            const photo: any = itac[photoType as keyof Itac];
            if (this.checkIfServerImage(photo)) return true;
        }    
        return false;
    }

    /**
     * Retrieves the photo URL for a given water task.
     * 
     * @param waterTask - The water task object.
     * @returns The photo URL if found, otherwise an empty string.
     */
    getTaskPhoto(waterTask: WaterTask): string {
        const photoFields = getPhotoFields();
        for (const photoType of photoFields) {
            const photo: any = waterTask[photoType as keyof WaterTask];
            if (this.checkIfServerImage(photo)) return photo;
        }    
        return '';
    }

    /**
     * Retrieves the photos from a WaterTask object.
     * @param waterTask - The WaterTask object from which to retrieve the photos.
     * @returns An array of strings representing the photos.
     */
    getTaskPhotos(waterTask: WaterTask): string[] {
        let photos: string[] = []
        const photoFields = getPhotoFields();
        for (const photoType of photoFields) {
            const photo: any = waterTask[photoType as keyof WaterTask];
            if (this.checkIfServerImage(photo)) photos.push(photo);
        }    
        return photos;
    }

    /**
     * Retrieves the images associated with a water task.
     * 
     * @param waterTask - The water task for which to retrieve the images.
     * @returns An array of strings representing the image URLs.
     */
    getTaskImages(waterTask: WaterTask): string[] {
        return this.getTaskPhotos(waterTask);
    }

    /**
     * Retrieves the photos from the given Itac object.
     * 
     * @param itac - The Itac object from which to retrieve the photos.
     * @returns An array of strings representing the photos.
     */
    getItacPhotos(itac: Itac): string[] {
        let photos: string[] = []
        const photoFields = getItacPhotoFields();
        for (const photoType of photoFields) {
            const photo: any = itac[photoType as keyof Itac];
            if (this.checkIfServerImage(photo)) photos.push(photo);
        }   
        return photos;
    }

    /**
     * Retrieves the images associated with the given ITAC.
     * @param itac - The ITAC object.
     * @returns An array of strings representing the image URLs.
     */
    getItacImages(itac: Itac): string[] {
        return this.getItacPhotos(itac);
    }

    /**
     * Displays the selected company and manager information.
     */
    showCompanyAndManager() {
        this.openInformationDialog('Seleccionados', [this.getCompanyAndManagerText()]);
    }

    /**
     * Checks if the given image URL is valid.
     * 
     * @param image_url - The URL of the image to check.
     * @returns `true` if the image URL is valid and includes the environment URL server, `false` otherwise.
     */
    isValidImage(image_url: string) {
        if (this.isFieldValid(image_url) && image_url.includes(environment.url_server)) return true;
        return false;
    }

    /**
     * Retrieves a list of objects based on the given values.
     * @param value - An array of values.
     * @returns An array of objects.
     */
    getObjectList(value: any) {
        return value.map((v: string) => this.getObject(v));
    }

    /**
     * Returns an object with the specified value.
     * 
     * @param value - The value to be assigned to the object.
     * @returns An object with the specified value.
     */
    getObject(value: string) {
        return { value: value };
    } 

    /**
     * Retrieves the possible result from a string.
     * 
     * @param posible_result - The string containing the possible result.
     * @returns An array of objects representing the possible result.
     */
    getPosibleResultFromString(posible_result: string) {
        if (posible_result) {
            return posible_result
                .split('-')
                .map((res: string) => this.getObject(this.addCeros(res.trim(), 3)));
        }
        return null;
    }

    /**
     * @brief Add ceros to the start of the string until completes the needed size
     *
     * @param  {string} value Current value string
     * @param  {number} size  Needed size of string
     * @returns string The modififed string of with the needed size and ceros
     */
    addCeros(value: string, size: number): string {
        for (let i = value.length; i < size; i++) {
            value = '0' + value;
        }
        return value;
    }

    /**
     * @brief Checks if the screen is in full-screen mode.
     * @return True if the screen is in full-screen mode, false otherwise.
     */
    isFullScreen() {
        if (!window.screenTop && !window.screenY) {
            return false;
        }
        return true;
    }

    /**
     * @brief Gets the logged-in user's name.
     * @return The full name of the logged-in user or an empty string if no user is logged in.
     */
    getLoggedInUserName(): string {
        const user = this.getLoggedInUser();
        if (user) return this.getUserFullName(user);
        return '';
    }

    /**
     * @brief Gets the error message along with the stack trace.
     * @param error - The error object.
     * @return The error message along with the stack trace, or a generic message if an unknown error occurs.
     */
    getErrorMessageWithStackTrace(error: any): string {
        if (error instanceof Error) {
            const errorMessage = error.message;
            const stackTrace = error.stack;
            const errorMessageWithStackTrace = `${errorMessage}\n${stackTrace}`;
            return errorMessageWithStackTrace;
        } else {
            return 'An unknown error occurred. - ' + error as string;
        }
    }

    /**
     * @brief Removes invalid characters from a string to make it parseable.
     * Cannot receive a JSON string like '{ "key": "value" }'. Only can receive 
     * 'value' to clean it from invalid characters
     *
     * This function takes a string as input and removes
     * any invalid characters causing parsing errors.
     *
     * @param input The string with potentially invalid characters.
     * @return The cleaned string without invalid characters.
     *
     * @note This function may result in loss of information by removing characters.
     */
    removeInvalidJsonCharacters(input: string): string {
        const cleanedString = input.replace(/[\u0000-\u001F\u007F-\u009F"]/g, '');
        return cleanedString;
    }

    /**
     * @brief Checks if debug mode is enabled for front-end errors.
     *
     * This function retrieves the logged-in user and checks if the user has
     * debug_front_errors enabled in their settings. If enabled, it returns true,
     * indicating that debug mode is active for front-end errors.
     *
     * @return True if debug mode is enabled for front-end errors, false otherwise.
     */
    isDebugEnable(): boolean {
        const user = this.getLoggedInUser();
        if(user && user.settings && user.settings.debug_front_errors) return true;
        return false;
    }

    /**
     * Checks if notifications are enabled by the user.
     * @returns {boolean} Returns true if notifications are enabled, false otherwise.
     */
    isNotificationsEnableByUser(): boolean {
        const showNotifications = localStorage.getItem('showNotifications');
        if (showNotifications == 'true') {
            return true;
        }
        return false;
    }

    /**
     * Checks if notifications are enabled for the logged-in user.
     * @returns {boolean} True if notifications are enabled, false otherwise.
     */
    isNotificationsEnable(): boolean {
        const user = this.getLoggedInUser();
        if(user && user.settings && user.settings.read_notifications) {
            return true;
        }
        return false;
    }

    /**
     * Checks if the messages are enabled for the logged-in user.
     * @returns {boolean} Returns true if the messages are enabled, false otherwise.
     */
    isMessagesEnable(): boolean {
        const user = this.getLoggedInUser();
        if(user && user.settings && user.settings.read_messages) return true;
        return false;
    }

    /**
     * @function
     * @description
     * Handles errors globally and performs additional actions if debug mode is enabled.
     * @param {any} error - The error object to be handled.
     * @return {void}
     * @implements ErrorHandler.handleError
     */
    handleError(error: any): void {
        if(error.toString().includes(`TypeError: Cannot read properties of undefined (reading 'time')`)) return; //error in time picker
        console.error('Global error handler caught an error:', error);
        if(this.isDebugEnable()){
            const errorText = this.getErrorMessageWithStackTrace(error);
            this.downloadErrorFile(errorText);
            this.showErrorMessage();
        }
    }

    /**
     * @function
     * @description
     * Displays an error message using the UtilsService's openSnackBar method.
     * This method is typically used to inform the user that an error is being downloaded
     * and encourages them to send it to the developers.
     * @return {void}
     */
    showErrorMessage(): void {
        this.openSnackBar(
            'Se esta descargando el error, por favor envíelo a los desarrolladores',
            'error'
        );
    }

    /**
     * @brief Downloads an error file.
     * @param errorText - The error text to be downloaded.
     */
    downloadErrorFile(errorText: string): void {
        const url = window.URL.createObjectURL(new Blob([errorText], { type: 'text/plain' }));
        const a = document.createElement('a');
        a.href = url;
        a.download = this.getErrorFileName();
        a.click();
        window.URL.revokeObjectURL(url);
    }

    /**
     * Retrieves the Excel sheet names and data from a given reader.
     * @param reader - The reader object containing the Excel data.
     * @returns An object containing the JSON data and sheet names.
     */
    getExcelSheetAndData(reader: any) {
        let workBook: XLSX.WorkBook;
        let jsonData = null;
        const data = reader.result;
        workBook = XLSX.read(data, { type: 'binary' });
        jsonData = workBook.SheetNames.reduce((initial: any, name) => {
            const sheet = workBook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet);
            return initial;
        }, {});
        let sheets: string[] = [];
        Object.keys(jsonData).forEach((key: string) => sheets.push(key));
        return { jsonData, sheets };
    }

    /**
     * Extracts and transforms values from JSON data based on specified Excel columns and application fields.
     *
     * @param jsonData - The JSON data containing tasks, organized by sheets.
     * @param sheets - An array of sheet names to process.
     * @param excelColumns - An array of column names in the Excel data to extract values from.
     * @param appFields - An array of corresponding application field names to map the extracted values to.
     * @returns An array of objects where each object maps an application field to an array of extracted values.
     */
    getTaskExcelValues(jsonData: any, sheets: string[], excelColumns: string[], appFields: string[]): any[] {
        let jsonArrayValues = [];
        for (let i = 0; i < excelColumns.length; i++) {
            let excelColumn = excelColumns[i];
            let appField = appFields[i];
            let values: any[] = [];
            for (let sheet of sheets) {
                for (let jsonTask of jsonData[sheet]) {
                    let excelValue = jsonTask[excelColumn];
                    let value = this.getTaskValue(excelValue, appField);
                    values.push(value);
                }
            }
            jsonArrayValues.push({ [appField]: values });
        }
        return jsonArrayValues;
    }

    /**
     * Retrieves the values of a specific column from multiple sheets in an Excel file.
     * 
     * @param jsonData - The JSON data representing the Excel file.
     * @param sheets - An array of sheet names to retrieve the values from.
     * @param excelColumn - The name of the column to retrieve the values from.
     * @returns An array of string values from the specified column in the given sheets.
     */
    getExcelValues(jsonData: any, sheets: string[], excelColumn: string) {
        let values: string[] = [];
        for (let sheet of sheets) {
            for (let jsonTask of jsonData[sheet]) {
                let value = jsonTask[excelColumn];
                if(value && typeof value === 'string') value = value.trim();
                values.push(value);
            }
        }
        return values;
    }

    /**
     * Retrieves the unique keys from the specified sheets in the given JSON data.
     * @param jsonData - The JSON data containing multiple sheets.
     * @param sheets - An array of sheet names to retrieve keys from.
     * @returns An array of unique keys found in the specified sheets.
     */
    getExcelKeys(jsonData: any, sheets: string[]) {
        let keys: string[] = [];
        for (let sheet of sheets) {
            for (let jsonTask of jsonData[sheet]) {
                Object.keys(jsonTask).forEach((key) => {
                    if(!keys.includes(key.trim())) keys.push(key.trim());
                });
                break;
            }
        }
        return keys;
    }

    /**
     * Generates a file name for an error log, combining the date and time.
     * @returns {string} - The formatted error file name.
     */
    getErrorFileName(): string {
        const now = new Date();
        const dateFormatted = `${now.getFullYear()}-${(now.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}`;
        const timeFormatted = 
            `${now.getHours().toString().padStart(2, '0')}-`
            +`${now.getMinutes().toString().padStart(2, '0')}-`
            +`${now.getSeconds().toString().padStart(2, '0')}`;
        const fileName = `error_500_response_${dateFormatted}_${timeFormatted}.txt`;
        return fileName;
    }

    /**
     * Retrieves the logged-in user from the session storage.
     * @returns The logged-in user object if found, otherwise undefined.
     */
    getLoggedInUser(): MiRutaUser | undefined {
        const userString = sessionStorage.getItem('user');
        if (userString) {
            try {
                const user: MiRutaUser = JSON.parse(userString);
                if (user) return user;
            } catch (err) {}
        }
        return undefined;
    }

    /**
     * Checks if the logged-in user is a superuser.
     * @returns {boolean} True if the user is a superuser, false otherwise.
     */
    isDeveloper(): boolean {
        const user = this.getLoggedInUser();
        if (user && user.developer) return true;
        return false;
    }

    /**
     * Checks if the logged-in user is a superuser.
     * @returns {boolean} True if the user is a superuser, false otherwise.
     */
    isSuperUser(): boolean {
        const user = this.getLoggedInUser();
        if (user && user.superusuario) return true;
        return false;
    }

    /**
     * Checks if the user is a client.
     * @param user - The user object to check.
     * @returns True if the user is a client, false otherwise.
     */
    isClient(user: MiRutaUser) {
        if (!user) return false;
        if (user?.cliente && !user?.administrador && !user?.superusuario) return true;
        return false;
    }

    /**
     * Checks if the logged-in user is a client.
     * Returns true if the user is only a client, false if the user is a superuser or administrator.
     * @returns A boolean value indicating whether the user is a client.
     */
    isClientUser(): boolean {
        //? Returns true if is only a client, false if is superusuario or administrator
        const user = this.getLoggedInUser();
        if (user) {
            if (user.administrador || user.superusuario) {
                // operators cannot enter on app || user.operario
                return false;
            }
            if (user.cliente) return true;
        }
        return false;
    }

    /**
     * Checks if the logged-in user is an administrator.
     * @returns {boolean} Returns true if the user is an administrator, otherwise returns false.
     */
    isAdmin(): boolean {
        const user = this.getLoggedInUser();
        if (user && user.administrador) return true;
        return false;
    }

    /**
     * Saves a JSON object to a file.
     * @param json - The JSON object to be saved.
     * @param filename - The name of the file to be saved.
     * @param download - Optional. Specifies whether the file should be downloaded. Default is `true`.
     * @param jsonSpace - Optional. The number of spaces to use for indentation in the JSON string. Default is `0`.
     * @returns A promise that resolves when the file is saved.
     */
    saveJsonTofile(json: any, filename: string, download?: boolean, jsonSpace?: number) {
        return this.saveTextTofile(
            JSON.stringify(json, null, jsonSpace ? jsonSpace : 0),
            filename,
            download
        );
    }

    /**
     * Exports the given JSON array to an Excel file with the specified filename.
     * @param jsonArray The JSON array to export.
     * @param filename The name of the Excel file to save.
     */
    exportExcel(jsonArray: any[], filename: string) {
        if(!filename.includes('.xlsx')) filename += '.xlsx';
        const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsonArray);
        /* generate workbook and add the worksheet */
        const wb: XLSX.WorkBook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
        /* save to file */
        XLSX.writeFile(wb, filename);
    }

    /**
     * Saves the given text as a file with the specified filename.
     * 
     * @param text - The text content to be saved as a file.
     * @param filename - The name of the file to be saved.
     * @param download - Optional. Specifies whether to trigger a download of the file. Default is false.
     * @returns A File object representing the saved file.
     */
    saveTextTofile(text: string, filename: string, download?: boolean) {
        var blob = new Blob([text], { type: 'text/csv' });
        if (download) saveAs(blob, filename);
        let arrayOfBlob = new Array<Blob>();
        arrayOfBlob.push(blob);
        return new File(arrayOfBlob, filename, { type: 'text/csv' });
    }

    /**
     * Removes the trash information from a base64 encoded photo.
     * @param photoBase64 - The base64 encoded photo string.
     * @returns The cleaned base64 encoded photo string.
     */
    removeBase64TrashInfo(photoBase64: string): string {
        photoBase64 = photoBase64.replace(/^data:image\/png;base64,/, '');
        photoBase64 = photoBase64.replace(/^data:image\/jpg;base64,/, '');
        photoBase64 = photoBase64.replace(/^data:image\/jpeg;base64,/, '');
        return photoBase64;
    }

    /**
     * Converts a Blob object to a base64 string.
     * @param blob - The Blob object to convert.
     * @returns A Promise that resolves to the base64 string representation of the Blob.
     */
    async convertBlobToBase64(blob: Blob): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onloadend = () => {
                let base64data = reader.result as string;
                base64data = this.removeBase64TrashInfo(base64data);
                resolve(base64data);
            };
        });
    }

    /**
     * Extracts the filename from a given image URL.
     *
     * @param image_url - The URL of the image.
     * @returns The extracted filename or 'download.jpg' if the filename cannot be determined.
     */
    getImageName(image_url: string): string { 
        const filename = image_url.split('/').pop() || image_url.split('\\').pop();
        return filename || 'download.jpg';
    }

    /**
     * Converts the given text to a base64-encoded barcode image.
     * @param text The text to be encoded as a barcode.
     * @returns The base64-encoded representation of the barcode image.
     */
    textToBase64Barcode(text: string) {
        let canvas = document.createElement('canvas');
        JsBarcode(canvas, text, { format: 'CODE128' });
        return canvas.toDataURL('image/png');
    }

    /**
     * Generates a barcode as an HTML canvas element.
     * 
     * @param text - The text to be encoded in the barcode.
     * @returns The generated HTML canvas element containing the barcode.
     */
    textHTMLCanvasBarcode(text: string) {
        let canvas = document.createElement('canvas');
        JsBarcode(canvas, text, { format: 'CODE128' });
        return canvas;
    }

    /**
     * Retrieves the task metadata for a given water task.
     * @param waterTask - The water task object.
     * @param photoType - The type of photo.
     * @param company - The company name.
     * @returns The task metadata object.
     */
    public getTaskMetadata(waterTask: WaterTask, photoType: string, company: string): any {
        return {
            cacheControl: 'max-age=300',
            customMetadata: {
                date_upload: `${Date.now().toString()}`,
                type_photo: this.isFieldValid(photoType) ? photoType : '',
                task_COMPANY: `${company}`,
                task_MANAGER: `${waterTask.GESTOR}`,
                task_NINTERNAL_NUMBER: `${waterTask.NUMIN}`,
                task_SUBSCRIBER_NUMBER: `${waterTask.Numero_de_ABONADO}`,
                task_ANOMALY: `${waterTask.ANOMALIA}`,
            },
        };
    }

    /**
     * Retrieves the metadata for an ITAC (Inspection, Testing, and Certification) object.
     * @param itac - The ITAC object.
     * @param photoType - The type of photo.
     * @param company - The company name.
     * @returns The metadata object for the ITAC.
     */
    public getItacMetadata(itac: Itac, photoType: string, company: string): any {
        return {
            cacheControl: 'max-age=300',
            customMetadata: {
                date_upload: `${Date.now().toString()}`,
                type_photo: this.isFieldValid(photoType) ? photoType : '',
                itac_COMPANY: `${company}`,
                itac_MANAGER: `${itac.gestor}`,
                itac_CODE: `${itac.codigo_itac}`,
                itac_ADDRESS: `${itac.itac}`,
            },
        };
    }

    public get sectors(): Sector[] {
        return this._sectors;
    }
    public set sectors(value: Sector[]) {
        this._sectors = value;
    }

    public get typesRadius(): TypeRadius[] {
        return this._typesRadius;
    }
    public set typesRadius(value: TypeRadius[]) {
        this._typesRadius = value;
    }

    public get dateStatus(): DateStatus[] {
        return this._dateStatus;
    }
    public set dateStatus(value: DateStatus[]) {
        this._dateStatus = value;
    }

    public get typesCounters(): TypeCounter[] {
        return this._typesCounters;
    }
    public set typesCounters(value: TypeCounter[]) {
        this._typesCounters = value;
    }

    public get emplacements(): Emplacement[] {
        return this._emplacements;
    }
    public set emplacements(value: Emplacement[]) {
        this._emplacements = value;
    }
    public get marks(): Mark[] {
        return this._marks;
    }
    public set marks(value: Mark[]) {
        this._marks = value;
    }
    public get plannings(): Planning[] {
        return this._plannings;
    }
    public set plannings(value: Planning[]) {
        this._plannings = value;
    }
    public get results(): Result[] {
        return this._results;
    }
    public set results(value: Result[]) {
        this._results = value;
    }
    public get users(): MiRutaUser[] {
        return this._users;
    }
    public set users(value: MiRutaUser[]) {
        this._users = value;
    }
    public get teams(): Team[] {
        return this._teams;
    }
    public set teams(value: Team[]) {
        this._teams = value;
    }
    public get companies(): Company[] {
        return this._companies;
    }
    public set companies(value: Company[]) {
        this._companies = value;
    }

    public get managers(): Manager[] {
        return this._managers;
    }
    public set managers(value: Manager[]) {
        if (value) {
            this._managers = value;
        } else {
            console.log('manager is null');
        }
    }

    public get agrupations(): Agrupation[] {
        return this._agrupations;
    }
    public set agrupations(value: Agrupation[]) {
        if (value) {
            this._agrupations = value;
        } else {
            console.log('agrupation is null');
        }
    }

    get zones(): Zone[] {
        return this._zones!;
    }
    set zones(zones: Zone[]) {
        if (zones) {
            this._zones = zones;
        } else {
            console.log('zone is null');
        }
    }
    get causes(): Cause[] {
        return this._causes!;
    }
    set causes(causes: Cause[]) {
        if (causes) {
            this._causes = causes;
        } else {
            console.log('cause is null');
        }
    }
    get planningDetails(): PlanningDetail[] {
        return this._planningDetails!;
    }
    set planningDetails(planningDetails: PlanningDetail[]) {
        if (planningDetails) {
            this._planningDetails = planningDetails;
        } else {
            console.log('planningDetail is null');
        }
    }
    get planningDetailExtras(): PlanningDetailExtra[] {
        return this._planningDetailExtras!;
    }
    set planningDetailExtras(planningDetailExtras: PlanningDetailExtra[]) {
        if (planningDetailExtras) {
            this._planningDetailExtras = planningDetailExtras;
        } else {
            console.log('planningDetailExtra is null');
        }
    }

    /**
     * Gets the selected Itac.
     * @returns The selected Itac.
     */
    get itacSelected(): Itac {
        return this._itacSelected!;
    }

    /**
     * Setter for the `itacSelected` property.
     * @param itac - The selected Itac object.
     */
    set itacSelected(itac: Itac) {
        if (itac) {
            this._itacSelected = itac;
        } else {
            console.log('task is null');
        }
    }

    /**
     * Gets the selected counter.
     * @returns The selected counter.
     */
    get counterSelected(): Counter {
        return this._counterSelected!;
    }

    /**
     * Setter for the counterSelected property.
     * @param {Counter} counter - The counter object to set.
     */
    set counterSelected(counter: Counter) {
        if (counter) {
            this._counterSelected = counter;
        } else {
            console.log('counter is null');
        }
    }

    /**
     * Gets the country codes.
     * @returns {any[]} The country codes.
     */
    get country_codes(): any[] {
        return this._country_codes;
    }

    /**
     * Gets the list of services.
     * @returns An array of strings representing the services.
     */
    get services(): string[] {
        return this._services;
    }

    /**
     * Gets the supplies.
     * @returns An array of strings representing the supplies.
     */
    get suplies(): string[] {
        return this._suplies;
    }

    /**
     * Calculates the scaled size of an image based on the original dimensions and the maximum width and height.
     * @param originalWidth - The original width of the image.
     * @param originalHeight - The original height of the image.
     * @param maxWidth - The maximum width for the scaled image.
     * @param maxHeight - The maximum height for the scaled image.
     * @returns An object containing the width and height of the scaled image.
     */
    getScaledSize(
        originalWidth: number,
        originalHeight: number,
        maxWidth: number,
        maxHeight: number
    ) {
        let ratio: number;
        if (originalWidth > originalHeight) {
            ratio = originalHeight / originalWidth;
            maxHeight = maxWidth * ratio;
        } else {
            ratio = originalWidth / originalHeight;
            maxWidth = maxHeight * ratio;
        }
        return { width: maxWidth, height: maxHeight };
    }

    /**
     * Retrieves the prefix from a serial number.
     * 
     * @param serialNumber - The serial number from which to extract the prefix.
     * @returns The prefix extracted from the serial number.
     */
    getPrefixFromSerialNumber(serialNumber: string): string {
        let prefix: string = '';
        if (serialNumber.includes(' ')) {
            const list = serialNumber.split(' ');
            if (list.length >= 2) {
                prefix = list[0].trim();
            }
        }
        return prefix;
    }

    /**
     * Returns the geolocation URL for a given point.
     * @param point - The point for which to generate the geolocation URL.
     * @returns The geolocation URL.
     */
    getGeolocationUrl(point: MyLatLng | undefined): string {
        if (point) {
            let url =
                `https://maps.google.com/?q=` + `${point.lat.toString()},${point.lng.toString()}`;
            return url;
        }
        return '';
    }

    /**
     * Extracts latitude and longitude from a Google Maps URL.
     *
     * @param url - The Google Maps URL containing the geolocation query.
     * @returns An object containing latitude and longitude, or null if extraction fails.
     */
    getMyLatLngFromGeolocationUrl(url: string): MyLatLng | null {
        const geoString = url.replace('https://maps.google.com/?q=', '');
        return this.getMyLatLngFromString(geoString);
    }

    /**
     * Converts a WaterTask object into a FormGroup object.
     * @param task - The WaterTask object to convert.
     * @returns A FormGroup object representing the form data from the task.
     */
    getFormFromTask(task: WaterTask): FormGroup {
        const taskFormData = getFormControls();
        const keys = Object.keys(task);
        for (let key of keys) {
            let value: any = task[key as keyof WaterTask];
            if (value || value === 0) {
                if (
                    Array.isArray(value) &&
                    value.length > 0 &&
                    Object.keys(value[0]).includes('value')
                ) {
                    if (value.length > 0) {
                        let values = [];
                        for (const v of value) {
                            const keysInside = Object.keys(v);
                            if (keysInside.includes('value')) values.push(v['value']);
                        }
                        taskFormData.controls[key].setValue(values);
                    }
                } else {
                    try {
                        taskFormData.controls[key].setValue(value);
                    } catch (err) {
                        console.log('************* err *************');
                        console.log(key);
                        console.log(err);
                    }
                }
            }
        }
        return taskFormData;
    }
    
    /**
     * Converts an Itac object into a FormGroup.
     * @param itac - The Itac object to convert.
     * @returns A FormGroup representing the Itac object.
     */
    getFormFromItac(itac: Itac): FormGroup {
        const itacFormData = getItacFormControls();
        const keys = Object.keys(itac);
        for (let key of keys) {
            let value: any = itac[key as keyof Itac];
            if (value || value === 0) {
                if (
                    Array.isArray(value) &&
                    value.length > 0 &&
                    Object.keys(value[0]).includes('value')
                ) {
                    if (value.length > 0) {
                        let values = [];
                        for (const v of value) {
                            const keysInside = Object.keys(v);
                            if (keysInside.includes('value')) values.push(v['value']);
                        }
                        itacFormData.controls[key].setValue(values);
                    }
                } else {
                    try {
                        itacFormData.controls[key].setValue(value);
                    } catch (err) {
                        console.log('************* err *************');
                        console.log(key);
                        console.log(err);
                    }
                }
            }
        }
        return itacFormData;
    }

    /**
     * Converts a Counter object into a FormGroup.
     * @param counter - The Counter object to convert.
     * @returns A FormGroup representing the Counter object.
     */
    getFormFromCounter(counter: Counter): FormGroup {
        const counterFormData = getCounterFormControls();
        const keys = Object.keys(counter);
        for (let key of keys) {
            let value: any = counter[key as keyof Counter];
            if (value || value === 0) {
                if (
                    Array.isArray(value) &&
                    value.length > 0 &&
                    Object.keys(value[0]).includes('value')
                ) {
                    if (value.length > 0) {
                        let values = [];
                        for (const v of value) {
                            const keysInside = Object.keys(v);
                            if (keysInside.includes('value')) values.push(v['value']);
                        }
                        counterFormData.controls[key].setValue(values);
                    }
                } else {
                    try {
                        counterFormData.controls[key].setValue(value);
                    } catch (err) {
                        console.log('************* err *************');
                        console.log(key);
                        console.log(err);
                    }
                }
            }
        }
        return counterFormData;
    }

    /**
     * Converts a WaterRoute object into a FormGroup object.
     * 
     * @param waterRoute - The WaterRoute object to convert.
     * @returns A FormGroup object representing the form data of the WaterRoute.
     */
    getFormFromWaterRoute(waterRoute: WaterRoute): FormGroup {
        const waterRouteFormData = getWaterRouteFormControls();
        const keys = Object.keys(waterRoute);
        for (let key of keys) {
            let value: any = waterRoute[key as keyof WaterRoute];
            if (value || value === 0) {
                if (
                    Array.isArray(value) &&
                    value.length > 0 &&
                    Object.keys(value[0]).includes('value')
                ) {
                    if (value.length > 0) {
                        let values = [];
                        for (const v of value) {
                            const keysInside = Object.keys(v);
                            if (keysInside.includes('value')) values.push(v['value']);
                        }
                        waterRouteFormData.controls[key].setValue(values);
                    }
                } else {
                    try {
                        waterRouteFormData.controls[key].setValue(value);
                    } catch (err) {
                        console.log('************* err *************');
                        console.log(key);
                        console.log(err);
                    }
                }
            }
        }
        return waterRouteFormData;
    }

    /**
     * Opens the specified URL in a new browser window or tab.
     * @param url - The URL to navigate to.
     * @param newPage - Optional. Specifies whether to open the URL in a new page. Default is true.
     */
    goToLink(url: string, newPage: boolean = true): void {
        if (newPage) {
            window.open(url, '_blank');
        } else {
            window.open(url);
        }
    }

    /**
     * Calculates the logarithm base 2 of a given number.
     * Throws an error if the number is less than or equal to 0.
     * 
     * @param n - The number to calculate the logarithm base 2 of.
     * @returns The logarithm base 2 of the given number.
     */
    log2(n: number): number {
        if (n <= 0) {
            console.log('error log2');
        }
        return 31 - Math.clz32(n);
    }

    /**
     * Converts degrees to radians.
     * @param degrees The value in degrees to be converted.
     * @returns The value in radians.
     */
    degreesToRadians(degrees: number) {
        return (degrees * Math.PI) / 180;
    }

    /**
     * Calculates the distance in kilometers between two sets of Earth coordinates.
     * @param lat1 - The latitude of the first coordinate.
     * @param lon1 - The longitude of the first coordinate.
     * @param lat2 - The latitude of the second coordinate.
     * @param lon2 - The longitude of the second coordinate.
     * @returns The distance in kilometers between the two coordinates.
     */
    getDistanceInKmBetweenEarthCoordinates(lat1: number, lon1: number, lat2: number, lon2: number) {
        const earthRadiusKm: number = 6371;

        const dLat = this.degreesToRadians(lat2 - lat1);
        const dLon = this.degreesToRadians(lon2 - lon1);

        lat1 = this.degreesToRadians(lat1);
        lat2 = this.degreesToRadians(lat2);

        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        return earthRadiusKm * c;
    }

    /**
     * Converts a priority integer value to its corresponding string representation.
     * @param priorityInt - The priority integer value to convert.
     * @returns The string representation of the priority.
     */
    getPriorityFromInt(priorityInt: number): string {
        if (priority_status.HIBERNATE == priorityInt) return 'HIBERNAR';
        if (priority_status.LOW == priorityInt) return 'BAJA';
        if (priority_status.MEDIUM == priorityInt) return 'MEDIA';
        if (priority_status.HIGH == priorityInt) return 'ALTA';
        return 'BAJA';
    }

    /**
     * Converts a priority string to a number.
     * 
     * @param priorityString - The priority string to convert.
     * @returns The converted priority as a number.
     */
    getPriorityFromString(priorityString: string): number {
        return priorityFromString(priorityString);
    }

    /**
     * Converts a counter status integer value to its corresponding string representation.
     * @param statusInt - The counter status integer value.
     * @returns The string representation of the counter status.
     */
    getCounterStatusFromInt(statusInt: number): string {
        if (counter_status.AVAILABLE == statusInt) {
            return 'DISPONIBLE';
        }
        if (counter_status.INSTALLED == statusInt) {
            return 'INSTALADO';
        }
        return 'DISPONIBLE';
    }

    /**
     * Converts a boolean status to a corresponding string value.
     * @param statusBoolean - The boolean status to convert.
     * @returns The string representation of the boolean status.
     */
    getCounterActivatedStatusFromBoolean(statusBoolean: any): string {
        if (statusBoolean) {
            return 'ACTIVADA';
        } else {
            return 'NO ACTIVADA';
        }
    }

    /**
     * Converts a counter status string to its corresponding number value.
     * @param statusString - The counter status string to convert.
     * @returns The number value corresponding to the counter status string.
     */
    getCounterStatusFromString(statusString: string): number {
        if ('DISPONIBLE' == statusString.toUpperCase()) {
            return counter_status.AVAILABLE;
        }
        if ('AVAILABLE' == statusString.toUpperCase()) {
            return counter_status.AVAILABLE;
        }
        if ('INSTALADO' == statusString.toUpperCase()) {
            return counter_status.INSTALLED;
        }
        if ('INSTALLED' == statusString.toUpperCase()) {
            return counter_status.INSTALLED;
        }
        return counter_status.AVAILABLE;
    }

    
    /**
     * Retrieves an array of task statuses.
     * 
     * @returns An array of task statuses.
     */
    getTaskStatuses() {
        const statuses: number[] = [];
        for (let i = task_status.REQUIRED; i <= task_status.TRASHED; i++) {
            statuses.push(i);
        }
        return statuses;
    }

    /**
     * Converts a task status integer value to its corresponding string representation.
     * @param statusInt - The task status integer value.
     * @returns The string representation of the task status.
     */
    getTaskStatusFromInt(statusInt: number): string {
        if (task_status.REQUIRED == statusInt) {
            return 'REQUERIDA';
        }
        if (task_status.IDLE == statusInt) {
            return 'ABIERTA';
        }
        if (task_status.DONE == statusInt) {
            return 'EJECUTADA';
        }
        if (task_status.CLOSED == statusInt) {
            return 'CERRADA';
        }
        if (task_status.INFORMED == statusInt) {
            return 'INFORMADA';
        }
        if (task_status.INCIDENCE == statusInt) {
            return 'INCIDENCIA';
        }
        if (task_status.TRASHED == statusInt) {
            return 'TRASTERO';
        }
        return 'ABIERTA';
    }
    
    /**
     * Converts a task status string to a corresponding number.
     * @param statusString - The task status string to convert.
     * @returns The corresponding number for the task status.
     */
    getTaskStatusFromString(statusString: string): number {
        return taskStatusFromString(statusString);
    }

    /**
     * Converts an incidence number to a corresponding string representation.
     * @param incidence - The incidence number to convert.
     * @returns The string representation of the incidence.
     */
    getIncidenceFromInt(incidence: number): string {
        if (incidence) {
            return 'INCIDENCIA';
        }
        return 'NORMAL';
    }
    
    /**
     * Converts a boolean value to an incidence string.
     * @param incidence - The boolean value representing an incidence.
     * @returns The corresponding incidence string ('INCIDENCIA' if true, 'NORMAL' if false).
     */
    getIncidenceFromBoolean(incidence: boolean): string {
        if (incidence) {
            return 'INCIDENCIA';
        }
        return 'NORMAL';
    }

    /**
     * Converts an integer value representing a task order to its corresponding string representation.
     * @param orderInt - The integer value representing the task order.
     * @returns The string representation of the task order.
     */
    getTaskOrderFromInt(orderInt: number): string {
        if (order_status.DAILY == orderInt) {
            return 'DIARIA';
        }
        if (order_status.MASIVE == orderInt) {
            return 'MASIVA';
        }
        if (order_status.SPECIAL == orderInt) {
            return 'ESPECIAL';
        }
        return 'DIARIA';
    }

    /**
     * Converts a string representation of a task order to its corresponding number value.
     * @param orderString - The string representation of the task order.
     * @returns The number value of the task order.
     */
    getTaskOrderFromString(orderString: string): number {
        if ('DIARIA' == orderString.toUpperCase() || 'DIARIAS' == orderString.toUpperCase()) {
            return order_status.DAILY;
        }
        if ('MASIVA' == orderString.toUpperCase() || 'MASIVAS' == orderString.toUpperCase()) {
            return order_status.MASIVE;
        }
        if ('ESPECIAL' == orderString.toUpperCase() || 'ESPECIALES' == orderString.toUpperCase()) {
            return order_status.SPECIAL;
        }
        return order_status.DAILY;
    }

    /**
     * Retrieves the status and additional status based on the given status string.
     * @param statusString - The status string to determine the status and additional status.
     * @returns An object containing the status and additional status.
     */
    getStatus(statusString?: string) {
        let status: number = -1;
        let additionalStatus = -1;
        if (statusString == 'Todas') status = -1;
        else if (statusString == 'Abiertas') status = task_status.IDLE;
        else if (statusString == 'Ausentes') {
            status = task_status.IDLE;
            additionalStatus = 1;
        } else if (statusString == 'Citas') {
            status = task_status.IDLE;
            additionalStatus = 2;
        } else if (statusString == 'Ejecutadas') status = task_status.DONE;
        else if (statusString == 'Cerradas') status = task_status.CLOSED;
        else if (statusString == 'Informadas') status = task_status.INFORMED;
        else if (statusString == 'Incidencias') status = task_status.INCIDENCE;
        else if (statusString == 'Requeridas') status = task_status.REQUIRED;
        else if (statusString == 'Trastero') status = task_status.TRASHED;

        return { status, additionalStatus };
    }

    /**
     * Clears all filters and orders.
     */
    clearFiltersAndOrders() {
        this.orderTasks = [];
        this.orderItacs = [];
        this.orderCounters = [];
        this.orderWaterRoutes = [];
        this.orderRadiusModules = [];
        this.orderSides = [];
        this.filterItacs = {};
        this.filterCounters = {};
        this.filterWaterRoutes = {};
        this.filterRadiusModules = {};
        this.filterSides = {};

        sessionStorage.setItem('orderTasks', '');
        sessionStorage.setItem('orderItacs', '');
        sessionStorage.setItem('orderCounters', '');
        sessionStorage.setItem('orderWaterRoutes', '');
        sessionStorage.setItem('orderRadiusModules', '');
        sessionStorage.setItem('orderSides', '');
        sessionStorage.setItem('filterItacs', '');
        sessionStorage.setItem('filterCounters', '');
        sessionStorage.setItem('filterWaterRoutes', '');
        sessionStorage.setItem('filterRadiusModules', '');

        this.clearTasksFilter();
        this.clearSidesFilter();
    }

    clearTasksFilter(){
        this.filterTasks = {};
        sessionStorage.setItem('filterTasks', '');
    }

    clearSidesFilter(){
        this.filterSides = {};
        sessionStorage.setItem('filterSides', '');
    }

    teamPipe(value: any): string {
        if (!value) return '';
        if (typeof value == 'string') {
            try {
                const intValue = parseInt(value, 1);
                value = intValue;
            } catch (err) {}
        }
        if (typeof value == 'number') {
            const teamsString = localStorage.getItem('teams');
            if (teamsString) {
                const teams: Team[] = JSON.parse(teamsString);
                if (teams && teams.length > 0) {
                    const team: any = teams.find((team: Team) => team.id == value);
                    if (team) return team.equipo_operario;
                }
            }
            return '';
        }
        if (this.teams.length > 0) {
            const team = this.teams.find((team) => team.id == value);
            if (team) {
                return team.equipo_operario;
            }
        } else {
            const teamsString = localStorage.getItem('teams');
            if (teamsString) {
                const teams: Team[] = JSON.parse(teamsString);
                if (teams && teams.length > 0) {
                    const team: any = teams.find((team: Team) => team.id == value);
                    if (team) return team.equipo_operario;
                }
            }
        }
        return value;
    }

    getLocalUsers(): MiRutaUser[] {
        const usersString = localStorage.getItem('users');
        if (usersString) {
            const users: MiRutaUser[] = JSON.parse(usersString);
            if (users && users.length) return users;
        }
        return [];
    }

    userPipe(value: any): string {
        if (!value) return '';
        if (typeof value == 'string') {
            try {
                if(value.includes(',')){
                    const ids = value.split(',').map((id: string) => parseInt(id, 10));
                    value = [];
                    const users: MiRutaUser[] = this.getLocalUsers();
                    for (let id of ids) {
                        const user: any = users.find((user: MiRutaUser) => user.id == id);
                        if (user) value.push(user);
                    }
                }
                else{
                    const intValue = parseInt(value, 10);
                    value = intValue;
                }
            } catch (err) {}
        }
        if (typeof value == 'number') {
            const users: MiRutaUser[] = this.getLocalUsers();
            if (users && users.length) {
                const user: any = users.find((user: MiRutaUser) => user.id == value);
                if (user) return this.getUserFullName(user);
            }
            return '';
        }
        if (Array.isArray(value)) {
            let userNames = '';
            value.forEach((user) => {
                if (user) {
                    if (!userNames) userNames = this.getUserFullName(user);
                    else userNames += `, ${this.getUserFullName(user)}`.trim();
                }
            });
            return userNames;
        } else {
            if (value) return this.getUserFullName(value);
        }
        return value;
    }

    /**
     * Retrieves an array of user names from the given array of MiRutaUser objects.
     * 
     * @param users - An optional array of MiRutaUser objects.
     * @returns An array of user names.
     */
    userNames(users?: MiRutaUser[]): string[] {
        if (!users) return [];
        let names: string[] = [];
        users.forEach((user) => {
            if (user) names.push(this.getUserFullName(user));
        });
        return names;
    }

    /**
     * Returns the full name of a user.
     * @param user - The user object containing the first name, last name, and middle name.
     * @returns The full name of the user.
     */
    getUserFullName(user: MiRutaUser) {
        let name = `${user.nombre} ${user.apellido1} ${user.apellido2}`.trim();
        const regex = /null/gi; //replaceAll
        name = name.replace(regex, '');
        return name;
    }

    /**
     * Loads the credentials from the Electron app using the provided `electronService`.
     * If the app is not running in Electron, an empty object is returned.
     * @param electronService - The Electron service used to communicate with the app.
     * @returns The loaded credentials as an object, or an empty object if the app is not running in Electron.
     */
    async loadCredentials(electronService: IpcService) {
        if (electronService.isElectronApp()) {
            const answer = electronService.sendMessageSync({ message: 'load-credentials' });
            if (answer) {
                try {
                    return JSON.parse(this.decodeBase64(answer));
                } catch (err) {}
            }
            return {};
        }
    }

    /**
     * Retrieves the company name based on the provided value.
     * 
     * @param value - The value used to determine the company name.
     * @returns The company name as a string.
     */
    companyPipe(value: any): string {
        if (!value) return '';
        if (typeof value == 'number') {
            const companysString = localStorage.getItem('companys');
            if (companysString) {
                const companys: Company[] = JSON.parse(companysString);
                if (companys && companys.length > 0) {
                    const company: any = companys.find((company: Company) => company.id == value);
                    if (company) return company.nombre_empresa;
                }
            }
            return '';
        }

        if (Array.isArray(value)) {
            let companyName = '';
            value.forEach((company) => {
                if (company) {
                    if (!companyName) {
                        companyName = `${company.nombre_empresa}`.trim();
                    } else {
                        companyName += `, ${company.nombre_empresa}`.trim();
                    }
                }
            });
            return companyName;
        } else {
            const company = this.companies.find((company) => company.id == value);
            if (company) {
                return `${company.nombre_empresa}`.trim();
            }
        }
        return value;
    }

    /**
     * Checks if a company with the specified ID exists in the given array of companies.
     * @param companies - The array of companies to search in.
     * @param companyId - The ID of the company to check for existence. Optional.
     * @returns A boolean value indicating whether the company exists or not.
     */
    checkCompany(companies: Company[], companyId?: string): boolean {
        try {
            const res = companies.findIndex((company) => company.id?.toString() === companyId);
            if (res >= 0) return true;
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
        return false;
    }

    /**
     * Checks if a manager with the specified managerId exists in the given array of managers.
     * @param managers - An array of Manager objects.
     * @param managerId - The ID of the manager to check for.
     * @returns A boolean indicating whether a manager with the specified managerId exists in the array.
     */
    checkManager(managers: Manager[], managerId?: string): boolean {
        try {
            const res = managers.findIndex((manager) => manager.id?.toString === managerId);
            if (res >= 0) return true;
        } catch (err) {
            console.log('************* err *************');
            console.log(err);
        }
        return false;
    }

    /**
     * Retrieves the manager name based on the provided value.
     * If the value is a number, it searches for the manager with the matching ID in the local storage.
     * If the value is an array, it concatenates the names of all managers in the array.
     * If the value is neither a number nor an array, it searches for the manager with the matching ID in the `managers` array.
     * @param value - The value used to determine the manager name.
     * @returns The manager name or an empty string if no manager is found.
     */
    managerPipe(value: any): string {
        if (!value) return '';
        if (typeof value == 'number') {
            const managersString = localStorage.getItem('managers');
            if (managersString) {
                const managers: Manager[] = JSON.parse(managersString);
                if (managers && managers.length > 0) {
                    const manager: any = managers.find((manager: Manager) => manager.id == value);
                    if (manager) return manager.gestor;
                }
            }
            return '';
        }
        if (Array.isArray(value)) {
            let managerName = '';
            value.forEach((manager: Manager) => {
                if (manager) {
                    if (!managerName) {
                        managerName = `${manager.gestor}`.trim();
                    } else {
                        managerName += `, ${manager.gestor}`.trim();
                    }
                }
            });
            return managerName;
        } else {
            const manager = this.managers.find((manager) => manager.id == value);
            if (manager) return `${manager.gestor}`.trim();
        }
        return value;
    }

    /**
     * Returns the agrupation name based on the provided value.
     * @param value - The value to be processed.
     * @returns The agrupation name as a string.
     */
    agrupationPipe(value: any): string {
        if (!value) return '';

        let temp = parseInt(value);
        if (typeof temp == 'number') {
            const agrupationString = localStorage.getItem('agrupations');
            if (agrupationString) {
                const agrupations: Agrupation[] = JSON.parse(agrupationString);
                if (agrupations && agrupations.length > 0) {
                    const agrupation: any = agrupations.find(
                        (agrupation: Agrupation) => agrupation.agrupationId == value
                    );
                    if (agrupation) return agrupation.agrupation;
                }
            }
            return '';
        }
        if (Array.isArray(value)) {
            let agrupationName = '';
            value.forEach((agrupation: Agrupation) => {
                if (agrupation) {
                    if (!agrupationName) {
                        agrupationName = `${agrupation.agrupation}`.trim();
                    } else {
                        agrupationName += `, ${agrupation.agrupation}`.trim();
                    }
                }
            });
            return agrupationName;
        } else {
            const manager = this.managers.find((manager) => manager.id == value);
            if (manager) return `${manager.gestor}`.trim();
        }
        return value;
    }

    /**
     * Converts a string array to a typed array.
     * 
     * @param stringArray - The string array to convert.
     * @param type - The type of the resulting array elements. Can be either 'string' or 'number'.
     * @returns The converted array.
     */
    convertStringArrayToArray(stringArray: string, type: string): any[] {
        if (stringArray) {
            stringArray = stringArray.replace('[', '');
            stringArray = stringArray.replace(']', '');
            const tempArray = stringArray.split(',');
            if (type === 'string') return tempArray;
            if (type === 'number') {
                const intArray = tempArray.map((e) => parseInt(e));
                return intArray;
            }
        }
        return [];
    }

    /**
     * Clears the local storage, optionally saving specific keys and their values.
     * @param keysToSave - An array of keys to save before clearing the local storage. Defaults to `undefined`.
     */
    clearLocalStorage(keysToSave?: string[]) {
        let keyValuesToSave: any = {};
        if (keysToSave) {
            for (const key of keysToSave) {
                try {
                    keyValuesToSave[key] = localStorage.getItem(key);
                } catch (err) {}
            }
        }
        localStorage.clear();
        if (keysToSave) {
            for (const key of keysToSave) {
                try {
                    if (keyValuesToSave[key]) localStorage.setItem(key, keyValuesToSave[key]);
                } catch (err) {}
            }
        }
    }

    /**
     * Applies a pipe to a given value based on the provided arguments.
     * If the arguments match any special fields, a special field pipe is applied.
     * Otherwise, a table data pipe is applied.
     * 
     * @param value - The value to be piped.
     * @param args - The arguments used to determine the pipe to be applied.
     * @returns The piped value.
     */
    fieldPipe(value: any, args: any) {
        if (args && this.specialFields.includes(args)) return this.specialFieldPipe(value, args);
        return this.tableDataPipe(value);
    }

    /**
     * Formats the given value based on its type and returns a string representation.
     * @param value - The value to be formatted.
     * @param shorten - A boolean value indicating whether the returned string should be shortened if it exceeds 40 characters. Default is true.
     * @returns The formatted string representation of the value.
     */
    tableDataPipe(value: any, shorten: boolean = true): string {
        if (value) {
            if (Array.isArray(value)) {
                if (value[0] && (value[0].id || value[0].value)) {
                    let str = value.map((v) => v.value).join(' - ');
                    if (str.length > 40 && shorten) str = str.slice(0, 40) + '...';
                    return str;
                } 
                else return value.join(' - ');
            } 
            else if (this.isValidDate(value)) return this.datepipe.transform(value, 'dd/MM/yyyy HH:mm') || '';
            else if (typeof value == 'boolean') return value ? 'Si' : 'No';
            else if (typeof value == 'number') return value.toString();
            else if (typeof value == 'string') {
                if (value.length > 40 && shorten) value = value.slice(0, 40) + '...';
                return value;
            } 
        }
        return '';
    }

    //name of the column in database
    public notNormalFilterFields = [
        'sectors',
        'fechas_nota_aviso',
        'fechas_tocado_puerta',
        'fechas_no_contesta',
        'MENSAJE_LIBRE',
        'OBSERVADV',
        'OPERARIO',
        'operario',
        'total',
        'OPERARIOS',
        'MODIFICADOR',
        'piezas',
        'servicios',
        'suministros',
        'telefonos_cliente',
    ];

    //Name of the column displayed
    specialFields = [
        'PERIMETROS',
        'EQUIPO',
        'ESTADO',
        'TIPORDEN',
        'Estado',
        'PRIORIDAD',
        'OPERARIO',
        'MODIFICADOR',
        'Operarios',
        'GESTOR',
        'Usuario activador',
        'Ubicación',
    ];

    /**
     * Transforms the given value based on the specified argument.
     * @param value - The value to be transformed.
     * @param args - The argument specifying the transformation to be applied.
     * @returns The transformed value.
     */
    specialFieldPipe(value: any, args?: string): string {
        if(args) {
            if (args == 'PERIMETROS') return this.sectorPipe(value);
            if (args == 'EQUIPO') return this.teamPipe(value);
            if (args == 'ESTADO') return this.getTaskStatusFromInt(value);
            if (args == 'TIPORDEN') return this.getTaskOrderFromInt(value);
            if (args == 'Estado') return this.getCounterStatusFromInt(value);
            if (args == 'PRIORIDAD') return this.getPriorityFromInt(value);
            if (args == 'OPERARIO' || args == 'Operarios') return this.userPipe(value);
            if (args == 'MODIFICADOR') return this.userPipe(value);
            if (args == 'Usuario activador') return this.userPipe(value);
            if (args == 'Ubicación') return this.getGeolocationUrl(value);
            if (args?.toUpperCase() == 'GESTOR') return this.managerPipe(value);
        }
        return value;
    }

    /**
     * Converts an array of sectors or a string of sector IDs into a formatted string of sector names.
     * 
     * @param sectors - An array of Sector objects or a string of comma-separated sector IDs.
     * @returns A formatted string of sector names.
     */
    sectorPipe(sectors: Sector[] | string): string {
        if(typeof sectors == 'string') {
            try {
                if(sectors.includes(',')){
                    const secs = [];
                    const ids = sectors.split(',').map((id: string) => parseInt(id, 10));
                    for (let id of ids) {
                        const sector: any = this.sectors.find((sector: Sector) => sector.id == id);
                        if (sector) secs.push(sector);
                    }
                    return secs.map((sector: Sector) => sector.name).join(', ');
                }
                else{
                    const sectorId = parseInt(sectors, 10);
                    const sector: any = this.sectors.find((sector: Sector) => sector.id == sectorId);
                    if (sector) return sector.name;
                }
            } catch (err) {}
        }
        else if(Array.isArray(sectors) && sectors.length) {
            const names = sectors.map((sector: Sector) => sector.name);
            return names.join(', ');
        }
        return '';
    }

    /**
     * Returns a string array representing the start and end date of a date range.
     * @param dateRange - An array of Date objects representing the start and end dates.
     * @param timeRange - An optional array of Date objects representing the start and end times.
     * @returns An array of two strings representing the start and end dates in the format 'YYYY-MM-DD HH:mm:ss.zzz'.
     * If the dateRange parameter is null, the method returns null.
     */
    getDateRangeString(dateRange: Date[], timeRange?: Date[]) {
        if (dateRange) {
            let startDate: Moment = moment(dateRange[0]);
            let endDate: Moment = (dateRange.length > 1)? moment(dateRange[1]): startDate;

            let h_end = 0,
                m_end = 0;
            if (timeRange && timeRange.length > 1) {
                (h_end = timeRange[1].getHours()), (m_end = timeRange[1].getMinutes());
            } else {
                (h_end = 23), (m_end = 59);
            }

            if (timeRange && timeRange.length > 1) {
                startDate = startDate.set({
                    hour: timeRange[0].getHours(),
                    minute: timeRange[0].getMinutes(),
                });
            }
            const startDateString = startDate.format('YYYY-MM-DD HH:mm:ss.zzz');
            if (!endDate) endDate = startDate;
            endDate = endDate.set({ hour: h_end, minute: m_end, second: 59 });
            const endDateString = endDate.format('YYYY-MM-DD HH:mm:ss.zzz'); //plus one to filter range

            return [startDateString, endDateString];
        }
        return null;
    }

    /**
     * Checks if the provided value is a valid date.
     * 
     * @param d - The value to be checked.
     * @returns A boolean indicating whether the value is a valid date or not.
     */
    isValidDate(d: any) {
        try {
            if (Object.prototype.toString.call(d) === '[object Date]') {
                if (isNaN(d)) {
                    return false;
                } else {
                    return true;
                }
            }
            if (typeof d == 'string' && d.includes('-') && d.includes(':') && d.includes('Z')) {
                const date = Date.parse(d);
                if (!isNaN(date)) {
                    return true;
                }
            }
        } catch (err) {}
        return false;
    }

    /**
     * Generates a random location within the specified bounds.
     * @param bounds - The bounds within which the random location should be generated.
     * @param lngSpan - The longitude span of the bounds.
     * @param latSpan - The latitude span of the bounds.
     * @returns A randomly generated location within the specified bounds.
     */
    getRandomLocation(bounds: google.maps.LatLngBounds, lngSpan: any, latSpan: any): MyLatLng {
        const lat = bounds.getSouthWest().lat() + latSpan * Math.random();
        const lng = bounds.getSouthWest().lng() + lngSpan * Math.random();
        return this.createLatLng(lat, lng);
    }

    /**
     * Calculates the bounds of a given path.
     * @param path - The path to calculate the bounds from.
     * @returns The calculated bounds.
     */
    getBoundsFromPath(path: any) {
        var bounds = new google.maps.LatLngBounds();
        path.forEach((point: any) => {
            bounds.extend(point.value);
        });
        return bounds;
    }

    /**
     * Retrieves the right coordinates from the given task.
     * If the task has a `codigo_de_localizacion`, it is returned.
     * If the task has a `geolocalizacion`, it is returned.
     * If neither is available, `undefined` is returned.
     * 
     * @param task - The task object containing the coordinates.
     * @returns The coordinates of the task, or `undefined` if not available.
     */
    getRightCoordinates(task: WaterTask | TaskLocationInfo) {
        if (task.codigo_de_localizacion) return task.codigo_de_localizacion;
        else if (task.geolocalizacion) return task.geolocalizacion;
        return undefined;
    }

    /**
     * Converts a string representation of latitude and longitude into a LatLng object.
     * @param geoString The string representation of latitude and longitude.
     * @returns The LatLng object representing the latitude and longitude.
     */
    getMyLatLngFromString(geoString: string) {
        return myLatLngFromString(geoString);
    }

    /**
     * Fills the task location with the URL geolocation if available.
     * @param waterTask - The water task object.
     * @returns The updated water task object.
     */
    fullFillTaskLocationWithURLGeo(waterTask: WaterTask): WaterTask {
        if (waterTask.url_geolocalizacion) {
            let split = waterTask.url_geolocalizacion.split('=');
            if (split && split.length > 1) {
                const lat = split[1].split(',')[0];
                const lng = split[1].split(',')[1];
                const geolocation: MyLatLng = this.createLatLng(parseFloat(lat), parseFloat(lng));
                if (!this.isFieldValid(waterTask.geolocalizacion))
                    waterTask.geolocalizacion = geolocation;
                if (!this.isFieldValid(waterTask.codigo_de_localizacion))
                    waterTask.codigo_de_localizacion = geolocation;
            }
        }
        const coords: MyLatLng | undefined = this.getRightCoordinates(waterTask); //TOOD: test this
        if (coords) {
            waterTask.pendent_location = false;
            waterTask.url_geolocalizacion = this.getGeolocationUrl(coords);
        }
        return waterTask;
    }

    /**
     * Fills the location of an Itac object with the geolocation from the old app.
     * If the old geolocation is provided and valid, it updates the geolocation of the Itac object.
     * 
     * @param itac - The Itac object to update.
     * @param old_geolocation - The old geolocation string.
     * @returns The updated Itac object.
     */
    fullFillItacLocationWithOldAppGeolocation(itac: Itac, old_geolocation: string): Itac {
        if (old_geolocation) {
            if (old_geolocation.includes(',')) {
                const lng = old_geolocation.split(',')[1];
                const lat = old_geolocation.split(',')[0];
                const geolocation: MyLatLng = this.createLatLng(parseFloat(lat), parseFloat(lng));
                if (!this.isFieldValid(itac.geolocalizacion))
                    itac.geolocalizacion = geolocation;
            }
        }
        return itac;
    }

    /**
     * Validates the emplacement code for a given water task.
     * If the ruta and NUME fields are valid, it generates an emplacement code based on the company and manager selected,
     * as well as the ruta, NUME, and BIS fields of the water task. The generated emplacement code is then assigned to the
     * codigo_de_geolocalizacion property of the water task.
     * If the ruta or NUME fields are not valid, the codigo_de_geolocalizacion property is assigned the value of the
     * Numero_de_ABONADO property of the water task.
     * @param waterTask - The water task object to validate and generate the emplacement code for.
     */
    onEmplacementCodeNotValid(waterTask: WaterTask): void {
        if (this.isFieldValid(waterTask.ruta) && this.isFieldValid(waterTask.NUME)) {
            try {
                const companySelected = JSON.parse(localStorage.getItem('companyJson') || '');
                const managerSelected = JSON.parse(localStorage.getItem('managerJson') || '');
                const companyPrefix =
                    companySelected.nombre_empresa.trim()[0] + companySelected.id;
                const managerPrefix = managerSelected.gestor.trim()[0] + managerSelected.id;

                const BIS = this.isFieldValid(waterTask.BIS)
                    ? '-' + waterTask.BIS?.trim()
                    : '';
                const portalInt = parseInt(waterTask.NUME!);
                let emplacement_code = '';
                if (isNaN(portalInt))
                    emplacement_code = `${waterTask.ruta}.${waterTask.NUME || ''}${BIS}`;
                else emplacement_code = `${waterTask.ruta}.${portalInt.toString()}${BIS}`;
                waterTask.codigo_de_geolocalizacion = `${companyPrefix}_${managerPrefix}_${emplacement_code}`;
            } catch (err) {
                console.log('============= err =============');
                console.log(err);
            }
        } else {
            waterTask.codigo_de_geolocalizacion = waterTask.Numero_de_ABONADO;
        }
    }

    /**
     * Sets the company and manager values in the given WaterTask object.
     * If the values are not present in the local storage or cannot be parsed as integers, default values of 10000 will be used.
     * 
     * @param waterTask - The WaterTask object to update.
     */
    setCompanyAndManagerInTask(waterTask: WaterTask) {
        const company = localStorage.getItem('company');
        const manager = localStorage.getItem('manager');
        try {
            waterTask.company = parseInt(company!) || 10000;
        } catch (err) {
            waterTask.company = 10000;
        }
        try {
            waterTask.GESTOR = parseInt(manager!) || 10000;
        } catch (err) {
            waterTask.GESTOR = 10000;
        }
    }

    /**
     * Fulfills a water task by populating its properties with default values or values from local storage.
     * @param waterTask - The water task to fulfill.
     * @param idOrdenStart - The starting ID for the water task.
     * @returns The fulfilled water task.
     */
    fullFillTask(waterTask: WaterTask, idOrdenStart: number): WaterTask {
        this.setCompanyAndManagerInTask(waterTask);

        waterTask.ID_SAT = idOrdenStart;
        waterTask.idOrdenCABB = idOrdenStart;

        if (!waterTask.NUMIN) {
            waterTask.NUMIN = moment.now().toString() + waterTask.Numero_de_ABONADO + this.getRandomInt(1000);
        }
        waterTask.NUMIN = this.removeInvalidPhotoUrlCharacters(waterTask.NUMIN.toString());
        
        if (!waterTask.Numero_de_ABONADO) waterTask.Numero_de_ABONADO = waterTask.NUMIN;
        waterTask.Numero_de_ABONADO = this.removeInvalidPhotoUrlCharacters(waterTask.Numero_de_ABONADO.toString());
        
        if (this.isFieldNotValid(waterTask.SERIE)) waterTask.SERIE = '0000';

        if (!waterTask.TIPORDEN) waterTask.TIPORDEN = order_status.DAILY;
        
        if (!waterTask.ANOMALIA) waterTask.ANOMALIA = defaultAnomaly;
        else {
            if (waterTask.ANOMALIA.length > 3) waterTask.ANOMALIA = waterTask.ANOMALIA.substring(0, 3);
            waterTask.ANOMALIA = waterTask.ANOMALIA.replace(/^\s+|\s+$/g, '');
        }
        waterTask.ANOMALIA = this.removeInvalidPhotoUrlCharacters(waterTask.ANOMALIA);
        
        if (!waterTask.status_tarea)  waterTask.status_tarea = task_status.IDLE;
        
        if (this.isClientUser()) waterTask.status_tarea = task_status.REQUIRED;

        if (waterTask.ruta) waterTask.ruta = this.addCeros(waterTask.ruta, 6);

        if (
            waterTask.ubicacion_en_bateria &&
            waterTask.EMPLAZA &&
            waterTask.ubicacion_en_bateria.includes('-')
        ) {
            if (!waterTask.ubicacion_en_bateria.includes(waterTask.EMPLAZA!)) {
                waterTask.ubicacion_en_bateria = waterTask.EMPLAZA + waterTask.ubicacion_en_bateria;
            }
        }

        if (!waterTask.codigo_de_geolocalizacion) this.onEmplacementCodeNotValid(waterTask);
        
        if(waterTask.codigo_de_geolocalizacion) {
            waterTask.codigo_de_geolocalizacion = this.removeInvalidPhotoUrlCharacters(waterTask.codigo_de_geolocalizacion);
        }

        if (!waterTask.prioridad) waterTask.prioridad = priority_status.LOW;
        
        if (this.isFieldValid(waterTask.ACCESO) && this.isFieldValid(waterTask.OBSERVA)) {
            waterTask.observaciones = `${waterTask.ACCESO}.${waterTask.OBSERVA}`;
        }

        waterTask.FechImportacion = new Date();
        if (!waterTask.FECEMISIO) waterTask.FECEMISIO = waterTask.FechImportacion;

        waterTask = this.autocompleteCauseFields(waterTask);

        if (waterTask.CALIBRE?.includes('-')) {
            if (waterTask.CALIBRE?.split('-').length > 1) {
                const longitude = waterTask.CALIBRE?.split('-')[1];
                waterTask.LARGO = longitude;
            }
        }
        if (waterTask.telefono1?.includes('-')) {
            if (waterTask.telefono1?.split('-').length > 1) {
                const phone2 = waterTask.CALIBRE?.split('-')[1];
                waterTask.telefono2 = phone2;
            }
        }
        if (waterTask.telefono1?.includes(' ')) {
            if (waterTask.telefono1?.split(' ').length > 1) {
                const phone2 = waterTask.CALIBRE?.split(' ')[1];
                waterTask.telefono2 = phone2;
            }
        }
        if (waterTask.NUME) waterTask.NUME = this.addCeros(waterTask.NUME, 3);

        waterTask = this.searchAppointmentInObservations(waterTask);
        waterTask = this.searchPhonesInObservations(waterTask);

        if (!waterTask.observaciones) waterTask.observaciones = waterTask.OBSERVA;

        if (waterTask.ACCESO && waterTask.ACCESO.includes('BAT')) waterTask.contador_bateria = true;

        this.setBatteryLocationData(waterTask);
        return waterTask;
    }

    /**
     * Sets the battery location data for a given water task.
     * If the EMPLAZA property of the water task includes 'BA-' or 'BT-', it sets the following properties:
     * - acceso_devuelto: 'BAT'
     * - contador_bateria: true
     * - ubicacion_en_bateria: the value of EMPLAZA
     * 
     * @param waterTask - The water task for which to set the battery location data.
     */
    setBatteryLocationData(waterTask: WaterTask) {
        if (waterTask.EMPLAZA && (waterTask.EMPLAZA.includes('BA-') || waterTask.EMPLAZA.includes('BT-'))) {
            waterTask.acceso_devuelto = 'BAT';
            waterTask.contador_bateria = true;
            waterTask.ubicacion_en_bateria = waterTask.EMPLAZA;
        }
    }

    /**
     * @brief Esta función elimina caracteres inválidos de una cadena de URL de foto.
     *
     * @param str - La cadena de URL de foto de entrada.
     * @return La cadena resultante después de eliminar caracteres inválidos y sanitizarla para su uso como nombre de carpeta.
     */
    removeInvalidPhotoUrlCharacters(str: string): string {
        // Elimina caracteres inválidos de la cadena de entrada
        let cleanedUrl = this.removeInvalidUrlCharacters(str);
        // Sanitiza la cadena resultante para su uso como nombre de carpeta
        cleanedUrl = this.sanitizeFolderName(cleanedUrl);
        return cleanedUrl;
    }
    
    /**
     * @brief Esta función elimina caracteres inválidos de una cadena de URL.
     *
     * @param inputUrl - La cadena de URL de entrada.
     * @return La cadena resultante después de eliminar caracteres inválidos.
     */
    removeInvalidUrlCharacters(inputUrl: string): string {
        // Define un patrón regex para coincidir con caracteres no permitidos en una URL
        const regex = /[^a-zA-Z0-9\-._~:/?#[\]@!$&'()*+,;=]/g;
        // Utiliza el método replace para reemplazar los caracteres inválidos por una cadena vacía
        const cleanedUrl = inputUrl.replace(regex, '');
        return cleanedUrl;
    }
    
    /**
     * @brief Esta función sanea un nombre de carpeta reemplazando caracteres inválidos con guiones bajos.
     *
     * @param folderName - El nombre de la carpeta a sanear.
     * @return El nombre de carpeta saneado después de reemplazar caracteres inválidos y eliminar espacios y guiones bajos al principio y al final.
     */
    sanitizeFolderName(folderName: string): string {
        // Define un patrón regex para coincidir con caracteres no permitidos en un nombre de carpeta
        const disallowedChars = /[\\/:"*?<>|,;=!#+()~\[\]@&]/g;
        // Reemplaza los caracteres inválidos por guiones bajos
        let sanitizedName = folderName.replace(disallowedChars, '_');
        // Elimina espacios y guiones bajos al principio y al final
        sanitizedName = sanitizedName.trim().replace(/^_+|_+$/g, '');
        return sanitizedName;
    }
    
    /**
     * Autocompletes the cause fields of a WaterTask object.
     * 
     * @param waterTask - The WaterTask object to autocomplete the cause fields for.
     * @returns The updated WaterTask object with the cause fields autocompleted.
     */
    autocompleteCauseFields(waterTask: WaterTask) {
        const taskType = this.getTaskType(
            waterTask.ANOMALIA!.toString(),
            waterTask.CALIBRE?.toString(),
            waterTask.MARCA
        );
        waterTask.tipo_tarea = taskType;
        waterTask.INTERVENCI = this.getIterventionFromCauseCode(waterTask.ANOMALIA!);
        waterTask.causa_origen = `${waterTask.ANOMALIA!} - ${waterTask.INTERVENCI}`;
        waterTask.accion_ordenada = this.getOrderActionFromCauseCode(waterTask.ANOMALIA!);
        waterTask.AREALIZAR = this.getToDoFromCauseCode(waterTask.ANOMALIA!);
        return waterTask;
    }

    /**
     * Retrieves the task type from the given anomaly cause code.
     * @param anomaly - The anomaly cause code.
     * @returns The task type associated with the cause code.
     */
    getTaskTypeFromCauseCode(anomaly: string): string {
        //only in cause code
        const t =
            this.causes.find((cause) => cause.codigo_causa == anomaly)?.tipo_tarea ||
            defaultTaskType;
        return t;
    }

    /**
     * Retrieves the intervention from the cause code.
     * @param anomaly - The cause code for which to retrieve the intervention.
     * @returns The intervention associated with the cause code.
     */
    getIterventionFromCauseCode(anomaly: string): string {
        const t =
            this.causes.find((cause) => cause.codigo_causa == anomaly)?.causa ||
            defaultTaskIntervention;
        return t;
    }

    /**
     * Retrieves the order action associated with a given anomaly cause code.
     * If no order action is found for the cause code, the default task order action is returned.
     *
     * @param anomaly - The anomaly cause code.
     * @returns The order action associated with the cause code, or the default task order action.
     */
    getOrderActionFromCauseCode(anomaly: string): string {
        const t =
            this.causes.find((cause) => cause.codigo_causa == anomaly)?.accion_ordenada ||
            defaultTaskOrderAction;
        return t;
    }

    /**
     * Retrieves the task to be done based on the given anomaly code.
     * @param anomaly - The anomaly code.
     * @returns The task to be done.
     */
    getToDoFromCauseCode(anomaly: string): string {
        //get arealizar
        const t =
            this.causes.find((cause) => cause.codigo_causa == anomaly)?.arealizar ||
            defaultTaskToDo;
        return t;
    }

    /**
     * Returns the task type based on the provided anomaly, caliber, and mark.
     * @param anomaly - The anomaly string.
     * @param caliber - The caliber string (optional).
     * @param mark - The mark string (optional).
     * @returns The task type based on the provided inputs.
     */
    getTaskType(anomaly: string, caliber?: string, mark?: string) {
        if (!caliber) caliber = '?';
        if (!anomaly) return 'NCI ' + caliber + 'mm';
        const taskType = this.getTaskTypeFromCauseCode(anomaly).trim();

        caliber = caliber?.trim();

        if (taskType == 'NCI')          return 'NCI ' + caliber + 'mm';
        else if (taskType == 'NCI + RC')return 'NCI ' + caliber + 'mm ' + '+ RC';
        else if (taskType == 'ITAC')    return 'ITAC';
        else if (taskType == 'LFTD')    return 'LFTD ' + caliber + 'mm';
        else if (taskType == 'S.I.')    return 'S.I. ' + caliber + 'mm';
        else if (taskType == 'U')       return 'U ' + caliber + 'mm';
        else if (taskType == 'TD') {
            if (mark?.toLowerCase().includes('sappel')) return 'TD';
            return 'TD/NCI ' + caliber + 'mm';
        } 
        else if (taskType == 'I+') return 'I+';
        else if (taskType == 'I+/NCI') {
            if (mark?.toLowerCase().includes('sappel')) return 'I+';
            return 'I+/NCI ' + caliber + 'mm';
        } 
        else if (taskType == 'LECT.R o RC') return 'LECT.R o RC';
        else if (taskType == 'RC')          return 'RC';
        else if (taskType == 'RC NCI')      return 'RC NCI ' + caliber + 'mm';
        else if (taskType == 'RCN')         return 'RCN';
        else if (taskType == 'C. H. W4')    return 'C. H. W4';
        else if (taskType == 'T') {
            let pre: string;
            if (caliber == '15') pre = 'T3/4" ';
            else if (caliber == '13') pre = 'T7/8" ';
            else if (caliber == '20') pre = 'T1" ';
            else if (caliber == '25') pre = 'T11/4" ';
            else if (caliber == '30') pre = 'T11/2" ';
            else if (caliber == '40') pre = 'T2" ';
            else pre = 'TBDN ';
            return pre + caliber + 'mm';
        } else if (taskType == 'T + NCI') {
            let pre: string;
            if (caliber == '15') pre = 'T3/4" ';
            else if (caliber == '13') pre = 'T7/8" ';
            else if (caliber == '20') pre = 'T1" ';
            else if (caliber == '25') pre = 'T11/4" ';
            else if (caliber == '30') pre = 'T11/2" ';
            else if (caliber == '40') pre = 'T2" ';
            else pre = 'TBDN ';
            return pre + '+ NCI ' + caliber + 'mm';
        }
        return taskType + ' ' + caliber + 'mm';
    }

    /**
     * Retrieves the day of the given zone.
     * 
     * @param zona_selected - The selected zone.
     * @returns The day of the zone.
     */
    getDayOfZona(zona_selected: string) {
        const bloque =
            this.zones.find((zone) => zona_selected.includes(`${zone.codigo_zona} - ${zone.zona}`))
                ?.bloque || '';
        return bloque;
    }

    /**
     * Checks if the given value contains only numbers.
     * 
     * @param value - The value to be checked.
     * @returns A boolean indicating whether the value contains only numbers.
     */
    checkIfOnlyNumbers(value: any): boolean {
        if (!this.isFieldValid(value)) return false;
        return /^\d+$/.test(value);
    }

    /**
     * Searches for appointment information in the observations of a WaterTask object and sets the corresponding date and time.
     * @param waterTask - The WaterTask object to search and update.
     * @returns The updated WaterTask object with the appointment date and time set.
     */
    searchAppointmentInObservations(waterTask: WaterTask) {
        try {
            let observaciones_string = waterTask.observaciones?.trim().toUpperCase();
            if (observaciones_string && observaciones_string.includes('CITA A LAS ')) {
                const dateMoment = moment(observaciones_string, '[CITA A LAS] HH:mm');
                waterTask = this.setDateTime(waterTask, dateMoment, dateMoment);
            } else if (observaciones_string && observaciones_string.includes('CITA ENTRE LAS ')) {
                let hora_string: string = observaciones_string.split(' Y LAS ')[0].trim();
                let hora2_string: string = observaciones_string.split(' Y LAS ')[1].trim();

                const startDateMoment = moment(hora_string, '[CITA ENTRE LAS] HH:mm');
                const endDateMoment = moment(hora_string, 'HH:mm');
                waterTask = this.setDateTime(waterTask, startDateMoment, endDateMoment);
            } else {
                if (
                    observaciones_string &&
                    observaciones_string.includes('CITA') &&
                    observaciones_string.includes(' A LAS ')
                ) {
                    const dateMoment = moment(observaciones_string, '[CITA] D/M [A LAS] HH:mm');
                    waterTask = this.setDateTime(waterTask, dateMoment, dateMoment);
                } else if (
                    observaciones_string &&
                    observaciones_string.includes('CITA') &&
                    observaciones_string.includes(' ENTRE LAS ') &&
                    observaciones_string.includes(' Y LAS ')
                ) {
                    let hora_string: string = observaciones_string.split(' Y LAS ')[0].trim();
                    let hora2_string: string = observaciones_string.split(' Y LAS ')[1].trim();

                    const startDateMoment = moment(hora_string, '[CITA] D/M [ENTRE LAS] HH:mm');
                    const endDateMoment = moment(hora_string, 'HH:mm');
                    waterTask = this.setDateTime(waterTask, startDateMoment, endDateMoment);
                }
            }
        } catch (err) {}
        return waterTask;
    }

    /**
     * Checks if a given date is deprecated.
     * @param taskDate - The date to check.
     * @returns True if the date is deprecated, false otherwise.
     */
    checkIfDateIsDepreceated(taskDate: Date) {
        let dateDepreated = moment(new Date());
        dateDepreated.add(15, 'minutes');
        let momentDate = moment(taskDate);
        if (momentDate.isBefore(dateDepreated)) return true;
        return false;
    }

    /**
     * Sets the date and time for a water task.
     * 
     * @param waterTask - The water task to set the date and time for.
     * @param startDate - The start date and time for the water task.
     * @param endDate - The end date and time for the water task (optional).
     * @returns The updated water task object.
     */
    setDateTime(waterTask: WaterTask, startDate: Moment, endDate?: Moment) {
        if (!endDate || !endDate.isValid()) {
            endDate = startDate;
        }
        try {
            moment.locale('es');
            const startDateString = startDate
                .locale('es')
                .format('dddd, D [de] MMMM [del] YYYY, [Entre las] HH:mm');
            const endDateString = endDate.locale('es').format(' [y las] HH:mm');
            waterTask.nuevo_citas = `${startDateString} ${endDateString}`;

            waterTask.cita_pendiente = true;

            waterTask.fecha_hora_cita = startDate.toDate();
            waterTask.fecha_hora_cita_end = endDate.toDate();
        } catch (e) {}
        return waterTask;
    }

    /**
     * Searches for phone numbers in the given WaterTask's observations.
     * @param waterTask - The WaterTask object to search in.
     * @returns The updated WaterTask object.
     */
    searchPhonesInObservations(waterTask: WaterTask) {
        try {
            this.findPhoneInField(waterTask, 'observaciones');
            this.findPhoneInField(waterTask, 'OBSERVA');
        } catch (err) {}
        return waterTask;
    }
    
    /**
     * Finds and extracts phone numbers from a specific field in a WaterTask object.
     * @param waterTask - The WaterTask object to search for phone numbers.
     * @param field_name - The name of the field in the WaterTask object to search for phone numbers.
     */
    findPhoneInField(waterTask: WaterTask, field_name: string) {
        let field_string = waterTask[field_name as keyof WaterTask];
        if (field_string && typeof field_string == 'string') {
            field_string = field_string.trim() || '';
            let tels: string = field_string;
            let tel2: string = '';
            let tel1: string = '';
            let split1: string;
            let split2: string;
            if (tels.includes('-') && tels.split('-').length >= 2) {
                split2 = tels.split('-')[1].trim();
                split1 = tels.split('-')[0].trim();
                if (this.checkIfOnlyNumbers(split2)) tel2 = split2;                
                if (this.checkIfOnlyNumbers(split1)) tel1 = split1;
            } else if (tels.includes(' ') && tels.split(' ').length >= 2) {
                split2 = tels.split(' ')[1].trim();
                split1 = tels.split(' ')[0].trim();
                if (this.checkIfOnlyNumbers(split2)) tel2 = split2;                
                if (this.checkIfOnlyNumbers(split1)) tel1 = split1;                
            }
            if (this.checkIfOnlyNumbers(tel1)) waterTask.telefono1 = tel1;
            if (this.checkIfOnlyNumbers(tel2)) waterTask.telefono2 = tel2;

            if (this.isFieldValid(field_string)) {
                let telefono: string = '';
                let first_digit: boolean = false;
                for (let i = 0; i < field_string.length; i++) {
                    if (this.checkIfOnlyNumbers(field_string[i])) {
                        first_digit = true;
                        telefono += field_string[i];
                    } else if (first_digit) break;
                }
                if (telefono.length > 8) waterTask.telefono1 = telefono;
            }
        }
    }

    /**
     * Opens the filter configuration dialog.
     * 
     * @param table_name - The name of the table.
     * @param filter - The filter object.
     * @returns A promise that resolves with the result of the dialog.
     */
    openFilterConfigurationDialog(table_name: string, filter?: MiRutaFilter): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.filterConfigurationDialogRef = this.filterDialog.open(
                FilterConfigurationComponent,
                {
                    data: { table_name: table_name, filter: filter },
                }
            );
            this.filterConfigurationDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    
    /**
     * Opens a filter dialog for a specific column in a table.
     * @param column_name - The name of the column.
     * @param column - The column identifier.
     * @param table_name - The name of the table.
     * @param filter - Optional filter object.
     * @returns A promise that resolves with the result of the filter dialog.
     */
    openFilterDialog(
        column_name: string,
        column: string,
        table_name: string,
        filter?: MiRutaFilter
    ): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.filterDialogRef = this.filterDialog.open(FilterComponent, {
                data: {
                    column: column,
                    column_name: column_name,
                    table_name: table_name,
                    filter: filter,
                },
            });
            this.filterDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeFilterDialog() {
        this.filterDialogRef.close();
    }

    openMapZoneAssignation(): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.mapDialogRef = this.dialog.open(AssignZoneLocationComponent);
            this.mapDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeMapDialog(path: any) {
        this.mapDialogRef.close(path);
    }

    openUserFilterDialog(column_name: string, column: string, filter?: MiRutaFilter): Promise<any> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.userFilterDialogRef = this.filterDialog.open(UserFilterComponent, {
                data: {
                    column: column,
                    column_name: column_name,
                    filter: filter,
                },
            });
            this.userFilterDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeUserFilterDialog() {
        this.userFilterDialogRef.close();
    }

    openUserActivityChartDialog(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            this.userActivityRef = this.dialog.open(UserActivityComponent);
        });
    }

    /**
     * Opens a date range selector dialog and returns a promise that resolves with the selected dates.
     * @param placeHolderText - The placeholder text to display in the date range selector dialog.
     * @returns A promise that resolves with an array of selected dates.
     */
    openDateRangeSelectorDialog(placeHolderText: string): Promise<Date[]> {
        return new Promise<Date[]>(async (resolve, reject) => {
            this.dateRangeSelectorDialogRef = this.dialog.open(DateRangeSelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                },
            });
            this.dateRangeSelectorDialogRef.beforeClosed().subscribe((result: Date[]) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }

    /**
     * Opens a date selector dialog and returns a promise that resolves to the selected date.
     * @param placeHolderText - The placeholder text to display in the date selector dialog.
     * @param defaultDate - The default date to preselect in the date selector dialog (optional).
     * @returns A promise that resolves to the selected date.
     */
    openDateSelectorDialog(placeHolderText: string, defaultDate?: Date): Promise<Date> {
        return new Promise<Date>(async (resolve, reject) => {
            this.dateSelectorDialogRef = this.dialog.open(DateSelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                    defaultDate: defaultDate,
                },
            });
            this.dateSelectorDialogRef.beforeClosed().subscribe((result: Date) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }
    /**
     * Opens a dialog for selecting a date and time range.
     * 
     * @param placeHolderText - The placeholder text for the date input field.
     * @param placeHolderTextStart - The placeholder text for the start time input field.
     * @param placeHolderTextEnd - The placeholder text for the end time input field.
     * @param show_date_status - A boolean indicating whether to show the date status.
     * @param currentDateObject - An optional parameter representing the current water task.
     * @returns A promise that resolves with the selected date and time range, or rejects with an error message if no selection is made.
     */
    openDateTimeRangeSelectorDialog(
        placeHolderText: string = 'Seleccione fecha',
        placeHolderTextStart: string = 'Hora inicial',
        placeHolderTextEnd: string = 'Hora final',
        show_date_status: boolean = false,
        currentDateObject?: WaterTask,
        is_date_range?: boolean
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.dateTimeRangeSelectorDialogRef = this.dialog.open(DateTimeSelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                    placeHolderTextStart: placeHolderTextStart,
                    placeHolderTextEnd: placeHolderTextEnd,
                    show_date_status: show_date_status,
                    currentDateObject: currentDateObject,
                    is_date_range: is_date_range,
                },
            });
            this.dateTimeRangeSelectorDialogRef.beforeClosed().subscribe((result: any) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }
    closeDateTimeRangeSelectorDialog(result: any) {
        this.dateTimeRangeSelectorDialogRef.close(result);
    }

    openTimeRangeSelectorDialog(placeHolderText: string): Promise<Date[]> {
        return new Promise<Date[]>(async (resolve, reject) => {
            this.timeRangeSelectorDialogRef = this.dialog.open(TimeRangeSelectorComponent, {
                data: {
                    placeHolderTextStart: placeHolderText,
                    placeHolderTextEnd: placeHolderText,
                },
            });
            this.timeRangeSelectorDialogRef.beforeClosed().subscribe((result: Date[]) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }
    openTimeSelectorDialog(placeHolderText: string): Promise<Date> {
        return new Promise<Date>(async (resolve, reject) => {
            this.timeSelectorDialogRef = this.dialog.open(TimeSelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                },
            });
            this.timeSelectorDialogRef.beforeClosed().subscribe((result: Date) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }
    openWhatsappMessageDialog(task?: WaterTask, itac?: Itac): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            this.whatsappMessageDialogRef = this.dialog.open(WhatsappMessageComponent, {
                data: {
                    task: task,
                    itac: itac,
                },
            });
            this.whatsappMessageDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }

    openCommonServicesInformDialog(task: WaterTask): Promise<WaterTask> {
        return new Promise<WaterTask>(async (resolve, reject) => {
            this.commonServicesInformDialogRef = this.dialog.open(CommonServicesInformComponent, {
                data: { task: task }
            });
            this.commonServicesInformDialogRef.beforeClosed().subscribe((result) => {
                if (result) resolve(result);
                else reject();
            });
        });
    }
    closeCommonServicesInformDialog(task: WaterTask) {
        this.commonServicesInformDialogRef.close(task);
    }
    
    openServicesInformDialog(task: WaterTask): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.servicesInformDialogRef = this.dialog.open(ServicesInformComponent, {
                data: {
                    task: task,
                },
            });
            this.servicesInformDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }
    closeServicesInformDialog(result: boolean) {
        this.servicesInformDialogRef.close(result);
    }

    openTaskAssignDialog(): Promise<WaterTask> {
        return new Promise<WaterTask>(async (resolve, reject) => {
            this.taskAssignDialogRef = this.dialog.open(TaskAssignComponent);
            this.taskAssignDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject(false);
                }
            });
        });
    }
    closeTaskAssignDialog(result: WaterTask) {
        this.taskAssignDialogRef.close(result);
    }

    openPartsSelectionDialog(parts?: string[]): Promise<WaterTask> {
        return new Promise<WaterTask>(async (resolve, reject) => {
            this.partsSelectionDialogRef = this.dialog.open(PartsSelectionComponent, {
                data: {
                    parts: parts,
                },
            });
            this.partsSelectionDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject(false);
                }
            });
        });
    }
    closePartsSelectionDialog(result: string[]) {
        this.partsSelectionDialogRef.close(result);
    }

    openPhoneStatusDialog(waterTask?: WaterTask): Promise<any> {
        return new Promise<PhoneStatusAnswers>(async (resolve, reject) => {
            this.phoneStatusDialogRef = this.dialog.open(PhoneStatusComponent, {
                data: {
                    waterTask: waterTask,
                },
            });
            this.phoneStatusDialogRef.beforeClosed().subscribe((result: PhoneStatusAnswers) => {
                if (result) {
                    resolve(result);
                } else {
                    reject(false);
                }
            });
        });
    }
    closePhoneStatusDialog(result: PhoneStatusAnswers) {
        this.phoneStatusDialogRef.close(result);
    }

    openCounterDialog(counterId: string): Promise<Counter> {
        return new Promise<Counter>(async (resolve, reject) => {
            this.counterDialogRef = this.dialog.open(CounterComponent, {
                data: {
                    counterId: counterId,
                },
            });
            this.counterDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeCounterDialog(result: Counter) {
        this.counterDialogRef.close(result);
    }

    openCounterAssignDialog(assing_to_counters?: boolean): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.counterAssignDialogRef = this.dialog.open(CounterAssignComponent, {
                data: {
                    assing_to_counters: assing_to_counters || false,
                },
            });
            this.counterAssignDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeCounterAssignDialog(result: any) {
        this.counterAssignDialogRef.close(result);
    }

    openItacAssignDialog(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.itacAssignDialogRef = this.dialog.open(ItacAssignComponent);
            this.itacAssignDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeItacAssignDialog(result: any) {
        this.itacAssignDialogRef.close(result);
    }

    openWaterRouteDialog(waterRouteId: string): Promise<WaterRoute> {
        return new Promise<WaterRoute>(async (resolve, reject) => {
            this.waterRouteDialogRef = this.dialog.open(WaterRouteComponent, {
                data: {
                    waterRouteId: waterRouteId,
                },
            });
            this.waterRouteDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeWaterRouteDialog(result: WaterRoute) {
        this.waterRouteDialogRef.close(result);
    }

    openRadiusModuleDialog(radiusModuleId: string): Promise<RadiusModule> {
        return new Promise<RadiusModule>(async (resolve, reject) => {
            this.radiusModuleDialogRef = this.dialog.open(RadiusModuleComponent, {
                data: {
                    radiusModuleId: radiusModuleId,
                },
            });
            this.radiusModuleDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeRadiusModuleDialog(result: RadiusModule) {
        this.radiusModuleDialogRef.close(result);
    }

    openCaliberDialog(caliberId: string): Promise<Caliber> {
        return new Promise<Caliber>(async (resolve, reject) => {
            this.caliberDialogRef = this.dialog.open(CaliberComponent, {
                data: {
                    caliberId: caliberId,
                },
            });
            this.caliberDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeCaliberDialog(result: any) {
        this.caliberDialogRef.close(result);
    }
    openCauseDialog(causeId: string): Promise<Cause> {
        return new Promise<Cause>(async (resolve, reject) => {
            this.causeDialogRef = this.dialog.open(CauseComponent, {
                data: {
                    causeId: causeId,
                },
            });
            this.causeDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeCauseDialog(result: any) {
        this.causeDialogRef.close(result);
    }
    openClassCounterDialog(classCounterId: string): Promise<ClassCounter> {
        return new Promise<ClassCounter>(async (resolve, reject) => {
            this.classCounterDialogRef = this.dialog.open(ClassCounterComponent, {
                data: {
                    classCounterId: classCounterId,
                },
            });
            this.classCounterDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the class counter dialog and returns the specified result.
     * @param result The result to be returned when closing the dialog.
     */
    closeClassCounterDialog(result: any) {
        this.classCounterDialogRef.close(result);
    }

    /**
     * Opens the emplacement dialog.
     * @param emplacementId - The ID of the emplacement.
     * @returns A promise that resolves with the result of the dialog.
     */
    openEmplacementDialog(emplacementId: string): Promise<Emplacement> {
        return new Promise<Emplacement>(async (resolve, reject) => {
            this.emplacementDialogRef = this.dialog.open(EmplacementComponent, {
                data: {
                    emplacementId: emplacementId,
                },
            });
            this.emplacementDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the emplacement dialog and passes the result.
     * @param result The result to pass when closing the dialog.
     */
    closeEmplacementDialog(result: any) {
        this.emplacementDialogRef.close(result);
    }

    /**
     * Opens a dialog for longitude and returns a promise that resolves with the result.
     * @param longitudeId - The ID of the longitude.
     * @returns A promise that resolves with the result of the dialog.
     */
    openLongitudeDialog(longitudeId: string): Promise<Longitude> {
        return new Promise<Longitude>(async (resolve, reject) => {
            this.longitudeDialogRef = this.dialog.open(LongitudeComponent, {
                data: {
                    longitudeId: longitudeId,
                },
            });
            this.longitudeDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the longitude dialog and returns the specified result.
     * @param result - The result to be returned when closing the dialog.
     */
    closeLongitudeDialog(result: any) {
        this.longitudeDialogRef.close(result);
    }

    /**
     * Opens a dialog for a specific mark.
     * @param markId - The ID of the mark.
     * @returns A Promise that resolves with the result of the dialog.
     */
    openMarkDialog(markId: string): Promise<Mark> {
        return new Promise<Mark>(async (resolve, reject) => {
            this.markDialogRef = this.dialog.open(MarkComponent, {
                data: {
                    markId: markId,
                },
            });
            this.markDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the mark dialog and returns the specified result.
     * @param result The result to be returned when closing the mark dialog.
     */
    closeMarkDialog(result: any) {
        this.markDialogRef.close(result);
    }

    /**
     * Opens the observation dialog with the specified observation ID.
     * @param observationId - The ID of the observation.
     * @returns A promise that resolves to the result of the observation dialog.
     */
    openObservationDialog(observationId: string): Promise<Observation> {
        return new Promise<Observation>(async (resolve, reject) => {
            this.observationDialogRef = this.dialog.open(ObservationComponent, {
                data: {
                    observationId: observationId,
                },
            });
            this.observationDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the observation dialog and returns the specified result.
     * @param result The result to be returned from the observation dialog.
     */
    closeObservationDialog(result: any) {
        this.observationDialogRef.close(result);
    }

    /**
     * Opens a dialog for a specific part and returns a promise that resolves with the result.
     * @param partId - The ID of the part.
     * @returns A promise that resolves with the result of the dialog.
     */
    openPartDialog(partId: string): Promise<Part> {
        return new Promise<Part>(async (resolve, reject) => {
            this.partDialogRef = this.dialog.open(PartComponent, {
                data: {
                    partId: partId,
                },
            });
            this.partDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the part dialog and returns the specified result.
     * @param result The result to be returned when closing the dialog.
     */
    closePartDialog(result: any) {
        this.partDialogRef.close(result);
    }

    /**
     * Opens a manager message dialog and returns a promise that resolves with the selected manager message.
     * @param managerMessageId - The ID of the manager message to open.
     * @returns A promise that resolves with the selected manager message.
     */
    openManagerMessageDialog(managerMessageId: string, itac_message: boolean = false): Promise<ManagerMessage> {
        return new Promise<ManagerMessage>(async (resolve, reject) => {
            this.managerMessageDialogRef = this.dialog.open(ManagerMessageComponent, {
                data: {
                    managerMessageId: managerMessageId,
                    itac_message: itac_message,
                },
            });
            this.managerMessageDialogRef.beforeClosed().subscribe((managerMessage) => {
                resolve(managerMessage);
            });
        });
    }

    /**
     * Closes the manager message dialog.
     * @param managerMessage - The manager message to be closed.
     */
    closeManagerMessageDialog(managerMessage: any) {
        this.managerMessageDialogRef.close(managerMessage);
    }

    /**
     * Opens a planning dialog.
     * @param planningId - The ID of the planning.
     * @returns A promise that resolves with the planning object when the dialog is closed.
     */
    openPlanningDialog(planningId: string): Promise<Planning> {
        return new Promise<Planning>(async (resolve, reject) => {
            this.planningDialogRef = this.dialog.open(PlanningComponent, {
                data: {
                    planningId: planningId,
                },
            });
            this.planningDialogRef.beforeClosed().subscribe((planning) => {
                resolve(planning);
            });
        });
    }

    /**
     * Closes the planning dialog and passes the planning object.
     * @param planning The planning object to be passed when closing the dialog.
     */
    closePlanningDialog(planning: any) {
        this.planningDialogRef.close(planning);
    }

    /**
     * Opens a dialog for displaying planning detail extra.
     * 
     * @param planningDetailExtraId - The ID of the planning detail extra.
     * @returns A promise that resolves with the planning detail extra when the dialog is closed.
     */
    openPlanningDetailExtraDialog(planningDetailExtraId: string): Promise<PlanningDetailExtra> {
        return new Promise<PlanningDetailExtra>(async (resolve, reject) => {
            this.planningDetailExtraDialogRef = this.dialog.open(PlanningDetailExtraComponent, {
                data: {
                    planningDetailExtraId: planningDetailExtraId,
                },
            });
            this.planningDetailExtraDialogRef.beforeClosed().subscribe((planningDetailExtra) => {
                resolve(planningDetailExtra);
            });
        });
    }

    /**
     * Closes the planning detail dialog.
     * 
     * @param planningDetailExtra - The planning detail to be closed.
     */
    closePlanningDetailExtraDialog(planningDetailExtra: any) {
        this.planningDetailExtraDialogRef.close(planningDetailExtra);
    }

    /**
     * Opens a dialog for displaying planning detail.
     * 
     * @param planningDetailId - The ID of the planning detail.
     * @returns A promise that resolves with the planning detail when the dialog is closed.
     */
    openPlanningDetailDialog(planningDetailId: string): Promise<PlanningDetail> {
        return new Promise<PlanningDetail>(async (resolve, reject) => {
            this.planningDetailDialogRef = this.dialog.open(PlanningDetailComponent, {
                data: {
                    planningDetailId: planningDetailId,
                },
            });
            this.planningDetailDialogRef.beforeClosed().subscribe((planningDetail) => {
                resolve(planningDetail);
            });
        });
    }

    /**
     * Closes the planning detail dialog.
     * 
     * @param planningDetail - The planning detail to be closed.
     */
    closePlanningDetailDialog(planningDetail: any) {
        this.planningDetailDialogRef.close(planningDetail);
    }

    /**
     * Opens a result dialog with the specified result ID.
     * @param resultId - The ID of the result to display in the dialog.
     * @returns A promise that resolves with the result when the dialog is closed.
     */
    openResultDialog(resultId: string): Promise<Result> {
        return new Promise<Result>(async (resolve, reject) => {
            this.resultDialogRef = this.dialog.open(ResultComponent, {
                data: {
                    resultId: resultId,
                },
            });
            this.resultDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Closes the result dialog.
     * @param result - The result to be passed to the dialog close method.
     */
    closeResultDialog(result: any) {
        this.resultDialogRef.close(result);
    }

    openTypeRadiusDialog(typeRadiusId: string): Promise<TypeRadius> {
        return new Promise<TypeRadius>(async (resolve, reject) => {
            this.typeRadiusDialogRef = this.dialog.open(TypeRadiusComponent, {
                data: {
                    typeRadiusId: typeRadiusId,
                },
            });
            this.typeRadiusDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeTypeRadiusDialog(result: any) {
        this.typeRadiusDialogRef.close(result);
    }

    openTypeCounterDialog(typeCounterId: string): Promise<TypeCounter> {
        return new Promise<TypeCounter>(async (resolve, reject) => {
            this.typeCounterDialogRef = this.dialog.open(TypeCounterComponent, {
                data: {
                    typeCounterId: typeCounterId,
                },
            });
            this.typeCounterDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeTypeCounterDialog(result: any) {
        this.typeCounterDialogRef.close(result);
    }
    openZoneDialog(zoneId: string): Promise<Zone> {
        return new Promise<Zone>(async (resolve, reject) => {
            this.zoneDialogRef = this.dialog.open(ZoneComponent, {
                data: {
                    zoneId: zoneId,
                },
            });
            this.zoneDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeZoneDialog(result: any) {
        this.zoneDialogRef.close(result);
    }
    openCompanyDialog(companyId: string): Promise<Company> {
        return new Promise<Company>(async (resolve, reject) => {
            this.companyDialogRef = this.dialog.open(CompanyComponent, {
                data: {
                    companyId: companyId,
                },
            });
            this.companyDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeCompanyDialog(result: any) {
        this.companyDialogRef.close(result);
    }

    openActivationLogDialog(activationLogId: string): Promise<ActivationLog> {
        return new Promise<ActivationLog>(async (resolve, reject) => {
            this.activationLogDialogRef = this.dialog.open(ActivationLogComponent, {
                data: {
                    activationLogId: activationLogId,
                },
            });
            this.activationLogDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    openIntegrationItelazpiDialog(integrationItelazpiId: string): Promise<IntegrationItelazpi> {
        return new Promise<IntegrationItelazpi>(async (resolve, reject) => {
            this.integrationItelazpiDialogRef = this.dialog.open(IntegrationItelazpiComponent, {
                data: {
                    integrationItelazpiId: integrationItelazpiId,
                },
            });
            this.integrationItelazpiDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    openManagerDialog(managerId: string): Promise<Manager> {
        return new Promise<Manager>(async (resolve, reject) => {
            this.managerDialogRef = this.dialog.open(ManagerComponent, {
                data: {
                    managerId: managerId,
                },
            });
            this.managerDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeManagerDialog(result: any) {
        this.managerDialogRef.close(result);
    }

    openAgrupationDialog(agrupationId: string): Promise<Agrupation> {
        return new Promise<Agrupation>(async (resolve, reject) => {
            this.agrupationDialogRef = this.dialog.open(AgrupationComponent, {
                data: {
                    agrupationId: agrupationId,
                },
            });
            this.agrupationDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeAgrupationDialog(result: any) {
        this.agrupationDialogRef.close(result);
    }

    openInfoDialog(infoId: string): Promise<Info> {
        return new Promise<Info>(async (resolve, reject) => {
            this.infoDialogRef = this.dialog.open(InfoComponent, {
                data: {
                    infoId: infoId,
                },
            });
            this.infoDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeInfoDialog(result: any) {
        this.infoDialogRef.close(result);
    }
    openTeamDialog(teamId: string): Promise<Team> {
        return new Promise<Team>(async (resolve, reject) => {
            this.teamDialogRef = this.dialog.open(TeamComponent, {
                data: {
                    teamId: teamId,
                },
            });
            this.teamDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeTeamDialog(result: Team) {
        this.teamDialogRef.close(result);
    }
    openMiRutaUserDialog(miRutaUserId: string): Promise<MiRutaUser> {
        return new Promise<MiRutaUser>(async (resolve, reject) => {
            this.miRutaUserDialogRef = this.dialog.open(UserComponent, {
                data: {
                    miRutaUserId: miRutaUserId,
                },
            });
            this.miRutaUserDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeMiRutaUserDialog(result: MiRutaUser | undefined) {
        this.miRutaUserDialogRef.close(result);
    }

    /**
     * Opens an information dialog with the specified title and text.
     * @param title - The title of the information dialog.
     * @param text - An array of strings representing the text content of the information dialog.
     * @returns A promise that resolves with the result of the information dialog.
     */
    openInformationDialog(title: string, text: string[]): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.informationDialogRef = this.dialog.open(InformationDialogComponent, {
                data: {
                    title: title,
                    text: text,
                },
            });
            this.informationDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    /**
     * Opens a dialog to display information in JSON format.
     * 
     * @param title - The title of the dialog.
     * @param text - An array of strings to be displayed in the dialog.
     * @returns A promise that resolves with the result when the dialog is closed.
     */
    openInformationJsonDialog(title: string, text: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.informationDialogJsonRef = this.dialog.open(InformationJsonDialogComponent, {
                data: {
                    title: title,
                    text: text,
                },
            });
            this.informationDialogJsonRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    openBarcodeDialog(title: string, text: string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.barcodeDialogRef = this.dialog.open(BarcodeDialogComponent, {
                data: {
                    title: title,
                    text: text,
                },
            });
            this.barcodeDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }
    closeBarcodeDialog() {
        this.barcodeDialogRef.close();
    }

    /**
     * Opens the help dialog.
     * @returns A promise that resolves with the result when the dialog is closed.
     */
    openHelpDialog(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.helpDialogRef = this.dialog.open(HelpComponent, {});
            this.helpDialogRef.beforeClosed().subscribe((result) => {
                resolve(result);
            });
        });
    }

    openSection1Dialog(itac: Itac): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.section1DialogRef = this.dialog.open(Section1Component, {
                data: {
                    itac: itac,
                },
            });
            this.section1DialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }
    closeSection1Dialog(result: boolean) {
        this.section1DialogRef.close(result);
    }
    openSection2Dialog(itac: Itac): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.section2DialogRef = this.dialog.open(Section2Component, {
                data: {
                    itac: itac,
                },
            });
            this.section2DialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }
    closeSection2Dialog(result: boolean) {
        this.section2DialogRef.close(result);
    }
    openSection3Dialog(itac: Itac): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.section3DialogRef = this.dialog.open(Section3Component, {
                data: {
                    itac: itac,
                },
            });
            this.section3DialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }
    closeSection3Dialog(result: boolean) {
        this.section3DialogRef.close(result);
    }
    openSection4Dialog(itac: Itac): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.section4DialogRef = this.dialog.open(Section4Component, {
                data: {
                    itac: itac,
                },
            });
            this.section4DialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }
    closeSection4Dialog(result: boolean) {
        this.section4DialogRef.close(result);
    }
    openSection5Dialog(itac: Itac): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            this.section5DialogRef = this.dialog.open(Section5Component, {
                data: {
                    itac: itac,
                },
            });
            this.section5DialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    resolve(false);
                }
            });
        });
    }
    closeSection5Dialog(result: boolean) {
        this.section5DialogRef.close(result);
    }

    /**
     * Opens a selector dialog with the given placeholder text, options, and search functionality.
     * @param placeHolderText - The text to display as a placeholder in the selector dialog.
     * @param options - An array of strings representing the selectable options in the dialog.
     * @param enableSearch - A boolean value indicating whether to enable search functionality in the dialog. Default is false.
     * @returns A promise that resolves with the selected option or rejects with 'No selection' if no option is selected.
     */
    openSelectorDialog(placeHolderText: string, options: string[], enableSearch = false): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.dialogRef = this.dialog.open(SelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                    options: options,
                    enableSearch: enableSearch || options.length > 8,
                },
            });
            this.dialogRef.beforeClosed().subscribe((result) => {
                if (result) resolve(result);
                else reject('No selection');
            });
        });
    }
    closeSelectorDialog(result: any) {
        this.dialogRef.close(result);
    }

    openMultipleOptionsDialog(
        placeHolderText: string,
        options: string[],
        currentOptionsSelected: string[], 
        enableSearch = false
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.multipleOptionsDialogRef = this.dialog.open(MultipleSelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                    options: options,
                    currentOptionsSelected: currentOptionsSelected,
                    enableSearch: enableSearch || options.length > 8,
                },
            });
            this.multipleOptionsDialogRef.beforeClosed().subscribe((result) => {
                if (result) resolve(result);
                else reject('No selection');
            });
        });
    }

    openFillCounter(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.fillCounterDialogRef = this.dialog.open(FillCounterComponent);
            this.fillCounterDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }

    openSerialNumberOptionSelector(): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.serialNumberOptionSelectorDialogRef = this.dialog.open(
                SerialNumberOptionSelectorComponent
            );
            this.serialNumberOptionSelectorDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }

    openEditTextListDialog(list: string[]): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.editTextListRef = this.dialog.open(EditTextListComponent, {
                data: {
                    list: list,
                },
            });
            this.editTextListRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }
    closeEditTextListDialog(result: string[]) {
        this.editTextListRef.close(result);
    }

    openInputSelectorDialog(
        placeHolderText: string,
        currentValue: string,
        inputType?: string
    ): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            this.inputSelectorDialogRef = this.dialog.open(InputSelectorComponent, {
                data: {
                    placeHolderText: placeHolderText,
                    currentValue: currentValue,
                    inputType: inputType,
                },
            });
            this.inputSelectorDialogRef.beforeClosed().subscribe((result) => {
                if (result) {
                    resolve(result);
                } else {
                    reject('No selection');
                }
            });
        });
    }

    /**
     * Compares two arrays of strings to determine if they are equal.
     * 
     * This function checks if both arrays have the same length and contain the same elements,
     * regardless of the order. It uses sets to eliminate duplicates and compare elements.
     * 
     * @param array1 - The first array of strings to compare.
     * @param array2 - The second array of strings to compare.
     * @returns `true` if the arrays are equal, `false` otherwise.
     */
    areArraysEqual(array1: string[], array2: string[]): boolean {
        // Verifica si los arreglos tienen la misma longitud
        if (array1.length !== array2.length) return false;
        
        // Crea conjuntos para comparar los elementos sin importar el orden y eliminar duplicados
        const set1 = new Set(array1);
        const set2 = new Set(array2);
        
        // Verifica que cada elemento de set1 esté en set2 y viceversa
        if (set1.size !== set2.size) return false;
        
        for (const element of set1) {
            if (!set2.has(element)) return false;
        }
        return true;
    }

    /**
     * Generates a random integer between 0 and the specified maximum value (exclusive).
     *
     * @param max - The maximum value (exclusive) for the random integer.
     * @returns A random integer between 0 and the specified maximum value (exclusive).
     */
    getRandomInt(max: number) {
        return Math.floor(Math.random() * max);
    }

    /**
    * Calculates the distance between two geographical points using the Haversine formula.
    * @param lat1 - Latitude of the first point.
    * @param lng1 - Longitude of the first point.
    * @param lat2 - Latitude of the second point.
    * @param lng2 - Longitude of the second point.
    * @returns The distance in meters.
    */
    calculateDistance(lat1: number, lng1: number, lat2: number, lng2: number): number {
        const R = 6371e3; // Earth's radius in meters
        const φ1 = lat1 * Math.PI / 180;
        const φ2 = lat2 * Math.PI / 180;
        const Δφ = (lat2 - lat1) * Math.PI / 180;
        const Δλ = (lng2 - lng1) * Math.PI / 180;

        const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
                Math.cos(φ1) * Math.cos(φ2) *
                Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return R * c;
    }
    
    /**
     * Checks if the distance between two coordinates is within a specified radius.
     * @param coord1 - The first coordinate.
     * @param coord2 - The second coordinate.
     * @param radius - The radius in meters.
     * @returns True if the distance is within the radius, false otherwise.
     */
    isWithinRadius(coord1: google.maps.LatLng, coord2: google.maps.LatLng,radius: number): boolean {
        const distance = this.calculateDistance(
            coord1.lat(), coord1.lng(),
            coord2.lat(), coord2.lng()
        );
        return distance < radius;
    }

    /**
     * Generates a random NgxSpinner type.
     * 
     * @returns A string representing the randomly selected NgxSpinner type.
     */
    getRandomNgxSpinnerType(): string {
        const spinnerTypeName =
            this.ngxSpinnerTypes[this.getRandomInt(this.ngxSpinnerTypes.length)];
        return spinnerTypeName;
    }

    /**
     * Creates a new instance of MyLatLng with the specified latitude and longitude.
     * @param lat - The latitude value.
     * @param lng - The longitude value.
     * @returns A new instance of MyLatLng.
     */
    createLatLng(lat: number, lng: number): MyLatLng {
        return myLatLng(lat, lng);
    }

    myLatLngFromLatLang(pos: google.maps.LatLng): MyLatLng {
        return myLatLng(pos.lat(), pos.lng());
    }

    /**
     * Retrieves the LatLng object from the given WaterTask object.
     * @param task - The WaterTask object containing the coordinates.
     * @returns The LatLng object if the coordinates are valid, otherwise null.
     */
    getLatLngFromMyLatLng(task: WaterTask): google.maps.LatLng | null {
        const myLatLng = this.getRightCoordinates(task);
        if (myLatLng) {
            return new google.maps.LatLng(myLatLng.lat, myLatLng.lng);
        }
        return null;
    }

    isSameLocation(coord1: google.maps.LatLng, coord2: google.maps.LatLng): boolean {
        return coord1.lat() === coord2.lat() && coord1.lng() === coord2.lng();
    }
    
    /**
     * Retrieves a `google.maps.LatLng` object from the geolocation coordinates of an `Itac` object.
     * @param itac - The `Itac` object containing the geolocation coordinates.
     * @returns A `google.maps.LatLng` object representing the geolocation coordinates, or `null` if the coordinates are not available.
     */
    getLatLngFromItacMyLatLng(itac: Itac): google.maps.LatLng | null {
        const myLatLng = itac.geolocalizacion;
        if (myLatLng) {
            return new google.maps.LatLng(myLatLng.lat, myLatLng.lng);
        }
        return null;
    }

    /**
     * Checks if the given value is a timestamp.
     * @param value - The value to check.
     * @returns `true` if the value is a timestamp, `false` otherwise.
     */
    checkIfTimestamp(value: any) {
        if (value) {
            if (typeof value == 'object' && 'nanoseconds' in value) {
                return true;
            }
        }
        return false;
    }
    checkIfDate(value: any) {
        if (value) {
            if (Object.prototype.toString.call(value) === '[object Date]') {
                return true;
            }
        }
        return false;
    }

    openQuestionDialog(
        title: string,
        text: string,
        acceptText?: string,
        cancelText?: string
    ): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const dialogRef = this.questionDialog.open(QuestionDialogComponent, {
                data: {
                    title: title,
                    text: text,
                    acceptText: acceptText,
                    cancelText: cancelText,
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    /**
     * Opens a notification dialog and returns a promise that resolves to a boolean value.
     * The promise resolves to `true` if the dialog is closed with a result, and `false` otherwise.
     * 
     * @returns A promise that resolves to a boolean value indicating the result of the dialog.
     */
    openNotificationDialog(): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            if(this.notificationDialogRef) this.closeNotificationDialogDialog();
            
            this.notificationDialogRef = this.notificationDialog.open(NotificationCardComponent);
            this.notificationDialogRef.beforeClosed().subscribe((result) => {
                if (result) resolve(true);
                else resolve(false);
            });
        });
    }
    /**
     * Closes the notification dialog.
     */
    closeNotificationDialogDialog() {
        this.notificationDialogRef.close();
    }

    /**
     * Opens a data dialog with the specified title, data, accept text, and cancel text.
     * @param title - The title of the data dialog.
     * @param data - The data to be displayed in the data dialog.
     * @param acceptText - Optional. The text for the accept button in the data dialog.
     * @param cancelText - Optional. The text for the cancel button in the data dialog.
     * @returns A promise that resolves to a boolean indicating whether the accept button was clicked or not.
     */
    openDataDialog(
        title: string,
        data: any,
        acceptText?: string,
        cancelText?: string
    ): Promise<boolean> {
        return new Promise<boolean>(async (resolve, reject) => {
            const dialogRef = this.dialog.open(DataDialogComponent, {
                data: {
                    title: title,
                    data: data,
                    acceptText: acceptText,
                    cancelText: cancelText,
                },
            });
            dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
    }

    /**
     * Returns the task counter text line based on the provided WaterTask object.
     * @param task - The WaterTask object.
     * @returns The task counter text line.
     */
    getTaskCounterTextLine(task: WaterTask) {
        let type = `${task.tipo_tarea}, CAL ${task.CALIBRE}mm, CT ${task.SERIE}`;
        const regex = /null/gi;
        type = type.replace(regex, '');
        return type.trim();
    }

    /**
     * Returns the text line for a given water task subscriber.
     * @param task - The water task object.
     * @returns The formatted text line for the subscriber.
     */
    getTaskSubscriberTextLine(task: WaterTask) {
        let abonado = `ABONADO ${task.Numero_de_ABONADO}, ${task.NOMBRE_ABONADO}`;
        const regex = /null/gi;
        abonado = abonado.replace(regex, '');
        return abonado.trim();
    }

    /**
     * Returns the hibernation text for a given WaterTask.
     * @param task - The WaterTask object.
     * @returns The hibernation text.
     */
    getHibernationText(task: WaterTask) {
        if(task.prioridad == priority_status.HIBERNATE || task.hibernacion){
            if(task.end_hibernation_date){
                let momentDate = moment(task.end_hibernation_date);
                return momentDate.locale('es')
                .format('[Hibernada hasta] dddd, D [de] MMMM [del] YYYY, [a las] HH:mm');
            }
            else return 'Hibernada hasta fecha desconocida'
        }
        return '';
    }

    /**
     * Checks if the given string represents angle coordinates in the format "N12.345 E123.456".
     * 
     * The expected format is:
     * - A direction character ('N' or 'S') followed by one or two digits, a period, and one or more digits.
     * - A space.
     * - A direction character ('E' or 'W') followed by one to three digits, a period, and one or more digits.
     * 
     * @param value - The string to be checked.
     * @returns `true` if the string matches the angle coordinates format, otherwise `false`.
     */
    isAngleCoords(value: string): boolean {
        if (this.isFieldNotValid(value)) return false;
        const regex = /^[NS]\d{1,2}\.\d+\s[EW]\d{1,3}\.\d+$/;
        return regex.test(value.trim());
    }

    /**
     * Parses a string representing coordinates in the format "NSdd.ddd EWddd.ddd".
     * 
     * @param value - The string containing the coordinates to be parsed.
     * @returns A `MyLatLng` object if the string is in the correct format, otherwise `null`.
     */
    parseAngleCoordinates(value: string): MyLatLng | null {
        if (this.isAngleCoords(value)) {
            const regex = /^([NS])(\d{1,2}\.\d+)\s([EW])(\d{1,3}\.\d+)$/;
            const match = value.trim().match(regex);
            
            if (match) {
                let lat = parseFloat(match[2]); // Latitud
                let lng = parseFloat(match[4]); // Longitud
                if (match[1] === 'S') lat = -lat;
                if (match[3] === 'W') lng = -lng;
                return myLatLng(lat, lng);
            }
        }
        return null;
    }

    /**
     * Returns the directory of a given task.
     * @param task - The WaterTask object representing the task.
     * @param untilPortal - Optional parameter indicating whether to include the portal information in the directory.
     * @returns The directory of the task.
     */
    getDirOfTask(task: WaterTask | WaterTaskUpdate, untilPortal?: boolean) {
        let dir = '';
        let field = task.MUNICIPIO;
        if (this.isFieldValid(field)) {
            dir += field + ', ';
        }
        field = task.CALLE;
        if (this.isFieldValid(field)) {
            dir += field + ', ';
        }
        field = task.NUME;
        if (this.isFieldValid(field)) {
            dir += field + ' ';
        }
        if (untilPortal) {
            return dir.trim();
        }
        field = task.BIS;
        if (this.isFieldValid(field)) {
            dir += field + ' ';
        }
        field = task.PISO;
        if (this.isFieldValid(field)) {
            dir += field + ' ';
        }
        field = task.MANO;
        if (this.isFieldValid(field)) {
            dir += field;
        }
        const regex = /null/gi;
        dir = dir.replace(regex, '');
        return dir.trim();
    }

    /**
     * Returns the formatted address of a WaterTask for mapping purposes.
     * @param task - The WaterTask object.
     * @param untilPortal - Optional parameter to indicate whether to return the address until the portal or the complete address. Default is false.
     * @returns The formatted address of the WaterTask.
     */
    getDirOfTaskForMap(task: WaterTask, untilPortal?: boolean) {
        let dir = '';
        let field = task.CALLE;
        if (this.isFieldValid(field)) {
            dir += field + ', ';
        }
        field = task.NUME;
        if (this.isFieldValid(field)) {
            dir += parseInt(field!) + ' ';
        }
        if (untilPortal) {
            return dir.trim();
        }
        field = task.BIS;
        if (this.isFieldValid(field)) {
            dir += field + ' ';
        }
        field = task.MUNICIPIO;
        if (this.isFieldValid(field)) {
            dir = dir.trim() + ', ' + field;
        }
        const regex = /null/gi;
        dir = dir.replace(regex, '');
        return dir.trim();
    }

    /**
     * Saves the Firebase token to the local storage.
     * @param token - The Firebase token to be saved.
     */
    saveFirebaseToken(token: string): void {
        if(token) localStorage.setItem('notificacion-token', token as string);
    }

    /**
     * Displays a snackbar notification with the specified text and options.
     * @param text - The text to display in the snackbar.
     * @param type - The type of the snackbar (default: 'info').
     * @param durationInSeconds - The duration of the snackbar in seconds (default: 5000).
     * @param action - The action text to display in the snackbar (default: '').
     * @param onPress - The function to execute when the snackbar action is pressed (default: empty function).
     */
    openSnackBar(
        text: string,
        type: string = 'info',
        durationInSeconds: number = 5000,
        action: string = '',
        onPress: Function = () => {},
    ) {
        this.notificationQueue.push({ text, type, durationInSeconds, action, onPress });
        if (this.isNotificationOpen) return;
        this.showNextNotification();
    }

    
    /**
     * Removes empty fields from the given water task options object.
     * @param waterTaskOptions - The water task options object.
     */
    removeEmptyFields(waterTaskOptions: any): void { 
        const keys = Object.keys(waterTaskOptions);
        for (const key of keys) {
            if (waterTaskOptions[key] === 0) continue;
            if (waterTaskOptions[key] === false) continue;
            if (this.isFieldNotValid(waterTaskOptions[key])) delete waterTaskOptions[key];
        }
    } 

    /**
     * Removes null fields from the given water task options object.
     * @param waterTaskOptions - The water task options object.
     */
    removeNullFields(waterTaskOptions: any): void { 
        const keys = Object.keys(waterTaskOptions);
        for (const key of keys) {
            if (waterTaskOptions[key] === 0) continue;
            if (waterTaskOptions[key] === false) continue;
            if (waterTaskOptions[key] == null) delete waterTaskOptions[key];
        }
    }

    /**
     * Displays the next notification in the queue.
     */
    showNextNotification(): void {
        if (this.notificationQueue.length > 0) {
            const notification = this.notificationQueue.shift();
            if(notification){
                const snackBarRef = this._snackBar.open(
                    notification.text || '', 
                    notification.action || '', 
                    {
                        horizontalPosition: this.horizontalPosition,
                        verticalPosition: this.verticalPosition,
                        duration: notification.durationInSeconds,
                        panelClass: [`my-snack-bar-${notification.type}`],
                    }
                );
                if(notification.onPress) {
                    snackBarRef.onAction().subscribe(() => {
                        notification.onPress();
                    });
                }
                snackBarRef.afterDismissed().subscribe(() => {
                    this.isNotificationOpen = false;
                    this.showNextNotification();
                });
                this.isNotificationOpen = true;
            }
        }
    }

    /**
     * Checks if the given water task is only a module installation task.
     * @param watertask - The water task to check.
     * @returns True if the water task is only a module installation task, false otherwise.
     */
    isOnlyModuleInstalationTask(watertask: WaterTask): boolean {
        if (!watertask.AREALIZARDV || !watertask.numero_serie_modulo) return false;
        const cause_return: string = watertask.AREALIZARDV.split('-')[0].trim().toUpperCase();
        const module: string = watertask.numero_serie_modulo;
        if (module.length > 4 && cause_return == 'X23') return true;
        return false;
    }

    
    /**
     * Selects a date range for an appointment.
     * 
     * @param currentDateTask - The current water task.
     * @returns An object containing the selected date range and other related data, or null if no date range was selected.
     */
    async selectDateAppointment(currentDateTask?: WaterTask, is_date_range?: boolean): Promise<any> {
        try {
            const show_date_status = currentDateTask ? true: false;
            const { startDate, endDate, dateStatus } =
                await this.selectDateRange(show_date_status, currentDateTask, is_date_range);
            if(startDate && endDate && startDate.isValid() && endDate.isValid()) {
                let data: any = {};
                data['fecha_hora_cita'] = startDate.toDate();
                data['fecha_hora_cita_end'] = endDate.toDate();
                data['cita_pendiente'] = true;
                data['nuevo_citas'] = this.getNewDateString(startDate, endDate);
                if(dateStatus) data['date_status'] = dateStatus;
                return data;
            }
        } catch (error) {
            this.openSnackBar('Cita no seleccionada', 'warning');
        }
        return null;
    }

    /**
     * Resets the priority of a given WaterTask.
     * If the task is in hibernation or has a priority of HIBERNATE, the priority is reset to the end_hibernation_priority if available, otherwise it is set to LOW.
     * The hibernacion flag is also set to false.
     * 
     * @param task - The WaterTask to reset the priority for.
     * @returns An object containing the updated priority and hibernacion flag.
     */
    resetTaskPriority(task: WaterTask) {
        if(task && (task.hibernacion || task.prioridad == priority_status.HIBERNATE)){
            let data: any = {};
            if(task.end_hibernation_priority) data['prioridad'] = task.end_hibernation_priority;
            else data['prioridad'] = priority_status.LOW;
            data['hibernacion'] = false;
            return data;
        }
        return {}
    }

    /**
     * Checks if a value is not null or undefined.
     * 
     * @param value - The value to check.
     * @returns `true` if the value is not null or undefined, `false` otherwise.
     */
    checkIfNotNull(value?: any): boolean {
        if (value != undefined && value != null) return true;
        return false;
    }
    
    /**
     * Checks if a value is null or undefined.
     * 
     * @param value - The value to check.
     * @returns Returns `true` if the value is null or undefined, `false` otherwise.
     */
    checkIfIsNull(value?: any): boolean {
        return !this.checkIfNotNull(value);
    }

    /**
     * Parses a string representation of a date and returns a Date object.
     * @param dateString - The string representation of the date in the format 'DD/MM/YYYY HH:mm'.
     * @returns A Date object representing the parsed date, or null if the parsing fails.
     */
    getDateFromString(dateString: string): Date | null {
        const m = moment(dateString, 'DD/MM/YYYY HH:mm');
        if(m) return m.toDate();
        return null;
    }

    /**
     * Retrieves the value of a task field based on its type.
     * 
     * @param value - The value of the task field.
     * @param field - The type of the task field.
     * @returns The value of the task field, converted to the appropriate type.
     */
    getTaskValue(value: any, field: string): string | number | Date | null {
        try {
            if (typeof value === 'number') {
                if (getFieldType(field) == 'number') return value;
                else return value.toString().trim();
            } 
            else if (typeof value === 'string') {
                if(this.isFieldValid(value)) {
                    if (getFieldType(field) == 'string') return value.trim();
                    else if(getFieldType(field) == 'Date') {
                        const date = this.getDateFromString(value);
                        return (date)? date: null;
                    } 
                    else return parseInt(value);
                }
            }
        } catch (error) {}
        return null;
    }

    /**
     * Checks if a field value is valid.
     * 
     * @param value - The value to be checked.
     * @returns A boolean indicating whether the field value is valid or not.
     */
    isFieldValid(value?: any): boolean {
        if (typeof value == 'string') {
            if (
                value == null ||
                value == undefined ||
                value == 'null' ||
                value == 'NULL' ||
                value.trim() === ''
            ) {
                return false;
            }
        } else if (value == null || value == undefined) {
            return false;
        }
        return true;
    }
    
    /**
     * Checks if a field is not valid.
     * @param value - The value to be checked.
     * @returns A boolean indicating whether the field is not valid.
     */
    isFieldNotValid(value?: any): boolean {
        return !this.isFieldValid(value);
    }

    /**
     * Checks if two dates are the same day.
     * @param firstDate - The first date to compare.
     * @param secondDate - The second date to compare.
     * @returns A boolean indicating whether the two dates are the same day.
     */
    checkIfSameDay(firstDate: Date, secondDate: Date): boolean {
        try {
            if (this.isValidDate(firstDate) && this.isValidDate(secondDate) ){
                return (
                    firstDate.getFullYear() == secondDate.getFullYear() &&
                    firstDate.getMonth() == secondDate.getMonth() &&
                    firstDate.getDate() == secondDate.getDate()
                );
            }
        } catch (err) {
            console.log('============= err =============');
            console.log(err);
        }
        return false;
    }

    

    /**
     * Returns the priority text for a given WaterTask based on its priority status.
     *
     * @param {WaterTask} task - The task for which to get the priority text.
     * @returns {string} - The priority text corresponding to the task's priority status.
     *                      Possible values are:
     *                      - 'alta' for high priority
     *                      - 'media' for medium priority
     *                      - 'baja' for low priority
     *                      - 'hibernar' for hibernate priority
     *                      - 'baja' if the priority is null or undefined
     */
    getPriorityText(task: WaterTask): string {
        let stringMarker: string = 'baja';
        if (this.isTaskHibernated(task)) return 'hibernar';
        if (this.isTaskDate(task)) return 'cita';

        if (task.prioridad != null && task.prioridad != undefined) {
            if (task.prioridad == priority_status.HIGH) stringMarker = 'alta';
            else if (task.prioridad == priority_status.MEDIUM) stringMarker = 'media';
            else if (task.prioridad == priority_status.LOW) stringMarker = 'baja';
            else if (task.prioridad == priority_status.HIBERNATE) stringMarker = 'hibernar';
        }
        return stringMarker;
    }

    /**
     * Checks if the given task's appointment date is today.
     *
     * @param task - The task to check, which includes an appointment date.
     * @returns `true` if the task has a pending appointment and the appointment date is today, otherwise `false`.
     */
    isTaskDate(task: WaterTask): boolean {
        let now = new Date();
        if (task.cita_pendiente && this.isValidDate(task.fecha_hora_cita) &&
            this.checkIfSameDay(task.fecha_hora_cita!, now)) {
            return true;
        } 
        return false;
    }

    /**
     * Checks if a given task is currently hibernated.
     *
     * @param task - The task to check, which includes hibernation status and end hibernation date.
     * @returns `true` if the task is hibernated and the end hibernation date is in the future, otherwise `false`.
     */
    isTaskHibernated(task: WaterTask): boolean {
        let now = new Date();
        if (task.hibernacion && task.end_hibernation_date) {
            if(new Date(task.end_hibernation_date).getTime() > now.getTime()) return true;
        }
        return false;
    }

    /**
     * Returns the color code based on the priority of a task.
     * @param t - The WaterTask object representing the task.
     * @returns The color code corresponding to the task priority.
     */
    getTaskPriorityColor(t: WaterTask) {
        const dateColor = '#368DCE'; //date
        const highColor = '#EA4846'; //high
        const lowColor = '#82D060'; //low
        const mediumColor = '#FF9D5B'; //medium
        const hibernatedColor = '#B3B3B3'; //hibernated

        const colorsArray = [hibernatedColor, lowColor, mediumColor, highColor];
        if (t.cita_pendiente && t.fecha_hora_cita != null && this.checkIfSameDay(t.fecha_hora_cita, new Date())) {
            return dateColor;
        }
        let positionInArray = 1;
        if (t.prioridad != undefined && t.prioridad != null && t.prioridad >= 0 && t.prioridad <= 4) {
            positionInArray = t.prioridad;
        }
        return colorsArray[positionInArray];
    }

    /**
     * Checks if the task date is within the specified time range.
     * @param task - The WaterTask object representing the task.
     * @param startTime - The start time of the time range in 'HH:mm' format.
     * @param endTime - The end time of the time range in 'HH:mm' format.
     * @returns A boolean indicating whether the task date is within the time range.
     */
    isTaskDateInTimeRange(task: WaterTask, startTime: string, endTime: string): boolean {
        const dateStartTime = moment(task.fecha_hora_cita, 'HH:mm');
        const dateEndTime = moment(task.fecha_hora_cita_end, 'HH:mm');
        const rangeStartTime = moment(task.fecha_hora_cita).set(this.getTime(startTime));
        const rangeEndTime = moment(task.fecha_hora_cita).set(this.getTime(endTime));

        const isWithinRange = dateStartTime.isSameOrBefore(rangeStartTime) && dateEndTime.isSameOrAfter(rangeEndTime);
        const startDateInRange = rangeStartTime.isSameOrBefore(dateStartTime) && rangeEndTime.isAfter(dateStartTime);
        const endDateInRange = rangeStartTime.isBefore(dateEndTime) && rangeEndTime.isAfter(dateEndTime);
        const res = isWithinRange || startDateInRange || endDateInRange;
        return res;
    }

    getTime(time: string): { hour: number, minute: number } {
        const h = moment(time, 'HH:mm').get('hour');
        const m = moment(time, 'HH:mm').get('minute');
        return { hour: h, minute: m };
    }

    /**
     * Adds status to the given where clause.
     * 
     * @param where_clause - The original where clause.
     * @returns The modified where clause with added status.
     */
    addStatusToWhereClause(where_clause?: string): string {
        const currentStatusString = localStorage.getItem('currentStatus');
        const additionalStatusString = localStorage.getItem('additionalStatus');
        let currentStatus;
        let additionalStatus: number = -1;
        try {
            if (this.checkIfNotNull(currentStatusString)) currentStatus = parseInt(currentStatusString!);
        } catch (err) {}
        try {
            if (this.checkIfNotNull(additionalStatusString)) additionalStatus = parseInt(additionalStatusString!);
        } catch (err) {}

        if (currentStatus != -1) {
            let whereJsonArray = JSON.parse(where_clause || '[]');

            if (additionalStatus > 0) this.addAdditionalStatus(additionalStatus, whereJsonArray);

            let currentStatusJson: any = {};
            currentStatusJson['field'] = 'status_tarea';
            currentStatusJson['value'] = currentStatus;
            currentStatusJson['type'] = 'AND';
            whereJsonArray.push(currentStatusJson);

            where_clause = JSON.stringify(whereJsonArray);
        }
        return where_clause || '';
    }

    /**
     * Adds additional status to the given JSON array.
     * @param additionalStatus - The additional status to be added.
     * @param whereJsonArray - The JSON array to which the additional status will be added.
     */
    addAdditionalStatus(additionalStatus: number, whereJsonArray: any){
        let additionalStatusJson: any = {};
        if(additionalStatus == 1) additionalStatusJson['field'] = 'absent';
        if(additionalStatus == 2) additionalStatusJson['field'] = 'cita_pendiente';
        additionalStatusJson['value'] = 1;
        additionalStatusJson['type'] = 'AND';
        whereJsonArray.push(additionalStatusJson);
    }

    /**
     * Sets the right filter for the specified table.
     * @param table_name - The name of the table.
     * @param filter - The filter to be set.
     */
    setRightFilter(table_name: string, filter?: MiRutaFilter) {
        if (filter) {
            if (table_name == this._mySqlService.tasksTableName) {
                this.filterTasks = filter;
                sessionStorage.setItem('filterTasks', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.itacsTableName) {
                this.filterItacs = filter;
                sessionStorage.setItem('filterItacs', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.countersTableName) {
                this.filterCounters = filter;
                sessionStorage.setItem('filterCounters', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.waterRoutesTableName) {
                this.filterWaterRoutes = filter;
                sessionStorage.setItem('filterWaterRoutes', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.radiusModulesTableName) {
                this.filterRadiusModules = filter;
                sessionStorage.setItem('filterRadiusModules', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.sidesTableName) {
                this.filterSides = filter;
                sessionStorage.setItem('filterSides', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.activationLogsTableName) {
                this.filterActivationLogs = filter;
                sessionStorage.setItem('filterActivationLogs', JSON.stringify(filter));
            } else if (table_name == this._mySqlService.integrationItelazpisTableName) {
                this.filterIntegrationItelazpis = filter;
                sessionStorage.setItem('filterIntegrationItelazpis', JSON.stringify(filter));
            }
        }
    }

    /**
     * Retrieves the right filter based on the provided table name.
     * @param table_name - The name of the table.
     * @returns The corresponding filter for the given table name, or `undefined` if no filter is found.
     */
    getRightFilter(table_name: string): MiRutaFilter | undefined {
        if (table_name == this._mySqlService.tasksTableName) {
            return this.filterTasks;
        } else if (table_name == this._mySqlService.itacsTableName) {
            return this.filterItacs;
        } else if (table_name == this._mySqlService.countersTableName) {
            return this.filterCounters;
        } else if (table_name == this._mySqlService.waterRoutesTableName) {
            return this.filterWaterRoutes;
        } else if (table_name == this._mySqlService.radiusModulesTableName) {
            return this.filterRadiusModules;
        } else if (table_name == this._mySqlService.sidesTableName) {
            return this.filterSides;
        } else if (table_name == this._mySqlService.activationLogsTableName) {
            return this.filterActivationLogs;
        } else if (table_name == this._mySqlService.integrationItelazpisTableName) {
            return this.filterIntegrationItelazpis;
        }
        return this.filterTasks;
    }

    /**
     * Sets the right order for the specified table.
     * @param {string} table_name - The name of the table.
     * @param {any} order - The order to be set.
     */
    setRightOrder(table_name: string, order: any) {
        if (table_name == this._mySqlService.tasksTableName) {
            this.orderTasks = order;
            sessionStorage.setItem('orderTasks', JSON.stringify(order));
        } else if (table_name == this._mySqlService.itacsTableName) {
            this.orderItacs = order;
            sessionStorage.setItem('orderItacs', JSON.stringify(order));
        } else if (table_name == this._mySqlService.countersTableName) {
            this.orderCounters = order;
            sessionStorage.setItem('orderCounters', JSON.stringify(order));
        } else if (table_name == this._mySqlService.waterRoutesTableName) {
            this.orderWaterRoutes = order;
            sessionStorage.setItem('orderWaterRoutes', JSON.stringify(order));
        } else if (table_name == this._mySqlService.radiusModulesTableName) {
            this.orderRadiusModules = order;
            sessionStorage.setItem('orderRadiusModules', JSON.stringify(order));
        } else if (table_name == this._mySqlService.sidesTableName) {
            this.orderSides = order;
            sessionStorage.setItem('orderSides', JSON.stringify(order));
        } else if (table_name == this._mySqlService.activationLogsTableName) {
            this.orderActivationLogs = order;
            sessionStorage.setItem('orderActivationLogs', JSON.stringify(order));
        } else if (table_name == this._mySqlService.integrationItelazpisTableName) {
            this.orderIntegrationItelazpis = order;
            sessionStorage.setItem('orderIntegrationItelazpis', JSON.stringify(order));
        }
    }

    /**
     * Retrieves the right order for a given table name.
     * @param table_name - The name of the table.
     * @returns The order associated with the table name.
     */
    getRightOrder(table_name: string): any {
        if (table_name == this._mySqlService.tasksTableName) {
            return this.orderTasks;
        } else if (table_name == this._mySqlService.itacsTableName) {
            return this.orderItacs;
        } else if (table_name == this._mySqlService.countersTableName) {
            return this.orderCounters;
        } else if (table_name == this._mySqlService.waterRoutesTableName) {
            return this.orderWaterRoutes;
        } else if (table_name == this._mySqlService.radiusModulesTableName) {
            return this.orderRadiusModules;
        } else if (table_name == this._mySqlService.sidesTableName) {
            return this.orderSides;
        } else if (table_name == this._mySqlService.activationLogsTableName) {
            return this.orderActivationLogs;
        } else if (table_name == this._mySqlService.integrationItelazpisTableName) {
            return this.orderIntegrationItelazpis;
        }
        return {};
    }

    /**
     * Processes the filter based on the provided parameters and updates the filter object.
     * If the column already exists in the filter, it updates the existing column.
     * If the column does not exist, it adds a new column filter field.
     * 
     * @param filter - The filter object to be processed.
     * @param newValues - The new values to be applied to the filter.
     * @param column - The name of the column to be processed.
     * @param fieldType - The type of the field associated with the column.
     * @param table_name - The name of the table associated with the filter.
     * @param updateFilter - Optional. Specifies whether to update the filter object. Default is true.
     * @param not_empty - Optional. Specifies whether the filter should include non-empty values. Default is false.
     * @param empty_values - Optional. Specifies whether the filter should include empty values. Default is false.
     * @param custom_join_json - Optional. Custom join JSON object for the column. Default is null.
     * @param searchTerm - Optional. Specifies whether the filter is a search term. Default is false.
     * @returns The updated filter object.
     */
    processFilter(
        filter: MiRutaFilter,
        newValues: any,
        column: string,
        fieldType: string,
        table_name: string,
        updateFilter: boolean = true,
        not_empty: boolean = false,
        empty_values: boolean = false,
        custom_join_json: any = null,
        searchTerm: boolean = false,
    ) {
        let oldParams: SearchParam = {};
        let column_exist = false;
        if (filter && filter.fields) {
            for (let oldfilterField of filter.fields) {
                if (oldfilterField && oldfilterField.field_name == column) { //column exists block
                    column_exist = true;
                    oldParams = oldfilterField.search_params![0];
                    this.processOldColumn(oldfilterField, oldParams, newValues, 
                        custom_join_json, not_empty, empty_values, searchTerm)
                }
            }
        }
        if (!column_exist) { //add new column filter field
            this.processNewColumn(filter, column, fieldType, newValues, 
                custom_join_json, not_empty, empty_values, searchTerm);
        }
        if (updateFilter) this.setRightFilter(table_name, filter);
        return filter;
    }

    /**
     * Processes the old column based on the provided parameters and values.
     * @param oldfilterField - The old filter field object.
     * @param oldParams - The old search parameters object.
     * @param newValues - The new values to be processed.
     * @param custom_join_json - The custom join JSON object.
     * @param not_empty - A boolean indicating whether the values should not be empty.
     * @param empty_values - A boolean indicating whether empty values are allowed.
     * @param searchTerm - A boolean indicating whether the values are for a search term.
     */
    processOldColumn(oldfilterField: FilterField, oldParams: SearchParam, 
        newValues: any, custom_join_json: any, not_empty: boolean, 
        empty_values: boolean, searchTerm: boolean) {
        if(searchTerm) {
            oldParams.value = newValues;
            oldParams.active = true;
        }
        else if(oldfilterField.field_type == 'string' || oldfilterField.field_type == 'number') {
            this.processStringOrNumber(oldfilterField, newValues, not_empty, empty_values);
        }
        else if(oldfilterField.field_type == 'Date') {
            this.processDateType(oldParams, custom_join_json, newValues);
        }
    } 

    /**
     * Processes a new column for the given filter.
     * @param filter - The MiRutaFilter object to update.
     * @param column - The name of the column.
     * @param fieldType - The type of the field.
     * @param newValues - The new values for the column.
     * @param custom_join_json - The custom join JSON.
     * @param not_empty - A boolean indicating if the column should not be empty.
     * @param empty_values - A boolean indicating if the column should have empty values.
     * @param searchTerm - A boolean indicating if the column is a search term.
     */
    processNewColumn(filter: MiRutaFilter, column: string, fieldType: string, 
        newValues: any, custom_join_json: any, not_empty: boolean, 
        empty_values: boolean, searchTerm: boolean) {
        let filterField: FilterField = {
            field_type: fieldType,
            field_name: column,
            search_params: [],
            active: true,
        };
        let filterParam: SearchParam = { search_type: 'inside', inside: newValues, active: true, custom_join: custom_join_json };

        if(searchTerm) filterParam = { search_type: 'term', value: newValues, active: true };
        else if (fieldType == 'Date') this.processNewDateType(filterParam, custom_join_json, newValues);
        else if (empty_values) filterParam = { search_type: 'empty_values', active: true };
        else if (not_empty) {
            filterParam = { search_type: 'not_empty', active: true };
            if (newValues && newValues.length) filterParam.value = newValues[newValues.length-1];
        }
        filterField.search_params = [filterParam];
        
        if (filter.fields) filter.fields.push(filterField);
        else filter.fields = [filterField];
        
        if (filter.And_field) filter.And_field.push(column);
        else filter.And_field = [column];
    }

    /**
     * Processes a string or number based on the provided parameters.
     * @param oldfilterField - The old filter field object.
     * @param newValues - The new values to process.
     * @param not_empty - A boolean indicating whether the values should not be empty.
     * @param empty_values - A boolean indicating whether the values should be empty.
     */
    processStringOrNumber(oldfilterField: FilterField, newValues: any, not_empty: boolean, empty_values: boolean) {
        if(not_empty) {
            if (newValues && newValues.length) {
                oldfilterField.search_params = [{ search_type: 'not_empty', 
                    active: true, value: newValues[newValues.length-1]
                }];
            }
            else oldfilterField.search_params = [{ search_type: 'not_empty', active: true }];
        }
        else if(empty_values) oldfilterField.search_params![0] = { search_type: 'empty_values', active: true };
        else {
            for (let searchParam of oldfilterField.search_params!) {
                if(searchParam.inside) {
                    if (Array.isArray(newValues)) {
                        newValues = newValues.concat(searchParam.inside.filter((v: any) => !newValues.includes(v)));
                    } else {
                        if(!searchParam.inside.includes(newValues)) searchParam.inside.push(newValues);
                        newValues = searchParam.inside;
                    }
                    searchParam.inside = newValues;
                    searchParam.active = true;
                }
            }
        }
    }

    /**
     * Processes the date type filter parameter and updates the searchParam object accordingly.
     * If custom_join_json is provided, it creates a SearchParamBetweenJoin object and adds it to the filterParam.between_join array.
     * If custom_join_json is not provided, it creates a SearchParamBetween object and adds it to the filterParam.between array.
     * Finally, it sets the filterParam.active property to true.
     * 
     * @param filterParam - The search parameter object to be updated.
     * @param custom_join_json - The custom join JSON object.
     * @param values - The values array containing the upper and lower values for the date range.
     */
    processDateType(filterParam: SearchParam, custom_join_json: any, values: any) {
        if(custom_join_json) {
            let between_join: SearchParamBetweenJoin = {
                ...custom_join_json,
                upper_value: values[1],
                lower_value: values[0],
            };
            /* if (!filterParam.between_join) */ 
            filterParam.between_join = [between_join];
            // else filterParam.between_join.push(between_join); //?Uncomment for 2 dates of same column
            filterParam.active = true;
        }
        else {
            let between: SearchParamBetween = { upper_value: values[1], lower_value: values[0] };
            /* if (!filterParam.between) */ 
            filterParam.between = [between]; 
            // else filterParam.between.push(between);//?Uncomment for 2 dates of same column
            filterParam.active = true;
        }
    }

    /**
     * Processes a new date type for filtering.
     * @param filterParam - The filter parameter object.
     * @param custom_join_json - The custom join JSON object.
     * @param values - The values for the date range.
     */
    processNewDateType(filterParam: any, custom_join_json: any, values: any) {
        if(custom_join_json) {
            let between_join: SearchParamBetweenJoin = {
                ...custom_join_json, upper_value: values[1], lower_value: values[0],
            };
            filterParam.search_type = 'between_join';
            filterParam.between_join = [between_join];
        }
        else {
            let between: SearchParamBetween = { upper_value: values[1], lower_value: values[0] };
            filterParam.search_type = 'between';
            filterParam.between = [between];
        }
        filterParam.inside = undefined;
        filterParam.active = true;
    }

    /**
     * Processes the order by removing any existing order with the same column and adds a new order at the beginning of the order array.
     * @param table_name - The name of the table.
     * @param order - The array of orders.
     * @param column - The column to be ordered by.
     * @param orderType - The type of order (e.g., 'asc' or 'desc').
     * @returns The updated order array.
     */
    processOrder(table_name: string, order: any, column: string, orderType: string) {
        for (let element of order) {
            if (element.column === column) {
                order.splice(order.indexOf(element), 1);
            }
        }
        order.unshift({ column: column, order_type: orderType });
        this.setRightOrder(table_name, order);
        return order;
    }

    /**
     * Checks if the provided image name is a server image.
     * @param imageName - The name of the image.
     * @returns A boolean indicating whether the image is a server image.
     */
    checkIfImageInServer(imageName?: string) {
        return imageName && imageName.includes('http');
    }

    /**
     * Retrieves the image URL for a given WaterTask.
     * If the task has an incidence, it checks for valid images in the following order:
     * - foto_incidencia_3
     * - foto_incidencia_2
     * - foto_incidencia_1
     * If the task does not have an incidence, it checks for valid images in the following order:
     * - foto_despues_instalacion
     * - foto_antes_instalacion
     * - foto_numero_serie
     * - foto_lectura
     * 
     * @param task - The WaterTask object.
     * @returns The image URL for the task.
     */
    getTaskImage(task: WaterTask): string {
        let imageTask: string = '';
        if (task.incidence) {
            if (
                this.isFieldValid(task.foto_incidencia_3) &&
                this.checkIfImageInServer(task.foto_incidencia_3)
            ) {
                imageTask = task.foto_incidencia_3!;
            } else if (
                this.isFieldValid(task.foto_incidencia_2) &&
                this.checkIfImageInServer(task.foto_incidencia_2)
            ) {
                imageTask = task.foto_incidencia_2!;
            } else if (
                this.isFieldValid(task.foto_incidencia_1) &&
                this.checkIfImageInServer(task.foto_incidencia_1)
            ) {
                imageTask = task.foto_incidencia_1!;
            }
        } else {
            if (
                this.isFieldValid(task.foto_despues_instalacion) &&
                this.checkIfImageInServer(task.foto_despues_instalacion)
            ) {
                imageTask = task.foto_despues_instalacion!;
            } else if (
                this.isFieldValid(task.foto_antes_instalacion) &&
                this.checkIfImageInServer(task.foto_antes_instalacion)
            ) {
                imageTask = task.foto_antes_instalacion!;
            } else if (
                this.isFieldValid(task.foto_numero_serie) &&
                this.checkIfImageInServer(task.foto_numero_serie)
            ) {
                imageTask = task.foto_numero_serie!;
            } else if (
                this.isFieldValid(task.foto_lectura) &&
                this.checkIfImageInServer(task.foto_lectura)
            ) {
                imageTask = task.foto_lectura!;
            }
        }
        return imageTask;
    }

    /**
     * Retrieves the image URL for the given Itac object.
     * The image URL is determined based on the availability and validity of the image fields in the Itac object.
     * If multiple image fields are available and valid, the priority is given to the higher numbered fields.
     * If no valid image field is found, an empty string is returned.
     *
     * @param itac - The Itac object for which to retrieve the image URL.
     * @returns The image URL for the given Itac object.
     */
    getItacImage(itac: Itac): string {
        let imageItac: string = '';
        if (this.isFieldValid(itac.foto_8) && this.checkIfImageInServer(itac.foto_8)) {
            imageItac = itac.foto_8!;
        } else if (
            this.isFieldValid(itac.foto_7) &&
            this.checkIfImageInServer(itac.foto_7)
        ) {
            imageItac = itac.foto_7!;
        } else if (
            this.isFieldValid(itac.foto_6) &&
            this.checkIfImageInServer(itac.foto_6)
        ) {
            imageItac = itac.foto_6!;
        } else if (
            this.isFieldValid(itac.foto_5) &&
            this.checkIfImageInServer(itac.foto_5)
        ) {
            imageItac = itac.foto_5!;
        } else if (
            this.isFieldValid(itac.foto_4) &&
            this.checkIfImageInServer(itac.foto_4)
        ) {
            imageItac = itac.foto_4!;
        } else if (
            this.isFieldValid(itac.foto_3) &&
            this.checkIfImageInServer(itac.foto_3)
        ) {
            imageItac = itac.foto_3!;
        } else if (
            this.isFieldValid(itac.foto_2) &&
            this.checkIfImageInServer(itac.foto_2)
        ) {
            imageItac = itac.foto_2!;
        } else if (
            this.isFieldValid(itac.foto_1) &&
            this.checkIfImageInServer(itac.foto_1)
        ) {
            imageItac = itac.foto_1!;
        }
        return imageItac;
    }

    /**
     * Checks if a field requires joining.
     * @param field - The field to check.
     * @returns A boolean indicating whether the field requires joining.
     */
    isFieldJoinNeeded(field: string): boolean {
        if(field == 'advice_portal') return true;
        if(field == 'advice_portal_end') return true;
        if(field == 'sectors') return true;
        if(field == 'OPERARIO') return true;
        if(field == 'telefonos_cliente') return true;
        if(field == 'suministros') return true;
        return false;
    }

    /**
     * Returns the replace field value based on the given field.
     * @param field - The field to get the replace value for.
     * @returns The replace field value.
     */
    getReplaceFieldValue(field: string): string {
        if(field == 'advice_portal') return 'fecha_hora_cita';
        if(field == 'advice_portal_end') return 'fecha_hora_cita_end';
        if(field == 'sectors') return 'sector';
        if(field == 'OPERARIO') return 'user';
        if(field == 'telefonos_cliente') return 'client_phone_status';
        if(field == 'suministros') return 'supplies_tasks';
        return '';
    }

    /**
     * Retrieves the join information for a given field.
     * @param field - The field for which to retrieve the join information.
     * @returns The join information object if the field is 'advice_portal' or 'advice_portal_end', otherwise null.
     */
    getFieldJoinJson(field: string): any {
        if(field == 'advice_portal' || field == 'advice_portal_end'){
            return {
                join_table: 'itac',
                join_table_field: 'codigo_itac',
                join_field: 'codigo_de_geolocalizacion'
            }
        }
        else if(field == 'sectors') return this.getSectorJoinJson();
        else if(field == 'OPERARIO') return this.getUserJoinJson();
        else if(field == 'telefonos_cliente'){
            return {
                join_table: 'client_phone_status',
                join_table_field: 'waterTaskId',
                join_search_field: 'value',
                join_field: 'id'
            }
        }
        else if(field == 'suministros'){
            return {
                join_table: 'supplies_tasks',
                join_table_field: 'waterTaskId',
                join_search_field: 'value',
                join_field: 'id'
            }
        }
        return null;
    }

    getSectorJoinJson(): any {
        return {
            join_table: 'sector',
            join_table_field: 'id',
            join_search_field: 'id',
            join_field: 'id',
            complex: true,
            complex_table: 'water_task_sectors_sector',
        }
    }

    getUserJoinJson(): any {
        return {
            join_table: 'user',
            join_table_field: 'id',
            join_search_field: 'id',
            join_field: 'id',
            complex: true,
            complex_table: 'water_task_operario_user',
        }
    }

    /**
     * Converts an array of order elements into a JSON string representing the order clause.
     * @param order - The array of order elements.
     * @returns The JSON string representing the order clause.
     */
    getOrderClauseFromOrder(order: any[]) {
        let orderJsonArray: any = [];
        let order_clause: string = '';
        for (let orderElement of order) {
            let orderJson: any = {};
            orderJson['field'] = orderElement.column;
            orderJson['type'] = orderElement.order_type;
            orderJsonArray.push(orderJson);
        }
        order_clause = JSON.stringify(orderJsonArray);
        return order_clause;
    }

    /**
     * Converts an array of groupBy objects into a groupBy clause string.
     * @param groupBy - An array of groupBy objects.
     * @returns The groupBy clause string.
     */
    getGroupByClauseFromGroupBy(groupBy: any[]) {
        let groupByJsonArray: any = [];
        let groupBy_clause: string = '';
        for (let groupByElement of groupBy) {
            let groupByJson: any = {};
            groupByJson['field'] = groupByElement.column;
            groupByJsonArray.push(groupByJson);
        }
        groupBy_clause = JSON.stringify(groupByJsonArray);
        return groupBy_clause;
    }

    /**
     * Retrieves the value of a specific field from a given filter.
     * @param filter - The MiRutaFilter object containing the fields.
     * @param fieldToDelete - The name of the field to retrieve the value from.
     * @returns The value of the specified field, or null if not found.
     */
    getValueFieldFromFilter(filter: MiRutaFilter, fieldToDelete: string): any {
        if(filter.fields) {
            const fields = filter.fields;
            if(fields) {
                for (let i = fields.length - 1; i >= 0; i--) {
                    if (fields[i].field_name === fieldToDelete
                        && fields[i].search_params) {
                        const searchParams = fields[i].search_params!;
                        for (const searchParam of searchParams) {
                            if(searchParam.active && searchParam.search_type == fieldToDelete) {
                                return searchParam.value;
                            }
                        }
                    }
                }
            }
        }
        return null;
    }

    /**
     * Deletes a specific field from the given filter.
     * @param filter - The filter object to modify.
     * @param fieldToDelete - The name of the field to delete.
     */
    deleteFieldFromFilter(filter: MiRutaFilter, fieldToDelete: string): void {
        for (const field in filter) {
            if (filter.hasOwnProperty(field)) {
                if (field === 'Or_field') {
                    this.deleteFilterField(filter[field], fieldToDelete);
                } 
                if (field === 'And_field') {
                    this.deleteFilterField(filter[field], fieldToDelete);
                } 
                if (field === 'fields') {
                    const fields = filter[field];
                    if(fields) {
                        for (let i = fields.length - 1; i >= 0; i--) {
                            if (fields[i].field_name === fieldToDelete) {
                                fields.splice(i, 1);
                            }
                        }
                    }
                }
            }
        }
    }

    /**
     * Deletes a specific field from a filter property.
     * @param filterProperty - The filter property to modify.
     * @param fieldToDelete - The field to delete from the filter property.
     */
    deleteFilterField(filterPropery: any, fieldToDelete: string) {
        if(filterPropery){
            for (let i = filterPropery.length - 1; i >= 0; i--) {
                if (filterPropery[i] === fieldToDelete) filterPropery.splice(i, 1);
            }
        }
    }

    /**
     * Generates a WHERE clause from the provided filter object.
     * @param filter - The filter object containing the fields and search parameters.
     * @returns The generated WHERE clause as a string.
     */
    getWhereClauseFromFilter(filter: MiRutaFilter): string {
        let whereJsonArray: any = [];
        if(filter.fields) {
            let filterFields: FilterField[] = filter.fields;
            for (let filterField of filterFields) {
                const type = filterField.field_type;
                if (filterField.active && (type == 'string' || type == 'number' || type == 'Date')) {
                    for (let search_param of filterField.search_params!) {
                        if (search_param.active) {
                            if (search_param.search_type == 'equals') {
                                this.getEqualsSearchType(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'not_empty') {
                                this.getNotEmptySearchType(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'empty_values') {
                                this.getEmptyValuesSearchType(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'term') {
                                this.getTermSearchType(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'includes') {
                                this.getIncludesSearchType(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'inside') {
                                this.getInsideSearchType(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'between') {
                                this.getBetweenJsonArray(search_param, filterField, whereJsonArray);
                            } else if (search_param.search_type == 'between_join') {
                                this.getBetweenJoinJsonArray(search_param, filterField, whereJsonArray);
                            }
                        }
                    }
                }
            }
        }
        return JSON.stringify(whereJsonArray);
    }

    /**
     * Adds a search condition to the `whereJsonArray` based on the provided search parameter and filter field.
     * @param search_param - The search parameter object.
     * @param filterField - The filter field object.
     * @param whereJsonArray - The array to which the search condition will be added.
     */
    getEqualsSearchType(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        let whereJson: any = {};
        if (whereJsonArray.length > 0) {
            whereJson['field'] = filterField.field_name;
            whereJson['value'] = search_param.value;
            whereJson['type'] = 'OR';
        } else {
            whereJson['field'] = filterField.field_name;
            whereJson['value'] = search_param.value;
        }
        whereJsonArray.push(whereJson);
    }
    
    /**
     * Adds a search type to the given whereJsonArray based on the provided search parameter and filter field.
     * @param search_param - The search parameter containing the value to search for.
     * @param filterField - The filter field containing the field name to filter on.
     * @param whereJsonArray - The array to which the search type will be added.
     */
    getNotEmptySearchType(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        let whereJson: any = {};
        whereJson['field'] = filterField.field_name;
        whereJson['nullity'] = 'IS NOT NULL';
        whereJson['type'] = 'AND';
        whereJsonArray.push(whereJson);
        if (search_param.value) {
            let whereJsonValues: any = {};
            whereJsonValues['field'] = filterField.field_name;
            whereJsonValues['value'] = search_param.value;
            whereJsonValues['type'] = 'AND';
            whereJsonArray.push(whereJsonValues);
        }
    }

    /**
     * Retrieves empty values search type.
     * 
     * @param search_param - The search parameter.
     * @param filterField - The filter field.
     * @param whereJsonArray - The array to store the generated JSON object.
     */
    getEmptyValuesSearchType(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        let whereJson: any = {};
        whereJson['field'] = filterField.field_name;
        whereJson['nullity'] = 'IS NULL';
        whereJson['type'] = 'AND';
        whereJsonArray.push(whereJson);

    }
    
    /**
     * Gets the term search type.
     * @param search_param - The search parameter.
     * @param filterField - The filter field.
     * @param whereJsonArray - The array to which the whereJson object will be pushed.
     */
    getTermSearchType(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        let whereJson: any = {};
        whereJson['term'] = search_param.value;
        whereJson['type'] = 'AND';
        whereJsonArray.push(whereJson);
    }

    /**
     * Adds a search parameter to the `whereJsonArray` array based on the provided `search_param`, `filterField`, and `whereJsonArray`.
     * @param search_param - The search parameter object.
     * @param filterField - The filter field object.
     * @param whereJsonArray - The array to which the search parameter will be added.
     */
    getIncludesSearchType(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        let whereJson: any = {};
        if (whereJsonArray.length > 0) {
            whereJson['field'] = filterField.field_name;
            whereJson['value'] = `%${search_param.value}%`;
            whereJson['type'] = 'OR';
        } else {
            whereJson['field'] = filterField.field_name;
            whereJson['value'] = `%${search_param.value}%`;
        }
        whereJsonArray.push(whereJson);
    }

    /**
     * Adds a search filter to the given whereJsonArray based on the provided search parameter and filter field.
     * @param search_param - The search parameter containing the inside value.
     * @param filterField - The filter field containing the field name.
     * @param whereJsonArray - The array to which the search filter will be added.
     */
    getInsideSearchType(search_param: SearchParam, filterField: FilterField, whereJsonArray: any){
        let whereJson: any = {};
        whereJson['field'] = filterField.field_name;
        whereJson['value'] = search_param.inside;
        whereJson['type'] = 'AND';
        if(search_param.custom_join) {
            whereJson['join_field'] = search_param.custom_join.join_field;
            whereJson['join_table'] = search_param.custom_join.join_table;
            whereJson['join_table_field'] = search_param.custom_join.join_table_field;
            whereJson['join_search_field'] = search_param.custom_join.join_search_field;
            whereJson['complex'] = search_param.custom_join.complex;
            whereJson['complex_table'] = search_param.custom_join.complex_table;
        }
        whereJsonArray.push(whereJson);
    }

    /**
     * Retrieves the JSON objects from the given `whereJsonArray` that fall within the specified range.
     * @param search_param - The search parameter containing the range values.
     * @param filterField - The filter field to apply the range filter on.
     * @param whereJsonArray - The array of JSON objects to filter.
     */
    getBetweenJsonArray(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        for (let between of search_param.between!) {
            let whereJsonUpper: any = {};
            let whereJsonLower: any = {};

            const lower: number = between.lower_value;
            const upper: number = between.upper_value;

            whereJsonLower['field'] = filterField.field_name;
            whereJsonLower['value'] = lower;
            whereJsonLower['field_type'] = 'DATE';
            whereJsonLower['operation'] = '>=';
            whereJsonLower['date_type'] = 'start_at';

            whereJsonUpper['field'] = filterField.field_name;
            whereJsonUpper['value'] = upper;
            whereJsonUpper['field_type'] = 'DATE';
            whereJsonUpper['operation'] = '<';
            whereJsonUpper['date_type'] = 'end_at';
            whereJsonUpper['type'] = 'AND';

            whereJsonArray.push(whereJsonLower);
            whereJsonArray.push(whereJsonUpper);
        }
    }

    /**
     * Retrieves the between join JSON array based on the search parameters and filter field.
     * @param search_param - The search parameters.
     * @param filterField - The filter field.
     * @param whereJsonArray - The array to store the generated where JSON objects.
     */
    getBetweenJoinJsonArray(search_param: SearchParam, filterField: FilterField, whereJsonArray: any) {
        if(search_param.between_join) {
            for (let between_join of search_param.between_join) {
                let whereJsonUpper: any = {};
                let whereJsonLower: any = {};

                const lower: number = between_join.lower_value;
                const upper: number = between_join.upper_value;

                whereJsonLower['join_field'] = between_join.join_field;
                whereJsonLower['join_table'] = between_join.join_table;
                whereJsonLower['join_table_field'] = between_join.join_table_field;

                whereJsonLower['field'] = filterField.field_name;
                whereJsonLower['value'] = lower;
                whereJsonLower['field_type'] = 'DATE';
                whereJsonLower['operation'] = '>=';
                whereJsonLower['date_type'] = 'start_at';

                
                whereJsonUpper['join_field'] = between_join.join_field;
                whereJsonUpper['join_table'] = between_join.join_table;
                whereJsonUpper['join_table_field'] = between_join.join_table_field;

                whereJsonUpper['field'] = filterField.field_name;
                whereJsonUpper['value'] = upper;
                whereJsonUpper['field_type'] = 'DATE';
                whereJsonUpper['operation'] = '<';
                whereJsonUpper['date_type'] = 'end_at';
                whereJsonUpper['type'] = 'AND';

                whereJsonArray.push(whereJsonLower);
                whereJsonArray.push(whereJsonUpper);
            }
        }
    }

    /**
     * Returns a SQL clause for filtering records based on a time range.
     * The clause checks if the value of the specified field is between the lower and upper values.
     *
     * @param field_name - The name of the field to filter on.
     * @param between - An object containing the lower and upper values of the time range.
     * @returns A SQL clause for filtering records based on the time range.
     */
    getBetweenTimeClause(field_name: string, between: SearchParamBetween) {
        return `( ${field_name} >= TIMESTAMP('${between.lower_value}') AND ${field_name} < TIMESTAMP('${between.upper_value}') )`;
    }

    /**
     * Returns the right field name based on the provided table name and column.
     * @param table_name - The name of the table.
     * @param column - The name of the column.
     * @returns The right field name.
     */
    getRightFieldName(table_name: string, column: string) {
        if (table_name == this._mySqlService.tasksTableName) {
            return getFieldName(column);
        } else if (table_name == this._mySqlService.itacsTableName) {
            return getItacFieldName(column);
        } else if (table_name == this._mySqlService.countersTableName) {
            return getCounterFieldName(column);
        } else if (table_name == this._mySqlService.waterRoutesTableName) {
            return getWaterRouteFieldName(column);
        } else if (table_name == this._mySqlService.sidesTableName) {
            return getSideFieldName(column);
        } else if (table_name == this._mySqlService.activationLogsTableName) {
            return getActivationLogFieldName(column);
        } else if (table_name == this._mySqlService.radiusModulesTableName) {
            return getRadiusModuleFieldName(column);
        }else if (table_name == this._mySqlService.integrationItelazpisTableName) {
            return getIntegrationItelazpiFieldName(column);
        }
        return getFieldName(column);
    }

    getLocalStorageBoolean(varName: string) {
        const value = localStorage.getItem(varName);
        if (value && value == 'true') return true;
        return false;
    }

    /**
     * Checks if the provided filter is valid.
     * 
     * @param filter - The filter object to be validated.
     * @returns A boolean value indicating whether the filter is valid or not.
     */
    isFilterValid(filter?: MiRutaFilter): boolean {
        if (filter && filter.fields && filter.fields!.length) {
            return true;
        }
        return false
    }

    /**
     * Checks if the provided filter is invalid.
     * @param filter - The filter to be checked.
     * @returns A boolean value indicating whether the filter is invalid.
     */
    isFilterInvalid(filter?: MiRutaFilter): boolean {
        return !this.isFilterValid(filter);
    }

    /**
    * @brief Sets the nodes of the filter selected in filter dialog (left side of filter)
    * @param table_name: The table selected to filter
    * @param filter: The filter selected in the filter dialog
    */
    getConvertedFilterToNodes(table_name: string, filter: MiRutaFilter) {
        let filterNodes: FilterNode[] = [];
        if (filter && filter.fields) {
            let filterFields: FilterField[] = filter.fields!;
            for (let filterField of filterFields) {
                let filterNode: FilterNode = {
                    name: this.getRightFieldName(table_name, filterField.field_name),
                    active: filterField.active,
                };
                filterNode.children = [];
                let values = '';
                for (let search_param of filterField.search_params!) {
                    if (search_param.search_type == 'equals') {
                        if (values == '') {
                            values = search_param.value;
                        } else {
                            values += `, ${search_param.value}`;
                        }
                    } else if (search_param.search_type == 'inside') {
                        for (let value of search_param.inside!) {
                            let filterNodeChildren: FilterNode = {
                                name: value,
                                active: filterField.active,
                            };
                            filterNode.children.push(filterNodeChildren);
                        }
                    } else if (search_param.search_type == 'term') {
                        let filterNodeChildren: FilterNode = {
                            name: search_param.value,
                            active: filterField.active,
                        };
                        filterNode.children.push(filterNodeChildren);
                    } else if (search_param.search_type == 'not_empty') {
                        let filterNodeChildren: FilterNode = {
                            name: 'Todos (no vacios)',
                            active: filterField.active,
                        };
                        filterNode.children.push(filterNodeChildren);
                        if (search_param.value) {
                            let filterNodeChildren: FilterNode = {
                                name: search_param.value,
                                active: filterField.active,
                            };
                            filterNode.children.push(filterNodeChildren);
                        }
                    } else if (search_param.search_type == 'empty_values') {
                        let filterNodeChildren: FilterNode = {
                            name: 'Vacios',
                            active: filterField.active,
                        };
                        filterNode.children.push(filterNodeChildren);
                    } else if (search_param.search_type == 'between') {
                        for (let value of search_param.between!) {
                            let filterNodeChildren: FilterNode = {
                                name: `${value.lower_value.replace(
                                    '00:00:00',
                                    ''
                                )} <-> ${value.upper_value.replace('00:00:00', '')}`,
                                active: filterField.active,
                            };
                            filterNode.children.push(filterNodeChildren);
                        }
                    } else if (search_param.search_type == 'between_join') {
                        for (let value of search_param.between_join!) {
                            let filterNodeChildren: FilterNode = {
                                name: `${value.lower_value.replace(
                                    '00:00:00',
                                    ''
                                )} <-> ${value.upper_value.replace('00:00:00', '')}`,
                                active: filterField.active,
                            };
                            filterNode.children.push(filterNodeChildren);
                        }
                    }
                }
                filterNodes.push(filterNode);
            }
        }
        return filterNodes;
    }

    /**
     * Converts an array of place predictions into an array of GoogleTextOptions.
     * @param placePredictions - An array of place predictions.
     * @returns An array of GoogleTextOptions.
     */
    getTextOptionsWithPredictions(placePredictions: Prediction[]) {
        let googleTextOptions: GoogleTextOption[] = [];
        for (const prediction of placePredictions) {
            googleTextOptions.push({
                value: prediction.description,
                place_id: prediction.place_id,
            });
        }
        return googleTextOptions;
    }

    /**
     * Creates a GoogleLocation object.
     *
     * @param userId - The ID of the user.
     * @param taskId - The ID of the task.
     * @param timeElapsed - The time elapsed in milliseconds.
     * @param initText - The initial text.
     * @param text_options - An array of GoogleTextOption objects.
     * @param finalText - The final text.
     * @param point - The geolocation point.
     * @returns The created GoogleLocation object.
     */
    createGoogleLocation(
        userId: number,
        taskId: number,
        timeElapsed: number,
        initText: string,
        text_options: GoogleTextOption[],
        finalText: string,
        point: MyLatLng
    ): GoogleLocation {
        let googleLocation: GoogleLocation = {};
        googleLocation.user_id = userId;
        googleLocation.task_id = taskId;
        googleLocation.time_selecting_ms = timeElapsed;
        googleLocation.initialText = initText;
        googleLocation.finalText = finalText;
        googleLocation.text_options = text_options;
        googleLocation.geolocation = point;
        googleLocation.date = new Date();
        return googleLocation;
    }

    /**
     * Checks if a counter is valid for activation.
     * @param counter - The counter object to check.
     * @returns A boolean indicating whether the counter is valid for activation.
     */
    checkCounterForActivation(counter: Counter): boolean {
        if (!counter.geolocalizacion) return false;
        if (!counter.numero_serie_contador) return false;
        if (!counter.agrupationId) return false;
        if (!counter.numero_serie_modulo) return false;
        if (!counter.company) return false;
        return true;
    }

    /**
     * Checks the counter for activation and cause.
     * @param counter - The counter object to be checked.
     * @returns The LoraResponse object containing the result of the check.
     */
    checkCounterForActivationAndCause(counter: Counter): LoraResponse {
        let loraResponse: LoraResponse = { code: 0, message: '', status: true, counter: counter.numero_serie_contador! }
        if (!counter.geolocalizacion) {
            loraResponse.code = -1;
            loraResponse.message = 'No se ha encontrado la geolocalización del contador.';
            loraResponse.status = false;
        };
        if (!counter.numero_serie_contador) {
            loraResponse.code = -2;
            loraResponse.message = 'No se ha encontrado la numero_serie_contador del contador.';
            loraResponse.status = false;
            loraResponse.counter = `Id Contador: ${counter.id}`;
        };
        if (!counter.agrupationId) {
            loraResponse.code = -3;
            loraResponse.message = 'No se ha encontrado la agrupación del contador.';
            loraResponse.status = false;
        };
        if (!counter.numero_serie_modulo) {
            loraResponse.code = -4;
            loraResponse.message = 'No se ha encontrado la numero_serie_modulo del contador.';
            loraResponse.status = false;
        };
        if (!counter.company) {
            loraResponse.code = -5;
            loraResponse.message = 'No se ha encontrado la empresa del contador.';
            loraResponse.status = false;
        };
        return loraResponse;
    }

    /**
     * Checks if the counter can be activated for the given water task.
     * @param waterTask - The water task to check.
     * @returns True if the counter can be activated, false otherwise.
     */
    checkIfCanActivateCounter(waterTask: WaterTask) {
        if (waterTask.seriedv && waterTask.numero_serie_modulo && waterTask.codigo_de_localizacion)
            return true;
        return false;
    }

    checkIfCanActivateCounterAndCause(waterTask: WaterTask): LoraResponse {
        let loraResponse: LoraResponse = { code: 0, message: '', status: true, counter: waterTask.seriedv! }
        if (this.isFieldNotValid(waterTask.codigo_de_localizacion)) {
            loraResponse.code = -1;
            loraResponse.message = 'No se ha encontrado la geolocalización del contador.';
            loraResponse.status = false;
        };
        if (this.isFieldNotValid(waterTask.seriedv)) {
            loraResponse.code = -2;
            loraResponse.message = 'No se ha encontrado la numero_serie_contador devuelto de la tarea.';
            loraResponse.status = false;
            loraResponse.counter = `Id tarea: ${waterTask.id}`;
        };
        if (this.isFieldNotValid(waterTask.numero_serie_modulo)) {
            loraResponse.code = -4;
            loraResponse.message = 'No se ha encontrado la numero_serie_modulo de la tarea.';
            loraResponse.status = false;
            if(this.isFieldNotValid(waterTask.seriedv)) loraResponse.counter = `Id tarea: ${waterTask.id}`;
        };
        return loraResponse;
    }
    
    /**
     * Retrieves the activation data for a counter.
     * @param counter - The counter object.
     * @returns The activation data.
     */
    getActivationData(counter: Counter): ActivationData { 
        let companyId = 0;
        try {
            companyId = parseInt(localStorage.getItem('company')!);
        } catch (err) {}
        let userId: number | undefined = this.getLoggedInUser()?.id;
        const userCounter = parseInt(counter.last_modification_operator_uid || '0');
        let activationData: ActivationData = {
            userId: userCounter || userId!,
            companyId: counter.company! || companyId,
            nroSerie: counter.numero_serie_contador!,
            latitude: counter.geolocalizacion!.lat,
            longitude: counter.geolocalizacion!.lng,
            agrupationId: counter.agrupationId!,
            rfModule: counter.numero_serie_modulo!,
            comments: `Activando contador ${counter.numero_serie_contador} con módulo ${counter.numero_serie_modulo}`,
        };
        return activationData;
    }

    _suplies: string[] = [
        'Contador',
        'Módulo R3',
        'Módulo R4',
        'Módulo W4',
        'Módulo LRW',
        'Reed',
        'Dp',
        'Pulsar',
    ];
    public _services: string[] = [
        'Instalación',
        'Baja',
        'Toma Dato',
        'Reparación',
        'Incidencia',
        'Rcfg Propio',
        'Rcfg Baja',
        'Rcfg Nuevo',
        'Toma Dato Radio',
        'Precintados',
        'Desprecintados',
    ];

    ngxSpinnerTypes = [
        // "ball-8bits", //this is an ugly one
        'ball-atom',
        'ball-beat',
        'ball-circus',
        'ball-climbing-dot',
        'ball-clip-rotate',
        'ball-clip-rotate-multiple',
        'ball-clip-rotate-pulse',
        'ball-elastic-dots',
        'ball-fall',
        'ball-fussion',
        'ball-grid-beat',
        'ball-grid-pulse',
        'ball-newton-cradle',
        'ball-pulse',
        'ball-pulse-rise',
        'ball-pulse-sync',
        'ball-rotate',
        'ball-running-dots',
        'ball-scale',
        'ball-scale-multiple',
        'ball-scale-pulse',
        'ball-scale-ripple',
        'ball-scale-ripple-multiple',
        'ball-spin',
        'ball-spin-clockwise',
        'ball-spin-clockwise-fade',
        'ball-spin-clockwise-fade-rotating',
        'ball-spin-fade',
        'ball-spin-fade-rotating',
        'ball-spin-rotate',
        'ball-square-clockwise-spin',
        'ball-square-spin',
        'ball-zig-zag',
        'ball-triangle-path',
        'ball-zig-zag-deflect',
        'cube-transition',
        'fire',
        'line-scale',
        'line-scale-party',
        'line-scale-pulse-out',
        'line-scale-pulse-out-rapid',
        'line-spin-clockwise-fade',
        'line-spin-clockwise-fade-rotating',
        'line-spin-fade',
        'line-spin-fade-rotating',
        'pacman',
        'square-jelly-box',
        'square-loader',
        'square-spin',
        'timer',
        'triangle-skew-spin',
    ];

    _country_codes: CountryCode[] = [
        {
            name_en: 'Afghanistan',
            name_es: 'Afganistán',
            dial_code: '+93',
            code: 'AF',
        },
        {
            name_en: 'Albania',
            name_es: 'Albania',
            dial_code: '+355',
            code: 'AL',
        },
        {
            name_en: 'Algeria',
            name_es: 'Argelia',
            dial_code: '+213',
            code: 'DZ',
        },
        {
            name_en: 'AmericanSamoa',
            name_es: 'Samoa Americana',
            dial_code: '+1684',
            code: 'AS',
        },
        {
            name_en: 'Andorra',
            name_es: 'Andorra',
            dial_code: '+376',
            code: 'AD',
        },
        {
            name_en: 'Angola',
            name_es: 'Angola',
            dial_code: '+244',
            code: 'AO',
        },
        {
            name_en: 'Anguilla',
            name_es: 'Anguilla',
            dial_code: '+1264',
            code: 'AI',
        },
        {
            name_en: 'Antarctica',
            name_es: 'Antártida',
            dial_code: '+672',
            code: 'AQ',
        },
        {
            name_en: 'Antigua and Barbuda',
            name_es: 'Antigua y Barbuda',
            dial_code: '+1268',
            code: 'AG',
        },
        {
            name_en: 'Argentina',
            name_es: 'Argentina',
            dial_code: '+54',
            code: 'AR',
        },
        {
            name_en: 'Armenia',
            name_es: 'Armenia',
            dial_code: '+374',
            code: 'AM',
        },
        {
            name_en: 'Aruba',
            name_es: 'Aruba',
            dial_code: '+297',
            code: 'AW',
        },
        {
            name_en: 'Australia',
            name_es: 'Australia',
            dial_code: '+61',
            code: 'AU',
        },
        {
            name_en: 'Austria',
            name_es: 'Austria',
            dial_code: '+43',
            code: 'AT',
        },
        {
            name_en: 'Azerbaijan',
            name_es: 'Azerbaiyán',
            dial_code: '+994',
            code: 'AZ',
        },
        {
            name_en: 'Bahamas',
            name_es: 'Bahamas',
            dial_code: '+1242',
            code: 'BS',
        },
        {
            name_en: 'Bahrain',
            name_es: 'Baréin',
            dial_code: '+973',
            code: 'BH',
        },
        {
            name_en: 'Bangladesh',
            name_es: 'Banglades',
            dial_code: '+880',
            code: 'BD',
        },
        {
            name_en: 'Barbados',
            name_es: 'Barbados',
            dial_code: '+1246',
            code: 'BB',
        },
        {
            name_en: 'Belarus',
            name_es: 'Bielorrusia',
            dial_code: '+375',
            code: 'BY',
        },
        {
            name_en: 'Belgium',
            name_es: 'Bélgica',
            dial_code: '+32',
            code: 'BE',
        },
        {
            name_en: 'Belize',
            name_es: 'Belice',
            dial_code: '+501',
            code: 'BZ',
        },
        {
            name_en: 'Benin',
            name_es: 'Benin',
            dial_code: '+229',
            code: 'BJ',
        },
        {
            name_en: 'Bermuda',
            name_es: 'Bermudas',
            dial_code: '+1441',
            code: 'BM',
        },
        {
            name_en: 'Bhutan',
            name_es: 'Butan',
            dial_code: '+975',
            code: 'BT',
        },
        {
            name_en: 'Bolivia',
            name_es: 'Bolivia',
            dial_code: '+591',
            code: 'BO',
        },
        {
            name_en: 'Bosnia and Herzegovina',
            name_es: 'Bosnia-Herzegovina',
            dial_code: '+387',
            code: 'BA',
        },
        {
            name_en: 'Botswana',
            name_es: 'Botsuana',
            dial_code: '+267',
            code: 'BW',
        },
        {
            name_en: 'Brazil',
            name_es: 'Brasil',
            dial_code: '+55',
            code: 'BR',
        },
        {
            name_en: 'British Indian Ocean Territory',
            name_es: 'Territorio Británico del Océano Índico',
            dial_code: '+246',
            code: 'IO',
        },
        {
            name_en: 'Brunei Darussalam',
            name_es: 'Brunei',
            dial_code: '+673',
            code: 'BN',
        },
        {
            name_en: 'Bulgaria',
            name_es: 'Bulgaria',
            dial_code: '+359',
            code: 'BG',
        },
        {
            name_en: 'Burkina Faso',
            name_es: 'Burkina Faso',
            dial_code: '+226',
            code: 'BF',
        },
        {
            name_en: 'Burundi',
            name_es: 'Burundi',
            dial_code: '+257',
            code: 'BI',
        },
        {
            name_en: 'Cambodia',
            name_es: 'Camboya',
            dial_code: '+855',
            code: 'KH',
        },
        {
            name_en: 'Cameroon',
            name_es: 'Camerún',
            dial_code: '+237',
            code: 'CM',
        },
        {
            name_en: 'Canada',
            name_es: 'Canadá',
            dial_code: '+1',
            code: 'CA',
        },
        {
            name_en: 'Cape Verde',
            name_es: 'Cabo Verde',
            dial_code: '+238',
            code: 'CV',
        },
        {
            name_en: 'Cayman Islands',
            name_es: 'Islas Caimán',
            dial_code: '+ 345',
            code: 'KY',
        },
        {
            name_en: 'Central African Republic',
            name_es: 'República Centroafricana',
            dial_code: '+236',
            code: 'CF',
        },
        {
            name_en: 'Chad',
            name_es: 'Chad',
            dial_code: '+235',
            code: 'TD',
        },
        {
            name_en: 'Chile',
            name_es: 'Chile',
            dial_code: '+56',
            code: 'CL',
        },
        {
            name_en: 'China',
            name_es: 'China',
            dial_code: '+86',
            code: 'CN',
        },
        {
            name_en: 'Christmas Island',
            name_es: 'Isla de Navidad',
            dial_code: '+61',
            code: 'CX',
        },
        {
            name_en: 'Cocos (Keeling) Islands',
            name_es: 'Islas Cocos',
            dial_code: '+61',
            code: 'CC',
        },
        {
            name_en: 'Colombia',
            name_es: 'Colombia',
            dial_code: '+57',
            code: 'CO',
        },
        {
            name_en: 'Comoros',
            name_es: 'Comoras',
            dial_code: '+269',
            code: 'KM',
        },
        {
            name_en: 'Congo',
            name_es: 'Congo',
            dial_code: '+242',
            code: 'CG',
        },
        {
            name_en: 'Congo, The Democratic Republic of the',
            name_es: 'República Democrática del Congo',
            dial_code: '+243',
            code: 'CD',
        },
        {
            name_en: 'Cook Islands',
            name_es: 'Islas Cook',
            dial_code: '+682',
            code: 'CK',
        },
        {
            name_en: 'Costa Rica',
            name_es: 'Costa Rica',
            dial_code: '+506',
            code: 'CR',
        },
        {
            name_en: "Cote d'Ivoire",
            name_es: 'Costa de Marfil',
            dial_code: '+225',
            code: 'CI',
        },
        {
            name_en: 'Croatia',
            name_es: 'Croacia',
            dial_code: '+385',
            code: 'HR',
        },
        {
            name_en: 'Cuba',
            name_es: 'Cuba',
            dial_code: '+53',
            code: 'CU',
        },
        {
            name_en: 'Cyprus',
            name_es: 'Chipre',
            dial_code: '+537',
            code: 'CY',
        },
        {
            name_en: 'Czechia',
            name_es: 'Chequia',
            dial_code: '+420',
            code: 'CZ',
        },
        {
            name_en: 'Denmark',
            name_es: 'Dinamarca',
            dial_code: '+45',
            code: 'DK',
        },
        {
            name_en: 'Djibouti',
            name_es: 'Yibuti',
            dial_code: '+253',
            code: 'DJ',
        },
        {
            name_en: 'Dominica',
            name_es: 'Dominica',
            dial_code: '+1767',
            code: 'DM',
        },
        {
            name_en: 'Dominican Republic',
            name_es: 'República Dominicana',
            dial_code: '+1849',
            code: 'DO',
        },
        {
            name_en: 'Ecuador',
            name_es: 'Ecuador',
            dial_code: '+593',
            code: 'EC',
        },
        {
            name_en: 'Egypt',
            name_es: 'Egipto',
            dial_code: '+20',
            code: 'EG',
        },
        {
            name_en: 'El Salvador',
            name_es: 'El Salvador',
            dial_code: '+503',
            code: 'SV',
        },
        {
            name_en: 'Equatorial Guinea',
            name_es: 'Guinea Ecuatorial',
            dial_code: '+240',
            code: 'GQ',
        },
        {
            name_en: 'Eritrea',
            name_es: 'Eritrea',
            dial_code: '+291',
            code: 'ER',
        },
        {
            name_en: 'Estonia',
            name_es: 'Estonia',
            dial_code: '+372',
            code: 'EE',
        },
        {
            name_en: 'Ethiopia',
            name_es: 'Etiopía',
            dial_code: '+251',
            code: 'ET',
        },
        {
            name_en: 'Falkland Islands (Malvinas)',
            name_es: 'Islas Malvinas',
            dial_code: '+500',
            code: 'FK',
        },
        {
            name_en: 'Faroe Islands',
            name_es: 'Islas Feroe',
            dial_code: '+298',
            code: 'FO',
        },
        {
            name_en: 'Fiji',
            name_es: 'Fiyi',
            dial_code: '+679',
            code: 'FJ',
        },
        {
            name_en: 'Finland',
            name_es: 'Finlandia',
            dial_code: '+358',
            code: 'FI',
        },
        {
            name_en: 'France',
            name_es: 'Francia',
            dial_code: '+33',
            code: 'FR',
        },
        {
            name_en: 'French Guiana',
            name_es: 'Guayana Francesa',
            dial_code: '+594',
            code: 'GF',
        },
        {
            name_en: 'French Polynesia',
            name_es: 'Polinesia Francesa',
            dial_code: '+689',
            code: 'PF',
        },
        {
            name_en: 'Gabon',
            name_es: 'Gabón',
            dial_code: '+241',
            code: 'GA',
        },
        {
            name_en: 'Gambia',
            name_es: 'Gambia',
            dial_code: '+220',
            code: 'GM',
        },
        {
            name_en: 'Georgia',
            name_es: 'Georgia',
            dial_code: '+995',
            code: 'GE',
        },
        {
            name_en: 'Germany',
            name_es: 'Alemania',
            dial_code: '+49',
            code: 'DE',
        },
        {
            name_en: 'Ghana',
            name_es: 'Ghana',
            dial_code: '+233',
            code: 'GH',
        },
        {
            name_en: 'Gibraltar',
            name_es: 'Gibraltar',
            dial_code: '+350',
            code: 'GI',
        },
        {
            name_en: 'Greece',
            name_es: 'Grecia',
            dial_code: '+30',
            code: 'GR',
        },
        {
            name_en: 'Greenland',
            name_es: 'Groenlandia',
            dial_code: '+299',
            code: 'GL',
        },
        {
            name_en: 'Grenada',
            name_es: 'Granada',
            dial_code: '+1473',
            code: 'GD',
        },
        {
            name_en: 'Guadeloupe',
            name_es: 'Guadalupe',
            dial_code: '+590',
            code: 'GP',
        },
        {
            name_en: 'Guam',
            name_es: 'Guam',
            dial_code: '+1671',
            code: 'GU',
        },
        {
            name_en: 'Guatemala',
            name_es: 'Guatemala',
            dial_code: '+502',
            code: 'GT',
        },
        {
            name_en: 'Guernsey',
            name_es: 'Guernsey',
            dial_code: '+44',
            code: 'GG',
        },
        {
            name_en: 'Guinea',
            name_es: 'Guinea',
            dial_code: '+224',
            code: 'GN',
        },
        {
            name_en: 'Guinea-Bissau',
            name_es: 'Guinea-Bisau',
            dial_code: '+245',
            code: 'GW',
        },
        {
            name_en: 'Guyana',
            name_es: 'Guyana',
            dial_code: '+595',
            code: 'GY',
        },
        {
            name_en: 'Haiti',
            name_es: 'Haití',
            dial_code: '+509',
            code: 'HT',
        },
        {
            name_en: 'Holy See (Vatican City State)',
            name_es: 'Ciudad del Vaticano',
            dial_code: '+379',
            code: 'VA',
        },
        {
            name_en: 'Honduras',
            name_es: 'Honduras',
            dial_code: '+504',
            code: 'HN',
        },
        {
            name_en: 'Hong Kong',
            name_es: 'Hong Kong',
            dial_code: '+852',
            code: 'HK',
        },
        {
            name_en: 'Hungary',
            name_es: 'Hungría',
            dial_code: '+36',
            code: 'HU',
        },
        {
            name_en: 'Iceland',
            name_es: 'Islandia',
            dial_code: '+354',
            code: 'IS',
        },
        {
            name_en: 'India',
            name_es: 'India',
            dial_code: '+91',
            code: 'IN',
        },
        {
            name_en: 'Indonesia',
            name_es: 'Indonesia',
            dial_code: '+62',
            code: 'ID',
        },
        {
            name_en: 'Iran, Islamic Republic of',
            name_es: 'Irán',
            dial_code: '+98',
            code: 'IR',
        },
        {
            name_en: 'Iraq',
            name_es: 'Iraq',
            dial_code: '+964',
            code: 'IQ',
        },
        {
            name_en: 'Ireland',
            name_es: 'Irlanda',
            dial_code: '+353',
            code: 'IE',
        },
        {
            name_en: 'Isle of Man',
            name_es: 'Isla de Man',
            dial_code: '+44',
            code: 'IM',
        },
        {
            name_en: 'Israel',
            name_es: 'Israel',
            dial_code: '+972',
            code: 'IL',
        },
        {
            name_en: 'Italy',
            name_es: 'Italia',
            dial_code: '+39',
            code: 'IT',
        },
        {
            name_en: 'Jamaica',
            name_es: 'Jamaica',
            dial_code: '+1876',
            code: 'JM',
        },
        {
            name_en: 'Japan',
            name_es: 'Japón',
            dial_code: '+81',
            code: 'JP',
        },
        {
            name_en: 'Jersey',
            name_es: 'Jersey',
            dial_code: '+44',
            code: 'JE',
        },
        {
            name_en: 'Jordan',
            name_es: 'Jordania',
            dial_code: '+962',
            code: 'JO',
        },
        {
            name_en: 'Kazakhstan',
            name_es: 'Kazajistán',
            dial_code: '+7',
            code: 'KZ',
        },
        {
            name_en: 'Kenya',
            name_es: 'Kenia',
            dial_code: '+254',
            code: 'KE',
        },
        {
            name_en: 'Kiribati',
            name_es: 'Kiribati',
            dial_code: '+686',
            code: 'KI',
        },
        {
            name_en: "Korea, Democratic People's Republic of",
            name_es: 'Corea del Norte',
            dial_code: '+850',
            code: 'KP',
        },
        {
            name_en: 'Korea, Republic of',
            name_es: 'Corea del Sur',
            dial_code: '+82',
            code: 'KR',
        },
        {
            name_en: 'Kosovo',
            name_es: 'Kosovo',
            dial_code: '+383',
            code: 'XK',
        },
        {
            name_en: 'Kuwait',
            name_es: 'Kuwait',
            dial_code: '+965',
            code: 'KW',
        },
        {
            name_en: 'Kyrgyzstan',
            name_es: 'Kirguistán',
            dial_code: '+996',
            code: 'KG',
        },
        {
            name_en: "Lao People's Democratic Republic",
            name_es: 'Laos',
            dial_code: '+856',
            code: 'LA',
        },
        {
            name_en: 'Latvia',
            name_es: 'Letonia',
            dial_code: '+371',
            code: 'LV',
        },
        {
            name_en: 'Lebanon',
            name_es: 'Líbano',
            dial_code: '+961',
            code: 'LB',
        },
        {
            name_en: 'Lesotho',
            name_es: 'Lesoto',
            dial_code: '+266',
            code: 'LS',
        },
        {
            name_en: 'Liberia',
            name_es: 'Liberia',
            dial_code: '+231',
            code: 'LR',
        },
        {
            name_en: 'Libyan Arab Jamahiriya',
            name_es: 'Libia',
            dial_code: '+218',
            code: 'LY',
        },
        {
            name_en: 'Liechtenstein',
            name_es: 'Liechtenstein',
            dial_code: '+423',
            code: 'LI',
        },
        {
            name_en: 'Lithuania',
            name_es: 'Lituania',
            dial_code: '+370',
            code: 'LT',
        },
        {
            name_en: 'Luxembourg',
            name_es: 'Luxemburgo',
            dial_code: '+352',
            code: 'LU',
        },
        {
            name_en: 'Macao',
            name_es: 'Macao',
            dial_code: '+853',
            code: 'MO',
        },
        {
            name_en: 'Macedonia, The Former Yugoslav Republic of',
            name_es: 'República de Macedonia',
            dial_code: '+389',
            code: 'MK',
        },
        {
            name_en: 'Madagascar',
            name_es: 'Madagascar',
            dial_code: '+261',
            code: 'MG',
        },
        {
            name_en: 'Malawi',
            name_es: 'Malaui',
            dial_code: '+265',
            code: 'MW',
        },
        {
            name_en: 'Malaysia',
            name_es: 'Malasia',
            dial_code: '+60',
            code: 'MY',
        },
        {
            name_en: 'Maldives',
            name_es: 'Maldivas',
            dial_code: '+960',
            code: 'MV',
        },
        {
            name_en: 'Mali',
            name_es: 'Malí',
            dial_code: '+223',
            code: 'ML',
        },
        {
            name_en: 'Malta',
            name_es: 'Malta',
            dial_code: '+356',
            code: 'MT',
        },
        {
            name_en: 'Marshall Islands',
            name_es: 'Islas Marshall',
            dial_code: '+692',
            code: 'MH',
        },
        {
            name_en: 'Martinique',
            name_es: 'Martinica',
            dial_code: '+596',
            code: 'MQ',
        },
        {
            name_en: 'Mauritania',
            name_es: 'Mauritania',
            dial_code: '+222',
            code: 'MR',
        },
        {
            name_en: 'Mauritius',
            name_es: 'Mauricio',
            dial_code: '+230',
            code: 'MU',
        },
        {
            name_en: 'Mayotte',
            name_es: 'Mayotte',
            dial_code: '+262',
            code: 'YT',
        },
        {
            name_en: 'Mexico',
            name_es: 'México',
            dial_code: '+52',
            code: 'MX',
        },
        {
            name_en: 'Micronesia, Federated States of',
            name_es: 'Estados Federados de Micronesia',
            dial_code: '+691',
            code: 'FM',
        },
        {
            name_en: 'Moldova, Republic of',
            name_es: 'Moldavia',
            dial_code: '+373',
            code: 'MD',
        },
        {
            name_en: 'Monaco',
            name_es: 'Monaco',
            dial_code: '+377',
            code: 'MC',
        },
        {
            name_en: 'Mongolia',
            name_es: 'Mongolia',
            dial_code: '+976',
            code: 'MN',
        },
        {
            name_en: 'Montenegro',
            name_es: 'Montenegro',
            dial_code: '+382',
            code: 'ME',
        },
        {
            name_en: 'Montserrat',
            name_es: 'Montserrat',
            dial_code: '+1664',
            code: 'MS',
        },
        {
            name_en: 'Morocco',
            name_es: 'Marruecos',
            dial_code: '+212',
            code: 'MA',
        },
        {
            name_en: 'Mozambique',
            name_es: 'Mozambique',
            dial_code: '+258',
            code: 'MZ',
        },
        {
            name_en: 'Myanmar',
            name_es: 'Birmania',
            dial_code: '+95',
            code: 'MM',
        },
        {
            name_en: 'Namibia',
            name_es: 'Namibia',
            dial_code: '+264',
            code: 'NA',
        },
        {
            name_en: 'Nauru',
            name_es: 'Nauru',
            dial_code: '+674',
            code: 'NR',
        },
        {
            name_en: 'Nepal',
            name_es: 'Nepal',
            dial_code: '+977',
            code: 'NP',
        },
        {
            name_en: 'Netherlands',
            name_es: 'Holanda',
            dial_code: '+31',
            code: 'NL',
        },
        {
            name_en: 'Netherlands Antilles',
            name_es: 'Antillas Holandesas',
            dial_code: '+599',
            code: 'AN',
        },
        {
            name_en: 'New Caledonia',
            name_es: 'Nueva Caledonia',
            dial_code: '+687',
            code: 'NC',
        },
        {
            name_en: 'New Zealand',
            name_es: 'Nueva Zelanda',
            dial_code: '+64',
            code: 'NZ',
        },
        {
            name_en: 'Nicaragua',
            name_es: 'Nicaragua',
            dial_code: '+505',
            code: 'NI',
        },
        {
            name_en: 'Niger',
            name_es: 'Niger',
            dial_code: '+227',
            code: 'NE',
        },
        {
            name_en: 'Nigeria',
            name_es: 'Nigeria',
            dial_code: '+234',
            code: 'NG',
        },
        {
            name_en: 'Niue',
            name_es: 'Niue',
            dial_code: '+683',
            code: 'NU',
        },
        {
            name_en: 'NorfolkIsland',
            name_es: 'IslaNorfolk',
            dial_code: '+672',
            code: 'NF',
        },
        {
            name_en: 'NorthernMarianaIslands',
            name_es: 'IslasMarianasdelNorte',
            dial_code: '+1670',
            code: 'MP',
        },
        {
            name_en: 'Norway',
            name_es: 'Noruega',
            dial_code: '+47',
            code: 'NO',
        },
        {
            name_en: 'Oman',
            name_es: 'Omán',
            dial_code: '+968',
            code: 'OM',
        },
        {
            name_en: 'Pakistan',
            name_es: 'Pakistán',
            dial_code: '+92',
            code: 'PK',
        },
        {
            name_en: 'Palau',
            name_es: 'Palaos',
            dial_code: '+680',
            code: 'PW',
        },
        {
            name_en: 'Panama',
            name_es: 'Panamá',
            dial_code: '+507',
            code: 'PA',
        },
        {
            name_en: 'Papua New Guinea',
            name_es: 'Papúa Nueva Guinea',
            dial_code: '+675',
            code: 'PG',
        },
        {
            name_en: 'Paraguay',
            name_es: 'Paraguay',
            dial_code: '+595',
            code: 'PY',
        },
        {
            name_en: 'Peru',
            name_es: 'Perú',
            dial_code: '+51',
            code: 'PE',
        },
        {
            name_en: 'Philippines',
            name_es: 'Filipinas',
            dial_code: '+63',
            code: 'PH',
        },
        {
            name_en: 'Pitcairn',
            name_es: 'Islas Pitcairn',
            dial_code: '+872',
            code: 'PN',
        },
        {
            name_en: 'Poland',
            name_es: 'Polonia',
            dial_code: '+48',
            code: 'PL',
        },
        {
            name_en: 'Portugal',
            name_es: 'Portugal',
            dial_code: '+351',
            code: 'PT',
        },
        {
            name_en: 'Puerto Rico',
            name_es: 'Puerto Rico',
            dial_code: '+1939',
            code: 'PR',
        },
        {
            name_en: 'Qatar',
            name_es: 'Qatar',
            dial_code: '+974',
            code: 'QA',
        },
        {
            name_en: 'Romania',
            name_es: 'Rumania',
            dial_code: '+40',
            code: 'RO',
        },
        {
            name_en: 'Russia',
            name_es: 'Rusia',
            dial_code: '+7',
            code: 'RU',
        },
        {
            name_en: 'Rwanda',
            name_es: 'Ruanda',
            dial_code: '+250',
            code: 'RW',
        },
        {
            name_en: 'Réunion',
            name_es: 'Reunion',
            dial_code: '+262',
            code: 'RE',
        },
        {
            name_en: 'Saint Barthélemy',
            name_es: 'San Bartolome',
            dial_code: '+590',
            code: 'BL',
        },
        {
            name_en: 'Saint Helena, Ascension and Tristan Da Cunha',
            name_es: 'Santa Elena, Ascensión y Tristán de Acuña',
            dial_code: '+290',
            code: 'SH',
        },
        {
            name_en: 'Saint Kitts and Nevis',
            name_es: 'San Cristóbal y Nieves',
            dial_code: '+1869',
            code: 'KN',
        },
        {
            name_en: 'Saint Lucia',
            name_es: 'Santa Lucía',
            dial_code: '+1758',
            code: 'LC',
        },
        {
            name_en: 'Saint Martin',
            name_es: 'Isla de San Martín',
            dial_code: '+590',
            code: 'MF',
        },
        {
            name_en: 'Saint Pierre and Miquelon',
            name_es: 'San Pedro y Miquelon',
            dial_code: '+508',
            code: 'PM',
        },
        {
            name_en: 'Saint Vincent and the Grenadines',
            name_es: 'San Vicente y las Granadinas',
            dial_code: '+1784',
            code: 'VC',
        },
        {
            name_en: 'Samoa',
            name_es: 'Samoa',
            dial_code: '+685',
            code: 'WS',
        },
        {
            name_en: 'San Marino',
            name_es: 'San Marino',
            dial_code: '+378',
            code: 'SM',
        },
        {
            name_en: 'Sao Tome and Principe',
            name_es: ' Santo Tomé y Príncipe',
            dial_code: '+239',
            code: 'ST',
        },
        {
            name_en: 'Saudi Arabia',
            name_es: 'Arabia Saudita',
            dial_code: '+966',
            code: 'SA',
        },
        {
            name_en: 'Senegal',
            name_es: 'Senegal',
            dial_code: '+221',
            code: 'SN',
        },
        {
            name_en: 'Serbia',
            name_es: 'Serbia',
            dial_code: '+381',
            code: 'RS',
        },
        {
            name_en: 'Seychelles',
            name_es: 'Seychelles',
            dial_code: '+248',
            code: 'SC',
        },
        {
            name_en: 'Sierra Leone',
            name_es: 'Sierra Leona',
            dial_code: '+232',
            code: 'SL',
        },
        {
            name_en: 'Singapore',
            name_es: 'Singapur',
            dial_code: '+65',
            code: 'SG',
        },
        {
            name_en: 'Slovakia',
            name_es: 'Eslovaquia',
            dial_code: '+421',
            code: 'SK',
        },
        {
            name_en: 'Slovenia',
            name_es: 'Eslovenia',
            dial_code: '+386',
            code: 'SI',
        },
        {
            name_en: 'Solomon Islands',
            name_es: 'Islas Salomón',
            dial_code: '+677',
            code: 'SB',
        },
        {
            name_en: 'Somalia',
            name_es: 'Somalia',
            dial_code: '+252',
            code: 'SO',
        },
        {
            name_en: 'South Africa',
            name_es: 'Sudáfrica',
            dial_code: '+27',
            code: 'ZA',
        },
        {
            name_en: 'South Sudan',
            name_es: 'Sudán del Sur',
            dial_code: '+211',
            code: 'SS',
        },
        {
            name_en: 'Spain',
            name_es: 'España',
            dial_code: '+34',
            code: 'ES',
        },
        {
            name_en: 'Sri Lanka',
            name_es: 'Sri Lanka',
            dial_code: '+94',
            code: 'LK',
        },
        {
            name_en: 'State of Palestine',
            name_es: 'Estado de Palestina',
            dial_code: '+970',
            code: 'PS',
        },
        {
            name_en: 'Sudan',
            name_es: 'Sudán',
            dial_code: '+249',
            code: 'SD',
        },
        {
            name_en: 'Suriname',
            name_es: 'Surinam',
            dial_code: '+597',
            code: 'SR',
        },
        {
            name_en: 'Svalbard and Jan Mayen',
            name_es: 'Svalbard y Jan Mayen',
            dial_code: '+47',
            code: 'SJ',
        },
        {
            name_en: 'Swaziland',
            name_es: 'Suazilandia',
            dial_code: '+268',
            code: 'SZ',
        },
        {
            name_en: 'Sweden',
            name_es: 'Suecia',
            dial_code: '+46',
            code: 'SE',
        },
        {
            name_en: 'Switzerland',
            name_es: 'Suiza',
            dial_code: '+41',
            code: 'CH',
        },
        {
            name_en: 'Syrian Arab Republic',
            name_es: 'Siria',
            dial_code: '+963',
            code: 'SY',
        },
        {
            name_en: 'Taiwan, Province of China',
            name_es: 'Taiwán',
            dial_code: '+886',
            code: 'TW',
        },
        {
            name_en: 'Tayikistan',
            name_es: 'Tayikistán',
            dial_code: '+992',
            code: 'TJ',
        },
        {
            name_en: 'Tanzania, United Republic of',
            name_es: 'Tanzania',
            dial_code: '+255',
            code: 'TZ',
        },
        {
            name_en: 'Thailand',
            name_es: 'Tailandia',
            dial_code: '+66',
            code: 'TH',
        },
        {
            name_en: 'Timor-Leste',
            name_es: 'Timor Oriental',
            dial_code: '+670',
            code: 'TL',
        },
        {
            name_en: 'Togo',
            name_es: 'Togo',
            dial_code: '+228',
            code: 'TG',
        },
        {
            name_en: 'Tokelau',
            name_es: 'Tokelau',
            dial_code: '+690',
            code: 'TK',
        },
        {
            name_en: 'Tonga',
            name_es: 'Tonga',
            dial_code: '+676',
            code: 'TO',
        },
        {
            name_en: 'Trinidad and Tobago',
            name_es: 'Trinidad y Tobago',
            dial_code: '+1868',
            code: 'TT',
        },
        {
            name_en: 'Tunisia',
            name_es: 'Túnez',
            dial_code: '+216',
            code: 'TN',
        },
        {
            name_en: 'Turkey',
            name_es: 'Turquía',
            dial_code: '+90',
            code: 'TR',
        },
        {
            name_en: 'Turkmenistan',
            name_es: 'Turkmenistán',
            dial_code: '+993',
            code: 'TM',
        },
        {
            name_en: 'Turks and Caicos Islands',
            name_es: 'Islas Turcas y Caicos',
            dial_code: '+1649',
            code: 'TC',
        },
        {
            name_en: 'Tuvalu',
            name_es: 'Tuvalu',
            dial_code: '+688',
            code: 'TV',
        },
        {
            name_en: 'Uganda',
            name_es: 'Uganda',
            dial_code: '+256',
            code: 'UG',
        },
        {
            name_en: 'Ukraine',
            name_es: 'Ucrania',
            dial_code: '+380',
            code: 'UA',
        },
        {
            name_en: 'United Arab Emirates',
            name_es: 'Emiratos Árabes Unidos',
            dial_code: '+971',
            code: 'AE',
        },
        {
            name_en: 'United Kingdom',
            name_es: 'Reino Unido',
            dial_code: '+44',
            code: 'GB',
        },
        {
            name_en: 'United States',
            name_es: 'Estados Unidos',
            dial_code: '+1',
            code: 'US',
        },
        {
            name_en: 'Uruguay',
            name_es: 'Uruguay',
            dial_code: '+598',
            code: 'UY',
        },
        {
            name_en: 'Uzbekistan',
            name_es: 'Uzbekistán',
            dial_code: '+998',
            code: 'UZ',
        },
        {
            name_en: 'Vanuatu',
            name_es: 'Vanuatu',
            dial_code: '+678',
            code: 'VU',
        },
        {
            name_en: 'Venezuela, Bolivarian Republic of',
            name_es: 'Venezuela',
            dial_code: '+58',
            code: 'VE',
        },
        {
            name_en: 'Vietnam',
            name_es: 'Vietnam',
            dial_code: '+84',
            code: 'VN',
        },
        {
            name_en: 'Virgin Islands, British',
            name_es: 'Islas Vírgenes Británicas',
            dial_code: '+1284',
            code: 'VG',
        },
        {
            name_en: 'Virgin Islands, U.S.',
            name_es: 'Islas Vírgenes de los Estados Unidos',
            dial_code: '+1340',
            code: 'VI',
        },
        {
            name_en: 'Wallis and Futuna',
            name_es: 'Wallis y Futuna',
            dial_code: '+681',
            code: 'WF',
        },
        {
            name_en: 'Yemen',
            name_es: 'Yemen',
            dial_code: '+967',
            code: 'YE',
        },
        {
            name_en: 'Zambia',
            name_es: 'Zambia',
            dial_code: '+260',
            code: 'ZM',
        },
        {
            name_en: 'Zimbabwe',
            name_es: 'Zimbabue',
            dial_code: '+263',
            code: 'ZW',
        },
        {
            name_en: 'Åland Islands',
            name_es: 'Åland',
            dial_code: '+358',
            code: 'AX',
        },
    ];
}
