<div *ngIf="electronService.isElectronApp()" class="floating-top-left" (click)="location.back()">
    <button mat-fab color="primary">
        <mat-icon style="margin-left: 10px" matTooltip="Ir atrás">arrow_back_ios</mat-icon>
    </button>
</div>

<div
    *ngIf="task || taskId == 'new'"
    [formGroup]="taskFormData"
    class="animate__animated animate__fadeIn animate__slow"
    style="background-color: #f7f7f7"
>
    <!-- *ngIf="task" -->
    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de importación</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field style="max-width: 90px" appearance="fill">
                                <mat-label>ID Orden</mat-label>
                                <input
                                    formControlName="idOrdenCABB"
                                    matInput
                                    type="number"
                                    placeholder="ID Orden"
                                />
                            </mat-form-field>

                            <mat-form-field style="max-width: 90px" appearance="fill">
                                <mat-label
                                    *ngIf="orderType_dirty || taskFormData.get('TIPORDEN')?.value"
                                    >Tipo Orden</mat-label
                                >
                                <mat-select
                                    placeholder="DIARIA"
                                    formControlName="TIPORDEN"
                                    required
                                    #typeOrderSelect
                                >
                                    <mat-option *ngFor="let option of [0, 1, 2]" [value]="option">{{
                                        option | taskOrder
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field style="width: 120px" appearance="fill">
                                <mat-label>Numero interno</mat-label>
                                <input
                                    formControlName="NUMIN"
                                    matInput
                                    type="text"
                                    placeholder="Numero interno"
                                />
                            </mat-form-field>
                            <mat-form-field style="max-width: 140px" appearance="fill">
                                <mat-label>Fecha Emisión</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker_FECEMISIO"
                                    formControlName="FECEMISIO"
                                    placeholder="Fecha Emisión"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker_FECEMISIO"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker_FECEMISIO></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field style="width: 80px" appearance="fill">
                                <mat-label>Operarios</mat-label>
                                <input
                                    formControlName="OPERARIO"
                                    [value]="utilsService.userNames(task?.OPERARIO)"
                                    matInput
                                    type="text"
                                    placeholder="Operarios"
                                    readonly
                                />
                                <button
                                    matTooltip="Asignar operario"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Open operator selection"
                                    (click)="editOperators()"
                                >
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de ejecución</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field style="max-width: 140px" appearance="fill">
                                <mat-label>Fecha Cierre</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker_FECH_CIERRE"
                                    formControlName="FECH_CIERRE"
                                    placeholder="Fecha Cierre"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker_FECH_CIERRE"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker_FECH_CIERRE></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field style="max-width: 140px" appearance="fill">
                                <mat-label>Fecha Información</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker_fech_informacionnew"
                                    formControlName="fech_informacionnew"
                                    placeholder="Fecha Información"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker_fech_informacionnew"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker_fech_informacionnew></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Resultado</mat-label>
                                <input
                                    type="text"
                                    placeholder="Resultado ..."
                                    aria-label="Result"
                                    matInput
                                    [formControl]="resultsControl"
                                    [matAutocomplete]="autoResultado"
                                />
                                <mat-autocomplete #autoResultado="matAutocomplete" panelWidth="250">
                                    <mat-option
                                        *ngFor="let result of results"
                                        [value]="result"
                                        [matTooltip]="result"
                                    >
                                        {{ result }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Equipo</mat-label>
                                <input
                                    matInput
                                    [value]="taskFormData.get('equipo')?.value | team"
                                    type="text"
                                    placeholder="Equipo"
                                    readonly
                                />
                                <button
                                    *ngIf="verifyCompanyAndManager()"
                                    matTooltip="Cambiar equipo"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Open team selection"
                                    (click)="editTeam()"
                                >
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Ubicación de Finca</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field style="width: 70px" appearance="fill">
                                <mat-label>Id Finca</mat-label>
                                <input
                                    formControlName="ID_FINCA"
                                    matInput
                                    type="text"
                                    placeholder="Id Finca"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 120px" appearance="fill">
                                <mat-label>DNI/CIF Comunidad</mat-label>
                                <input
                                    formControlName="DNI_CIF_COMUNIDAD"
                                    matInput
                                    type="text"
                                    placeholder="DNI/CIF Comunidad"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>CodLec</mat-label>
                                <input
                                    formControlName="CODLEC"
                                    matInput
                                    type="number"
                                    placeholder="CodLec"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Tarifa</mat-label>
                                <input
                                    formControlName="TARIFA"
                                    matInput
                                    type="text"
                                    placeholder="Tarifa"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Tot Cont</mat-label>
                                <input
                                    formControlName="TOTAL_CONTADORES"
                                    matInput
                                    type="text"
                                    placeholder="Tot Cont"
                                />
                            </mat-form-field>
                            <mat-form-field style="max-width: 140px" appearance="fill">
                                <mat-label>Pueblo</mat-label>
                                <input
                                    formControlName="MUNICIPIO"
                                    matInput
                                    type="text"
                                    placeholder="Pueblo"
                                    required
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="flex: 3" appearance="fill">
                                <mat-label>Calle</mat-label>
                                <input
                                    formControlName="CALLE"
                                    matInput
                                    type="text"
                                    placeholder="Calle"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 70px" appearance="fill">
                                <mat-label>Número</mat-label>
                                <input
                                    formControlName="NUME"
                                    matInput
                                    type="text"
                                    placeholder="Número"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 7%" appearance="fill">
                                <mat-label>BIS</mat-label>
                                <input
                                    formControlName="BIS"
                                    matInput
                                    type="text"
                                    placeholder="BIS"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 7%" appearance="fill">
                                <mat-label>Escalera</mat-label>
                                <input
                                    formControlName="escalera"
                                    matInput
                                    type="text"
                                    placeholder="Escalera"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 70px" appearance="fill">
                                <mat-label>Piso</mat-label>
                                <input
                                    formControlName="PISO"
                                    matInput
                                    type="text"
                                    placeholder="Piso"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 70px" appearance="fill">
                                <mat-label>Mano</mat-label>
                                <input
                                    formControlName="MANO"
                                    matInput
                                    type="text"
                                    placeholder="Mano"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="flex: 5" appearance="fill">
                                <mat-label>Comentarios</mat-label>
                                <input
                                    formControlName="COMENTARIOS"
                                    matInput
                                    type="text"
                                    placeholder="Comentarios"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 100px" appearance="fill">
                                <mat-label>C.Canal</mat-label>
                                <input
                                    formControlName="C_CANAL"
                                    matInput
                                    type="text"
                                    placeholder="C.Canal"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 100px" appearance="fill">
                                <mat-label>C.LyC</mat-label>
                                <input
                                    formControlName="C_LYC"
                                    matInput
                                    type="text"
                                    placeholder="C.LyC"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 100px" appearance="fill">
                                <mat-label>C.Agrupa</mat-label>
                                <input
                                    formControlName="C_AGRUPA"
                                    matInput
                                    type="text"
                                    placeholder="C.Agrupa"
                                />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de abonado</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Id Abonado</mat-label>
                                <input
                                    formControlName="Numero_de_ABONADO"
                                    matInput
                                    type="text"
                                    placeholder="Id Abonado"
                                    required
                                />
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>DNI/CIF</mat-label>
                                <input
                                    formControlName="DNI_CIF_ABONADO"
                                    matInput
                                    type="text"
                                    placeholder="DNI/CIF"
                                />
                            </mat-form-field>
                            <div style="display: flex; margin: 5px; height: 50px;">
                                <mat-slide-toggle style="margin: auto;"
                                    color="warn" 
                                    formControlName="pendent_location"
                                    (change)="pendentLocationChange($event)"
                                    >Mal localizada
                                </mat-slide-toggle>
                            </div>
                            <button *ngIf="taskId != 'new' && verifyCompanyAndManager()"
                                mat-raised-button
                                ngClass="map-button"
                                [ngClass]="{
                                    'map-button': !taskFormData.get('pendent_location')?.value, 
                                    'map-button-red': taskFormData.get('pendent_location')?.value
                                }"
                                matTooltip="Mostrar posición en mapa"
                                (click)="openTaskLocation()"
                            >
                                <mat-icon>place</mat-icon>
                            </button>

                        </div>

                        <div class="row">
                            <mat-form-field style="width: 410px" appearance="fill">
                                <mat-label>Nombre</mat-label>
                                <input
                                    formControlName="NOMBRE_ABONADO"
                                    matInput
                                    type="text"
                                    placeholder="Nombre"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>C.Comunero</mat-label>
                                <input
                                    formControlName="C_COMUNERO"
                                    matInput
                                    type="text"
                                    placeholder="C.Comunero"
                                />
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Actividad</mat-label>
                                <input
                                    formControlName="ACTIVI"
                                    matInput
                                    type="text"
                                    placeholder="Actividad"
                                />
                            </mat-form-field>
                            <mat-form-field style="max-width: 90px" appearance="fill">
                                <mat-label>Propiedad</mat-label>
                                <input
                                    formControlName="PROPIEDAD"
                                    matInput
                                    type="text"
                                    placeholder="Propiedad"
                                />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px">
                        <div style="display: flex; width: 100%">
                            <p style="font-size: large; width: 50%">Datos contador levantado</p>
                            <div style="width: 50%; text-align: right">
                                <button
                                    mat-button
                                    type="button"
                                    matTooltip="Copiar información al contador nuevo"
                                    (click)="copyInformation()"
                                >
                                    <mat-icon>content_copy</mat-icon>
                                </button>
                            </div>
                        </div>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Fecha Instalación</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker_previous_instalation"
                                    formControlName="FECINST"
                                    placeholder="Fecha Instalación"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker_previous_instalation"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker_previous_instalation></mat-datepicker>
                            </mat-form-field>

                            <mat-form-field style="max-width: 100px" appearance="fill">
                                <mat-label>Radio</mat-label>
                                <input
                                    formControlName="tipoRadio"
                                    matInput
                                    type="text"
                                    placeholder="Radio"
                                    [matAutocomplete]="auto_radius"
                                />
                                <mat-autocomplete #auto_radius="matAutocomplete">
                                    <mat-option
                                        *ngFor="let typeRadius of typesRadius"
                                        [value]="typeRadius"
                                        [matTooltip]="typeRadius"
                                    >
                                        {{ typeRadius }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                            <mat-form-field appearance="fill">
                                <mat-label>Lectura Levantado</mat-label>
                                <input
                                    formControlName="LECT_LEV"
                                    matInput
                                    type="number"
                                    placeholder="Lectura Levantado"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field 
                                style="width: 150px" appearance="fill"
                                [class.serial-old-changed]="taskFormData.controls['serial_old_changed'].value"
                            >
                                <mat-label>Nº Contador</mat-label>
                                <input
                                    formControlName="SERIE"
                                    matInput
                                    type="text"
                                    placeholder="Nº Contador"
                                    required
                                />
                                <button
                                    *ngIf="taskFormData.controls['SERIE'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Show counter options"
                                    (click)="
                                        showCounterOptions(
                                            'Nº Serie retirado',
                                            taskFormData.controls['SERIE'].value
                                        )
                                    "
                                >
                                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field style="flex: 1; width: 80px" appearance="fill">
                                <mat-label>Marca</mat-label>
                                <input
                                    formControlName="MARCA"
                                    matInput
                                    type="text"
                                    placeholder="Marca"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Calibre</mat-label>
                                <input
                                    formControlName="CALIBRE"
                                    matInput
                                    type="text"
                                    placeholder="Calibre"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Longitud</mat-label>
                                <input
                                    formControlName="LARGO"
                                    matInput
                                    type="text"
                                    placeholder="Longitud"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Dígitos</mat-label>
                                <input
                                    formControlName="RUEDAS"
                                    matInput
                                    type="text"
                                    placeholder="Dígitos"
                                />
                            </mat-form-field>

                            <mat-form-field style="max-width: 90px" appearance="fill">
                                <mat-label>Tipo Fluido</mat-label>
                                <input
                                    formControlName="TIPOFLUIDO"
                                    matInput
                                    type="text"
                                    placeholder="Tipo Fluido"
                                    [matAutocomplete]="auto_fluid"
                                />
                                <mat-autocomplete #auto_fluid="matAutocomplete">
                                    <mat-option *ngFor="let typeCounter of typeCounters" [matTooltip]="typeCounter" [value]="typeCounter">
                                        {{ typeCounter }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill" style="flex: 2">
                                <mat-label>Clase Contador</mat-label>
                                <mat-select formControlName="TIPO">
                                    <mat-option *ngFor="let option of classes" [value]="option">{{
                                        option
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill" style="min-width: 100px; width: 130px;">
                                <mat-label>Emplazamiento</mat-label>
                                <input
                                    formControlName="EMPLAZA"
                                    matInput
                                    type="text"
                                    placeholder="Emplazamiento"
                                />
                            </mat-form-field>
                            <mat-form-field appearance="fill" 
                                [class.incorrect-phone]="tel1_incorrect"
                                [class.no-answer-phone]="tel1_no_answer"
                                [class.dont-know]="tel1_dont_know"
                                [class.correct-phone]="tel1_correct"
                                style="min-width: 130px; width: 130px;">
                                <mat-label>Teléfono 1</mat-label>
                                <input
                                    formControlName="telefono1"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono 1"
                                />
                                <button
                                    *ngIf="taskFormData.controls['telefono1'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Phone settings"
                                    (click)="showPhoneSetting()"
                                >
                                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field appearance="fill" 
                                [class.incorrect-phone]="tel2_incorrect"
                                [class.no-answer-phone]="tel2_no_answer"
                                [class.dont-know]="tel2_dont_know"
                                [class.correct-phone]="tel2_correct"
                                style="min-width: 130px; width: 130px;"
                                >
                                <mat-label>Teléfono 2</mat-label>
                                <input
                                    formControlName="telefono2"
                                    matInput
                                    type="text"
                                    placeholder="Teléfono 2"
                                />
                                <button
                                    *ngIf="taskFormData.controls['telefono2'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Phone settings"
                                    (click)="showPhoneSetting()"
                                >
                                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill" style="min-width: 130px; width: 130px;">
                                <mat-label>Causa Origen</mat-label>
                                <mat-select formControlName="causa_origen">
                                    <mat-option *ngFor="let option of causes" [value]="option">{{
                                        option
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Acción Ordenada</mat-label>
                                <input
                                    formControlName="accion_ordenada"
                                    matInput
                                    type="text"
                                    placeholder="Acción Ordenada ..."
                                />
                            </mat-form-field>

                            <mat-form-field style="min-width: 130px; width: 130px;" appearance="fill">
                                <mat-label>Planificación</mat-label>
                                <input
                                    type="text"
                                    placeholder="Planificación ..."
                                    aria-label="Planning"
                                    matInput
                                    [formControl]="planningsControl"
                                    [matAutocomplete]="autoPlanning"
                                />
                                <mat-autocomplete #autoPlanning="matAutocomplete">
                                    <mat-option
                                        *ngFor="let planning of plannings"
                                        [value]="planning"
                                        [matTooltip]="planning"
                                    >
                                        {{ planning }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>

                        </div>

                        <div class="row">

                            <mat-form-field appearance="fill">
                                <mat-label>Detalle de planificación</mat-label>
                                <mat-select formControlName="planning_details">
                                    <mat-option *ngFor="let option of planningDetails"
                                        [matTooltip]="option"
                                        [value]="option">{{option}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>

                            <mat-form-field style="width: 350px" appearance="fill">
                                <mat-label>Extras planificación</mat-label>
                                <mat-select formControlName="planning_detail_extras" multiple>
                                    <mat-option
                                        *ngFor="let option of planningDetailExtras"
                                        [matTooltip]="option"
                                        [value]="option"
                                        >{{ option }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill" style="flex: 1">
                                <mat-label>Datos específicos</mat-label>
                                <input
                                    formControlName="observaciones"
                                    matInput
                                    type="text"
                                    placeholder="Datos específicos"
                                />
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; width: 100%">
                        <div style="display: flex; width: 100%">
                            <p style="font-size: large; width: 48%">Datos contador nuevo</p>
                            <div
                                *ngIf="taskFormData.get('last_modification_operator_uid')?.value"
                                style="width: 50%; text-align: right"
                            >
                                Instalador -
                                {{
                                    taskFormData.get('last_modification_operator_uid')?.value | user
                                }} - 
                                {{
                                    taskFormData.get('date_modification_android')?.value | fieldPipe
                                }}
                            </div>
                        </div>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field style="flex: 1" appearance="fill">
                                <mat-label>Número serie módulo</mat-label>
                                <input
                                    formControlName="numero_serie_modulo"
                                    matInput
                                    type="text"
                                    placeholder="Número serie módulo"
                                />
                                <button
                                    *ngIf="taskFormData.controls['numero_serie_modulo'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Show module"
                                    (click)="
                                        showBarcode(
                                            'Numero serie de módulo',
                                            taskFormData.controls['numero_serie_modulo'].value
                                        )
                                    "
                                >
                                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field style="width: 60px" appearance="fill">
                                <mat-label>Radio</mat-label>
                                <input
                                    formControlName="TIPORADIO_DEVUELTO"
                                    matInput
                                    type="text"
                                    placeholder="Radio"
                                    [matAutocomplete]="auto_radius"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 150px" appearance="fill">
                                <mat-label>Fecha Ejecución</mat-label>
                                <input
                                    matInput
                                    [matDatepicker]="picker_execution_date"
                                    formControlName="F_INST"
                                    placeholder="Fecha Ejecución"
                                />
                                <mat-datepicker-toggle
                                    matSuffix
                                    [for]="picker_execution_date"
                                ></mat-datepicker-toggle>
                                <mat-datepicker #picker_execution_date></mat-datepicker>
                            </mat-form-field>
                            <mat-form-field style="max-width: 80px" appearance="fill">
                                <mat-label>Lectura</mat-label>
                                <input
                                    formControlName="LECTURA_CONTADOR_NUEVO"
                                    matInput
                                    type="number"
                                    placeholder="Lectura"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="min-width: 190px" appearance="fill">
                                <mat-label>Nº Contador</mat-label>
                                <input
                                    formControlName="seriedv"
                                    matInput
                                    type="text"
                                    placeholder="Nº Contador"
                                />
                                <button
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Show counter options"
                                    (click)="
                                        showCounterOptions(
                                            'Nº Serie instalado',
                                            taskFormData.controls['seriedv'].value
                                        )
                                    "
                                >
                                    <mat-icon style="font-size: 18px">more_vert</mat-icon>
                                </button>
                                <button
                                    *ngIf="taskFormData.controls['seriedv'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Fill counter data"
                                    matTooltip="Rellenar información de contador"
                                    (click)="searchCounterAndFillData()"
                                >
                                    <mat-icon style="font-size: 18px">youtube_searched_for</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field style="max-width: 40px" appearance="fill">
                                <mat-label>DC</mat-label>
                                <input
                                    formControlName="control_digit"
                                    matInput
                                    type="text"
                                    placeholder="DC"
                                />
                            </mat-form-field>
                            <mat-form-field style="flex: 1; width: 170px" appearance="fill">
                                <mat-label>Marca</mat-label>
                                <input
                                    formControlName="MARCADV"
                                    matInput
                                    type="text"
                                    placeholder="Marca"
                                    [matAutocomplete]="auto_markdv"
                                    [formControl]="marksControl"
                                />
                                <mat-autocomplete #auto_markdv="matAutocomplete" panelWidth="300">
                                    <mat-option *ngFor="let mark of marks" [matTooltip]="mark" [value]="mark">
                                        {{ mark }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field style="width: 50px; max-width: 70px;" appearance="fill">
                                <mat-label>Calibre</mat-label>
                                <input
                                    formControlName="CALIBREDV"
                                    matInput
                                    type="text"
                                    placeholder="Calibre"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 50px; max-width: 80px;" appearance="fill">
                                <mat-label>Longitud</mat-label>
                                <input
                                    formControlName="LONGDV"
                                    matInput
                                    type="text"
                                    placeholder="Longitud"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 50px; max-width: 70px;" appearance="fill">
                                <mat-label>Dígitos</mat-label>
                                <input
                                    formControlName="RUEDASDV"
                                    matInput
                                    type="text"
                                    placeholder="Dígitos"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="width: 160px" appearance="fill">
                                <mat-label>Clase Contador</mat-label>
                                <mat-select formControlName="TIPO_DEVUELTO">
                                    <mat-option *ngFor="let option of classes" [value]="option">{{
                                        option
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field style="width: 80px" appearance="fill">
                                <mat-label>Tipo Fluido</mat-label>
                                <input
                                    formControlName="TIPOFLUIDO_DEVUELTO"
                                    matInput
                                    type="text"
                                    placeholder="Tipo Fluido"
                                    [matAutocomplete]="auto_fluiddv"
                                    [formControl]="typeCountersControl"
                                />
                                <mat-autocomplete #auto_fluiddv="matAutocomplete" panelWidth="200">
                                    <mat-option *ngFor="let typeCounter of typeCounters" [matTooltip]="typeCounter" [value]="typeCounter">
                                        {{ typeCounter }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                            <mat-form-field style="max-width: 80px" appearance="fill">
                                <mat-label>Precinto</mat-label>
                                <input
                                    formControlName="numero_precinto"
                                    matInput
                                    type="text"
                                    placeholder="Precinto"
                                />
                            </mat-form-field>
                            <mat-form-field style="width: 80px" appearance="fill">

                                <mat-label>Emplazamiento</mat-label>
                                <input
                                    matInput
                                    type="text"
                                    placeholder="Emplazamiento"
                                    [matAutocomplete]="auto_emplacementdv"
                                    [formControl]="emplacementsControl"
                                />
                                <mat-autocomplete #auto_emplacementdv="matAutocomplete" panelWidth="200">
                                    <mat-option *ngFor="let emplacement of emplacements" [matTooltip]="emplacement" [value]="emplacement">
                                        {{ emplacement }}
                                    </mat-option>
                                </mat-autocomplete>
                                <button
                                    *ngIf="taskFormData.controls['EMPLAZA'].value"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Copy emplacement"
                                    matTooltip="Copiar emplazamiento a emplazamiento devuelto"
                                    (click)="copyOldEmplacement()"
                                >
                                    <mat-icon style="font-size: 18px">content_copy</mat-icon>
                                </button>
                            </mat-form-field>
                            <mat-form-field style="width: 120px" appearance="fill">
                                <mat-label>Resto Emplazamiento</mat-label>
                                <input
                                    formControlName="RESTO_EM"
                                    matInput
                                    type="text"
                                    placeholder="Resto Emplazamiento"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="flex:2;" appearance="fill">
                                <mat-label>Causa Destino</mat-label>
                                <mat-select formControlName="AREALIZARDV">
                                    <mat-option *ngFor="let option of causes" [value]="option">{{
                                        option
                                    }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-form-field appearance="fill">
                                <mat-label>Información interna</mat-label>
                                <input
                                    formControlName="intervencidv"
                                    matInput
                                    type="text"
                                    placeholder="Información interna"
                                />
                            </mat-form-field>
                            <mat-form-field style="max-width: 130px" appearance="fill">
                                <mat-label>Acceso devuelto</mat-label>
                                <input
                                    formControlName="acceso_devuelto"
                                    matInput
                                    type="text"
                                    placeholder="Acceso devuelto"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="width: 350px" appearance="fill">
                                <mat-label>Código Observaciones</mat-label>
                                <mat-select formControlName="OBSERVADV" multiple>
                                    <mat-option
                                        *ngFor="let option of observations"
                                        [matTooltip]="option"
                                        [value]="option"
                                        >{{ option }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field style="width: 350px" appearance="fill">
                                <mat-label>Mensaje Libre</mat-label>
                                <input
                                    formControlName="MENSAJE_LIBRE"
                                    matInput
                                    type="text"
                                    placeholder="Mensaje Libre"
                                    readonly
                                />
                                <button
                                    *ngIf="!utilsService.isClientUser()"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Edit messages"
                                    (click)="clearMessages()"
                                >
                                    <mat-icon style="font-size: 18px">delete</mat-icon>
                                </button>
                                <button
                                    *ngIf="!utilsService.isClientUser()"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Edit messages"
                                    (click)="editMessages()"
                                >
                                    <mat-icon style="font-size: 18px">edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <input class="input-hidden" type="file" (change)="uploadFile($event)" #input_file />

    <div *ngIf="taskId != 'new' && task?.incidence">
        <div class="container">
            <div class="row" class="row-content-center">
                <mat-card class="mat-card-incidence-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto Incidencia 1</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_incidencia_1')"
                            width="220"
                            [src]="getImage('foto_incidencia_1')"
                            id="foto_incidencia_1"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_incidencia_1'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_incidencia_1')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_incidencia_1')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_incidencia_1')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-incidence-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto Incidencia 2</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_incidencia_2')"
                            width="220"
                            [src]="getImage('foto_incidencia_2')"
                            id="foto_incidencia_2"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_incidencia_2'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_incidencia_2')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_incidencia_2')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_incidencia_2')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-incidence-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto Incidencia 3</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_incidencia_3')"
                            width="220"
                            [src]="getImage('foto_incidencia_3')"
                            id="foto_incidencia_3"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_incidencia_3'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_incidencia_3')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_incidencia_3')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_incidencia_3')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>

    <app-image-carousel *ngIf="imageList && imageList.length" [images]="imageList" (downloadImage)="downloadImage($event)"></app-image-carousel>

    <div *ngIf="taskId != 'new'">

        <div class="div-buttons">
            <button
                matTooltip="Abrir carpeta de fotos en servidor (puede que existan más fotos de esta tarea)"
                matSuffix
                mat-flat-button
                aria-label="Open server folder images"
                (click)="showFolderImages()"
            >
                <mat-icon color="primary">folder</mat-icon>
                <span>Ver fotos de servidor</span>
            </button>

            <button
                matTooltip="Descargar fotos en servidor (puede que existan más fotos de esta tarea)"
                matSuffix
                mat-flat-button
                aria-label="Download server folder images"
                (click)="downloadFolderImages()"
            >
                <mat-icon color="primary">download</mat-icon>
                <span>Descargar fotos del servidor</span>
            </button>
        </div>

        <div class="container">
            <div class="row" class="row-content-center">
                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Antes de la Instalación</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_antes_instalacion')"
                            width="220"
                            [src]="getImage('foto_antes_instalacion')"
                            id="foto_antes_instalacion"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_antes_instalacion'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_antes_instalacion')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_antes_instalacion')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_antes_instalacion')">
                            Mostrar
                        </button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Después de Instalación</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_despues_instalacion')"
                            width="220"
                            [src]="getImage('foto_despues_instalacion')"
                            id="foto_despues_instalacion"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_despues_instalacion'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_despues_instalacion')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_despues_instalacion')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_despues_instalacion')">
                            Mostrar
                        </button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Número de serie</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_numero_serie')"
                            width="220"
                            [src]="getImage('foto_numero_serie')"
                            id="foto_numero_serie"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_numero_serie'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_numero_serie')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_numero_serie')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_numero_serie')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto de lectura</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_lectura')"
                            width="220"
                            [src]="getImage('foto_lectura')"
                            id="foto_lectura"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_lectura'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_lectura')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_lectura')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_lectura')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Foto de entorno</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('foto_entorno')"
                            width="220"
                            [src]="getImage('foto_entorno')"
                            id="foto_entorno"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'foto_entorno'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'foto_entorno')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('foto_entorno')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('foto_entorno')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>

            </div>
        </div>
    </div>

    <div *ngIf="task?.firma_cliente && task?.firma_cliente!.includes('http')">
        <div class="container">
            <div class="row" class="row-content-center">
                <mat-card class="mat-card-photo">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: 1.2em; text-align: center">Firma del cliente</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <img
                            style="border-radius: 7px"
                            (click)="showImage('firma_cliente')"
                            [src]="getImage('firma_cliente')"
                            style="object-fit: scale-down"
                            id="firma_cliente"
                            onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                        />
                        <mat-progress-bar
                            *ngIf="uploading && photoColumn == 'firma_cliente'"
                            color="primary"
                            mode="determinate"
                            value="{{ percentage }}"
                        ></mat-progress-bar>
                    </mat-card-content>
                    <mat-card-actions style="text-align: center">
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-button
                            (click)="selectFile(input_file, 'firma_cliente')"
                        >
                            Cambiar
                        </button>
                        <button
                            *ngIf="!utilsService.isClientUser() || task?.status_tarea === requiredStatus"
                            mat-icon-button
                            (click)="rotateImage('firma_cliente')"
                        >
                            <mat-icon>crop_rotate</mat-icon>
                        </button>
                        <button mat-button (click)="showImage('firma_cliente')">Mostrar</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </div>

    <div>
        <div class="container">
            <div class="row bd-highlight" class="row-content-center">
                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de citas</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Nuevo Citas</mat-label>
                                <input
                                    formControlName="nuevo_citas"
                                    matInput
                                    type="text"
                                    placeholder="Nuevo Citas"
                                />
                                <button
                                    *ngIf="utilsService.isFieldValid(taskFormData.controls['nuevo_citas'].value)"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Clear"
                                    (click)="clearDate()"
                                >
                                    <mat-icon>close</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Fechas tocado puerta</mat-label>
                                <mat-select formControlName="fechas_tocado_puerta" multiple>
                                    <mat-option
                                        *ngFor="let option of task?.fechas_tocado_puerta"
                                        style="color: black !important"
                                        [value]="option.value"
                                        disabled
                                        >{{ option.value | fieldPipe }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Fechas nota aviso</mat-label>
                                <mat-select formControlName="fechas_nota_aviso" multiple>
                                    <mat-option
                                        *ngFor="let option of task?.fechas_nota_aviso"
                                        style="color: black !important"
                                        [value]="option.value"
                                        [disabled]="true"
                                        >{{ option.value | fieldPipe }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Fechas no contesta</mat-label>
                                <mat-select formControlName="fechas_no_contesta" multiple>
                                    <mat-option
                                        *ngFor="let option of task?.fechas_no_contesta"
                                        style="color: black !important"
                                        [value]="option.value"
                                        [disabled]="true"
                                        >{{ option.value | fieldPipe }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Acceso</mat-label>
                                <input
                                    formControlName="ACCESO"
                                    matInput
                                    type="text"
                                    placeholder="Acceso"
                                />
                            </mat-form-field>
                        </div>

                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos de ubicación</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Sector P</mat-label>
                                <input
                                    type="text"
                                    placeholder="Sector P ..."
                                    aria-label="zone"
                                    matInput
                                    [formControl]="zonesControl"
                                    [matAutocomplete]="autoZone"
                                />
                                <mat-autocomplete #autoZone="matAutocomplete" panelWidth="250">
                                    <mat-option
                                        *ngFor="let zone of zones"
                                        [value]="zone"
                                        [matTooltip]="zone"
                                    >
                                        {{ zone }}
                                    </mat-option>
                                </mat-autocomplete>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Ruta</mat-label>
                                <input
                                    formControlName="ruta"
                                    matInput
                                    type="text"
                                    placeholder="Ruta"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label
                                    *ngIf="priority_dirty || taskFormData.get('prioridad')?.value"
                                    >Prioridad</mat-label
                                >
                                <mat-select
                                    placeholder="HIBERNAR"
                                    formControlName="prioridad"
                                    required
                                    #prioritySelect
                                >
                                    <mat-option
                                        *ngFor="let option of [0, 1, 2, 3]"
                                        [value]="option"
                                        >{{ option | priority }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Código de emplazamiento</mat-label>
                                <input
                                    formControlName="codigo_de_geolocalizacion"
                                    matInput
                                    type="text"
                                    placeholder="Código de emplazamiento"
                                />
                                <button
                                    *ngIf="
                                        taskFormData.controls['codigo_de_geolocalizacion'].value &&
                                        taskId &&
                                        verifyCompanyAndManager()
                                    "
                                    matTooltip="Abrir ITAC"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Open Itac"
                                    (click)="
                                        openITAC(
                                            taskFormData.controls['codigo_de_geolocalizacion'].value
                                        )
                                    "
                                >
                                    <mat-icon color="primary">assignment</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>URL Google Maps</mat-label>
                                <input
                                    formControlName="url_geolocalizacion"
                                    matInput
                                    type="text"
                                    placeholder="URL Google Maps"
                                    readonly
                                />
                                <button
                                    *ngIf="taskFormData.controls['url_geolocalizacion'].value"
                                    matTooltip="Abrir ubicación de tarea en Google Maps"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Open google maps"
                                >
                                    <a
                                        [href]="taskFormData.controls['url_geolocalizacion'].value"
                                        target="_blank"
                                    >
                                        <img
                                            src="assets/img/google_maps_icon.svg"
                                            width="20"
                                            alt="Open google maps"
                                        />
                                    </a>
                                </button>
                            </mat-form-field>
                        </div>
                    </mat-card-content>
                </mat-card>

                <mat-card class="mat-card-block">
                    <mat-card-subtitle style="margin: 6px; margin-bottom: 15px">
                        <p style="font-size: large">Datos extras</p>
                    </mat-card-subtitle>
                    <mat-card-content>
                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Código de acción ordenada</mat-label>
                                <input
                                    formControlName="tipo_tarea"
                                    matInput
                                    type="text"
                                    placeholder="Código de acción ordenada"
                                />
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Bloque</mat-label>
                                <mat-select formControlName="bloque" required>
                                    <mat-option
                                        *ngFor="let option of ['1', '2', '3', '4', '5']"
                                        [value]="option"
                                        >{{ option }}</mat-option
                                    >
                                </mat-select>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Piecerio</mat-label>
                                <input
                                    formControlName="piezas"
                                    matInput
                                    type="text"
                                    placeholder="Piecerio"
                                    readonly
                                />
                                <button
                                    matTooltip="Añadir piezas"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Open parts selection"
                                    (click)="openPartsSelection()"
                                >
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Estado</mat-label>
                                <input 
                                    value="{{ ((taskId !== 'new') ? task!.status_tarea: 1) | taskStatus }}"
                                    matInput
                                    type="text"
                                    placeholder="Estado"
                                    readonly
                                />
                                <button
                                    *ngIf="taskId !== 'new'"
                                    matTooltip="Cambiar estado"
                                    matSuffix
                                    mat-icon-button
                                    aria-label="Open state selection"
                                    (click)="editState()"
                                >
                                    <mat-icon color="primary">edit</mat-icon>
                                </button>
                            </mat-form-field>
                        </div>

                        <div class="row">
                            <mat-form-field appearance="fill">
                                <mat-label>Ubicación en Batería</mat-label>
                                <input
                                    formControlName="ubicacion_en_bateria"
                                    matInput
                                    type="text"
                                    placeholder="Ubicación en Batería"
                                />
                            </mat-form-field>
                        </div>
                        
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>

    <div style="height: 82px"></div>

    <div *ngIf="task?.last_modification_android" class="floating-top-right">
        <mat-icon
            style="font-size: 50px"
            color="primary"
            matTooltip="Última modificación en aplicación móvil"
            >phone_android</mat-icon
        >
        <div
            style="
                position: absolute;
                bottom: -25px;
                right: -20px;
                background-color: #82d060;
                height: 12px;
                width: 12px;
                border-radius: 50%;
            "
        ></div>
    </div>

    <footer>
        <div class="floating-bottom-left">
            <ngx-audio-player
                *ngIf="
                    task?.audio_detalle && task?.audio_detalle?.includes('http') && msaapPlaylist
                "
                style="
                    box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.4), 0 6px 20px 0 rgba(0, 0, 0, 0.4) !important;
                "
                [playlist]="msaapPlaylist"
                [displayTitle]="msaapDisplayTitle"
                [autoPlay]="false"
                muted="muted"
                [displayPlaylist]="msaapDisplayPlayList"
                [displayVolumeControls]="msaapDisplayVolumeControls"
                [displayRepeatControls]="msaapDisplayRepeatControls"
                [disablePositionSlider]="msaapDisablePositionSlider"
                [displayArtist]="msaapDisplayArtist"
                [displayDuration]="msaapDisplayDuration"
                [expanded]="true"
            ></ngx-audio-player>
        </div>

        <section *ngIf="verifyCompanyAndManager()">
            <div class="example-button-row">
                <div class="example-flex-container">
                    <div
                        *ngIf="
                            !utilsService.isClientUser() || task?.status_tarea == requiredStatus
                        "
                        class="example-button-container"
                    >
                        <button
                            mat-fab
                            color="primary"
                            aria-label="save icon"
                            matTooltip="Guardar cambios"
                            (click)="saveChanges()"
                        >
                            <mat-icon>save</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!utilsService.isClientUser()" class="example-button-container">
                        <button
                            mat-fab
                            color="primary"
                            class="white-color"
                            aria-label="date_range icon"
                            matTooltip="Añadir cita a la tarea"
                            (click)="selectDateAppointment()"
                        >
                            <mat-icon color="primary">date_range</mat-icon>
                        </button>
                    </div>
                    <div *ngIf="!utilsService.isClientUser()" class="example-button-container">
                        <button
                            mat-fab
                            class="whatsapp-color"
                            aria-label="Example icon button with a heart icon"
                            matTooltip="Enviar mensaje por whatsapp"
                            (click)="sendMessage()"
                        >
                            <img src="assets/img/whatsapp_icon.svg" />
                        </button>
                    </div>
                    <div class="example-button-container">
                        <button
                            mat-fab
                            class="acrobat-reader-color"
                            aria-label="Example icon button with a heart icon"
                            matTooltip="Opciones de PDF"
                            (click)="openPDF()"
                        >
                            <fa-icon style="font-size: 20px" [icon]="faFilePdf"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </section>

        <button
            *ngIf="taskId != 'new' && !utilsService.isClientUser() && verifyCompanyAndManager()"
            mat-fab
            color="primary"
            class="close-button"
            matTooltip="Cerrar tarea"
            (click)="onCloseTask()"
        >
            <mat-icon>check</mat-icon>
            <!-- <span>Cerrar tarea</span> -->
        </button>
    </footer>
</div>

<div *ngIf="!loading && !task && taskId != 'new'" class="center-child">
    <div style="text-align: center">
        <fa-icon style="font-size: 80px; color: #777777" [icon]="faInbox"></fa-icon>
    </div>
    <div style="margin: 15px">
        <span style="font-size: 20px; color: #777777">No hay información</span>
    </div>
</div>

<ngx-spinner
    [bdColor]="task ? 'rgba(0, 0, 0, 0.2)' : 'rgba(255, 255, 255, 1)'"
    size="large"
    color="#368DCE"
    type="ball-scale-multiple"
    [fullScreen]="true"
    name="taskSpinner"
>
    <div
        *ngIf="utilsService.resposeError"
        class="reload-loading-button animate__animated animate__fadeInUp"
        (click)="reload()"
    >
        <button mat-icon-button matTooltip="Reintentar" color="primary">
            <mat-icon style="font-size: 25px; color: white">refresh</mat-icon>
        </button>
    </div>
    <div
        *ngIf="utilsService.resposeError"
        class="close-loading-button animate__animated animate__fadeInDown"
        (click)="showLoading(false)"
    >
        <button mat-icon-button matTooltip="Cancelar petición" color="primary">
            <mat-icon style="font-size: 25px; color: white">close</mat-icon>
        </button>
    </div>
</ngx-spinner>
