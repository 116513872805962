<mat-toolbar color="primary"  [formGroup]="toolbarGroup">
    <button mat-icon-button class="example-icon" aria-label="menu icon" (click)="toggleDrawer()">
        <mat-icon>menu</mat-icon>
    </button>
    <span style="margin-left: 10px">Mi Ruta</span>
    <span class="example-spacer"></span>

    <div style="display: flex; margin-right: 5px">
        <button
            title="back_button"
            *ngIf="showBackButton"
            mat-flat-button
            color="primary"
            (click)="previousView()"
            style="
                max-width: 54px;
                max-height: 54px;
                margin-top: 15px;
                background-color: transparent;
            "
            matTooltip="Ir atrás"
        >
            <fa-icon style="font-size: 22px" [icon]="faReply"></fa-icon>
        </button>
        <button
            title="select_all_button"
            mat-flat-button
            color="primary"
            (click)="selectAllTable()"
            [style]="getIconStyle()"
            matTooltip="Seleccionar toda la tabla"
        >
            <fa-icon style="font-size: 22px" [icon]="faBorderNone"></fa-icon>
        </button>
        <button
            title="reload_button"
            mat-flat-button
            color="primary"
            (click)="reload()"
            [style]="getIconStyle()"
            matTooltip="Actualizar información"
        >
            <fa-icon style="font-size: 22px" [icon]="faSync"></fa-icon>
        </button>
        <button
            title="activity_button"
            *ngIf="showActivity"
            mat-flat-button
            color="primary"
            [style]="getIconStyle()"
            matTooltip="Actividad de operario"
            [matMenuTriggerFor]="userActivityMenu"
        >
            <fa-icon style="font-size: 22px" [icon]="faEye"></fa-icon>
        </button>
        <mat-menu #userActivityMenu="matMenu">
            <button mat-menu-item (click)="showUserActivity('list')">
                <fa-icon class="fa-icon-column" [icon]="faList"></fa-icon>
                <span style="margin-bottom: 10px">Lista</span>
            </button>
            <button mat-menu-item (click)="showUserActivity('chart')">
                <fa-icon class="fa-icon-column" [icon]="faChartLine"></fa-icon>
                <span style="margin-bottom: 10px">Gráfica</span>
            </button>
            <button mat-menu-item (click)="showUserActivity('actions')">
                <fa-icon class="fa-icon-column" [icon]="faMobileScreenButton"></fa-icon>
                <span style="margin-bottom: 10px">Acciones</span>
            </button>
            <button mat-menu-item (click)="showUserActivity('validations')">
                <fa-icon class="fa-icon-column" [icon]="faSquareCheck"></fa-icon>
                <span style="margin-bottom: 10px">Validaciones</span>
            </button>
        </mat-menu>
        <button
            title="filter_button"
            *ngIf="showFilter"
            mat-flat-button
            color="primary"
            [matMenuTriggerFor]="filterMenu"
            [style]="filterStyle"
            matTooltip="Filtro específico"
        >
            <fa-icon style="font-size: 22px" [icon]="faFilter"></fa-icon>
        </button>
        <button
            title="filter_clear_button"
            *ngIf="showCleanFilter"
            mat-flat-button
            color="primary"
            [style]="cleanFilterStyle"
            matTooltip="Limpiar filtro"
            (click)="clearFilter()"
        >
            <img src="assets/img/filter-off.svg" width="25" height="25" />
        </button>
        <button
            title="calendar_button"
            *ngIf="showCalendar && !utilsService.isClientUser()"
            mat-flat-button
            color="primary"
            style="margin-top: 15px; margin-right: 15px; background-color: transparent"
            matTooltip="Abrir calendario"
            [matMenuTriggerFor]="showCalendarMenu"
        >
            <fa-icon style="font-size: 22px" [icon]="faCalendarDays"></fa-icon>
        </button>
        <mat-menu #showCalendarMenu="matMenu">
            <button mat-menu-item (click)="openCalendar('user_week')">
                <fa-icon class="fa-icon-column" [icon]="faUserClock"></fa-icon>
                <span style="margin-bottom: 10px">Abrir semana de operario</span>
            </button>
            <button mat-menu-item (click)="openCalendar('calendar_dates')">
                <fa-icon class="fa-icon-column" [icon]="faCalendarDays"></fa-icon>
                <span style="margin-bottom: 10px">Abrir calendario de citas</span>
            </button>
        </mat-menu>
        <mat-menu #filterMenu="matMenu">
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Sin revisar')">
                <fa-icon class="fa-icon-column" [icon]="faMobile"></fa-icon>
                <span style="margin-bottom: 10px">Sin revisar</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Mal localizadas')">
                <fa-icon class="fa-icon-column" [icon]="faCircleQuestion"></fa-icon>
                <span style="margin-bottom: 10px">Mal localizadas</span>
            </button>
            <button mat-menu-item (click)="filter('Dirección')">
                <fa-icon class="fa-icon-column" [icon]="faHome"></fa-icon>
                <span style="margin-bottom: 10px">Dirección</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Perímetro')">
                <fa-icon class="fa-icon-column" [icon]="faDrawPolygon"></fa-icon>
                <span style="margin-bottom: 10px">Perímetro</span>
            </button>
            <button mat-menu-item (click)="filter('Teléfono correcto')">
                <fa-icon class="fa-icon-column" [icon]="faHeadset"></fa-icon>
                <span style="margin-bottom: 10px">Teléfono correcto</span>
            </button>
            <button mat-menu-item (click)="filter('Detalle extra planificación')">
                <fa-icon class="fa-icon-column" [icon]="faListCheck"></fa-icon>
                <span style="margin-bottom: 10px">Extra planificación</span>
            </button>
            <button mat-menu-item (click)="filter('Incidencias')">
                <fa-icon class="fa-icon-column" [icon]="faCircleExclamation"></fa-icon>
                <span style="margin-bottom: 10px">Incidencias en android</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Causa Origen')">
                <fa-icon class="fa-icon-column" [icon]="faAsterisk"></fa-icon>
                <span style="margin-bottom: 10px">Causa Origen</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Titular')">
                <fa-icon class="fa-icon-column" [icon]="faHouseUser"></fa-icon>
                <span style="margin-bottom: 10px">Titular</span>
            </button>
            <button mat-menu-item (click)="filter('Abonado')">
                <fa-icon class="fa-icon-column" [icon]="faUserCog"></fa-icon>
                <span style="margin-bottom: 10px">Abonado</span>
            </button>
            <button mat-menu-item (click)="filter('Servicio')">
                <fa-icon class="fa-icon-column" [icon]="faToolbox"></fa-icon>
                <span style="margin-bottom: 10px">Servicio</span>
            </button>
            <button mat-menu-item (click)="filter('Suministros')">
                <fa-icon class="fa-icon-column" [icon]="faTools"></fa-icon>
                <span style="margin-bottom: 10px">Suministros</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Equipo')">
                <fa-icon class="fa-icon-column" [icon]="faUsers"></fa-icon>
                <span style="margin-bottom: 10px">Equipo</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Operario')">
                <fa-icon class="fa-icon-column" [icon]="faUserGroup" style="margin-left: 3px !important"></fa-icon>
                <span style="margin-bottom: 10px; margin-left: 2px !important">Operarios</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('last_modification_operator_uid')">
                <fa-icon class="fa-icon-column" [icon]="faIdBadge" style="margin-left: 3px !important"></fa-icon>
                <span style="margin-bottom: 10px; margin-left: 2px !important">Operario modificador</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('ultima_modificacion')">
                <fa-icon class="fa-icon-column" [icon]="faUser" style="margin-left: 3px !important"></fa-icon>
                <span style="margin-bottom: 10px; margin-left: 2px !important">Último modificador</span>
            </button>
            <button mat-menu-item (click)="filter('Serie')">
                <fa-icon class="fa-icon-column" [icon]="faBarcode" style="margin-left: 0.5px !important"></fa-icon>
                <span style="margin-bottom: 10px">Serie</span>
            </button>
            <button mat-menu-item (click)="filter('seriedv')">
                <fa-icon class="fa-icon-column" [icon]="faBarcode" style="margin-left: 0.5px !important"></fa-icon>
                <span style="margin-bottom: 10px">Serie devuelta</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Código de emplazamiento')">
                <fa-icon class="fa-icon-column" [icon]="faMapMarkedAlt"></fa-icon>
                <span style="margin-bottom: 10px">Código de emplazamiento</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('Sector P')">
                <fa-icon class="fa-icon-column" [icon]="faMap"></fa-icon>
                <span style="margin-bottom: 10px">Sector P</span>
            </button>
            <button mat-menu-item (click)="filter('telefono1')">
                <fa-icon class="fa-icon-column" [icon]="faPhoneAlt"></fa-icon>
                <span style="margin-bottom: 10px">Teléfono 1</span>
            </button>
            <button mat-menu-item (click)="filter('telefono2')">
                <fa-icon class="fa-icon-column" [icon]="faPhoneAlt"></fa-icon>
                <span style="margin-bottom: 10px">Teléfono 2</span>
            </button>
            <button *ngIf="!utilsService.isClientUser()" mat-menu-item (click)="filter('planning')">
                <fa-icon class="fa-icon-column" [icon]="faCalendarCheck"></fa-icon>
                <span style="margin-bottom: 10px">Planificación</span>
            </button>
            <button mat-menu-item (click)="filter('resultado')">
                <fa-icon class="fa-icon-column" [icon]="faCheckCircle"></fa-icon>
                <span style="margin-bottom: 10px">Resultado</span>
            </button>
            <button mat-menu-item (click)="filter('prioridad')">
                <fa-icon class="fa-icon-column" [icon]="faSortNumericAsc"></fa-icon>
                <span style="margin-bottom: 10px">Prioridad</span>
            </button>
            <button mat-menu-item (click)="filter('TIPORDEN')">
                <fa-icon class="fa-icon-column" [icon]="faShoppingCart"></fa-icon>
                <span style="margin-bottom: 10px">Tipo de Orden</span>
            </button>

            <button mat-menu-item (click)="filter('Fecha')">
                <fa-icon class="fa-icon-column" [icon]="faCalendar"></fa-icon>
                <span style="margin-bottom: 10px">Fecha</span>
            </button>
        </mat-menu>

        <mat-form-field [style]="inputStyle">
            <mat-label style="color: rgb(199, 199, 199)">Buscar</mat-label>
            <input
                matInput
                formControlName="search_value"
                color="accent"
                placeholder="Buscar en tabla..."
            />
        </mat-form-field>
        
        <button
            *ngIf="utilsService.isNotificationsEnable()"
            title="notifications_button"
            mat-icon-button
            style="margin-top: 10px; margin-right: 10px; background-color: transparent"
            matTooltip="Notificaciones"
            (click)="showNotifications()"
        >
            <mat-icon 
                [matBadge]="notificationCount" 
                matBadgeColor="warn"
                [matBadgeHidden]="notificationCount < 1"
                style="font-size: 30px; margin-top: -3px; margin-left: -6px;"
            >notifications
            </mat-icon>
        </button>

        <button
            mat-icon-button
            [style]="buttonStyle"
            aria-label="User image"
            [matMenuTriggerFor]="userMenu"
        >
            <img
                *ngIf="userImageUrl"
                class="animate__animated animate__fadeIn animate__slower"
                [src]="userImageUrl"
                onerror="this.onerror=null; this.src='assets/img/noimage.png'"
                style="border-radius: 50%;"
                width="45"
                height="45"
                alt="user image"
                [matTooltip]="utilsService.getLoggedInUserName()"
            />
            <mat-icon *ngIf="!userImageUrl" [style]="iconStyle">account_circle</mat-icon>
        </button>
        <mat-menu #userMenu="matMenu">
            <button
                mat-menu-item
                (click)="selectCompanyAndGestor()"
                [matTooltip]="utilsService.getCompanyAndManagerText()"
            >
                <fa-icon class="fa-icon-column" [icon]="faBuilding"></fa-icon>
                <span style="margin-bottom: 10px">Empresa y gestor</span>
            </button>
            <button mat-menu-item (click)="logout()">
                <fa-icon class="fa-icon-column" [icon]="faSignOutAlt"></fa-icon>
                <span style="margin-bottom: 10px">Cerrar sesión</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>
