/**
 * Created by Ing. Luis Alejandro Reyes Morales on 10/11/2024.
 *
 * email: inglreyesm@gmail.com
 * github: https://github.com/lreyesm
 * linkedin: https://linkedin.com/in/luis-alejandro-reyes-morales-9b672012a
 *
 */
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InformationDialogJsonData {
  title: string;
  text: string;
}

@Component({
  selector: 'app-information-json-dialog',
  templateUrl: './information-json-dialog.component.html',
  styleUrls: ['./information-json-dialog.component.scss']
})
export class InformationJsonDialogComponent implements OnInit {
    title?: string = 'Titulo';
    text?: string = '';

    constructor(@Inject(MAT_DIALOG_DATA) public data: InformationDialogJsonData) {
        this.title = data.title;
        this.text = data.text;
    }

    ngOnInit(): void {}
}
